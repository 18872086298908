import { AppBar, Button, Checkbox, Dialog, IconButton, TextField, Toolbar, Tooltip } from '@mui/material';
import React from 'react';
import { ApiSignupEvent } from '../../../../../../../../server/src/hrpcb/models/apiSignup';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import { HRPButton } from '../../../../components/widgets/Customized';
import { ArrowBackIos, ArrowForwardIos, Close, Delete, Today } from '@mui/icons-material';
import "./ModalAddSignup.scss";

interface ModalAddSignupProps {
    user: ApiUser
    open: ApiSignupEvent | null
    onSignupEvent: (
        newSignupEvent: ApiSignupEvent
    ) => void
    onClose: () => void
}

interface ModalAddSignupState {
    currentSignup: {
        count: number,
        name: string,
        days: string[],
        times: Array<{s: string, e:string}>
    }
    viewMonth: Date
}

function pad(num: number, amount?: number) {
    if (typeof amount === "undefined") {
        amount = 2;
    }
    let s = "" + num;
    while (s.length < amount) {
        s = "0" + s;
    }
    return s;
}

export default class ModalAddSignup extends React.Component<ModalAddSignupProps, ModalAddSignupState> {
    state: ModalAddSignupState = {
        currentSignup: this.resetVals(),
        viewMonth: new Date()
    }

    onState(field: "count" | "name", evt: any) {
        let newState: ModalAddSignupState = JSON.parse(JSON.stringify(this.state));
        if (field === "name") {
            newState.currentSignup[field] = evt.target.value;
        } else {
            newState.currentSignup[field] = parseInt(evt.target.value);
        }
        newState.viewMonth = new Date(newState.viewMonth);
        this.setState(newState);
    }

    resetVals() {
        return {
            count: 1,
            name: "",
            days: [],
            times: [{ s: "", e: ""}]
        }
    }

    isValid() {
        const cs = this.state.currentSignup;
        if (cs.count < 1) return false;
        if (cs.name.trim().length === 0) return false;
        if (cs.days.length === 0) return false;
        if (cs.times.filter(time => time.s !== "" || time.e !== "").length === 0) return false;
        return true;
    }

    async createSignups() {
        let event : ApiSignupEvent = JSON.parse(JSON.stringify(this.props.open!))
        const curS = this.state.currentSignup;
        for (const day of curS.days) {
            for (const time of curS.times) {
                if (time.s !== "" || time.e !== "") {
                    event.signups.push({
                        name: curS.name,
                        date: day,
                        startTime: time.s,
                        endTime: time.e,
                        count: curS.count,
                        remaining: curS.count,
                        claimed_by: {},
                        timeCreated: new Date().getTime()
                    })
                }
            }
        }

        await this.props.onSignupEvent(event);
        this.setState({
            currentSignup: this.resetVals()
        });
        this.props.onClose();
    }

    allSelected(vals: string[]) {
        let allFound = true;
        for (const val of vals) {
            if (!(this.state.currentSignup.days.includes(val))) {
                allFound = false;
            }
        }
        return allFound;
    }

    selectAll(vals: string[]) {
        let update = JSON.parse(JSON.stringify(this.state.currentSignup));
        update.days = update.days.filter((day: string) => !vals.includes(day)).concat(vals);
        this.setState({currentSignup: update});
    }

    deselectAll(vals: string[]) {
        let update = JSON.parse(JSON.stringify(this.state.currentSignup));
        update.days = update.days.filter((day: string) => !vals.includes(day));
        this.setState({currentSignup: update});
    }

    keyEvent(evt: React.KeyboardEvent) {
        const keyEvents : { [key: string] : () => void } = {
            "ArrowRight": () => {
                let d = new Date(this.state.viewMonth.getTime());
                d.setDate(d.getDate()+1);
                this.setState({ viewMonth: d });
            },
            "ArrowLeft": () => {
                let d = new Date(this.state.viewMonth.getTime());
                d.setDate(d.getDate()-1);
                if (padYYYYMMDD(new Date()).localeCompare(padYYYYMMDD(d)) <= 0) {
                    this.setState({ viewMonth: d });
                }
            },
            "ArrowUp": () => {
                let d = new Date(this.state.viewMonth.getTime());
                d.setDate(d.getDate()-7);
                if (padYYYYMMDD(new Date()).localeCompare(padYYYYMMDD(d)) <= 0) {
                    this.setState({ viewMonth: d });
                }
            },
            "ArrowDown": () => {
                let d = new Date(this.state.viewMonth.getTime());
                d.setDate(d.getDate()+7);
                this.setState({ viewMonth: d });
            },
            "Enter": () => {
                let update = JSON.parse(JSON.stringify(this.state.currentSignup));
                const val = dateYYYYMMDD(this.state.viewMonth);
                if (update.days.includes(val)) {
                    update.days = update.days.filter((day: string) => day !== val);
                } else {
                    update.days.push(val);
                }
                this.setState({currentSignup: update});
            },
            " ": () => {
                let update = JSON.parse(JSON.stringify(this.state.currentSignup));
                const val = dateYYYYMMDD(this.state.viewMonth);
                if (update.days.includes(val)) {
                    update.days = update.days.filter((day: string) => day !== val);
                } else {
                    update.days.push(val);
                }
                this.setState({currentSignup: update});
            }
        }
        if (evt.key in keyEvents) {
            evt.preventDefault();
            keyEvents[evt.key]();
        }
    }

    render() {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate()-1);
        const curS = this.state.currentSignup;
        let calArray = getCalArray(this.state.viewMonth);
        let dateParts = this.state.viewMonth.toDateString().split(" ");
        let genChkCol = (idx: number) => {
            let vals : string[] = []
            for (const row of calArray) {
                if (row[idx].length > 0) {
                    vals.push(row[idx]);
                }
            }
            const dow = [
                "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
            ]
            return <Checkbox 
                style={{paddingRight: "0px"}} 
                color="primary" 
                aria-label={`Select all ${dow[idx]}`}
                checked={this.allSelected(vals)}
                onClick={() => {
                    if (this.allSelected(vals)) {
                        this.deselectAll(vals);
                    } else {
                        this.selectAll(vals);
                    }
                }}
            />
        }
        let genChkRow = (row: string[]) => {
            let vals = row.filter(cell => cell.length > 0);
            return <Checkbox 
                style={{paddingRight: "0px"}} 
                color="primary" 
                checked={this.allSelected(vals)}
                onClick={() => {
                    if (this.allSelected(vals)) {
                        this.deselectAll(vals);
                    } else {
                        this.selectAll(vals);
                    }
                }}
            />
        }

        this.state.currentSignup.days.sort((a, b) => {
            return new Date(a).getTime()-new Date(b).getTime();
        })
        return (
            <Dialog
                open={!!this.props.open}
                maxWidth="md"
                fullWidth={true}
                fullScreen={true}
            >
                <AppBar>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={()=>{
                                this.setState({
                                    currentSignup: this.resetVals()
                                });
                                this.props.onClose();
                            }}
                            aria-label="Close"
                            size="large">
                            <Close />
                        </IconButton>
                        <div style={{flex: 1}}>Add signups</div>
                        <Button 
                            color="inherit" 
                            disabled={!this.isValid()}
                            onClick={() => {
                                this.createSignups();
                            }}
                        >
                            Add
                        </Button>
                    </Toolbar>
                </AppBar>
                <div style={{ marginTop: "6rem" }} />
                <div style={{ margin: "0rem 1.5rem" }}>
                    <h2>1. What is this signup for?</h2>
                    <div style={{ marginTop: "1.5rem" }} />
                    <div style={{ display: "flex" }}>
                        <TextField
                            style={{marginRight: "1rem"}}
                            label="People needed"
                            type="number"
                            value={curS.count}
                            onChange={this.onState.bind(this, "count")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            autoFocus
                            placeholder="Ex: 'Check-in', 'Concessions', 'Game booth'"
                            style={{flex: 1}}
                            label="Position name"
                            value={curS.name}
                            onChange={this.onState.bind(this, "name")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div style={{ marginTop: "2.5rem" }} />
                    <h2>2. What day(s) are people needed?</h2>
                    <div style={{ marginTop: "0.5rem" }} />
                    <table className="signupCalendar">
                        <thead>
                            <tr>
                                <td></td>
                                <td style={{textAlign: "center"}}>
                                    {
                                        (this.state.viewMonth.getFullYear() !== today.getFullYear() ||
                                        this.state.viewMonth.getMonth() !== today.getMonth()) &&
                                    <Tooltip placement="top" title="Prev month">
                                        <IconButton size="small" aria-label="prev month" onClick={(async () => {
                                            let viewTS = new Date(this.state.viewMonth.getTime());
                                            viewTS.setDate(1);
                                            viewTS.setMonth(viewTS.getMonth()-1);
                                            this.setState({viewMonth: viewTS});
                                        })} >
                                            <ArrowBackIos style={{color: "#2F5043"}} />
                                        </IconButton>
                                    </Tooltip>
                                    }
                                </td>
                                <td style={{textAlign: "center"}} colSpan={5}>
                                    <Tooltip placement="top" title="Today">
                                        <IconButton size="small" aria-label="today" onClick={(async () => {
                                            let viewTS = new Date();
                                            this.setState({viewMonth: viewTS});
                                        })} >
                                            <Today style={{color: "#2F5043"}} />
                                        </IconButton>
                                    </Tooltip><span style={{fontSize:"1.25em"}}>{dateParts[1]} {dateParts[3]}</span>
                                </td>
                                <td style={{textAlign: "center"}}>
                                    <Tooltip placement="top" title="Next month">
                                        <IconButton size="small" aria-label="next month" onClick={(async () => {
                                            let viewTS = new Date(this.state.viewMonth.getTime());
                                            viewTS.setDate(1);
                                            viewTS.setMonth(viewTS.getMonth()+1);
                                            this.setState({viewMonth: viewTS});
                                        })} >
                                            <ArrowForwardIos style={{color: "#2F5043"}} />
                                        </IconButton>
                                    </Tooltip>
                                </td>
                            </tr>
                        </thead>
                        <tbody onKeyDown={(evt: React.KeyboardEvent) => {
                            this.keyEvent(evt);
                        }} tabIndex={0}>
                          {/* aria-activedescendant={`d_${dateYYYYMMDD(this.state.viewMonth)}`} */}
                            <tr>
                                <td></td>
                                <td style={{textAlign: "right"}}>{genChkCol(0)}</td>
                                <td style={{textAlign: "right"}}>{genChkCol(1)}</td>
                                <td style={{textAlign: "right"}}>{genChkCol(2)}</td>
                                <td style={{textAlign: "right"}}>{genChkCol(3)}</td>
                                <td style={{textAlign: "right"}}>{genChkCol(4)}</td>
                                <td style={{textAlign: "right"}}>{genChkCol(5)}</td>
                                <td style={{textAlign: "right"}}>{genChkCol(6)}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <th scope="col" style={{textAlign: "right"}}>Sun</th>
                                <th scope="col" style={{textAlign: "right"}}>Mon</th>
                                <th scope="col" style={{textAlign: "right"}}>Tues</th>
                                <th scope="col" style={{textAlign: "right"}}>Wed</th>
                                <th scope="col" style={{textAlign: "right"}}>Thurs</th>
                                <th scope="col" style={{textAlign: "right"}}>Fri</th>
                                <th scope="col" style={{textAlign: "right"}}>Sat</th>
                            </tr>
                            { calArray.map((row) => (
                                <tr>
                                    <td>{genChkRow(row)}</td>
                                    { row.map((val) => {
                                        
                                        if (val.length === 0 || new Date(val) < yesterday) {
                                            return <td className="calDay inactive">{ val.length > 0 && val.split("-")[2] }</td>
                                        } else if (this.state.currentSignup.days.includes(val)) {
                                            // Selected
                                            return <td id={`d_${val}`} 
                                                className={dateYYYYMMDD(this.state.viewMonth) === val ? "calDay curFocus selected" : "calDay selected"} 
                                                onClick={() => {
                                                    let update = JSON.parse(JSON.stringify(this.state.currentSignup));
                                                    update.days = update.days.filter((day: string) => day !== val);
                                                    let d = new Date(val);
                                                    this.setState({
                                                        currentSignup: update,
                                                        viewMonth: d
                                                    });
                                                }}
                                            >
                                                { val.split("-")[2] }
                                            </td>
                                        } else {
                                            return <td id={`d_${val}`}
                                                className={dateYYYYMMDD(this.state.viewMonth) === val ? "calDay curFocus unselected" : "calDay unselected"} 
                                                onClick={() => {
                                                    let update = JSON.parse(JSON.stringify(this.state.currentSignup));
                                                    update.days.push(val);
                                                    let d = new Date(val);
                                                    this.setState({
                                                        currentSignup: update,
                                                        viewMonth: d
                                                    });
                                                }}
                                            >
                                                { val.split("-")[2] }
                                            </td>
                                        }
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {this.state.currentSignup.days.length > 0 && <>
                        <div style={{marginTop: "1rem"}}><strong>Selected days</strong></div>
                        {this.state.currentSignup.days.map((day) => (
                            <div>{new Date(day).toDateString()}</div>
                        ))}
                    </>}
                    <div style={{ marginTop: "2.5rem" }} />
                    <h2>3. What time(s) are people needed on those days?</h2>
                    <div style={{ marginTop: "1.5rem" }} />
                    { this.state.currentSignup.times.map((time: {s: string, e: string}, index: number) => (
                        <div style={{marginBottom: "1rem"}}>
                            <TextField
                                style={{ marginRight: "1rem" }}
                                label="Start"
                                type="time"
                                value={ time.s }
                                onChange={(evt) => {
                                    let update = JSON.parse(JSON.stringify(this.state.currentSignup));
                                    update.times[index].s = evt.target.value;
                                    let last = update.times.length-1;
                                    if (update.times[last].s !== "" || update.times[last].e !== "") {
                                        update.times.push({ s: "", e: ""});
                                    }
                                    this.setState({currentSignup: update});
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }} 
                            />
                            <TextField
                                style={{ marginRight: "1rem" }}
                                label="End"
                                type="time"
                                value={ time.e }
                                onChange={(evt) => {
                                    let update = JSON.parse(JSON.stringify(this.state.currentSignup));
                                    update.times[index].e = evt.target.value;
                                    let last = update.times.length-1;
                                    if (update.times[last].s !== "" || update.times[last].e !== "") {
                                        update.times.push({ s: "", e: ""});
                                    }
                                    this.setState({currentSignup: update});
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }} 
                            />
                            { (time.s !== "" || time.e !== "") && <Tooltip placement="top" title="Delete">
                                <IconButton style={{marginTop: "1rem"}} size="small" aria-label="delete" onClick={(async () => {
                                    let update = JSON.parse(JSON.stringify(this.state.currentSignup));
                                    update.times.splice(index, 1);
                                    this.setState({currentSignup: update});
                                })} >
                                    <Delete style={{color: "#2F5043"}} />
                                </IconButton>
                            </Tooltip> }
                        </div>
                    ))}

                    <div style={{marginTop: "2.5rem"}}></div>
                    <Button sx={HRPButton} variant="contained" color="primary"
                        disabled={!this.isValid()}
                        style={{width: "inherit", marginRight: "1rem" }} 
                        onClick={() => {
                            this.createSignups();
                        }}
                    >
                        Add
                    </Button>
                    <Button
                        style={{width: "inherit" }} 
                        onClick={() => {
                            this.setState({
                                currentSignup: this.resetVals()
                            });
                            this.props.onClose();
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </Dialog>
        );
    }
}

function dateYYYYMMDD(d: Date) {
    return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`;
}

function padYYYYMMDD(d: Date) {
    return `${d.getFullYear()}-${pad(d.getMonth()+1)}-${pad(d.getDate())}`;
}

function getCalArray(d: Date) {
    let monStart = new Date(d.getTime());
    let monEnd = new Date(d.getTime());
    monStart.setDate(1);
    monEnd.setMonth(monEnd.getMonth()+1);
    monEnd.setDate(0);
    let monDays = monEnd.getDate()+monStart.getDay();
    let weeks = Math.ceil(monDays/7);
    let calDays = weeks * 7;
    let iterCal = new Date(d.getTime());
    iterCal.setDate(1-monStart.getDay());
    let retVal: string[][] = [];
    let nextRow: string[] = [];
    while (calDays > 0) {
        --calDays;
        if (iterCal.getMonth() === d.getMonth()) {
            nextRow.push(dateYYYYMMDD(iterCal));
        } else {
            nextRow.push("");
        }
        iterCal.setDate(iterCal.getDate()+1);
        if (nextRow.length === 7) {
            retVal.push(nextRow);
            nextRow = [];
        }
    }
    return retVal;
}




