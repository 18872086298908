import React from 'react';

import "./NewHomeroom.scss";
import { Alert, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

import { eMemberTitle, eMemberRole, ApiMember, GradeType } from "../../../../../../../server/src/hrpcb/models/apiHomeroom";
import HRPTable, { HRPTableHeadCell } from '../../../components/widgets/HRPTable';
import ModalAddMember from './ModalAddMember';
import ModalEditMember from './ModalEditMember';
import Nls from '../../../nls/nls';
export interface IMember {
    name: string,
    email: string,
    title: eMemberTitle
}

export interface HomeroomInfoState {
    grade: GradeType,
    memberInfos: ApiMember[]
}

interface HomeroomInfoProps {
    dialog?: boolean,
    initState: HomeroomInfoState,
    onStateChange: (info: HomeroomInfoState) => void
}

interface HomeroomInfoStateUI extends HomeroomInfoState {
    editMember: ApiMember | null
    dialogAdd: boolean
}

function titleToRole(title: eMemberTitle) : eMemberRole {
    if (title === "Parent/Guardian") return "READER";
    return "ADMIN";
}

export default class HomeroomInfo extends React.Component<HomeroomInfoProps, HomeroomInfoStateUI> {
    state: HomeroomInfoStateUI = {
        grade: (this.props?.initState?.grade) || (Nls.get("URL_PART") === "club" && "NA") || "K",
        memberInfos: (this.props.initState && JSON.parse(JSON.stringify(this.props.initState.memberInfos))) || [],
        editMember: null,
        dialogAdd: false
    }

    // componentDidMount() {
    // }

    onChangeValue(field: string, _evt: any, value: any) {
        let stateChange: {
            [key: string]: string
        } = {};
        stateChange[field] = value;
        this.props.onStateChange(stateChange as any);
        this.setState(stateChange as any);
    }

    // onStateChange(field: string, evt: any) {
    //     let stateChange: {
    //         [key: string]: any
    //     } = {};
    //     stateChange[field] = evt.target.value;
    //     if (field === "grade") {
    //         this.props.onStateChange(stateChange as any);
    //     }
    //     this.setState(stateChange as any);
    // }

    onMember(member: IMember) {
        let found = false;
        for (const teacherInfo of this.state.memberInfos) {
            if (teacherInfo.name === member.name && teacherInfo.email === member.email) {
                found = true;
            }
        }
        if (!found) {
            let role : eMemberRole = titleToRole(member.title);
            this.state.memberInfos.push({ userId: "", role: role, ...member });
            this.props.onStateChange({ memberInfos: this.state.memberInfos } as any);
            this.setState({ memberInfos: this.state.memberInfos });
        }
    }

    onReplaceMember(oldMember: ApiMember, newMember: IMember) {
        for (const memberInfo of this.state.memberInfos) {
            if (memberInfo.userId === oldMember.userId) {
                memberInfo.name = newMember.name;
                memberInfo.email = newMember.email;
                memberInfo.title = newMember.title;
                memberInfo.role = titleToRole(newMember.title);
            }
        }
        this.props.onStateChange({ memberInfos: this.state.memberInfos } as any);
        this.setState({ memberInfos: this.state.memberInfos, editMember: null });
    }

    render() {
        let headings : HRPTableHeadCell[] = [
            { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
            { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
            { id: 'title', numeric: false, disablePadding: false, label: 'Type' }
        ]
        let titleMap : { [key: string]: string } = Nls.get("URL_PART") === "club" ? {
            "Homeroom Parent" : "Communications Chair",
            "School Representative": "Club Representative",
            "Teacher": "President/Coach",
            "Parent/Guardian": "Members/Team"
        } : {
            "Homeroom Parent" : "Homeroom Parent",
            "School Representative": "School Representative",
            "Teacher": "Teacher",
            "Parent/Guardian": "Parent/Guardian"
        };
        let rows : { id: string, name: string, email: string, title: string }[] = 
            this.state.memberInfos.map(member => ({
                id: member.userId,
                name: member.name,
                email: member.email,
                title: member.title in titleMap ? titleMap[member.title] : member.title
            }));
        let grades = [
            { label: "Pre-K", value: "PK" },
            { label: "K", value: "K" },
            { label: "1st", value: "1" },
            { label: "2nd", value: "2" },
            { label: "3rd", value: "3" },
            { label: "4th", value: "4" },
            { label: "5th", value: "5" },
            { label: "6th", value: "6" },
            { label: "7th", value: "7" },
            { label: "8th", value: "8" },
            { label: "9th", value: "9" },
            { label: "10th", value: "10" },
            { label: "11th", value: "11" },
            { label: "12th", value: "12" },
            { label: "N/A", value: "NA" }
        ]

        return <div className="gradeInfo">
            <div className={!this.props.dialog ? "hrpRow" : undefined}>
                <div className={!this.props.dialog ? "hrpCol-skip-1-desk hrpCol-4-mob hrpCol-12-desk" : undefined}>
                    <FormControl className="nhrFormControl" component="fieldset">
                        <FormLabel component="legend">Grade Level</FormLabel>
                        <RadioGroup aria-label="position" name="position" value={this.state.grade} onChange={this.onChangeValue.bind(this, "grade")} row>
                            {grades.map(ginfo => <FormControlLabel
                                key={ginfo.value} 
                                value={ginfo.value}
                                control={<Radio color="primary" />}
                                label={ginfo.label}
                                labelPlacement="top"
                            />)}
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <div style={{marginTop: "1.5rem"}}></div>
            { Nls.get("URL_PART") === "hrp" && !this.state.memberInfos.some(member => member.title === "Teacher") && (
                <div className={!this.props.dialog ? "hrpRow" : undefined}>
                    <div className={!this.props.dialog ? "hrpCol-skip-1-desk hrpCol-4-mob hrpCol-6-tab hrpCol-8-desk" : undefined }>
                        <div style={{margin: "1.5rem -1rem"}}>
                            <Alert severity="warning">
                                {Nls.get("ADD_STEP2_ALERT")}
                                <div role="link" className="link" style={{textAlign: "right"}} onClick={() => { this.setState({dialogAdd: true}) }}>{Nls.get("ADD_STEP2_ALERT_BUTTON")}</div>
                            </Alert>
                        </div>
                    </div>
                </div>
            )}
            <div className={!this.props.dialog ? "hrpRow" : undefined}>
                <div className={!this.props.dialog ? "hrpCol-skip-1-desk hrpCol-4-mob hrpCol-6-tab hrpCol-8-desk" : undefined }>
                    <div style={{margin: "0rem -1rem"}}>
                        <HRPTable 
                            title={`${Nls.get("SELECTOR_LABEL")} members`} 
                            headCells={headings} 
                            rows={rows} 
                            emptyContent={<></>}
                            onAdd={() => { this.setState({dialogAdd: true}) }}
                            onDelete={(userIds: string[]) => { 
                                let memberInfos = this.state.memberInfos.filter((member) => {
                                    return !userIds.includes(member.userId);
                                })
                                this.setState({memberInfos: memberInfos})
                            }}
                            onEdit={(userId: string) => {
                                let editMember = this.state.memberInfos.filter((member) => member.userId === userId)[0];
                                this.setState({editMember: editMember});
                            }}
                        />
                    </div>
                </div>
            </div>

            <ModalAddMember
                open={this.state.dialogAdd} 
                onMember={(member: IMember) => {this.onMember(member)}} 
                onClose={() => {this.setState({dialogAdd: false})} }
            />
            <ModalEditMember 
                editMember={this.state.editMember} 
                onMember={(member: IMember) => {
                    this.onReplaceMember(this.state.editMember!, member)}
                } 
                onClose={() => {
                    this.setState({ editMember: null })} 
                }/>
        </div>;
    }
}