import React, { ReactElement, useState } from 'react';
import { Loading } from "@carbon/react";
import UserV1 from "../services/UserV1";
import { IUserBasic } from '../models/IUser';

export function ProtectLogin({ children, roles } : {children: ReactElement, roles?: string[]}) {
    const [user, setUser] = useState<IUserBasic | undefined>(undefined);
    const [isInit, setInit] = useState<boolean>(false);
    UserV1.addUserListener((newUser) => {
        setUser(newUser);
        setInit(true);
    })
    UserV1.getUser().then(newUser => {
        if (JSON.stringify(newUser) !== JSON.stringify(user)) {
            setUser(newUser);
        }
        setInit(true);
    });
    if (!isInit) {
        return <Loading />
    } else if (user) {
        if (!roles || roles.length === 0) {
            // Just need login, no roles required
            return children;
        } else if (user?.user_type && user.user_type.filter(role => roles!.includes(role)).length > 0) {
            // The user has one of the specified roles
            return children;
        } else {
            // Role required, but user does not have that role
            document.location.href=`/unauthorized`;
        }
    } else {
        document.location.href=`/login?redirect=${encodeURIComponent(document.location.href)}`;
    }
    return <></>;
}
