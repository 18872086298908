import React from 'react';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import HRCalendar from '../../../../components/calendar/HRCalendar';
import { ApiHomeroomClub } from '../../../../../../../../server/src/hrpcb/models/apiHomeroom';
import "./SectionCalendar.scss";
import HomeroomService from '../../../../services/HomeroomService';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip } from '@mui/material';
import { ExpandMore, PostAdd, Event } from '@mui/icons-material';
import Tile from '../../../../components/widgets/Tile';
import DialogAddCal from '../../../../components/calendar/DialogAddCal';
import InfoButton from '../../../../components/info/InfoButton';
import InfoCalendar from '../../../../components/info/InfoCalendar';


interface SectionCalendarMobileProps {
    user?: ApiUser
    homerooms: ApiHomeroomClub[]
    expanded: boolean,
    onToggle: () => void,
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
}

export function SectionCalendarMobile(props: SectionCalendarMobileProps) {
    return <SectionCalendarClass {...props} />
}

interface SectionCalendarDesktopProps {
    user?: ApiUser | null
    homerooms: ApiHomeroomClub[]
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
}

export function SectionCalendarDesktop(props: SectionCalendarDesktopProps) {
    return <SectionCalendarClass {...props} />
}

interface SectionCalendarState {
    dialogAdd: boolean
}

interface SectionCalendarClassProps {
    user?: ApiUser | null
    homerooms: ApiHomeroomClub[]
    expanded?: boolean,
    onToggle?: () => void,
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
}

export default class SectionCalendarClass extends React.Component<SectionCalendarClassProps, SectionCalendarState> {
    state : SectionCalendarState = {
        dialogAdd: false
    }

    render() {
        const mobile = typeof this.props.expanded !== "undefined"

        let editableHomerooms = (this.props.homerooms || []).filter((homeroom) => {
            return HomeroomService.canEdit(homeroom, this.props.user!);
        });

        const sectionHeader = <div className="sectionTitle">
            <h2 style={{marginRight: "1rem"}}>
                <Event /><span style={{verticalAlign: "top", marginLeft: ".5rem"}}>Calendars</span>
            </h2>
            <InfoButton style={{marginLeft: "-1rem"}} title="Calendar Info" showLogin={!this.props.user}>
                <InfoCalendar />
            </InfoButton>
                
            { HomeroomService.canEditAny(this.props.homerooms, this.props.user) && <>
                <Tooltip placement="top" title="Add calendar">
                    <IconButton
                        color={mobile ? undefined : "primary"}
                        style={{ width: "inherit", float: "right" }}
                        onClick={(evt) => { 
                            evt.stopPropagation();
                            this.setState({dialogAdd: true});
                        }}
                        size="large"><PostAdd className="homeIconButton" />
                    </IconButton>
                </Tooltip>
                <div style={{clear: "both"}}></div>
            </>
            }
        </div>;

        const sectionBody = <div style={{margin: "0rem -1rem"}}>
            <HRCalendar onHomerooms={this.props.onHomerooms} canEdit={editableHomerooms.length > 0} homerooms={this.props.homerooms} user={this.props.user} />
        </div>;

        const sectionDialog = <>
            {editableHomerooms.length > 0 && <>                
                <DialogAddCal
                    homerooms={editableHomerooms}
                    open={this.state.dialogAdd}
                    onClose={(async (res?: ApiHomeroomClub) => {
                        if (res) {
                            for (let idx=0; idx<this.props.homerooms.length; ++idx) {
                                if (this.props.homerooms[idx]._id === res._id) {
                                    this.props.homerooms[idx] = res;
                                }
                            }
                            this.props.onHomerooms && (await this.props.onHomerooms(this.props.homerooms));
                        }
                        this.setState({
                            dialogAdd: false
                        })
                    })}
                />
                </>
            }
        </>;

        if (typeof this.props.expanded !== "undefined") {
            return <Accordion
                square
                expanded={this.props.expanded}
                onChange={() => {
                    this.props.onToggle!();
                }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    {sectionHeader}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{width: "100%"}}>  
                        {sectionBody}
                    </div>
                </AccordionDetails>
                {sectionDialog}
            </Accordion>
        } else {
            return <Tile dark>
                {sectionHeader}
                {sectionBody}
                {sectionDialog}
            </Tile>
        }
    }
}