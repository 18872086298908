import React from 'react';
import Nls from '../../nls/nls';

interface InfoNeedsProps {
}

export default function InfoNeeds(_props: InfoNeedsProps) {

    if (Nls.get("URL_PART") === "hrp") {
        return <>
            <p>This is where you can
list various items that might be wanted by the teacher or the classroom.</p>
<ul>
    <li>Open the Add wish dialog.</li>
    <li>Choose a category, an item name, a URL to the item, and a quantity</li>
</ul>
<p>For example, you might list a link to an Amazon item for a box of
Kleenex.</p>
<p>Then, parents can quickly find specific items that are needed and
have them ordered. The should click the "Claim" icon for the
quantity that they have ordered to let the homeroom parent know
who has purchased it.</p>
        </>;
    } else {
        return <>
            <p>This is where you can list various items that might be wanted for the club.</p>
<ul>
    <li>Open the Add wish dialog.</li>
    <li>Choose a category, an item name, a URL to the item, and a quantity</li>
</ul>
<p>For example, you might list a link to an Amazon item for a box of
Kleenex.</p>
<p>Then, parents can quickly find specific items that are needed and
have them ordered. The should click the "Claim" icon for the
quantity that they have ordered to let the club administrators know
who has purchased it.</p>
        </>;
    }
}
