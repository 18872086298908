import React from 'react';

import "./Login.scss";

export default class PWResetSent extends React.Component<{location?:any}> {
    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (<>
            <main className="verifyLogin">
                <div className="hrpRow" style={{ marginTop: "0rem" }}>
                    <div className="hrpCol-4-mob hrpCol-8-tab hrpCol-12-desk">
                        <p>An email has been sent to verify your email address.
                        Please check your mail (and spam folder) to continue with
                        the password reset process</p>
                    </div>
                </div>
            </main>
        </>)
    }
}