import React from 'react';

import "./NewHomeroom.scss";
import { Autocomplete, FormControl, TextField, FormHelperText } from '@mui/material';
import Nls from '../../../nls/nls';

interface SchoolOption { 
    _id: string, 
    name: string 
}

export interface SelectSchoolState {
    zipCode: string,
    schoolId: string,
    schoolName: string,
    clubName: string,
    schoolOptions: SchoolOption[]
}

interface SelectSchoolStateUI extends SelectSchoolState {
    view: string,
}

interface SelectSchoolProps {
    initState: SelectSchoolState,
    onStateChange: (state: any) => void,
    onToggleWizard: (visible: boolean) => void
}

let zipPromise = new Promise<string>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition((position) => {
        fetch(`/api/hrpcb/util/zip_from_latlong?lat=${position.coords.latitude}&long=${position.coords.longitude}`)
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                resolve(result.zipCode);
            });
    });
})

export default class SelectSchool extends React.Component<SelectSchoolProps, SelectSchoolStateUI> {
    state: SelectSchoolStateUI = {
        view: "default",

        zipCode: this.props.initState.zipCode || "",
        schoolId: this.props.initState.schoolId || "",
        schoolName: this.props.initState.schoolName || "",
        clubName: this.props.initState.clubName || "",
        schoolOptions: this.props.initState.schoolOptions || []
    }

    componentDidMount() {
        zipPromise.then((zipCode: string) => {
            if (this.state.zipCode === "") {
                this.onChange("zipCode", { target: { value: zipCode }});
            }
        })
    }

    componentDidUpdate(prevProps: SelectSchoolProps) {
        if (prevProps.initState.schoolId !== this.props.initState.schoolId 
            || prevProps.initState.schoolName !== this.props.initState.schoolName) 
        {
            this.setState({ schoolId: this.props.initState.schoolId || "", schoolName: this.props.initState.schoolName || ""})
        }
    }
    // admins: UserID[],
    // students: Student[],
    // room: string,
    // lastDay: string

    // onChangeValue(field: string, _evt: any, value: any) {
    //     let stateChange: {
    //         [key: string]: string
    //     } = {};
    //     stateChange[field] = value;
    //     this.setState(stateChange as any);
    // }

    onChange(field: string, evt: any) {
        let stateChange: {
            [key: string]: any
        } = {};
        stateChange[field] = evt.target.value;
        if (field === "zipCode") {
            stateChange["schoolOptions"] = null;//[]
            if (stateChange.zipCode.match(/\d{5}/)) {
                fetch(`/api/hrpcb/school/by_zip/${stateChange.zipCode}`)
                    .then((result) => {
                        return result.json();
                    })
                    .then((result: SchoolOption[]) => {
                        result.sort((a: SchoolOption, b: SchoolOption) => {
                            return a.name.localeCompare(b.name);
                        })
                        for (const opt of result) {
                            let parts = opt.name.split(" ");
                            parts = parts.map(part => part.charAt(0).toUpperCase()+part.substring(1).toLowerCase());
                            opt.name = parts.join(" ");
                        }
                        this.setState({ schoolOptions: result })
                        this.props.onStateChange({ schoolOptions: result });
                    })
            }
        }
        this.props.onStateChange(stateChange as any);
        this.setState(stateChange as any);
    }

    lastkeypress = 0;
    searchStr = "";
    // onKeyDown(test: any) {
    //     let letter = ""+test.key.toLowerCase();
        
    //     // reset the search after one second
    //     if (new Date().getTime()-this.lastkeypress > 1000) {
    //         this.searchStr = "";
    //     }

    //     // Init this search
    //     this.lastkeypress = new Date().getTime();
    //     this.searchStr += letter;

    //     let sameLetter = this.searchStr.replace(new RegExp(letter, "g"), "").length === 0;
    //     let searchStr = sameLetter ? letter : this.searchStr;
    //     let matchingOptions = this.state.schoolOptions.filter((option) => {
    //         return option.name.toLowerCase().startsWith(searchStr);
    //     })
    //     // If the same letter and an option is selected, rotate the list so that the current option is last
    //     if (sameLetter && matchingOptions.some((option) => option._id === this.state.schoolId)) {
    //         while (matchingOptions[matchingOptions.length-1]._id !== this.state.schoolId) {
    //             matchingOptions.push(matchingOptions.shift() as SchoolOption);
    //         }
    //     }

    //     // If no options, do nothing
    //     if (matchingOptions && matchingOptions.length > 0) {
    //         this.props.onStateChange({schoolId: matchingOptions[0]._id, schoolName: matchingOptions[0].name });
    //         this.setState({schoolId: matchingOptions[0]._id});
    //     }
    // }

    curValue() {
        let opts = this.state.schoolOptions || []
        let items = opts.filter(option => this.state.schoolId === option._id);
        if (items.length > 0) {
            return items[0];
        } else {
            return {
                _id: "",
                name: this.state.schoolName
            }
        }
    }

    render() {
        let validZip = this.state.zipCode?.match(/\d{5}/);
        let idToNameMap : { [key: string] : string } = {}
        let nameToIdMap : { [key: string] : string } = {}
        let filtOpts = (this.state.schoolOptions || []);
        if (this.state.schoolName) {
            filtOpts = filtOpts
                .filter(opt => opt.name && this.state.schoolName && opt.name.toLowerCase().startsWith(this.state.schoolName.toLowerCase()));
        }

        for (const opt of filtOpts) {
            idToNameMap[opt._id] = opt.name;
            nameToIdMap[opt.name] = opt._id;
        }
        return <div className="hrpRow">
            <div className="hrpCol-skip-1-desk hrpCol-4-mob hrpCol-12-desk">
                { this.state.view === "default" && <>
                    { Nls.get("URL_PART") === "club" && <TextField style={{width: "18rem"}} autoFocus label="Club/booster name" value={this.state.clubName} onChange={this.onChange.bind(this, "clubName")} />}
                    { Nls.get("URL_PART") === "hrp" && <>
                        <TextField variant="standard" autoFocus label="Your Zip Code" value={this.state.zipCode} onChange={this.onChange.bind(this, "zipCode")} />
                        <div style={{marginTop: "1.5rem"}} />
                        <FormControl className="nhrFormControl nhrSchool" disabled={!validZip || !this.state.schoolOptions || this.state.schoolOptions.length === 0}>
                            <Autocomplete
                                disabled={!validZip}
                                freeSolo
                                options={filtOpts}
                                getOptionLabel={(option) => (typeof option !== "string" && option.name) || ""}
                                value={this.curValue()}
                                onChange={(_evt, newValue) => {
                                    if (newValue) {
                                        let id = (newValue as SchoolOption)._id;
                                        this.setState({ schoolId: id, schoolName: idToNameMap[id] });
                                        this.props.onStateChange({ schoolId: id, schoolName: idToNameMap[id]});
                                    } else {
                                        this.setState({ schoolId: "", schoolName: "" });
                                        this.props.onStateChange({ schoolId: "", schoolName: "" });
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField variant="standard" {...params} 
                                        autoFocus
                                        // variant="filled"
                                        label="Select a school or enter the school name" 
                                        onChange={(evt) => { 
                                            let name = evt.target.value;
                                            let id = nameToIdMap[name];
                                            this.setState( { schoolId: id, schoolName: idToNameMap[id] });
                                            this.props.onStateChange({ schoolId: id, schoolName: name});
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                            {!validZip && <FormHelperText>Provide your zip code to select a school</FormHelperText>}
                            {validZip && !this.state.schoolOptions && <FormHelperText>Loading...</FormHelperText>}
                            {/* {validZip && this.state.schoolOptions && this.state.schoolOptions.length === 0 && <FormHelperText>No schools found for this zip. Add one!</FormHelperText>} */}
                        </FormControl>
                    </>}
                </>}
            </div>
        </div>;
    }
}