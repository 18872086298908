import React from 'react';
import { ApiHomeroomClub, ApiWish } from '../../../../../../server/src/hrpcb/models/apiHomeroom';
import { ApiUser } from '../../../../../../server/src/hrpcb/models/apiUser';
import HomeroomLabel from '../../components/widgets/HomeroomLabel';
import HomeroomService from '../../services/HomeroomService';
import "./Wishlists.scss";
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from '@mui/material';
import ModalAddWish from '../../pages/auth/Homerooms/Wishes/ModalAddWish';
import TagService from '../../services/TagService';
import { Clear, AddShoppingCart, RemoveShoppingCart, ExpandMore } from '@mui/icons-material';
import Nls from '../../nls/nls';

function RemoveDialog({ onRemove, onClose } : { onRemove: () => void, onClose: () => void }) {

    return (
        <div>
            <Dialog
                open={true}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Remove item?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        All information about the item will be lost if it is removed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={onRemove} color="primary" autoFocus>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

interface WishlistsProps {
    homerooms: ApiHomeroomClub[]
    onHomerooms: (homerooms: ApiHomeroomClub[]) => Promise<void>
    user?: ApiUser | null
}

interface WishlistsState {
    dialogAdd: boolean
    addHomeroom: ApiHomeroomClub | null
    expandIndex?: string
    removeIndex?: string
}

export default class Wishlists extends React.Component<WishlistsProps, WishlistsState> {
    state: WishlistsState = {
        dialogAdd: false,
        addHomeroom: null
    }

    onWish(homeroomId: string, newWish: ApiWish) {
        let update = JSON.parse(JSON.stringify(this.props.homerooms));
        for (let idx = 0; idx < update.length; ++idx) {
            if (update[idx]._id === homeroomId) {
                newWish.url = TagService.tagUrl(newWish.url);
                newWish.timeCreated = new Date().getTime();
                update[idx].wishes.push(newWish);
            }
        }
        this.props.onHomerooms(update);
    }

    removeWish(homeroomId: string, idxWish: number) {
        let update = JSON.parse(JSON.stringify(this.props.homerooms));
        for (let idx = 0; idx < update.length; ++idx) {
            if (update[idx]._id === homeroomId) {
                update[idx].wishes.splice(idxWish, 1);
            }
        }
        this.props.onHomerooms(update);
    }

    render() {
        let user = this.props.user;
        let updateHRs : ApiHomeroomClub[] = JSON.parse(JSON.stringify(this.props.homerooms));
        return <>
            <div style={{ marginTop: "1rem" }}>
                {updateHRs.map((homeroom, index) => {
                    if (homeroom) {
                        let canEdit: boolean = !!user && HomeroomService.canEdit(homeroom, user);
                        let mapWishes : { [cat: string]: ApiWish[] } = {};
                        if (homeroom.wishes) {
                            for (const wish of homeroom.wishes) {
                                let cat = wish.category || "General";
                                mapWishes[cat] = mapWishes[cat] || [];
                                mapWishes[cat].push(wish);
                            }
                        }
                        let hrWishes : Array<{
                            catLabel: string,
                            wishes: ApiWish[]
                        }> = [];
                        for (let cat in mapWishes) {
                            hrWishes.push({
                                catLabel: cat,
                                wishes: mapWishes[cat]
                            })
                        }
                        return <React.Fragment key={homeroom._id}>
                            {index > 0 && <hr style={{ marginBottom: "1.5rem" }} />}
                            <div className="homeClass" style={{ display: "block", marginTop: "0rem", marginBottom: "1rem" }}>
                                <HomeroomLabel
                                    homeroom={homeroom}
                                    canAdd={canEdit}
                                    addLabel="Add wish"
                                    onAdd={() => {
                                        this.setState({
                                            dialogAdd: true,
                                            addHomeroom: homeroom
                                        })
                                    }}
                                />
                                <div style={{ marginTop: ".5rem" }} />
                                {(hrWishes.length > 0 &&
                                    <>
                                        {hrWishes.map((hrWish) => (<>
                                            <div style={{ marginTop: "1rem" }} />
                                            <div><strong>{hrWish.catLabel || "General"}</strong></div>
                                            <div style={{ marginTop: ".5rem" }} />
                                            {hrWish.wishes.map((wish: ApiWish, idx: number) => {
                                                let canAdjustClaim = !!user && (canEdit || wish.remaining > 0 || (user.email in wish.claimed_by));
                                                let hadClaimed = (!!user && (user.email in wish.claimed_by));
                                                return <Accordion
                                                    square
                                                    expanded={this.state.expandIndex === (index + "_" + idx)}
                                                    onChange={() => {
                                                        if (wish.remaining < wish.count) {
                                                            this.setState({ expandIndex: this.state.expandIndex === index + "_" + idx ? undefined : index + "_" + idx});
                                                        } else {
                                                            this.setState({ expandIndex: undefined});
                                                        }
                                                    }}>
                                                    <AccordionSummary expandIcon={(wish.remaining < wish.count && <ExpandMore />) || <span style={{width: "4px"}} />}>
                                                        <div style={{ width: "100%" }}>
                                                            <a href={TagService.tagUrl(wish.url)} target="_blank" rel="noreferrer noopener" onClick={(evt) => {
                                                                evt.stopPropagation();
                                                            }}>
                                                                {wish.name && wish.name}
                                                                {!wish.name && "Item"}
                                                            </a>
                                                            <div style={{ fontSize: ".8em", marginLeft: ".25rem" }}>[
                                                                {(wish.remaining > 0 &&
                                                                    <>{wish.remaining} more needed.</>
                                                                ) ||
                                                                    <>No more needed.</>}
                                                            ]</div>
                                                        </div>
                                                        <div style={{ marginTop: "4px", minWidth: canEdit ? "60px" : "40px" }}>
                                                            {canAdjustClaim && (<>
                                                                { wish.claimed_by[this.props.user!.email] > 0 && <Tooltip placement="top" title="Unclaim">
                                                                    <IconButton
                                                                        disabled={!hadClaimed}
                                                                        aria-label="unclaim"
                                                                        onClick={((evt) => {
                                                                            evt.stopPropagation();
                                                                            ++wish.remaining;
                                                                            --wish.claimed_by[this.props.user!.email];
                                                                            if (wish.claimed_by[this.props.user!.email] === 0) {
                                                                                delete wish.claimed_by[this.props.user!.email];
                                                                            }
                                                                            this.props.onHomerooms(updateHRs);
                                                                            this.setState({ expandIndex: (index + "_" + idx) });
                                                                        })}
                                                                        size="small"
                                                                    >
                                                                        <RemoveShoppingCart fontSize="small" style={{color: "#2F5043"}} />
                                                                    </IconButton>
                                                                </Tooltip>}
                                                                { (wish.remaining > 0 && 
                                                                <Tooltip placement="top" title="Claim">
                                                                    <IconButton
                                                                        disabled={wish.remaining === 0}
                                                                        aria-label="claim"
                                                                        onClick={((evt) => {
                                                                            evt.stopPropagation();
                                                                            wish.claimed_by[this.props.user!.email] = wish.claimed_by[this.props.user!.email] || 0;
                                                                            --wish.remaining;
                                                                            ++wish.claimed_by[this.props.user!.email];
                                                                            this.props.onHomerooms(updateHRs);
                                                                            this.setState({ expandIndex: (index + "_" + idx) });
                                                                        })}
                                                                        size="small"
                                                                    >
                                                                        <AddShoppingCart fontSize="small" style={{color: "#2F5043"}} />
                                                                    </IconButton>
                                                                </Tooltip>) || <>
                                                                        <span style={{marginLeft: "20px"}}></span>
                                                                </>}
                                                            </>)}
                                                            {canEdit &&
                                                                <Tooltip placement="top" title="Delete">
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                        onClick={((evt) => {
                                                                            evt.stopPropagation();
                                                                            this.setState({removeIndex: index+"_"+idx});
                                                                        })}
                                                                        size="small"
                                                                    >
                                                                        <Clear fontSize="small" style={{color: "#2F5043"}} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                    </AccordionSummary>
                                                    {wish.remaining !== wish.count && (
                                                        <AccordionDetails style={{ borderTop: "dashed black 1px" }}>
                                                            <div style={{ width: "100%" }}>

                                                                {Object.keys(wish.claimed_by).map((email) => (
                                                                    <div className="switchStatus">- {email}: {wish.claimed_by[email]}</div>
                                                                ))}
                                                            </div>

                                                        </AccordionDetails>
                                                    )}
                                                </Accordion>
                                            })}
                                        </>))}
                                    </>) || <>
                                        {/* Empty wish list */}
                                        <div>
                                            <p>{Nls.get("NEEDS_NONE")}</p>
                                            {canEdit && <p>You may include items wanted by the teacher, school, 
                                                or other classroom needs such as supplies. This may be
                                                for basic supplies, Teacher Appreciation Week, 
                                                teacher birthdays, and more.
                                            </p>}
                                        </div>
                                    </>
                                }
                            </div>
                            { this.state.removeIndex && 
                                <RemoveDialog 
                                    onRemove={() => {
                                        let idxs = this.state.removeIndex!.split("_").map(val => parseInt(val));
                                        let homeroom = updateHRs[idxs[0]];
                                        this.removeWish(homeroom._id, idxs[1]);
                                        this.setState({removeIndex: undefined});
                                    }}
                                    onClose={() => {
                                        this.setState({removeIndex: undefined});
                                    }}
                                />
                            }
                        </React.Fragment>
                    }
                    return null;
                })}
            </div>
            { this.props.user && this.state.addHomeroom && <ModalAddWish
                open={this.state.dialogAdd}
                onWish={(homeroomId: string, wish: ApiWish) => {
                    this.onWish(homeroomId, wish);
                }}
                onClose={() => { this.setState({ dialogAdd: false }) }}
                homeroom={this.state.addHomeroom}
                user={this.props.user}
            />}
        </>
    }
}