import React from 'react';

import { 
    Grid, Column, TextInput, 
    DatePicker, DatePickerInput,
    Dropdown,
    ExpandableTile,
    TileAboveTheFoldContent,
    TileBelowTheFoldContent,
    InlineLoading,
    UnorderedList,
    ListItem,
    Link
} from "@carbon/react";
import { convert24to12 } from "../../../../components/TimePicker12"
import "./Camps.scss";
import MyTheme from '../../../../components/MyTheme';
import DateUtil from '../../../../util/DateUtil';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';

interface SearchResultSession {
    id: string
    label: string
    dateStart: string
    dateEnd: string
    timeStart: string
    timeEnd: string
    campDays: string[]
    ageStart: string
    ageEnd: string
    costPerAttendee: number
}

interface SearchResultAccount {
    id: string
    name: string
    urlname: string
}

interface SearchResultCamp {
    id: string
    name: string
    urlname: string
    address: string
    city: string
    state: string
    zip: string
    shortdesc: string
    description: string
}

interface SearchResult {
    session: SearchResultSession,
    account: SearchResultAccount,
    camp: SearchResultCamp,
    capacity: number
}

interface SRInfoCamp {
    camp: SearchResultCamp,
    account: SearchResultAccount,
    sessions: SearchResultSession[]
}

interface SRInfo {
    year: string,
    month: string,
    camps: SRInfoCamp[]
}

type DropOption = {
    id: string,
    label: string
}

interface CampsState {
    zip: string,
    birthStr: string,
    searchWeek: DropOption,
    searchInfo?: SRInfo[]
}

// interface CampsQuery {
//     zip: string,
//     birthStr: string,
//     searchWeek: string
// }

class CampsCls extends React.Component<{ navigate: NavigateFunction, searchParams: URLSearchParams }, CampsState> {
    state : CampsState = {
        zip: "",
        birthStr: "",
        searchWeek: { id: "", label: "All weeks" }
    }

    changeZip(event: any) {
        this.setState({ zip: event.target.value });
        if (event.target.value.match(/\d{5}/)) {
            this.props.navigate(`?searchWeek=${this.state.searchWeek?.id}&zip=${event.target.value}&birthStr=${this.state.birthStr}`)
            setTimeout(() => {
                this.refresh();
            }, 0);
        }
    };

    changeWeek(chg: { selectedItem: DropOption }) {
        this.setState({ searchWeek: chg.selectedItem });
        this.props.navigate(`?searchWeek=${chg.selectedItem.id}&zip=${this.state.zip}&birthStr=${this.state.birthStr}`);

        setTimeout(() => {
            this.refresh();
        }, 0);
    }

    changeBirth(pickerDateStr: string) {
        let newDate = new Date(pickerDateStr);
        let birthStr = ((newDate.getMonth()+1)<10?"0":"")+(newDate.getMonth()+1);
        birthStr += "-" + (newDate.getDate()<10?"0":"")+newDate.getDate();
        birthStr += "-" + newDate.getFullYear()+ "";
        if (birthStr === "NaN-NaN-NaN") {
            birthStr = "";
        }
        this.setState({ birthStr });
        this.props.navigate(`?searchWeek=${this.state.searchWeek?.id}&zip=${this.state.zip}&birthStr=${birthStr}`);

        setTimeout(() => {
            this.refresh();
        }, 0);
    };

    componentDidMount() {
        const sp = this.props.searchParams;
        let searchWeeks = DateUtil.genWeeks(52);
        let searchWeek = searchWeeks.find(item => (sp.get("searchWeek") && (item.id+"" === sp.get("searchWeek"))));
        this.setState({ 
            birthStr: sp.get("birthStr")!, 
            zip: sp.get(".zip")!, 
            searchWeek: (searchWeek || { id: "", label: "All weeks" })
        });

        document.getElementById("root")!.style.backgroundColor = "rgb(244, 244, 244)";
        Promise.resolve()
        .then(() => {
            this.refresh();
        })
    }

    componentWillUnmount() {
        document.getElementById("root")!.style.backgroundColor = "#F4F4F4";
    }

    refresh() {
        (async () => {
            try {
                let resp = await fetch(`/api/camptaken/search?searchWeek=${this.state.searchWeek?.id}&zip=${this.state.zip}&birthStr=${this.state.birthStr}`);
                let searchResults: SearchResult[] = await resp.json();
                searchResults.sort((a, b) => {
                    if (a.session.dateStart === b.session.dateStart) {
                        return a.camp.id.localeCompare(b.camp.id);
                    }
                    return a.session.dateStart.localeCompare(b.session.dateStart);
                })
                let searchInfo : SRInfo[] = []
                for (const searchResult of searchResults) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    let [month, _day, year] = searchResult.session.dateStart.split("/");
                    let lastIdx = searchInfo.length - 1;
                    if (lastIdx === -1 || searchInfo[lastIdx].year !== year || searchInfo[lastIdx].month !== month) {
                        searchInfo.push({
                            year, month, camps: []
                        })
                        ++lastIdx;
                    }
                    let moInfo = searchInfo[lastIdx];
                    let lastCampIdx = moInfo.camps.length-1;
                    if (lastCampIdx === -1 || moInfo.camps[lastCampIdx].camp.id !== searchResult.camp.id) {
                        moInfo.camps.push({
                            camp: searchResult.camp,
                            account: searchResult.account,
                            sessions: []
                        })
                        ++lastCampIdx;
                    }
                    moInfo.camps[lastCampIdx].sessions.push(searchResult.session);
                }
                this.setState({searchInfo});
            } catch(err) {
                console.error(err);
            }
        })();
    }

    renderSearchResult(searchResult: SRInfoCamp, _idx: number) {
        return <Column sm={4} md={4} lg={6}>
            <ExpandableTile
        // id=""
            // tileCollapsedIconText="Interact to Expand tile"
        // 6      tileExpandedIconText="Interact to Collapse tile">
        >
                <TileAboveTheFoldContent>
                    <div className="campTitle">{searchResult.camp.name}</div>
                    <div style={{marginTop: "1rem"}}>{searchResult.camp.shortdesc}</div>
                    <div className="campTitle" style={{marginTop: "1rem"}}>Sessions</div>
                    <UnorderedList style={{marginLeft: "1rem"}}>
                        {searchResult.sessions.map(sess => (
                            <ListItem>
                                <div className="sesionTitle">
                                    <Link 
                                        href={`/auth/${searchResult.camp.id}/${sess.id}/takeit`}
                                        inline={true}
                                        onClick={(evt) => {
                                            // this.props.navigate(`/auth/${searchResult.camp.id}/${sess.id}/takeit`)
                                            evt.stopPropagation();
                                        }}
                                    >{sess.label}</Link>
                                </div>
                                <div className="sessionInfo">
                                    {sess.dateStart.replace(/\/\d*$/,"")}<span className="sep">&nbsp;&ndash;&nbsp;</span>
                                    {sess.dateEnd.replace(/\/\d*$/,"")}
                                </div>
                                {sess.timeStart && <div className="sessionInfo">
                                    {convert24to12(sess.timeStart)?.time}  {convert24to12(sess.timeStart)?.ampm}
                                    {sess.timeEnd && <>
                                        <span className="sep">&nbsp;&ndash;&nbsp;</span>
                                        {convert24to12(sess.timeEnd)?.time}  {convert24to12(sess.timeEnd)?.ampm}
                                    </>}
                                </div>}
                                <div className="sessionInfo">DOB: {sess.ageStart}<span className="sep">&nbsp;&ndash;&nbsp;</span>
                                {sess.ageEnd}</div>
                                <div className="sessionInfo">Cost: ${sess.costPerAttendee}</div>

                            </ListItem>
                        ))}
                    </UnorderedList>
                </TileAboveTheFoldContent>
                <TileBelowTheFoldContent>
                    <div style={{marginTop: "1rem"}}>{searchResult.camp.description}</div>
                    <div style={{marginTop: "1rem"}} />
                    <div>{searchResult.camp.address}</div>
                    <div>{searchResult.camp.city}, {searchResult.camp.state} {searchResult.camp.zip}</div>
                </TileBelowTheFoldContent>
            </ExpandableTile>
        </Column>                    
    }

    render() {
        const monVal = {
            "1": "January",
            "2": "February",
            "3": "March",
            "4": "April",
            "5": "May",
            "6": "June",
            "7": "July",
            "8": "August",
            "9": "September",
            "10": "October",
            "11": "November",
            "12": "December"
        }
        let searchWeeks = DateUtil.genWeeks(52);
        searchWeeks.unshift({
            id: "",
            label: "All weeks"
        });

        return (
            <>
                <main className="Camps">
                    <MyTheme siteKey="ct" theme="white">
                        <Grid>
                            <Column sm={4} md={4} lg={5}>
                                <div style={{marginTop: ".5rem"}} />
                                <TextInput 
                                    id="search-zip" 
                                    labelText="Zip code" 
                                    value={this.state.zip}
                                    onChange={this.changeZip.bind(this)}
                                    style={{maxWidth: "18rem" }}
                                />
                            </Column>
                            <Column sm={4} md={4} lg={5}>
                                <div style={{marginTop: ".5rem"}} />
                                <Dropdown
                                    style={{maxWidth: "18rem"}}
                                    ariaLabel="Dropdown"
                                    id="carbon-dropdown-example"
                                    items={searchWeeks}
                                    label="Week options..."
                                    titleText="Search week"
                                    onChange={this.changeWeek.bind(this)}
                                    selectedItem={this.state.searchWeek}
                                />
                            </Column>
                            <Column sm={4} md={4} lg={5}>
                                <div style={{marginTop: ".5rem"}} />
                                <DatePicker
                                    datePickerType="single"
                                    value={this.state.birthStr}
                                    onChange={this.changeBirth.bind(this)}
                                    >
                                    <DatePickerInput
                                        id="search-date"
                                        labelText="Camper's birthday (mm/dd/yyyy)" 
                                        // onClose={function noRefCheck(){}}
                                        // onOpen={function noRefCheck(){}}
                                        // helperText="mm/dd/yyyy"
                                    />
                                </DatePicker>
                                <div style={{marginBottom: "1rem"}} />
                            </Column>
                        </Grid>
                    </MyTheme>
                    <div style={{marginTop: "3rem"}} />
                    { (!this.state.searchInfo || this.state.searchInfo.length === 0) && <>
                        <Grid>
                            <Column sm={4} md={8} lg={16}>
                                { !this.state.searchInfo 
                                    && <InlineLoading description="Searching..." />
                                }
                                { this.state.searchInfo && this.state.searchInfo.length === 0 && <>
                                    No camps found.
                                </>}
                            </Column>
                        </Grid>
                    </>}
                    { (this.state.searchInfo && this.state.searchInfo.length > 0) && <>
                        {this.state.searchInfo.map((monInfo) => <>
                            <Grid>
                            <Column sm={4} md={8} lg={16}>
                            <h2>{monInfo.year} - {monVal[monInfo.month]}</h2>
                            </Column>
                            </Grid>
                            <Grid>
                            {monInfo.camps.map((camp, idx) => (
                                this.renderSearchResult(camp, idx)
                            ))}
                            </Grid>
                        </>)}
                    </>}
                </main>
            </>)
    }
}

export default function Camps() {
    let navigate = useNavigate();
    let [searchParams] = useSearchParams();
    return <CampsCls navigate={navigate} searchParams={searchParams} />
}