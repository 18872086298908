import clubsNls from "./clubs.json";
import hrpNls from "./hrp.json";

function getSite() {
    if (document.location.href.includes("9043") || document.location.href.includes("homeroomparent.com") || document.location.href.includes("homeroomparent.us-south.cf.appdomain.cloud")) {
        return "homeroomParent"
    } else {
        return "clubBooster";
    }
}

export default class Nls {
    static get(key: string): string {
        let hrpMap : { [key: string]: string } = hrpNls as any;
        let clubMap : { [key: string]: string } = clubsNls as any;
        if (getSite() === "clubBooster") {
            return key in clubMap ? clubMap[key] : key;
        } else {
            return key in hrpMap ? hrpMap[key] : key;
        }
    }
}