import { ApiSignupEvent } from "../../../../../server/src/hrpcb/models/apiSignup";
import Nls from "../nls/nls";

export default class SignupService {
    static async getByShareCode(code: string) : Promise<ApiSignupEvent> {
        let result = await fetch(`/api/hrpcb/signup/code/${code}`);
        let se : ApiSignupEvent = await result.json();
        return se;
    }

    static async getByHomeroomId(homeroomId: string, code: string) : Promise<ApiSignupEvent[]> {
        let result = await fetch(`/api/hrpcb/signup/by_hrid/${homeroomId}?code=${code}`);
        let ses : ApiSignupEvent[] = await result.json();
        return ses;
    }

    static async getByUser() : Promise<ApiSignupEvent[]> {
        let result = await fetch(`/api/hrpcb/signup/${Nls.get("URL_PART")}/cur_user`);
        let ses : ApiSignupEvent[] = await result.json();
        return ses;
    }

    static async createEvent(name: string, url: string) : Promise<ApiSignupEvent> {
        let result = await fetch(`/api/hrpcb/signup`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                url: url,
                signups: []
            })
        });
        return await result.json();
    }

    static async updateEvent(event: ApiSignupEvent) : Promise<ApiSignupEvent> {
        let result = await fetch(`/api/hrpcb/signup`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(event)
        });
        return await result.json();
    }
}