import React from 'react';
import './App.scss';
import SiteCampTaken from './sites/camptaken/SiteCampTaken';
import SiteClubAndBooster from './sites/clubandbooster/SiteClubAndBooster';
import SiteHomeroomParent from './sites/homeroomparent/SiteHomeroomParent';
import SitePartyTaken from './sites/partytaken/SitePartyTaken';

const App: React.FC = () => {
    if (document.location.href.includes("9043") || document.location.href.includes("homeroomparent.com")) {
        return <SiteHomeroomParent />
    } else if (document.location.href.includes("9044") || document.location.href.includes("clubandbooster.com")) {
        return <SiteClubAndBooster />
    } else if (document.location.href.includes("9045") || document.location.href.includes("partytaken.com")) {
        return <SitePartyTaken />;
    } else if (document.location.href.includes("9046") || document.location.href.includes("camptaken.com")) {
        return <SiteCampTaken />;
    } else {
        return <></>
    }
}

export default App;
