export default class AdminService {
    static async getBasicStats() : Promise<{
        userCount: number,
        homeroomCount: number,
        clubCount: number
    } | null> {
        let result = await fetch(`/api/hrpcb/admin/basic_stats`);
        if (result.status === 204 || result.statusText === "No Content") {
            return null;
        } else {
            return result.json();
        }
    }
}
