export default class Util {
    static uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }

    static t24to12(t: string) {
        let times = t.split(":");
        let hour = parseInt(times[0]);
        if (hour > 12) {
            return `${(hour-12)}:${times[1]}p`;
        } else {
            return `${hour}:${times[1]}a`;
        }
    }
}