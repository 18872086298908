import React from 'react';
import { ApiUser } from '../../../../../../../server/src/hrpcb/models/apiUser';
import "./BasicStats.scss";
import { ePageProfile } from '../../../App';
import { Helmet } from "react-helmet";
import AdminService from '../../../services/AdminService';
import Nls from '../../../nls/nls';

interface BasicStatsProps {
    user?: ApiUser | null
    pageProfile: ePageProfile
}

interface BasicStatsState {
    stats?: {
        userCount: number,
        homeroomCount: number,
        clubCount: number
    }
}

export default class BasicStats extends React.Component<BasicStatsProps, BasicStatsState> {
    state : BasicStatsState = {
    }

    componentDidMount() {
        (async () => {
            let stats = await AdminService.getBasicStats();
            if (stats) {
                this.setState({stats: stats});
            }
        })();
    }

    componentDidUpdate(_prevProps: BasicStatsProps) {
    }

    render() {
        return <>
            <Helmet>
                <title>Basic Stats - {Nls.get("SITE_TITLE")}</title>
            </Helmet>
            <div style={{ marginTop: "2rem" }} />
            <div className="hrpRow">
                <div className="hrpCol-4-mob">
                    <h2>Basic stats</h2>
                </div>
            </div>
            <div style={{ marginTop: "1rem" }} />
            <div className="hrpRow">
                <div className="hrpCol-4-mob">
                    <div># Users: {this.state.stats && this.state.stats!.userCount}</div>
                    <div># Homerooms: {this.state.stats && this.state.stats!.homeroomCount}</div>
                    <div># Clubs: {this.state.stats && this.state.stats!.clubCount}</div>
                </div>
            </div>
        </>
    }
}