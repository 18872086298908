import React from 'react';
import { Helmet } from "react-helmet";
import Nls from '../../nls/nls';

export default class Unauthorized extends React.Component<{location?:any}> {
    render() {
        return (<>
        <Helmet>
            <title>Unauthorized - {Nls.get("SITE_TITLE")}</title>
        </Helmet>
        <main>
        <div className="hrpRow">
            <div className="hrpCol-4-mob">
                <h1>Unauthorized</h1>
            </div>
        </div>
    </main></>)
    }
}