import React from 'react';

import { Grid, Column } from "@carbon/react";
import "./Reservations.scss";

interface ReservationsProps {
}

interface ReservationsState {
}

export default class Reservations extends React.Component<ReservationsProps, ReservationsState> {
    state: ReservationsState = {
    }

    componentDidMount() {
        // document.body.style.backgroundColor = "#eee";
        Promise.resolve()
            .then(() => {
                this.refresh();
            })
    }

    componentWillUnmount() {
        // document.body.style.backgroundColor = "inherit";
    }

    async refresh() {
        // const resp = await fetch(`/api/taken/account`);
        // const results = await resp.json();
    }

    render() {
        return (
            <>
                <main className="Reservations">
                    <div style={{ marginTop: "3rem" }} />
                    <Grid>
                        <Column sm={4} md={8} lg={16}>
                            Main section
                        </Column>
                    </Grid>
                </main>
            </>)
    }
}