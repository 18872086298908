import { Button, Dialog, DialogActions, DialogTitle, DialogContent, 
    FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip, TextField, AccordionSummary, AccordionDetails, Accordion } from '@mui/material';
import { Add, Edit, ExpandMore, Link } from '@mui/icons-material';
import React from 'react';
import ModalAddLink from "./ModalAddLink";
import { ApiHomeroomClub, ApiHRLinkMap } from '../../../../../../../../server/src/hrpcb/models/apiHomeroom';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import HomeroomService from '../../../../services/HomeroomService';
import deepEqual from "deep-equal";
import Tile from '../../../../components/widgets/Tile';
import Nls from '../../../../nls/nls';
import InfoLinks from '../../../../components/info/InfoLinks';
import InfoButton from '../../../../components/info/InfoButton';

const mainLinksNames = [
    "School", "District", "PTA/PTO", "Class", "Website"
]

interface SectionLinksMobileProps {
    user?: ApiUser | null
    homerooms: ApiHomeroomClub[]
    expanded: boolean,
    onToggle: () => void,
    onHomerooms: (homeroom: ApiHomeroomClub[]) => void
}

export function SectionLinksMobile(props: SectionLinksMobileProps) {
    return <SectionLinksClass {...props} />
}

interface SectionLinksDesktopProps {
    user?: ApiUser | null
    homerooms: ApiHomeroomClub[]
    onHomerooms: (homeroom: ApiHomeroomClub[]) => void
}

export function SectionLinksDesktop(props: SectionLinksDesktopProps) {
    return <SectionLinksClass {...props} />
}

interface SectionLinksClassProps {
    user?: ApiUser | null
    homerooms: ApiHomeroomClub[]
    expanded?: boolean,
    onToggle?: () => void,
    onHomerooms: (homeroom: ApiHomeroomClub[]) => void
}

interface SectionLinksState {
    addDialog: boolean
    editDialog: boolean
    homeroomIdx: number
    editableHomerooms: ApiHomeroomClub[]
}

type CatEntry = {
    category: string,
    labelList: Array<{
        label: string
        urls: string[]
    }>
}

class SectionLinksClass extends React.Component<SectionLinksClassProps, SectionLinksState> {
    state: SectionLinksState = {
        addDialog: false,
        editDialog: false,
        homeroomIdx: 0,
        editableHomerooms: []
    }

    componentDidMount() {
        this.resetState();
    }

    componentDidUpdate(prevProps: SectionLinksClassProps) {
        if (!deepEqual(prevProps, this.props)) {
            this.resetState();
        }
    }

    resetState() {
        let hrs : ApiHomeroomClub[] = JSON.parse(JSON.stringify(this.props.homerooms));
        let editableHomerooms = (hrs || []).filter((homeroom) => {
            return HomeroomService.canEdit(homeroom, this.props.user!);
        });

        this.setState({editableHomerooms: editableHomerooms});
    }

    getLinkList(selHrs: ApiHomeroomClub[]): CatEntry[] {
        const linkMap: ApiHRLinkMap = {}
        for (const hr of selHrs) {
            for (const cat in hr.links) {
                for (const label in hr.links[cat]) {
                    let urls = hr.links[cat][label];
                    if (urls.length > 0) {
                        linkMap[cat] = linkMap[cat] || {}
                        linkMap[cat][label] = linkMap[cat][label] || [];
                        for (const url of urls) {
                            if (!linkMap[cat][label].includes(url) && url.trim().length > 0) {
                                linkMap[cat][label].push(url);
                            }
                        }
                    }
                }
            }
        }

        const linkList: CatEntry[] = [];
        for (const cat in linkMap) {
            let catInfo: CatEntry = {
                category: cat,
                labelList: []
            }
            for (const label in linkMap[cat]) {
                catInfo.labelList.push({
                    label: label,
                    urls: linkMap[cat][label].length > 0 ? linkMap[cat][label] : [""]
                })
            }
            linkList.push(catInfo);
        }

        return linkList;
    }

    async onLink(category: string, name: string, url: string) {
        let copy = JSON.parse(JSON.stringify(this.props.homerooms));
        for (const hr of copy) {
            if (category in hr.links) {
                hr.links[category][name] = hr.links[category][name] || [];
                hr.links[category][name].push(url);
            }
        }
        this.props.onHomerooms(copy);
    }

    async onSave() {
        for (const hr of this.state.editableHomerooms) {
            for (const cat in hr.links) {
                for (const label in hr.links[cat]) {
                    if (!mainLinksNames.includes(label)) {
                        hr.links[cat][label] = hr.links[cat][label].filter(url => url.trim().length > 0);
                        // Optional, ensure that it's not empty
                        if (hr.links[cat][label].length === 0) {
                            delete hr.links[cat][label];
                        }
                    }
                }
            }
        }
        await this.props.onHomerooms(this.state.editableHomerooms);
        this.onClose();
    }

    onClose() {
        this.setState({ editDialog: false });
    }

    render() {
        const linkList = this.getLinkList(this.props.homerooms);
        let editLinkList : CatEntry[] = [];
        if (this.state.editDialog) {
            editLinkList = this.getLinkList([this.state.editableHomerooms[this.state.homeroomIdx]]);
        }

        const sectionHeader = <div className="sectionTitle">
            <h2>
                <Link /><span style={{verticalAlign: "top", marginLeft: ".5rem"}}>Links</span>
            </h2>
            <InfoButton title="Links Info" showLogin={!this.props.user}>
                <InfoLinks />
            </InfoButton>
            {this.props.user && HomeroomService.canEditAny(this.props.homerooms, this.props.user) &&
                <div style={{ marginLeft: "1rem", float: "right" }}>
                    <Tooltip placement="top" title="Edit links">
                        <IconButton aria-label="edit" size="small" onClick={((evt) => {
                            this.setState({ editDialog: true });
                            evt.stopPropagation();
                        })} >
                            <Edit className="homeIconButton" />
                        </IconButton>
                    </Tooltip>
                </div>
            }
            {this.props.user && HomeroomService.canEditAny(this.props.homerooms, this.props.user) &&
                <div style={{ marginLeft: "1rem", float: "right" }}>
                    <Tooltip placement="top" title="Add links">
                        <IconButton aria-label="add" size="small" onClick={((evt) => {
                            this.setState({ addDialog: true });
                            evt.stopPropagation();
                        })} >
                            <Add className="homeIconButton" />
                        </IconButton>
                    </Tooltip>
                </div>
            }
        </div>;

        let moreLinks = false;
        const sectionBody = <React.Fragment>
            { linkList.map((catEntry: CatEntry) => (<React.Fragment key={`ce_${catEntry.category}`}>
                <div style={{ fontSize: ".8em", marginTop: "1rem", fontWeight: 600 }}>{catEntry.category}</div>
                <ul style={{ marginTop: "0rem" }}>
                    { catEntry.labelList.map((labelInfo) => (<React.Fragment key={`scli_${labelInfo.label}`}>
                        { labelInfo.urls.map((url, index) => {
                            let mainLink = mainLinksNames.includes(labelInfo.label) || labelInfo.label.includes("favorites");
                            moreLinks = moreLinks || !mainLink;
                            return mainLink && (<React.Fragment key={`scli2_${index}`}>
                                { (url.trim().length === 0 && <li>{labelInfo.label} (link missing)</li>)
                                || <li key={`scli_${labelInfo.label}`}><a className="regLink" href={url}>{labelInfo.label}</a></li>}
                            </React.Fragment>)
                        })}
                    </React.Fragment>))}
                </ul>
            </React.Fragment>))}
            {moreLinks && <a className="regLink" style={{ fontSize: ".8em" }} href={`/auth/${Nls.get("URL_PART")}/links`}>More links</a>}
        </React.Fragment>

        let hasOptional = false;
        editLinkList.forEach((catEntry: CatEntry) => {
            catEntry.labelList.forEach((labelInfo) => {
                if (!mainLinksNames.includes(labelInfo.label)) {
                    hasOptional = true;
                }
            });
        })
        const sectionEditDialog = this.state.editDialog && (
            <Dialog
                open={this.state.editDialog}
                maxWidth="md"
                fullWidth={false}
                fullScreen={typeof this.props.expanded !== "undefined"}
                onClose={() => {this.onClose()}}
            >
                <DialogTitle>Edit links</DialogTitle>
                <DialogContent>
                    { hasOptional && <>
                        <div>Optional links may be removed by deleting the URL</div>
                        <div style={{marginTop: "1rem"}} />
                    </>}
                    <div>
                        <FormControl variant="standard">
                            <InputLabel id="homeroom-label">{Nls.get("SELECTOR_LABEL")}</InputLabel>
                            <Select
                                autoFocus
                                labelId="homeroom-label"
                                id="homeroom-select"
                                value={this.state.homeroomIdx}
                                onChange={(evt: any) => {
                                    this.setState({ homeroomIdx: evt.target.value });
                                }}
                            >
                                {this.state.editableHomerooms.map((homeroom, idx) => (
                                    <MenuItem key={homeroom._id} value={idx}>{HomeroomService.classLabel(homeroom)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div onKeyDown={(evt: React.KeyboardEvent<HTMLDivElement>) => { 
                        if (evt.key === "Enter") { 
                            evt.preventDefault();
                            this.onSave();
                        }
                    }}>
                    {
                        editLinkList.map((catEntry: CatEntry) => (<React.Fragment key={`el_${catEntry.category}`}>
                            <div style={{ fontSize: ".8em", marginTop: "1rem" }}>{catEntry.category}</div>
                            <ul style={{ marginTop: "0rem" }}>
                                { catEntry.labelList.map((labelInfo) => (!labelInfo.label.includes("favorites") && <React.Fragment key={`ell_${labelInfo.label}`}>
                                    { labelInfo.urls.length === 0 && (
                                        <TextField
                                            variant="filled"
                                            margin="dense"
                                            label={`${labelInfo.label}${!mainLinksNames.includes(labelInfo.label)?" (optional)": ""}`}
                                            type="text"
                                            fullWidth
                                            value=""
                                            onChange={((evt: any) => {
                                                const hrs = JSON.parse(JSON.stringify(this.state.editableHomerooms));
                                                const hr = hrs[this.state.homeroomIdx];
                                                hr.links = hr.links || {};
                                                hr.links[catEntry.category] = hr.links[catEntry.category] || {};
                                                hr.links[catEntry.category][labelInfo.label] = [evt.target.value];
                                                this.setState({editableHomerooms: hrs});
                                            })} 
                                            InputLabelProps={{
                                                shrink: true,
                                            }} />
                                    ) }
                                    { labelInfo.urls.map((url, urlIdx) => (
                                        <TextField
                                            variant="filled"
                                            margin="dense"
                                            label={`${labelInfo.label}${!mainLinksNames.includes(labelInfo.label)?" (optional)": ""}`}
                                            type="text"
                                            fullWidth
                                            value={url}
                                            onChange={((evt: any) => {
                                                const hrs = JSON.parse(JSON.stringify(this.state.editableHomerooms));
                                                const hr = hrs[this.state.homeroomIdx];
                                                hr.links = hr.links || {};
                                                hr.links[catEntry.category] = hr.links[catEntry.category] || {};
                                                hr.links[catEntry.category][labelInfo.label] = hr.links[catEntry.category][labelInfo.label] || []
                                                hr.links[catEntry.category][labelInfo.label][urlIdx] = evt.target.value;
                                                this.setState({editableHomerooms: hrs});
                                            })} 
                                            InputLabelProps={{
                                                shrink: true,
                                            }} />
                                    ))}
                                </React.Fragment>))}
                            </ul>
                        </React.Fragment>))
                    }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        this.onSave();
                    }} color="primary">
                        Save
                    </Button>
                    <Button
                        onClick={() => {
                            this.onClose();
                        }}
                        autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )

        const sectionAddDialog = this.props.user 
            && this.state.editableHomerooms 
            && this.state.editableHomerooms.length > 0
            && <ModalAddLink
                open={this.state.addDialog}
                onLink={async (category: string, name: string, url: string) => {
                    await this.onLink(category, name, url);
                }}
                onClose={() => { this.setState({ addDialog: false }) }}
                homerooms={this.state.editableHomerooms}
                user={this.props.user}
            />

        if (typeof this.props.expanded !== "undefined") {
            return <Accordion
                square
                expanded={this.props.expanded}
                onChange={() => {
                    this.props.onToggle!();
                }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    {sectionHeader}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{width: "100%"}}>
                        {sectionBody}
                    </div>
                </AccordionDetails>
                {sectionEditDialog}
                {sectionAddDialog}
            </Accordion>
        } else {
            return <Tile dark>
                {sectionHeader}
                {sectionBody}
                {sectionEditDialog}
                {sectionAddDialog}
            </Tile>
        }
    }
}
