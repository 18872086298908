import { Button, Dialog, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React, { KeyboardEvent, MouseEvent } from 'react';
import { HRPButton, HRPButtonSecondary } from '../../../components/widgets/Customized';
import Nls from '../../../nls/nls';
import { IMember } from './HomeroomInfo';

interface ModalEditMemberProps {
    editMember: IMember | null;
    onMember: (member: IMember) => void
    onClose: () => void
}

interface ModalEditMemberState {
    currentMember: IMember
}

export default class ModalEditMember extends React.Component<ModalEditMemberProps, ModalEditMemberState> {
    state : ModalEditMemberState = {
        currentMember: {
            name: "",
            email: "",
            title: "Teacher"
        }
    }

    componentDidUpdate(oldProps: ModalEditMemberProps) {
        if (oldProps.editMember === null && this.props.editMember !== null) {
            this.setState({ currentMember: this.props.editMember });
        }
    }

    onName(evt: any) {
        let stateChange = {
            currentMember: {
                name: evt.target.value,
                email: this.state.currentMember.email,
                title: this.state.currentMember.title
            }
        }
        this.setState(stateChange as any);
    }

    onTitle(evt: any) {
        let stateChange = {
            currentMember: {
                name: this.state.currentMember.name,
                email: this.state.currentMember.email,
                title: evt.target.value
            }
        }
        this.setState(stateChange as any);
    }

    onEmail(evt: any) {
        let stateChange = {
            currentMember: {
                name: this.state.currentMember.name,
                email: evt.target.value,
                title: this.state.currentMember.title
            }
        }
        this.setState(stateChange as any);
    }

    render() {
        let validMember = this.state.currentMember.email 
            && !!this.state.currentMember.email.match(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/) 
            && (this.state.currentMember.title !== "Teacher" || this.state.currentMember.name.trim().length > 0);
        return <Dialog open={this.props.editMember !== null} onKeyDown={(evt: KeyboardEvent<HTMLDivElement>) => { 
            if (evt.key === "Enter" && validMember) {
                this.props.onMember( this.state.currentMember ) 
                this.setState({ 
                    currentMember: { 
                        name: "", 
                        email: "", 
                        title: "Parent/Guardian"
                    } 
                }); 
                this.props.onClose(); 
                evt.preventDefault();
            }
        }}>
            <DialogTitle>Edit {Nls.get("SELECTOR_LABEL_LOWER")} member</DialogTitle>
            <div style={{ margin: "0rem 1.5rem"}}>
                <div style={{marginTop: ".5rem"}}></div>
                <FormControl component="fieldset" style={{fontSize: ".8em"}}>
                    <FormLabel component="legend">Type</FormLabel>
                    <RadioGroup aria-label="type" value={this.state.currentMember.title} onChange={this.onTitle.bind(this)}>
                        <FormControlLabel value="Teacher" control={<Radio />} label={<>
                            {Nls.get("ROLE_NAME_LABEL")}<span style={{fontWeight: 300}}>: {Nls.get("ROLE_NAME_DESC")}</span>
                        </>} />
                        <FormControlLabel value="School Representative" control={<Radio />} label={<>
                            {Nls.get("ROLE_WRITER_LABEL")}<span style={{fontWeight: 300}}>: {Nls.get("ROLE_WRITER_DESC")}</span>
                        </>} />
                        <FormControlLabel value="Homeroom Parent" control={<Radio />} label={<>
                            {Nls.get("ROLE_OWNER_LABEL")}<span style={{fontWeight: 300}}>: {Nls.get("ROLE_OWNER_DESC")}</span>
                        </>} />
                        <FormControlLabel value="Parent/Guardian" control={<Radio />} label={<>
                            {Nls.get("ROLE_MEMBER_LABEL")}<span style={{fontWeight: 300}}>: {Nls.get("ROLE_MEMBER_DESC")}</span>
                        </>} />
                    </RadioGroup>
                </FormControl>
                <div style={{marginTop: ".5rem"}}></div>
                <TextField 
                    variant="standard"
                    autoFocus
                    style={{minWidth: "400px"}} 
                    label={"Name" + (this.state.currentMember.title !== "Teacher" ? " (optional)" : "")}
                    value={this.state.currentMember.name} 
                    onChange={this.onName.bind(this)} 
                    />
                <div style={{marginTop: ".5rem"}}></div>
                <TextField 
                    variant="standard"
                    type="email" 
                    style={{minWidth: "400px"}} 
                    label="Email" 
                    value={this.state.currentMember.email} 
                    onChange={this.onEmail.bind(this)} 
                    />
                <div style={{marginTop: "1.5rem"}}></div>
                <div className="hrpRow">
                    <div className="hrpCol-2-mob">
                    <Button sx={HRPButtonSecondary} variant="contained" onClick={(evt: MouseEvent) => { 
                        this.setState({
                            currentMember: { 
                                name: "", 
                                email: "", 
                                title: "Teacher"
                            }
                        }); 
                        this.props.onClose(); 
                        evt.preventDefault();
                    }}>Cancel</Button>
                    </div>
                    <div className="hrpCol-2-mob">
                    <Button sx={HRPButton} variant="contained" color="primary" disabled={!validMember} onClick={(evt: MouseEvent) => { 
                        this.props.onMember( this.state.currentMember ) 
                        this.setState({ 
                            currentMember: { 
                                name: "", 
                                email: "", 
                                title: "Teacher"
                            } 
                        }); 
                        this.props.onClose(); 
                        evt.preventDefault();
                    }}>Update</Button>
                    </div>
                </div>
                <div style={{marginTop: "1.5rem"}}></div>
            </div>
        </Dialog>
    }
}