import { Button, Dialog, DialogTitle, TextField, useMediaQuery, useTheme } from '@mui/material';
import React, { MouseEvent } from 'react';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import { HRPButton, HRPButtonSecondary } from '../../../../components/widgets/Customized';
import { NewsRow } from './SectionNews';

interface ModalNewsPromptProps {
    user: ApiUser
    open: NewsRow
    title: string
    subTitle?: string
    initialValue?: string
    textPrompt: string
    onClose: () => void
    onResponse: (response: string) => Promise<void>
}

interface ModalNewsPromptState {
    currentResponse: {
        content: string
    }
}

export default function ModalNewsPrompt(props: ModalNewsPromptProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return <ModalNewsPromptClass {...props} fullScreen={fullScreen}/>
}
class ModalNewsPromptClass extends React.Component<ModalNewsPromptProps & { fullScreen: boolean }, ModalNewsPromptState> {
    state: ModalNewsPromptState = {
        currentResponse: this.resetVals()
    }

    componentDidMount() {
        this.setState({
            currentResponse: this.resetVals()
        })
    }

    onState(field: "content", evt: any) {
        let newState: ModalNewsPromptState = JSON.parse(JSON.stringify(this.state));
        newState.currentResponse[field] = evt.target.value;
        this.setState(newState);
    }

    resetVals() {
        return {
            content: this.props.initialValue || ""
        }
    }

    render() {
        const { fullScreen } = this.props;
        const curNews = this.state.currentResponse;
        let validQuestion = curNews.content.trim().length > 0;

        return <Dialog 
            open={true} 
            maxWidth="md"
            fullWidth={true}
            fullScreen={fullScreen}
            >
            <DialogTitle>{this.props.title}</DialogTitle>
            { this.props.subTitle && <div style={{ margin: "0rem 1.5rem 1.5rem 1.5rem" }}>
                {this.props.subTitle}
            </div> }
            <div style={{ margin: "0rem 1.5rem" }}>
                <TextField
                    variant="filled"
                    style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                    label={this.props.textPrompt}
                    value={this.state.currentResponse.content}
                    onChange={this.onState.bind(this, "content")}
                    multiline
                    rows={10}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <div style={{ marginTop: "1.5rem" }}></div>
                <div className="hrpRow">
                    <div className="hrpCol-2-mob">
                        <Button sx={HRPButtonSecondary} variant="contained" onClick={(evt: MouseEvent) => {
                            this.setState({
                                currentResponse: this.resetVals()
                            });
                            this.props.onClose();
                            evt.preventDefault();
                        }}>Cancel</Button>
                    </div>
                    <div className="hrpCol-2-mob">
                        <Button sx={HRPButton} variant="contained" color="primary" disabled={!validQuestion} onClick={async (evt: MouseEvent) => {
                            await this.props.onResponse(
                                this.state.currentResponse.content
                            );
                            this.setState({
                                currentResponse: this.resetVals()
                            });
                            this.props.onClose();
                            evt.preventDefault();
                        }}>Post</Button>
                    </div>
                </div>
                <div style={{ marginTop: "1.5rem" }}></div>
            </div>
        </Dialog>
    }
}