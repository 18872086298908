import { Button, Dialog, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme } from '@mui/material';
import React, { MouseEvent } from 'react';
import { ApiGoal, ApiHomeroomClub } from '../../../../../../../../server/src/hrpcb/models/apiHomeroom';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import { HRPButton, HRPButtonSecondary } from '../../../../components/widgets/Customized';
import * as uuid from "uuid";
import Nls from '../../../../nls/nls';
import HomeroomService from '../../../../services/HomeroomService';

interface ModalGoalAddEditProps {
    open: boolean
    onGoal: (homeroomId: string,
        goal: ApiGoal
    ) => void
    initValue?: ApiGoal & {homeroomId: string},
    onClose: () => void
    homerooms: ApiHomeroomClub[]
    user: ApiUser
}

interface ModalGoalAddEditState {
    currentGoal: {
        homeroomId: string
        label: string
        current: number
        goal: number
    }
}

export default function ModalGoalAddEdit(props: ModalGoalAddEditProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return <ModalGoalAddEditClass {...props} fullScreen={fullScreen}/>
}
class ModalGoalAddEditClass extends React.Component<ModalGoalAddEditProps & { fullScreen: boolean }, ModalGoalAddEditState> {
    state: ModalGoalAddEditState = {
        currentGoal: this.resetVals()
    }

    componentDidUpdate(prevProps: ModalGoalAddEditProps & { fullScreen: boolean }) {
        if (this.props.initValue && !prevProps.initValue) {
            this.setState({ currentGoal: this.resetVals()});
        }
    }

    onState(field: "homeroomId" | "label" | "current" | "goal", evt: any) {
        let newState: ModalGoalAddEditState = JSON.parse(JSON.stringify(this.state));
        if (field === "current" || field === "goal") {
            newState.currentGoal[field] = parseInt(evt.target.value);
        } else {
            newState.currentGoal[field] = evt.target.value;
        }
        this.setState(newState);
    }

    resetVals() {
        if (this.props.initValue) {
            return JSON.parse(JSON.stringify(this.props.initValue));
        } else {
            return {
                homeroomId: this.props.homerooms[0]._id,
                label: "",
                current: 0,
                goal: 100
            }
        }
    }

    render() {
        const { fullScreen } = this.props;
        const curGoal = this.state.currentGoal;
        let validGoal = curGoal.label.trim().length > 0;

        return <Dialog 
            open={this.props.open} 
            maxWidth="md"
            fullWidth={true}
            fullScreen={fullScreen}
            onClose={() => {
                this.setState({
                    currentGoal: this.resetVals()
                });
                this.props.onClose();
            }}
            >
            <DialogTitle>{this.props.initValue ? "Edit goal" : "Add goal"}</DialogTitle>
            <div style={{ margin: "0rem 1.5rem" }}>
                <FormControl variant="standard">
                    <InputLabel id="homeroom-label">{Nls.get("SELECTOR_LABEL")}</InputLabel>
                    <Select
                        labelId="homeroom-label"
                        id="homeroom-select"
                        value={this.state.currentGoal.homeroomId}
                        onChange={(evt) => {
                            this.setState({
                                currentGoal : {
                                    homeroomId: evt.target.value as string,
                                    label: this.state.currentGoal.label,
                                    current: this.state.currentGoal.current,
                                    goal: this.state.currentGoal.goal
                                }
                            })
                        }}
                    >
                        {this.props.homerooms.map((homeroom) => (
                            <MenuItem key={homeroom._id} value={homeroom._id}>{HomeroomService.classLabel(homeroom)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div style={{ marginTop: ".5rem" }}></div>
                <TextField
                    autoFocus
                    variant="filled"
                    style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                    label="Label"
                    value={this.state.currentGoal.label}
                    onChange={this.onState.bind(this, "label")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <div style={{ marginTop: ".5rem" }}></div>
                <TextField
                    variant="filled"
                    style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                    label="Current value"
                    type="number"
                    value={this.state.currentGoal.current}
                    onChange={this.onState.bind(this, "current")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <div style={{ marginTop: ".5rem" }}></div>
                <TextField
                    variant="filled"
                    style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                    label="Goal"
                    type="number"
                    value={this.state.currentGoal.goal}
                    onChange={this.onState.bind(this, "goal")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <div style={{ marginTop: "1.5rem" }}></div>
                <div className="hrpRow">
                    <div className="hrpCol-2-mob">
                        <Button sx={HRPButtonSecondary} variant="contained" onClick={(evt: MouseEvent) => {
                            this.setState({
                                currentGoal: this.resetVals()
                            });
                            this.props.onClose();
                            evt.preventDefault();
                        }}>Cancel</Button>
                    </div>
                    <div className="hrpCol-2-mob">
                        <Button sx={HRPButton} variant="contained" color="primary" disabled={!validGoal} onClick={async (evt: MouseEvent) => {
                            await this.props.onGoal(
                                this.state.currentGoal.homeroomId,
                                {
                                    id: this.props.initValue ? this.props.initValue.id : uuid.v4(),
                                    label: this.state.currentGoal.label,
                                    current: this.state.currentGoal.current,
                                    goal: this.state.currentGoal.goal
                                }
                            );
                            this.props.onClose();
                            evt.preventDefault();
                            this.setState({
                                currentGoal: this.resetVals()
                            });
                        }}>Post</Button>
                    </div>
                </div>
                <div style={{ marginTop: "1.5rem" }}></div>
            </div>
        </Dialog>
    }
}