import React from 'react';
import Nls from '../../nls/nls';

interface InfoNewsProps {
}

export default function InfoNews(_props: InfoNewsProps) {

    return <>
        <p>You can post News that will be sent to all
members of the {Nls.get("SELECTOR_LABEL_LOWER")}.</p>
<ul>
<li>Start by opening the add news dialog.</li>
<li>Choose a clasroom, and specify an expiration date.</li>
<li>After the expiration date, the news item will automatically be deleted.</li>
<li>Provide a subject and a message and Post it.</li>
</ul>
<p>Once a news item is posted, other members can ask questions.
Questions and the related answers are only seen by the person
asking the question and other {Nls.get("SELECTOR_LABEL_LOWER")} administrators.</p>
    </>;
}
