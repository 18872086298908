import { Button, Dialog, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme } from '@mui/material';
import React, { MouseEvent } from 'react';
import { ApiHomeroomClub } from '../../../../../../../../server/src/hrpcb/models/apiHomeroom';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import { HRPButton, HRPButtonSecondary } from '../../../../components/widgets/Customized';
import Nls from '../../../../nls/nls';
import HomeroomService from '../../../../services/HomeroomService';

interface ModalAllergyEditProps {
    open: boolean
    onAllergies: (allergies: { [hrId: string]: string}) => void
    onClose: () => void
    homerooms: ApiHomeroomClub[]
    user: ApiUser
}

interface ModalAllergyEditState {
    currentVal: {
        homeroomId: string
        allergies: {
            [hrId: string]: string
        }
    }
}

export default function ModalAllergyEdit(props: ModalAllergyEditProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return <ModalAllergyEditClass {...props} fullScreen={fullScreen}/>
}

class ModalAllergyEditClass extends React.Component<ModalAllergyEditProps & { fullScreen: boolean }, ModalAllergyEditState> {
    state: ModalAllergyEditState = {
        currentVal: this.resetVals()
    }

    componentDidUpdate(prevProps: ModalAllergyEditProps & { fullScreen: boolean }) {
        let oldAllergyVals : { [key: string]: string } = {};
        prevProps.homerooms.forEach(hr => { oldAllergyVals[hr._id] = hr.allergies || "" });
        for (const hr of this.props.homerooms) {
            if (oldAllergyVals[hr._id] !== (hr.allergies || "")) {
                this.setState({ currentVal: this.resetVals() });
            }
        }        
    }

    onState(field: "homeroomId", evt: any) {
        let newState: ModalAllergyEditState = JSON.parse(JSON.stringify(this.state));
        newState.currentVal[field] = evt.target.value;
        this.setState(newState);
    }

    onAllergy(hrId: string, evt: any) {
        let newState: ModalAllergyEditState = JSON.parse(JSON.stringify(this.state));
        newState.currentVal.allergies[hrId] = evt.target.value;
        this.setState(newState);
    }

    resetVals() {
        let updAllergies : { [key: string]: string } = {};
        for (const hr of this.props.homerooms) {
            updAllergies[hr._id] = hr.allergies || "";
        }
        return {
            homeroomId: this.props.homerooms[0]._id,
            allergies: updAllergies
        }
    }

    render() {
        const { fullScreen } = this.props;

        return <Dialog 
            open={this.props.open} 
            maxWidth="md"
            fullWidth={true}
            fullScreen={fullScreen}
            onClose={() => {
                this.props.onClose();
                this.setState({
                    currentVal: this.resetVals()
                });
            }}
            >
            <DialogTitle>Edit allergies</DialogTitle>
            <div style={{ margin: "0rem 1.5rem" }}>
                <FormControl variant="standard">
                    <InputLabel id="homeroom-label">{Nls.get("SELECTOR_LABEL")}</InputLabel>
                    <Select
                        labelId="homeroom-label"
                        id="homeroom-select"
                        value={this.state.currentVal.homeroomId}
                        onChange={(evt) => {
                            this.onState.bind(this, "homeroomId", evt);
                        }}
                    >
                        {this.props.homerooms.map((homeroom) => (
                            <MenuItem key={homeroom._id} value={homeroom._id}>{HomeroomService.classLabel(homeroom)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div style={{ marginTop: ".5rem" }}></div>
                <TextField
                    autoFocus
                    variant="filled"
                    style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                    label="Allergies"
                    value={this.state.currentVal.allergies[this.state.currentVal.homeroomId]}
                    onChange={this.onAllergy.bind(this, this.state.currentVal.homeroomId)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <div style={{ marginTop: "1.5rem" }}></div>
                <div className="hrpRow">
                    <div className="hrpCol-2-mob">
                        <Button sx={HRPButtonSecondary} variant="contained" onClick={(evt: MouseEvent) => {
                            this.props.onClose();
                            this.setState({
                                currentVal: this.resetVals()
                            });
                            evt.preventDefault();
                        }}>Cancel</Button>
                    </div>
                    <div className="hrpCol-2-mob">
                        <Button sx={HRPButton} variant="contained" color="primary" onClick={async (evt: MouseEvent) => {
                            await this.props.onAllergies(this.state.currentVal.allergies);
                            this.props.onClose();
                            evt.preventDefault();
                            this.setState({
                                currentVal: this.resetVals()
                            });
                        }}>Post</Button>
                    </div>
                </div>
                <div style={{ marginTop: "1.5rem" }}></div>
            </div>
        </Dialog>
    }
}