import React from 'react';
import Nls from '../../nls/nls';

interface InfoAllergiesProps {
}

export default function InfoAllergies(_props: InfoAllergiesProps) {
    return <>
        <p>You can list any allergies for the {Nls.get("SELECTOR_LABEL_LOWER")} that 
        others may need to be aware of, for instance, a food allergy.
Simply open the edit dialog and edit the text.</p>
    </>;
}
