import React from 'react';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbSkeleton,
    Column,
    Grid,
    Tabs,
    TabList,
    Tab,
    TabPanel,
    TabPanels,
    Tile
} from "@carbon/react";
import { WarningFilled } from '@carbon/react/icons';
import { AccountV1, IPTAccount } from '../../services/AccountV1';
import { LocationV1, IPTLocation } from '../../services/LocationV1';
import {
    NavigateFunction,
    Params,
    useNavigate,
    useParams
} from "react-router-dom";
import { LocationFields } from '../NewLocation/NewLocation';
import { LocationPackages } from "./LocationPackages";
import { LocationAccess } from './LocationAccess';
import { LocationDescription } from './LocationDescription';
import { LocationAreas } from './LocationAreas';
import { LocationAvailability, reservableAvailability } from './LocationAvailability';
import "./Location.scss";

interface LocationProps {
    navigate: NavigateFunction
    params: Params<string>
}

interface LocationState {
    editing: boolean,
    account?: IPTAccount
    confirmDelete?: {
        id: string,
        name: string
    }
    confirmDeleteTxt: string
    selectedTab: number,
    location?: IPTLocation
}

class LocationCls extends React.Component<LocationProps, LocationState> {
    state: LocationState = {
        editing: false,
        confirmDeleteTxt: "",
        selectedTab: parseInt((/#tab=(\d+)/.exec(document.location.hash) ?? ["", "0"])[1])
    }

    componentDidMount() {
        (async () => {
            let location = await LocationV1.getLocation(this.props.params["locationId"]!);
            let account = await AccountV1.getAccount(location.account_id);
            this.setState({
                account,
                location
            })
        })();
        this.refresh();
    }

    componentWillUnmount() {
        // document.body.style.backgroundColor = "inherit";
    }

    async refresh() {
    }

    async update(newLocation: Partial<IPTLocation>) {
        newLocation.id = this.state.location!.id;
        let location = await LocationV1.updateLocation(newLocation);
        this.setState({ location });
    }

    render() {
        let accountId = this.props.params["id"]!;
        let capacity = 0;
        for (const area of this.state.location?.areas || []) {
            for (const section of area.sections) {
                capacity += section.capacity;
            }
        }
        return (<>
            <div style={{ marginTop: "3rem" }} />
            <Grid>
                <Column sm={4} md={8} lg={16}>
                    {!this.state.account && <BreadcrumbSkeleton />}
                    {this.state.account && this.state.location && <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="/account/accounts">Accounts</BreadcrumbItem>
                        <BreadcrumbItem href={`/account/home/${accountId}`}>
                            {this.state.account.name}
                        </BreadcrumbItem>
                        <BreadcrumbItem href={`/account/home/${accountId}/location/home/${this.state.location.id}`} isCurrentPage>
                            {this.state.location.name}
                        </BreadcrumbItem>
                    </Breadcrumb>}
                </Column>
            </Grid>
            <main className="Location">
                <div style={{ marginTop: "1rem" }} />
                <Grid>
                    <Column sm={4} md={4} lg={6} style={{marginTop: "1rem"}}>
                        {this.state.account && <LocationFields
                            accountId={this.state.account.id}
                            editing={this.state.editing}
                            initialState={this.state.location}
                            submitLabel="Update location"
                            onSubmit={async (newLocation: Partial<IPTLocation>) => {
                                await this.update(newLocation);
                                this.setState({ location: newLocation as any });
                            }}
                        />}
                    </Column>
                    <Column sm={4} md={4} lg={6} style={{marginTop: "1rem"}}>
                        <Tile style={{ minHeight: "100%" }}>
                            <LocationAccess
                                account={this.state.account}
                                location={this.state.location}
                                onLocation={this.update.bind(this)}
                            />
                        </Tile>
                    </Column>
                </Grid>
                <div style={{ marginTop: "1rem" }} />
                <Grid>
                    <Column sm={4} md={8} lg={12}>
                        <Tabs
                            defaultSelectedIndex={this.state.selectedTab}
                            selectedIndex={this.state.selectedTab}
                            onChange={(val: { selectedIndex: number }) => {
                                document.location.hash = `#tab=${val.selectedIndex}`;
                                this.setState({ selectedTab: val.selectedIndex })
                            }}
                        >
                            <TabList aria-label="List of tabs" contained fullWidth>
                                <Tab>Description</Tab>
                                <Tab renderIcon={(!this.state.location?.packages || this.state.location?.packages.length === 0) ?
                                    WarningFilled : undefined
                                }>Packages</Tab>
                                <Tab renderIcon={
                                    (capacity === 0) ? WarningFilled : undefined
                                }>Areas / Sections</Tab>
                                <Tab renderIcon={!reservableAvailability(this.state.location) ? WarningFilled : undefined}
                                >Availability</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel style={{ margin: "-1rem" }}>
                                    <Tile>
                                        <LocationDescription
                                            location={this.state.location}
                                            onLocation={this.update.bind(this)}
                                        />
                                    </Tile>
                                </TabPanel>
                                <TabPanel style={{ margin: "-1rem" }}>
                                    <Tile>
                                        <LocationPackages
                                            location={this.state.location} 
                                            onLocation={this.update.bind(this)}
                                        />
                                    </Tile>
                                </TabPanel>
                                <TabPanel style={{ margin: "-1rem" }}>
                                    <Tile>
                                        <LocationAreas 
                                            location={this.state.location} 
                                            onLocation={this.update.bind(this)}
                                        />
                                    </Tile>
                                </TabPanel>
                                <TabPanel style={{ margin: "-1rem" }}>
                                    <Tile>
                                        <LocationAvailability 
                                            location={this.state.location} 
                                            onLocation={this.update.bind(this)}
                                        />
                                    </Tile>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>

                    </Column>
                </Grid>
                {/* <div style={{ paddingTop: "4rem" }} />
                { this.state.confirmDelete && <ModalConfirmDelete 
                    open={!!this.state.confirmDelete}
                    confirmText={this.state.confirmDelete.name}
                    onClose={async () => {
                        this.setState({ confirmDelete: undefined });
                    }}
                    onDelete={async () => {
                        await LocationV1.deleteLocation(this.state.confirmDelete!.id);
                        this.refresh();
                    }}
                />} */}
            </main>
        </>)
    }
}

export default function Location() {
    const navigate = useNavigate();
    const params = useParams();
    return <LocationCls navigate={navigate} params={params} />;
}

