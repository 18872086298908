import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Tooltip } from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiHomeroomClub } from '../../../../../../server/src/hrpcb/models/apiHomeroom';
import "./HomeroomLabel.scss";
import AddIcon from '@mui/icons-material/Add';
import HomeroomService from '../../services/HomeroomService';

interface HomeroomLabelProps {
    homeroom: ApiHomeroomClub
    onClick?: () => void
    edit?: boolean
    canAdd?: boolean
    addLabel?: string
    onEdit?: () => void
    onDelete?: () => void
    onAdd?: () => void
}

export default class HomeroomLabel extends React.Component<HomeroomLabelProps, {}> {
    render() {
        let homeroom = this.props.homeroom;
        let cls = "homeroomLabel";
        if (!!this.props.onClick) {
            cls = "homeroomLabelLink";
        }
        if (this.props.edit === true) {
            cls += " homeroomLabelEdit";
        }
        return (
            <ListItem className={cls}
                onClick={() => { this.props.onClick && this.props.onClick() }}
                onKeyDown={(evt: any) => { 
                    if (evt.key === "Enter") { 
                        this.props.onClick && this.props.onClick(); 
                    }
                }}>
                <ListItemAvatar>
                    <Avatar>
                        {homeroom.grade}
                        {/* <HomeIcon /> */}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={HomeroomService.hrClubName(homeroom)} secondary={homeroom.schoolName} />
                { this.props.edit === true && <>
                    { this.props.onEdit && 
                        <Tooltip placement="top" title="Edit">
                            <IconButton
                                style={{position: "absolute", right: "3rem"}}
                                aria-label="edit"
                                onClick={(() => { 
                                    this.props.onEdit && this.props.onEdit();
                                })}
                                size="large">
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    { this.props.onDelete && 
                        <Tooltip placement="top" title="Delete">
                            <IconButton
                                style={{position: "absolute", right: "0rem"}}
                                aria-label="delete"
                                onClick={(() => { 
                                    this.props.onDelete && this.props.onDelete();
                                })}
                                size="large">
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </>}
                { this.props.canAdd === true && this.props.onAdd && 
                    <Tooltip placement="top" title={this.props.addLabel || "Add"}>
                        <IconButton aria-label="add" size="small" onClick={(() => { 
                            this.props.onAdd && this.props.onAdd();
                        })} >
                            <AddIcon style={{color: "#2F5043"}}/>
                        </IconButton>
                    </Tooltip>
                }
            </ListItem>
        );
    }
}
