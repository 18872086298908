import React from "react";
import { IPTLocation, PTReservationTime } from "../../services/LocationV1";
import {
    Button,
    ButtonSet,
    Checkbox,
    CheckboxGroup,
    Column,
    Grid,
    InlineNotification,
    SelectItem,
    TimePicker,
    TimePickerSelect
} from "@carbon/react";
import ModalBasic from "../../../../components/ModalBasic";
import { clone } from "../../../../util/MiscUtil";
import { TimeUtil } from "../../../../util/TimeUtil";
import { LocationAvailabilityUpdateModal } from "./LocationAvailabilityUpdateModal";

const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

interface LocationAvailabilityState {
    dialog: "" | "add_weekly" | "add_day" | "modify_day"

    dialogTimes: PTReservationTime[],
    dialogDOWs: number[]
}

export class LocationAvailability extends React.Component<{
    location?: IPTLocation,
    onLocation: (location: IPTLocation) => Promise<void>
}, LocationAvailabilityState> {
    state: LocationAvailabilityState = {
        dialog: "",
        dialogTimes: [],
        dialogDOWs: [],
    }

    renderTimes(times: PTReservationTime[]) {
        if (!times) return <></>
        times.sort((a, b) => {
            let partsA = a.start.split(":");
            let partsB = b.start.split(":");
            return parseInt(partsA[0]) - parseInt(partsB[0]);
        })
        return <>
            {(!times || times.length === 0) && <div>No regularly reservable times on this day</div>}
            {times?.map((time: PTReservationTime) => (
                <div key={time.start + "-" + time.end}>{TimeUtil.conv24to12Str(time.start)}&ndash;{TimeUtil.conv24to12Str(time.end)}</div>
            ))}
        </>
    }

    daySchedule(location?: IPTLocation, dayOfWeek?: number) {
        if (!location || dayOfWeek === undefined || dayOfWeek < 0 || dayOfWeek > 6) return <></>;
        let times = this.props.location?.reservableSchedule
            && this.props.location?.reservableSchedule[dayOfWeek];
        return <>
            <div style={{ marginTop: ".5rem" }}><strong>{dayNames[dayOfWeek]}</strong></div>
            {this.renderTimes(times)}
        </>
    }

    updateAddTime(s: "start" | "end") {
        let chkdlg_time = document.getElementById(`chkdlg_time_${s}`) as HTMLInputElement;
        let dlgUpdate = clone(this.state.dialogTimes);
        if (!chkdlg_time || !TimeUtil.isHHMM12(chkdlg_time.value)) {
            dlgUpdate[0][s] = "";
        } else {
            let ampm = (document.getElementById(`chkdlg_time_${s}_select`) as HTMLInputElement).value;
            dlgUpdate[0][s] = TimeUtil.conv12to24({timeStr: chkdlg_time.value, ampm: ampm as "AM" | "PM"});
        }
        this.setState({ dialogTimes: dlgUpdate });
    }

    render() {
        let isReservable = reservableAvailability(this.props.location);
        let chkdlg_time_start = document.getElementById("chkdlg_time_start") as HTMLInputElement;
        let chkdlg_time_end = document.getElementById("chkdlg_time_end") as HTMLInputElement;
        return <>
            {!isReservable && <><InlineNotification hideCloseButton={true} lowContrast={true}
                title="Not reservable"
                subtitle="No availability has been defined for this location"
            />
                <div style={{ marginTop: "1rem" }} />
            </>}
            <ButtonSet>
                <Button
                    kind="primary"
                    onClick={() => {
                        this.setState({ dialog: "modify_day" });
                    }}>Modify schedule</Button>
            </ButtonSet>
            <Grid>
                <Column sm={4} md={6} lg={9} style={{
                    paddingBottom: ".75rem",
                    borderBottom: "solid #999 1px",
                    marginBottom: ".5rem"
                }}>

                    <div style={{ marginTop: "1rem" }} />
                    <div><h2>Regular availability:</h2></div>
                </Column>
            </Grid>
            <Grid>
                {[1, 2, 3, 4, 5, 6, 0].map(dow => (
                    <Column key={`dow_${dow}`} sm={2} md={3} lg={3} style={{
                        paddingBottom: ".75rem",
                        borderBottom: "solid #ccc 1px",
                        marginBottom: ".5rem"
                    }}>
                        {this.daySchedule(this.props.location, dow)}
                    </Column>
                ))}
            </Grid>
            <Grid>
                <Column sm={4} md={6} lg={9} style={{
                    paddingBottom: ".75rem",
                    borderBottom: "solid #999 1px",
                    marginBottom: ".5rem"
                }}>
                    <div style={{ marginTop: "1rem" }} />
                    <div><h2>Holidays / adjustments:</h2></div>
                </Column>
            </Grid>
            <Grid>
                {this.props.location?.reservableDays && Object.keys(this.props.location.reservableDays).map(dateStr => {
                    let [year, month, day] = dateStr.split("/");
                    return <Column key={dateStr} sm={2} md={3} lg={3} style={{
                        paddingBottom: ".75rem",
                        borderBottom: "solid #ccc 1px",
                        marginBottom: ".5rem"
                    }}>
                        <div style={{ marginTop: ".5rem" }}><strong>{month}/{day}/{year}</strong></div>
                        {this.renderTimes(this.props.location!.reservableDays[dateStr])}
                    </Column>
                })}
            </Grid>
            <ModalBasic
                open={this.state.dialog === "add_weekly"}
                heading="Add weekly time"
                primaryButtonText="Add"
                isDisabled={() => {
                    return !chkdlg_time_start || !chkdlg_time_end
                        || !TimeUtil.isHHMM12(chkdlg_time_start.value)
                        || !TimeUtil.isHHMM12(chkdlg_time_end.value)
                }}
                onReset={async () => {
                    this.setState({
                        dialogDOWs: [6, 0, 1, 2, 3, 4, 5],
                        dialogTimes: [{
                            start: "",
                            end: ""
                        }]
                    })
                }}
                onSubmit={async () => {
                    let locationUpdate = clone(this.props.location)!;
                    locationUpdate.reservableSchedule = locationUpdate.reservableSchedule || {}
                    for (const dowNum of this.state.dialogDOWs) {
                        locationUpdate.reservableSchedule[dowNum] = locationUpdate.reservableSchedule[dowNum] || [];
                        locationUpdate.reservableSchedule[dowNum] =
                            locationUpdate.reservableSchedule[dowNum].concat(
                                this.state.dialogTimes);
                    }
                    this.props.onLocation(locationUpdate);
                }}
                onClose={async () => {
                    this.setState({ dialog: "" })
                }}
                hasScrollingContent={true}
                size="md"
            >
                {this.state.dialog === "add_weekly" && <>
                    <Grid>
                        <Column sm={2} md={4} lg={6}>
                            <TimePicker id={`chkdlg_time_start`}
                                labelText="Reservation start time"
                                onChange={() => {
                                    this.updateAddTime("start");
                                }}
                            >
                                <TimePickerSelect
                                    id={`chkdlg_time_start_select`}
                                    onChange={() => {
                                        this.updateAddTime("start");
                                    }}
                                >
                                    <SelectItem value="AM" text="AM" />
                                    <SelectItem value="PM" text="PM" />
                                </TimePickerSelect>
                            </TimePicker>
                            <div style={{ marginTop: "1rem" }} />
                            <TimePicker id={`chkdlg_time_end`}
                                labelText="Reservation end time"
                                onChange={() => {
                                    this.updateAddTime("end");
                                }}
                            >
                                <TimePickerSelect
                                    id={`chkdlg_time_end_select`}
                                    onChange={() => {
                                        this.updateAddTime("end");
                                    }}
                                >
                                    <SelectItem value="AM" text="AM" />
                                    <SelectItem value="PM" text="PM" />
                                </TimePickerSelect>
                            </TimePicker>
                        </Column>
                        <Column sm={2} md={4} lg={6}>
                            <CheckboxGroup legendText="Days to add to">
                                {[6, 0, 1, 2, 3, 4, 5].map(dowNum => {
                                    let dayName = dayNames[dowNum];
                                    return <Checkbox
                                        id={`chkdlg_dow_${dayName}`}
                                        labelText={dayName}
                                        checked={this.state.dialogDOWs.includes(dowNum)}
                                        onChange={(_, { checked }) => {
                                            if (checked) {
                                                let update = new Set(this.state.dialogDOWs);
                                                update.add(dowNum);
                                                this.setState({ dialogDOWs: Array.from(update) })
                                            } else {
                                                this.setState({ dialogDOWs: this.state.dialogDOWs.filter(dow => dow !== dowNum) })
                                            }
                                        }}
                                    />
                                })}
                            </CheckboxGroup>
                        </Column>
                    </Grid>
                </>}
            </ModalBasic>
            <ModalBasic
                open={this.state.dialog === "add_day"}
                heading="Adjust day time"
                primaryButtonText="Add"
                isDisabled={() => false}
                onReset={async () => {
                }}
                onSubmit={async () => {
                }}
                onClose={async () => {
                    this.setState({ dialog: "" })
                }}
                hasScrollingContent={true}
                size="md"
            >
            </ModalBasic>
            <LocationAvailabilityUpdateModal
                open={this.state.dialog === "modify_day"}
                location={this.props.location}
                onLocation={this.props.onLocation}
                onClose={async () => {
                    this.setState({ dialog: "" })
                }}
            />
        </>
    }
}

export function reservableAvailability(location?: IPTLocation) {
    let isReservable = false;
    if (location) {
        if (location.reservableSchedule) {
            let values = Object.values(location.reservableSchedule);
            for (let idx = 0; !isReservable && idx < values.length; ++idx) {
                const value = values[idx];
                isReservable = isReservable || value.length > 0;
            }
        }
        if (location.reservableDays) {
            const today = new Date();
            let days = Object.keys(location.reservableDays);
            for (let iDay=0; !isReservable && iDay<days.length; ++iDay) {
                let dateStr = days[iDay];
                let [year, month, day] = dateStr.split("/");
                // If in the past, skip
                if ((parseInt(year) < today.getFullYear())
                    || (parseInt(year) === today.getFullYear() 
                        && parseInt(month) < (today.getMonth() + 1))
                    || (parseInt(year) === today.getFullYear()
                        && parseInt(month) === (today.getMonth() + 1)
                        && parseInt(day) < today.getDate())
                ) continue;
                let times = Object.keys(location.reservableDays[`${year}/${month}/${day}`]);
                // It's either today or in the future
                isReservable = isReservable || times.length > 0;
            }
        }
    }
    return isReservable;
}