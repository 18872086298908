import React from 'react';
import { Grid, Column } from "@carbon/react";
import { SiteKey } from '../../../../models/Site';
import "./VerifyFail.scss";

interface VerifyFailProps {
    siteKey: SiteKey
}

interface VerifyFailState {
}

export default class VerifyFail extends React.Component<VerifyFailProps, VerifyFailState> {
    state = {}

    resend() {
        fetch(`/api/${this.props.siteKey}/login/resend`);
    }
    render() {
        return (<>
            {/* <HomeNav siteKey={this.props.siteKey}></HomeNav> */}
            <div style={{ marginTop: "3rem" }} />
            <main>
                <Grid>
                    <Column sm={4} md={6} lg={8}>
                        <p>Thank you for reporting that the request to add your email to
                        an account was invalid. We have removed your email from the
                        specified account.</p>
                    </Column>
                </Grid>
            </main>
        </>)
    }
}