import React from 'react';
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';
import { Button } from '@carbon/react';
import "./Login.scss";
import { Grid } from '@carbon/react';
import { Column } from '@carbon/react';
import { TextInput } from '@carbon/react';

class PWResetPWCls extends React.Component<{location?:any}> {
    state = {
        password: "",
        passwordVerify: ""
    }

    changePassword(event: any) {
        this.setState({password: event.target.value});
    };

    changePasswordVerify(event: any) {
        this.setState({passwordVerify: event.target.value});
    };

    async requestReset(userId: string, code: string) {
        const response = await fetch(`/api/common/user/reset_pw?userId=${userId}&code=${code}`, { 
            headers: { 
                "Content-type": "application/json"
            }, 
            method: "POST", 
            body: JSON.stringify({
                password: this.state.password
            })
        });
        let data = await response.json();
        if (data.ok) {
            await fetch(`/api/common/user/logout`);
            document.location.href = "/login";
            return true;
        } else {
            console.log(data);
            return false;
        }
    }

    async requestResetCancel(userId: string, code: string) {
        document.location.href = `/api/common/user/reset_cancel?userId=${userId}&code=${code}`;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const validPWResetPW = this.state.password.length >= 12 && this.state.password === this.state.passwordVerify;
        let userId : string | null = null;
        let code : string | null = null;
        let parts = this.props.location.search.split(/[?&]/);
        const startsWith = (s1: string, s2: string) => s1.substring(0,s2.length) === s2;
        for (const part of parts) {
            if (startsWith(part, "userId=")) {
                userId = part.substring("userId=".length);
            } else if (startsWith(part, "code=")) {
                code = part.substring("code=".length);
            }
        }
        if (!userId || !code) {
            return (<main>Invalid parameters</main>)
        }
        return <>
        <Helmet>
            <title>Password reset</title>
        </Helmet>
        <main>
            <Grid className="login">
                <Column sm={4} md={{offset: 2, span: 4}} lg={{offset: 5, span: 6}}>
                    <h2>Create new password</h2>
                    <div onKeyDown={async (evt: any) => {
                        if (evt.key === "Enter" && validPWResetPW) {
                            await this.requestReset(userId!, code!);
                        }
                    }}>
                        <TextInput 
                            labelText="Password" 
                            type="password" 
                            value={this.state.password} 
                            onChange={this.changePassword.bind(this)} 
                            helperText="Password must be at least 12 characters"
                        />
                        <div style={{marginTop: "1rem"}} />
                        <TextInput labelText="Verify Password" type="password" 
                            value={this.state.passwordVerify} onChange={this.changePasswordVerify.bind(this)} />
                    </div>
                    <div style={{marginTop:"40px"}}><Button kind="primary" disabled={!validPWResetPW} onClick={async () => {
                        await this.requestReset(userId!, code!);
                    }}>Change password</Button></div>
                    <div style={{marginTop:"1rem"}}><Button kind="secondary" onClick={async () => {
                        await this.requestResetCancel(userId!, code!);
                    }}>Cancel change</Button></div>
                </Column>
            </Grid>
        </main></>;
    }
}

export default function PWResetPW() {
    let location = useLocation();
    return <PWResetPWCls location={location} />
}