import React from 'react';
import { TimePicker, TimePickerSelect, SelectItem } from '@carbon/react';

export interface Time12 {
    time: string,
    ampm: "AM" | "PM"
}

export function convert24to12(value?: string | null) : Time12 | undefined | null {
    if (typeof value === "undefined" || value === null) return value;

    let newState : Time12 = {
        time: value || "",
        ampm: "AM"
    }
    let m = (""+value).match(/^([01]?[0-9]|2[0-3])(:[0-5][0-9])?$/);
    if (m) {
        let h = parseInt(m[1]);
        if (h === 0) {
            newState.time = "12";
            if (m[2].length > 0) {
                newState.time += m[2];
            }
        } else if (h === 12) {
            newState.ampm = "PM";
        } else if (h > 12) {
            newState.time = ""+(h-12)
            if (m[2].length > 0) {
                newState.time += m[2];
            }
            newState.ampm = "PM";
        }
        return newState;
    }
    return null;
}

export function convert12to24(value?: Time12 | null) : string | undefined | null {
    if (typeof value === "undefined" || value === null) return value;

    let m = value.time.match(/^(0?[0-9]|1[0-2])(:[0-5][0-9])?$/);
    if (m) {
        let h = parseInt(m[1]);
        if (h === 12) {
            if (value.ampm === "AM") {
                h = 0;
            }
        } else if (value.ampm === "PM") {
            h += 12;
        }
        let min = !m[2] || m[2].length === 0 ? ":00" : m[2];
        return h+min;
    } else {
        return null;
    }
}

interface TimePicker12Props {
    id: string
    labelText: string
    value?: string | null
    onChange?: (val?: string | null) => void
}

export default class TimePicker12 extends React.Component<TimePicker12Props, Time12> {
    state : Time12 = {
        time: "",
        ampm: "AM"
    }

    componentDidMount(): void {
        let value = convert24to12(this.props.value);
        if (value) {
            this.setState(value);
        }
    }

    componentDidUpdate(prevProps: Readonly<TimePicker12Props>, _prevState: Readonly<Time12>, _snapshot?: any): void {
        if (this.props.value !== prevProps.value) {
            let myTime = convert12to24(this.state);
            if (myTime !== this.props.value) {
                // We have been given a different time / value
                let newTime = convert24to12(this.props.value);
                if (typeof newTime === "undefined") {
                    this.setState( { time: "", ampm: "AM" });
                } else if (newTime) {
                    this.setState(newTime);
                }
            }
        }
    }

    render() {
        return <TimePicker 
            id={this.props.id}
            labelText={this.props.labelText}
            value={this.state.time}
            invalid={this.state.time.length > 0 && !this.state.time.match(/^(1[012]|0?[1-9])(:[0-5][0-9](\s)?)?$/)}
            onChange={(evt) => {
                let time = evt.target.value;
                this.setState({ time });
                if (this.props.onChange) {
                    this.props.onChange(convert12to24({
                        time: time,
                        ampm: this.state.ampm
                    }));
                }
            }}
        >
            <TimePickerSelect 
                id={this.props.id+"-ampm"} 
                value={this.state.ampm}
                onChange={(evt) => {
                    let ampm = evt.target.value;
                    this.setState({ ampm });
                    if (this.props.onChange) {
                        this.props.onChange(convert12to24({
                            time: this.state.time,
                            ampm: ampm
                        }));
                    }
                }}
            >
                <SelectItem text="AM" value="AM" />
                <SelectItem text="PM" value="PM" />
            </TimePickerSelect>
        </TimePicker>;
    }
}