import React from 'react';
import { Grid, Column } from "@carbon/react";

import './Header.scss';
import { SiteKey } from '../models/Site';
import { Link } from 'react-router-dom';
import MyTheme from './MyTheme';

interface HeaderProps {
    siteKey: SiteKey
}

interface HeaderState {
}

export default class Header extends React.Component<HeaderProps, HeaderState> {

    render() {
        return (
            <MyTheme siteKey={this.props.siteKey} theme="brand">
                <header>
                    <Grid>
                        <Column sm={4} md={2} lg={8} style={{marginTop: "1rem"}}>
                            <span className="name">
                                { this.props.siteKey === "pt" && 
                                    <Link to="/">Party<span style={{ fontWeight: "bold" }}>Taken</span></Link>}
                                { this.props.siteKey === "ct" && 
                                    <Link to="/">Camp<span style={{ fontWeight: "bold" }}>Taken</span></Link>}
                            </span>
                        </Column>
                        <Column sm={4} md={6} lg={8} className="rightNav" style={{marginTop: "1.2rem", marginBottom: "1rem"}}>
                            <Link className="clean" to="/">Home</Link>
                            <span className="spacer">|</span>
                            <Link className="clean" to="/about">About</Link>
                            <span className="spacer">|</span>
                            <Link className="clean" to="/contactus">Contact Us</Link>
                        </Column>
                    </Grid>
                </header>
            </MyTheme>
        );
    }
}
