import React from 'react';
import { Box, Grid, TextField, Hidden } from '@mui/material';
import { Helmet } from "react-helmet";

import "./Login.scss";
import Nls from '../../nls/nls';
import { useLocation } from 'react-router-dom';
import { Button } from '@carbon/react';

class PWResetPWCls extends React.Component<{location?:any}> {
    state = {
        password: "",
        passwordVerify: ""
    }


    changePassword(event: any) {
        this.setState({password: event.target.value});
    };

    changePasswordVerify(event: any) {
        this.setState({passwordVerify: event.target.value});
    };

    async requestReset(userId: string, code: string) {
        const response = await fetch(`/api/common/user/reset_pw?userId=${userId}&code=${code}`, { 
            headers: { 
                "Content-type": "application/json"
            }, 
            method: "POST", 
            body: JSON.stringify({
                password: this.state.password
            })
        });
        let data = await response.json();
        if (data.ok) {
            await fetch(`/api/common/user/logout`);
            document.location.href = "/login";
            return true;
        } else {
            console.log(data);
            return false;
        }
    }

    async requestResetCancel(userId: string, code: string) {
        document.location.href = `/api/common/user/reset_cancel?userId=${userId}&code=${code}`;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const validPWResetPW = this.state.password.length >= 12 && this.state.password === this.state.passwordVerify;
        let userId : string | null = null;
        let code : string | null = null;
        let parts = this.props.location.search.split(/[?&]/);
        const startsWith = (s1: string, s2: string) => s1.substring(0,s2.length) === s2;
        for (const part of parts) {
            if (startsWith(part, "userId=")) {
                userId = part.substring("userId=".length);
            } else if (startsWith(part, "code=")) {
                code = part.substring("code=".length);
            }
        }
        if (!userId || !code) {
            return (<main>Invalid parameters</main>)
        }
        return <>
        <Helmet>
            <title>Password reset - {Nls.get("SITE_TITLE")}</title>
        </Helmet>
        <main>
            <Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Hidden mdDown>
                        <Grid item xs></Grid>
                    </Hidden>
                    <Grid item xs className="login">
                        <div>
                            <div><h2>Create new password</h2></div>
                            <div onKeyDown={async (evt: any) => {
                                if (evt.key === "Enter" && validPWResetPW) {
                                    await this.requestReset(userId!, code!);
                                }
                            }}>
                                <div><TextField fullWidth label="Password" type="password" 
                                        value={this.state.password} onChange={this.changePassword.bind(this)}></TextField></div>
                                <div style={{marginTop: "1rem"}} />
                                <div><TextField fullWidth label="Verify Password" type="password" 
                                        value={this.state.passwordVerify} onChange={this.changePasswordVerify.bind(this)}></TextField></div>
                            </div>
                            <div style={{marginTop:"40px"}}><Button kind="primary" disabled={!validPWResetPW} onClick={async () => {
                                await this.requestReset(userId!, code!);
                            }}>Change password</Button></div>
                            <div style={{marginTop:"1rem"}}><Button kind="secondary" onClick={async () => {
                                await this.requestResetCancel(userId!, code!);
                            }}>Cancel change</Button></div>
                        </div>
                    </Grid>
                    <Hidden mdDown>
                        <Grid item xs></Grid>
                    </Hidden>
                </Grid>
            </Box>
        </main></>;
    }
}

export default function PWResetPW() {
    let location = useLocation();
    return <PWResetPWCls location={location} />
}