import React from 'react';
import { Grid, Column } from "@carbon/react";
import { SiteKey } from '../../../../models/Site';
import UserV1 from '../../../../services/UserV1';
import "./VerifyLogin.scss";

interface VerifyLoginProps {
    siteKey: SiteKey
}

interface VerifyLoginState {
}

export default class VerifyLogin extends React.Component<VerifyLoginProps, VerifyLoginState> {
    state = {}

    componentDidMount() {
        setInterval(async () => {
            let user = await UserV1.getUser();
            if (user) {
                document.location.href = "/";
            }
        }, 10000);
    }

    componentWillUnmount() {
    }

    resend() {
        fetch(`/api/${this.props.siteKey}/login/resend`);
    }
    render() {
        return (<>
            {/* <HomeNav siteKey={this.props.siteKey}></HomeNav> */}
            <div style={{ marginTop: "3rem" }} />
            <main>
                <Grid>
                    <Column sm={4} md={6} lg={8}>
                        <p>The email address for this account has not been verified. Please check your
                        email for the verification email in order to proceed. If you have not
                        received it, please check your spam folder and ensure that you provided the correct email address.
                        You may request to <a href="#resend" onClick={this.resend.bind(this)}>resend the verification email.</a></p>
                    </Column>
                </Grid>
            </main>
        </>)
    }
}