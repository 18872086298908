import React, { ReactNode } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AddCircleOutline } from '@mui/icons-material';
import "./HRPTable.scss";

/*MuiTablePagination-root
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
    { id: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
    { id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
    { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
    { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
];
*/

function descendingComparator(a: any, b: any, orderBy: string) : 0 | 1 | -1 {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order: "asc" | "desc", orderBy: string) : (a: any, b: any) => 0 | 1 | -1 {
    return order === 'desc'
        ? (a: any, b: any) : 0 | 1 | -1 => descendingComparator(a, b, orderBy)
        : (a: any, b: any) : 0 | 1 | -1 => (-descendingComparator(a, b, orderBy) as 0 | 1 | -1);
}

function stableSort<HRPRow>(array: HRPRow[], comparator: (a: any, b: any) => 1 | -1 | 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return (a[1] as any) - (b[1] as any);
    });
    return stabilizedThis.map((el) => el[0]);
}

export interface HRPTableHeadCell {
    id: string
    numeric: boolean
    disablePadding: boolean
    label: string
}

interface HRPTableHeadProps {
    headCells: Array<HRPTableHeadCell>
    onSelectAllClick: (evt: any) => void
    order: "asc" | "desc"
    orderBy: string
    numSelected: number
    rowCount: number
    onRequestSort: (evt: any, property: string) => void
    selectable: boolean
}

interface HRPTableHeadState {
}

export class HRPTableHead extends React.Component<HRPTableHeadProps, HRPTableHeadState> {
    createSortHandler(property: string) {
        return (event: any) => {
            this.props.onRequestSort(event, property);
        }
    };

    render() {
        return (
            <TableHead>
                <TableRow>
                    {this.props.selectable && this.props.rowCount > 0 && <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={this.props.numSelected > 0 && this.props.numSelected < this.props.rowCount}
                            checked={this.props.rowCount > 0 && this.props.numSelected === this.props.rowCount}
                            onChange={this.props.onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                    }
                    {this.props.headCells.map((headCell: HRPTableHeadCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={this.props.orderBy === headCell.id ? this.props.order : false}
                        >
                            <TableSortLabel
                                active={this.props.orderBy === headCell.id}
                                direction={this.props.orderBy === headCell.id ? this.props.order : 'asc'}
                                onClick={this.createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {this.props.orderBy === headCell.id ? (
                                    <span className="a11y">
                                        {this.props.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
}

interface HRPTableToolbarProps {
    onAdd?: () => void
    onDelete?: () => void
    onEdit?: () => void
    numSelected: number
    title: string
}
export class HRPTableToolbar extends React.Component<HRPTableToolbarProps, {}> {

    render() {
        return (
            <Toolbar>
                <Typography variant="h6" id="tableTitle" component="div">
                    {this.props.title}
                </Typography>
                {this.props.numSelected > 0 && (
                    <Typography color="inherit" variant="subtitle1" component="div">
                        &nbsp;&mdash; {this.props.numSelected} selected
                    </Typography>
                )}
                  

                {this.props.numSelected > 0 ? (<>
                    { this.props.onDelete && <Tooltip placement="top" title="Delete">
                        <IconButton
                            aria-label="delete"
                            onClick={() => { this.props.onDelete!()}}
                            size="large">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>}
                    { this.props.numSelected === 1 && this.props.onEdit && <Tooltip placement="top" title="Edit">
                    <IconButton aria-label="edit" onClick={() => { this.props.onEdit!()}} size="large">
                        <EditIcon />
                    </IconButton>
                </Tooltip>}
                </>) : (
                        this.props.onAdd && <Tooltip placement="top" title="Add">
                            <IconButton aria-label="Add" onClick={() => { this.props.onAdd!()}} size="large">
                                <AddCircleOutline />
                            </IconButton>
                        </Tooltip>
                    )}
            </Toolbar>
        );
    }
};

interface HRPTableProps<RowType> {
    title: string
    headCells: Array<HRPTableHeadCell>
    rows: Array<RowType>
    onAdd?: () => void
    onDelete?:(ids: string[]) => void
    onEdit?:(id: string) => void
    emptyContent: ReactNode,
    rowsPerPage?: number
}

interface HRPTableState {
    order: "asc" | "desc"
    orderBy: string
    selected: string[]
    page: number
    rowsPerPage: number
}

export default class HRPTable<RowType extends { id: string }> extends React.Component<HRPTableProps<RowType>, HRPTableState> {
    state: HRPTableState = {
        order: "asc",
        orderBy: "",
        selected: [],
        page: 0,
        rowsPerPage: this.props.rowsPerPage || 10
    }

    handleRequestSort(_event: any, property: string) {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
    }

    handleSelectAllClick(event: any) {
        if (!!this.props.onDelete) {
            if (event.target.checked) {
                const newSelecteds = this.props.rows.map((n) => n.id);
                this.setState({ selected: newSelecteds });
                return;
            }
            this.setState({ selected: [] });
        }
    };

    handleClick(_event: any, id: string) {
        if (!!this.props.onDelete) {
            const selectedIndex = this.state.selected.indexOf(id);
            let newSelected : string[] = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(this.state.selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(this.state.selected.slice(1));
            } else if (selectedIndex === this.state.selected.length - 1) {
                newSelected = newSelected.concat(this.state.selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    this.state.selected.slice(0, selectedIndex),
                    this.state.selected.slice(selectedIndex + 1),
                );
            }

            this.setState({ selected: newSelected });
        }
    };

    handleChangePage(_event: any, newPage: number) {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage(event: any) {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        })
    };

    isSelected(id: string) {
        return this.state.selected.indexOf(id) !== -1;
    }

    render() {
        const emptyRows = 0;//this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.props.rows.length - this.state.page * this.state.rowsPerPage);
        let hasRowAction = !!this.props.onDelete;
        return (
            <div className="hrpTable">
                <Paper>
                    <HRPTableToolbar 
                        title={this.props.title} 
                        onAdd={this.props.onAdd} 
                        onDelete={this.props.onDelete && (() => {
                            this.props.onDelete!(this.state.selected);
                            this.setState({selected: []});
                        })}
                        onEdit={this.props.onEdit && (() => {
                            this.props.onEdit!(this.state.selected[0]);
                        })}
                        numSelected={this.state.selected.length} />
                    {this.props.rows.length === 0 && this.props.emptyContent}
                    {this.props.rows.length > 0 &&
                        <TableContainer>
                            <Table
                                aria-labelledby="tableTitle"
                                size="medium"
                                aria-label="enhanced table"
                            >
                                <HRPTableHead
                                    selectable={hasRowAction}
                                    headCells={this.props.headCells}
                                    numSelected={this.state.selected.length}
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onSelectAllClick={this.handleSelectAllClick.bind(this)}
                                    onRequestSort={this.handleRequestSort.bind(this)}
                                    rowCount={this.props.rows.length}
                                />
                                <TableBody>
                                    {stableSort(this.props.rows, getComparator(this.state.order, this.state.orderBy))
                                        .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                        .map((row: any, index: number) => {
                                            const isItemSelected = this.isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => this.handleClick(event, row.id)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                >
                                                    {hasRowAction && <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </TableCell>}
                                                    {this.props.headCells.map((headCell) => {
                                                        if (headCell.id === "id") {
                                                            return <TableCell key={headCell.id} component="th" id={labelId} scope="row">
                                                                {row.id}
                                                            </TableCell>
                                                        } else {
                                                            return <TableCell key={headCell.id} align={headCell.numeric ? "right" : "left"}>
                                                                {row[headCell.id]}
                                                            </TableCell>
                                                        }
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={this.props.rows.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage.bind(this)}
                        onRowsPerPageChange={this.handleChangeRowsPerPage.bind(this)}
                    />
                </Paper>
            </div>
        );
    }
}
