import React from 'react';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import { ApiHomeroomClub } from '../../../../../../../../server/src/hrpcb/models/apiHomeroom';
import "./SectionIdeas.scss";
import TagService from '../../../../services/TagService';
import Nls from '../../../../nls/nls';

interface SectionIdeasState {
}

interface SectionIdeasProps {
    user: ApiUser
    homerooms: ApiHomeroomClub[]
}

type ISection = { 
    [section: string] : {
        label: string,
        description?: string,
        virtualPages?: Array<{
            label: string
            url: string
        }>,
        pages?: Array<{
            label: string
            url: string
        }>
    }
}

const sectionsClub : ISection = {
    // august
    backtoschool: {
        label: "Back to School",
        virtualPages: [],
        pages: [
        ]
    },
    // sept 6
    laborday: {
        label: "Labor Day",
        virtualPages: [],
        pages: []
    },
    // Oct 1
    custodialworkers: {
        label: "National Custodial Workers Day",
        virtualPages: [],
        pages: []
    },
    // Oct 6
    walktoschool: {
        label: "National Walk to School Day",
        virtualPages: [],
        pages: []
    },
    // late october
    redribbonweek: {
        label: "Red Ribbon Week",
        virtualPages: [],
        pages: []
    },
    // oct
    halloween: {
        label: "Halloween",
        virtualPages: [],
        pages: [
        ]
    },
    // early nov
    electionday: {
        label: "Election Day",
        virtualPages: [],
        pages: []
    },
    // nov 11
    veterans: {
        label: "Veterans Day",
        virtualPages: [],
        pages: [
            { label: "Small flags", url: "https://www.amazon.com/dp/B07FS8YVT7" }
        ]
    },
    // late nov
    thanksgiving: {
        label: "Thanksgiving",
        virtualPages: [],
        pages: []
    },
    // dec 5
    volunteerday: {
        label: "International Volunteer Day",
        virtualPages: [],
        pages: []
    },
    // dec 25
    winterholidays: {
        label: "Winter Holidays",
        virtualPages: [],
        pages: []
    },
    // feb 14
    valentine: {
        label: "Valentine's Day",
        virtualPages: [
        ],
        pages: [
        ]
    },
    // mar 17
    stpatrick: {
        label: "St. Patrick's Day",
        virtualPages: [],
        pages: [
        ]
    },
    // April 27
    adminprofessional: {
        label: "Nation Administrative Professional Day",
        virtualPages: [],
        pages: []
    },
    // spring
    easter: {
        label: "Easter",
        virtualPages: [],
        pages: []
    },
    // may 3
    teacherappreciation: {
        label: "Teacher's Appreciation",
        virtualPages: [],
        pages: []
    },
    // may 30
    memorialday: {
        label: "Memorial Day",
        virtualPages: [],
        pages: []
    },
    // may
    graduation: {
        label: "Graduation",
        virtualPages: [],
        pages: []
    },
    clubevent: {
        label: "Meetings / Events",
        virtualPages: [],
        pages: [
            { label: "Icebreaker games", url: "https://www.amazon.com/dp/B06XH1HX7N" },
            { label: "Coffee", url: "https://www.amazon.com/dp/B0154AVVSK" },
            { label: "Coffee Urn / Maker", url: "https://www.amazon.com/dp/B000ON9TKU" },
            { label: "Paper plates", url: "https://www.amazon.com/dp/B093WX7Q9G" },
            { label: "Paper bowls", url: "https://www.amazon.com/dp/B093XC5NW5" },
            { label: "Coffee cups", url: "https://www.amazon.com/dp/B00F2P9CYI" },
            { label: "Swiss Miss Hot Cocoa Mix", url: "https://www.amazon.com/dp/B07KK66VKC"},
            { label: "Awards Night table covers", url: "https://www.amazon.com/dp/B07BBF8XYT" },
            { label: "Candles", url: "https://www.amazon.com/dp/B07DWH5J2S" }
        ]
    },
    cluboutdoor: {
        label: "Outdoor",
        virtualPages: [],
        pages: [
            { label: "Popup tents", url: "https://www.amazon.com/dp/B07VX14HKQ" },
            { label: "Shade chair", url: "https://www.amazon.com/dp/B016MOIIM4" },
            { label: "Folding table", url: "https://www.amazon.com/dp/B077M8CFDV" },
            { label: "Bleacher chair", url: "https://www.amazon.com/dp/B079LGGZVN"}
        ]
    }
}

const sectionsHR : ISection = {
    // august
    backtoschool: {
        label: "Back to School",
        virtualPages: [],
        pages: [
            { label: "Decorations", url: "https://www.amazon.com/dp/B089ZYLR1F" },
            { label: "Mother/daughter matching bracelets", url: "https://www.amazon.com/dp/B098WVXX4H" },
            { label: "Post-Its", url: "https://www.amazon.com/dp/B086DF1CR3"},
            { label: "Sharpened #2 pencils", url: "https://www.amazon.com/dp/B000JEAAP2"},
            { label: "Black pens", url: "https://www.amazon.com/dp/B001GAOTSW"},
            { label: "Red pens", url: "https://www.amazon.com/dp/B0000AQNTF"},
            { label: "Highlighters", url: "https://www.amazon.com/dp/B00006IEJ9"},
            { label: "Erasers", url: "https://www.amazon.com/dp/B00FXCZP5U"},
            { label: "Markers", url: "https://www.amazon.com/dp/B00IYDM814"},
            { label: "Crayons", url: "https://www.amazon.com/dp/B0096XWNNY"}
        ]
    },
    // sept 6
    laborday: {
        label: "Labor Day",
        virtualPages: [],
        pages: []
    },
    // Oct 1
    custodialworkers: {
        label: "National Custodial Workers Day",
        virtualPages: [],
        pages: []
    },
    // Oct 6
    walktoschool: {
        label: "National Walk to School Day",
        virtualPages: [],
        pages: [
            { label: "Dinosaur Costume", url: "https://www.amazon.com/dp/B00TO6E0T8" },
            { label: "Shark costume", url: "https://www.amazon.com/dp/B0147ARIWQ" }
        ]
    },
    // late october
    redribbonweek: {
        label: "Red Ribbon Week",
        virtualPages: [],
        pages: []
    },
    // oct
    halloween: {
        label: "Halloween",
        virtualPages: [],
        pages: [
            { label: "Bingo", url: "https://www.amazon.com/dp/B086BHZFCT"},
            { label: "Pumpkin decorating kit", url: "https://www.amazon.com/dp/B08BHYD7K2" },
            { label: "Halloween coloring books", url: "https://www.amazon.com/dp/B07H9D7FTL" },
            { label: "Pumpkin sticker crafts", url: "https://www.amazon.com/dp/B07W5V4Q6B" },
            { label: "Ornaments", url: "https://www.amazon.com/dp/B08C79PW3M" },
        ]
    },
    // early nov
    electionday: {
        label: "Election Day",
        virtualPages: [],
        pages: []
    },
    // nov 11
    veterans: {
        label: "Veterans Day",
        virtualPages: [],
        pages: [
            { label: "Small flags", url: "https://www.amazon.com/dp/B07FS8YVT7" }
        ]
    },
    // late nov
    thanksgiving: {
        label: "Thanksgiving",
        virtualPages: [],
        pages: [
            { label: "Bean bag toss game", url: "https://www.amazon.com/dp/B07WNTKB5X" },
            { label: "Turkey craft kit", url: "https://www.amazon.com/dp/B00M0NCGWK" },
            { label: "Turkey craft kit", url: "https://www.amazon.com/dp/B07JFL6GDM" },
            { label: "Thanksgiving bingo", url: "https://www.amazon.com/dp/B086BPXM6S" },
        ]
    },
    // dec 5
    volunteerday: {
        label: "International Volunteer Day",
        virtualPages: [],
        pages: []
    },
    // dec 25
    winterholidays: {
        label: "Winter Holidays",
        virtualPages: [],
        pages: []
    },
    // feb 14
    valentine: {
        label: "Valentine's Day",
        virtualPages: [
        ],
        pages: [
            { label: "Valentine Day Bingo Game", url: "https://www.amazon.com/dp/B07M7F7GBJ"},
            { label: "Valentine Bouncy Ball and Spoon Game", url: "https://www.amazon.com/dp/B081YZKVXX"},
            { label: "Valentine Ball Dartboard", url: "https://www.amazon.com/dp/B08PHQZ3JN"},
            { label: "Pin the Heart Game", url: "https://www.amazon.com/dp/B08RBJ5R8Q"},
            { label: "Valentine Bag Game", url: "https://www.amazon.com/dp/B08P3M8XPK"},
            { label: "For Older Kids the Escape Room Game", url: "https://www.amazon.com/dp/B01BX4G62W"},
            { label: "Heart craft", url: "https://www.amazon.com/dp/B08MSYC4LT"}
        ]
    },
    // mar 17
    stpatrick: {
        label: "St. Patrick's Day",
        virtualPages: [],
        pages: [
            { label: "Saint Patrick's Day Themed Fortune Teller Origami Catchers", url: "https://www.amazon.com/dp/B083XT1D7J"},
            { label: "Bingo Cards", url: "https://www.amazon.com/dp/B083DN48WL"},
            { label: "Bangle Bracelets", url: "https://www.amazon.com/dp/B084MDG3XJ"}
        ]
    },
    // April 27
    adminprofessional: {
        label: "Nation Administrative Professional Day",
        virtualPages: [],
        pages: []
    },
    // spring
    easter: {
        label: "Easter",
        virtualPages: [],
        pages: []
    },
    // may 3
    teacherappreciation: {
        label: "Teacher's Appreciation",
        virtualPages: [],
        pages: []
    },
    // may 30
    memorialday: {
        label: "Memorial Day",
        virtualPages: [],
        pages: []
    },
    // may
    graduation: {
        label: "Graduation",
        virtualPages: [],
        pages: []
    }
}

function inRange(now: Date, s: string, e: string) {
    let start = new Date(`${now.getFullYear()}-${s}`);
    let end = new Date(`${now.getFullYear()}-${e}`);
    if (start < end) {
        return start <= now && now <= end;
    } else {
        return now >= start || now <= end;
    }
}

function determineSections() {
    let retVal : string[] = [];
    const now = new Date();

    // End of July to late Aug
    if (inRange(now, `07-01`, `09-15`)) {
        retVal.push("backtoschool");
    }
    // Sept: First Mon
    if (inRange(now, `08-01`, `09-09`)) {
        retVal.push("laborday");
    }
    // Oct 1
    if (inRange(now, `09-01`, `10-02`)) {
        retVal.push("custodialworkers");
    }
    // Oct 6
    if (inRange(now, `09-06`, `10-07`)) {
        retVal.push("walktoschool");
    }
    // Oct 23-31
    if (inRange(now, `09-23`, `11-01`)) {
        retVal.push("redribbonweek");
    }
    // oct 31
    if (inRange(now, `09-30`, `11-01`)) {
        retVal.push("halloween");
    }
    // early nov
    if (inRange(now, `10-02`, `11-10`)) {
        retVal.push("electionday");
    }
    // nov 11
    if (inRange(now, `10-11`, `11-12`)) {
        retVal.push("veterans");
    }
    // nov 22-28
    if (inRange(now, `10-22`, `11-30`)) {
        retVal.push("thanksgiving");
    }
    // dec 5
    if (inRange(now, `11-05`, `12-06`)) {
        retVal.push("volunteerday");
    }
    // dec 25
    if (inRange(now, `11-05`, `12-31`)) {
        retVal.push("winterholidays");
    }
    // feb 14
    if (inRange(now, `01-14`, `02-15`)) {
        retVal.push("valentine");
    }
    // mar 17
    if (inRange(now, `02-17`, `03-18`)) {
        retVal.push("stpatrick");
    }
    // April 27
    if (inRange(now, `03-27`, `04-28`)) {
        retVal.push("adminprofessional");
    }
    // Mar 22 - April 25
    if (inRange(now, `02-22`, `04-26`)) {
        retVal.push("easter");
    }
    // may 3
    if (inRange(now, `04-03`, `05-04`)) {
        retVal.push("teacherappreciation");
    }
    // may 30
    if (inRange(now, `04-25`, `06-01`)) {
        retVal.push("memorialday");
    }
    // Late May-late June
    if (inRange(now, `04-15`, `06-30`)) {
        retVal.push("graduation");
    }
    retVal.push("clubevent");
    retVal.push("cluboutdoor");
    return retVal;
}

export default class SectionIdeas extends React.Component<SectionIdeasProps, SectionIdeasState> {
    render() {
        let showSections = determineSections();
        return <>
            { showSections.map((val: string) => {
                let info = Nls.get("URL_PART") === "hrp" ? sectionsHR[val] : sectionsClub[val];
                return ((info && info.virtualPages && info.virtualPages.length > 0) || (info && info.pages && info.pages.length > 0)) && <React.Fragment key={val}>
                    <div style={{marginTop: "1.5rem"}}>{info.label}</div>
                    {info.description && <div style={{marginTop: ".5rem", fontWeight: 300, fontSize: "1em"}}>{info.description}</div>}
                    <ul style={{margin: ".5rem 0rem"}}>
                        {info.virtualPages && info.virtualPages.map((page) => (
                            <li key={`vp_${page.label}`} style={{marginTop: ".2rem"}}><a className="regLink" target="_blank" rel="noopener noreferrer" href={TagService.tagUrl(page.url)}>{page.label}</a></li>
                        ))}
                        {info.pages && info.pages.map((page) => (
                            <li key={`vpp_${page.label}`} style={{marginTop: ".2rem"}}><a className="regLink" target="_blank" rel="noopener noreferrer" href={TagService.tagUrl(page.url)}>{page.label}</a></li>
                        ))}
                    </ul>
                </React.Fragment>
            })}
        </>;
    }
}
