import { Button } from '@mui/material';
import React from 'react';
import { HRPButton, HRPButtonSecondary } from "../../components/widgets/Customized";
import Nls from '../../nls/nls';


import "./VerifyLogin.scss";

export default class VerifyLogin extends React.Component<{user: any, onUser: ()=> Promise<any>, getUser:()=>Promise<any | undefined>}> {
    timer : any = null;
    componentDidMount() {
        let self = this;
        self.timer = setInterval(() => {
            self.props.getUser().then((user) => {
                if (user) {
                    this.props.onUser();
                    clearInterval(this.timer);
                }
            })
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        return (<>
            <main className="verifyLogin">
                <div className="hrpRow" style={{ marginTop: "0rem" }}>
                    <div className="hrpCol-4-mob hrpCol-8-tab hrpCol-12-desk">
                        <h1>Almost there</h1>

                        <p>An email has been sent to verify your email address.
                        Please check your mail (and spam folder) and confirm your email address.
                        This step is necessary to prevent fraud, and we value your security.
                        Thank you for your patience.</p>
                        {!this.props.user ? <>
                            <Button sx={HRPButtonSecondary} variant="contained" disabled>Awaiting Confirmation…</Button>
                        </> : <>
                            <Button sx={HRPButton} variant="contained" color="primary" href={`/auth/${Nls.get("URL_PART")}`}>Confirmed! Continue...</Button>
                        </>}
                    </div>
                </div>
            </main>
        </>)
    }
}