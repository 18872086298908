import React from 'react';
import { Box, Grid, Hidden } from '@mui/material';
import "./Login.scss";
import Nls from '../../nls/nls';
import { TextInput, Button } from '@carbon/react';

export default class PWReset extends React.Component<{location?:any}> {
    state = {
        email: ""
    }


    changeEmail(event: any) {
        this.setState({email: event.target.value});
    };

    async requestReset() {
        await fetch(`/api/common/user/pwreset?site=${Nls.get("URL_PART")}`, { 
            headers: { 
                "Content-type": "application/json"
            }, 
            method: "POST", 
            body: JSON.stringify(
        {
            email: this.state.email
        })});
        document.location.href = `/pwreset_sent?email=${this.state.email}`;
        return true;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const validPWReset = this.state.email.trim().length > 0 
            && this.state.email.trim().match(/.*@.*\..*/);
        return <>
        <main>
            <Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Hidden mdDown>
                        <Grid item xs></Grid>
                    </Hidden>
                    <Grid item xs className="login">
                        <div>
                            <div><h2>Reset password</h2></div>
                            <div onKeyDown={async (evt: any) => {
                                if (evt.key === "Enter" && validPWReset) {
                                    await this.requestReset();
                                }
                            }}>
                                <div><TextInput labelText="Email" type="email" 
                                    value={this.state.email} onChange={this.changeEmail.bind(this)} /></div>
                            </div>
                            <div style={{marginTop:"40px"}}><Button disabled={!validPWReset} onClick={async () => {
                                await this.requestReset();
                            }}>Request password reset</Button></div>
                        </div>
                    </Grid>
                    <Hidden mdDown>
                        <Grid item xs></Grid>
                    </Hidden>
                </Grid>
            </Box>
        </main></>;
    }
}