import React from "react"
import { IPTLocation, PTPackage } from "../../services/LocationV1"
import ModalBasic from "../../../../components/ModalBasic"
import { clone } from "../../../../util/MiscUtil"
import {
    Button,
    ClickableTile,
    Column,
    Grid,
    InlineNotification,
    NumberInput,
    TextInput,
    Tile
} from "@carbon/react"
import { AddAlt, TrashCan, Edit } from "@carbon/icons-react"
import * as uuid from "uuid";
import { ModalConfirmDelete } from "../../../../components/ModalConfirmDelete"
import { Checkbox } from "@carbon/react"

interface LocationPackagesState {
    dialog: "" | "add" | "edit",
    deleteId: string
    dialogValues: PTPackage
}

export class LocationPackages extends React.Component<{
    location?: IPTLocation
    onLocation: (location: IPTLocation) => Promise<void>
}, LocationPackagesState> {
    state: LocationPackagesState = {
        dialog: "",
        deleteId: "",
        dialogValues: {
            id: "",
            label: "",
            // Base cost in dollars
            base: {
                cost: 0,
                deposit: 0,
                // How many people included in the base package
                capacity: 0
            },
            // If deposit, how many days before event must full payment be made
            days_before_full_pay: -1,
            included_items: [],
            addons: []
        }
    }

    render() {
        return <>
            {(!this.props.location?.packages || this.props.location?.packages.length === 0) && <>
                <InlineNotification hideCloseButton={true} lowContrast={true}
                    title="Not reservable"
                    subtitle="No packages have been defined for this location"
                />
            </>}
            <div style={{ marginTop: "1rem" }} />
            {this.props.location && <>
                <div style={{ marginTop: "1rem" }} />
                <Grid>
                    {this.props.location?.packages.map((pkg: PTPackage) => (
                        <Column sm={4} md={4} lg={6} style={{ marginTop: "1rem" }} key={pkg.id}>
                            <Tile light={true} style={{height: "100%"}}>
                                <div style={{ float: "right", marginTop: "-.5rem", marginRight: "-.5rem" }}>
                                    <Button size="sm" hasIconOnly renderIcon={Edit} iconDescription="Edit package" kind="ghost" tooltipAlignment="end" onClick={() => {
                                        this.setState({
                                            dialog: "edit",
                                            dialogValues: this.props.location?.packages.find(fpkg => fpkg.id === pkg.id)!
                                        })
                                    }} />
                                    <Button size="sm" hasIconOnly renderIcon={TrashCan} iconDescription="Remove package" kind="ghost" tooltipAlignment="end" onClick={() => {
                                        this.setState({
                                            deleteId: pkg.id
                                        })
                                    }} />
                                </div>
                                <div><strong>{pkg.label}</strong></div>
                                <div><strong>Cost:</strong> ${pkg.base.cost}</div>
                                <div><strong>Deposit:</strong> ${pkg.base.deposit}</div>
                                <div><strong>Included guests:</strong> {pkg.base.capacity}</div>
                                {pkg.addon && <>
                                    <div><strong>Cost per additional guest:</strong> {pkg.addon.cost}</div>
                                    <div><strong>Deposit per additional guest:</strong> {pkg.addon.deposit}</div>
                                </>}
                            </Tile>
                        </Column>
                    ))}
                    <Column sm={4} md={4} lg={6} style={{ marginTop: "1rem" }}>
                        <ClickableTile light={true} style={{ height: "100%" }} onClick={() => {
                            this.setState({ dialog: "add" })
                        }}>
                            <div style={{ padding: "1rem", textAlign: "center", verticalAlign: "center" }}>
                                <AddAlt size={32} /><br />
                                Add package
                            </div>
                        </ClickableTile>
                    </Column>
                </Grid>
            </>}
            <ModalBasic
                open={this.state.dialog !== ""}
                heading={this.state.dialog === "add" ? "Add packages" : "Edit packages"}
                primaryButtonText={this.state.dialog === "add" ? "Add" : "Update"}
                isDisabled={() => this.state.dialogValues.label.length === 0}
                onReset={async () => {
                    if (this.state.dialog === "add") {
                        this.setState({
                            dialogValues: {
                                id: uuid.v4(),
                                label: "",
                                // Base cost in dollars
                                base: {
                                    cost: 0,
                                    deposit: 0,
                                    // How many people included in the base package
                                    capacity: 0
                                },
                                // If deposit, how many days before event must full payment be made
                                days_before_full_pay: -1,
                                included_items: [],
                                addons: []
                            }
                        })
                    }
                }}
                onSubmit={async () => {
                    if (this.props.location) {
                        let locationUpdate = clone(this.props.location);
                        let packageUpdate = clone(this.state.dialogValues);
                        if (this.state.dialog === "add") {
                            locationUpdate.packages = locationUpdate.packages || [];
                            locationUpdate.packages.push(packageUpdate);
                        } else {
                            for (let idx = 0; idx < locationUpdate.packages.length; ++idx) {
                                if (locationUpdate.packages[idx].id === this.state.dialogValues.id) {
                                    locationUpdate.packages[idx] = clone(packageUpdate);
                                }
                            }
                        }
                        await this.props.onLocation(locationUpdate);
                    }
                }}
                onClose={async () => {
                    this.setState({ dialog: "" })
                }}
            >
                <TextInput
                    data-modal-primary-focus
                    id="packageLabel"
                    labelText="Package name"
                    value={this.state.dialogValues.label}
                    onChange={(evt) => {
                        let dialogValues = clone(this.state.dialogValues);
                        dialogValues.label = evt.target.value;
                        this.setState({ dialogValues })
                    }
                    }
                />
                <Grid>
                    <Column sm={2} md={4} lg={4} style={{ marginTop: "1rem" }}>
                        <NumberInput id="packageCost"
                            min={0}
                            value={this.state.dialogValues.base.cost}
                            label="Package cost"
                            onChange={(evt) => {
                                let packageUpdate = clone(this.state.dialogValues);
                                packageUpdate.base.cost = parseInt(
                                    (document.getElementById("packageCost") as HTMLInputElement)?.value!) || 0;
                                this.setState({ dialogValues: packageUpdate });
                            }}
                        />
                    </Column>
                    <Column sm={2} md={4} lg={4} style={{ marginTop: "1rem" }}>
                        <NumberInput id="packageDeposit"
                            min={0} max={this.state.dialogValues.base.cost}
                            value={this.state.dialogValues.base.deposit}
                            label="Minimum deposit"
                            onChange={(evt) => {
                                let packageUpdate = clone(this.state.dialogValues);
                                packageUpdate.base.deposit = parseInt(
                                    (document.getElementById("packageDeposit") as HTMLInputElement)?.value!) || 0;
                                this.setState({ dialogValues: packageUpdate });
                            }}
                        />
                    </Column>
                    <Column sm={2} md={4} lg={4} style={{ marginTop: "1rem" }}>
                        <NumberInput id="packageCapacity"
                            min={0}
                            value={this.state.dialogValues.base.capacity}
                            label="Included guests"
                            onChange={(evt) => {
                                let packageUpdate = clone(this.state.dialogValues);
                                packageUpdate.base.capacity = parseInt(
                                    (document.getElementById("packageCapacity") as HTMLInputElement)?.value!) || 0;
                                this.setState({ dialogValues: packageUpdate });
                            }}
                        />
                    </Column>
                </Grid>
                <div style={{ marginTop: "1rem" }} />
                <Checkbox
                    id="chkAddon"
                    labelText="Additional guests can be added"
                    checked={this.state.dialogValues.addon}
                    onChange={(_, { checked }) => {
                        let packageUpdate = clone(this.state.dialogValues);
                        if (checked) {
                            packageUpdate.addon = {
                                cost: 0,
                                deposit: 0
                            }
                        } else {
                            delete packageUpdate.addon;
                        }
                        this.setState({ dialogValues: packageUpdate });
                    }}
                />
                {this.state.dialogValues.addon && <>
                    <Grid>
                        <Column sm={2} md={4} lg={4} style={{ marginTop: "1rem" }}>
                            <NumberInput id="packageAddonCost"
                                min={0}
                                value={this.state.dialogValues.addon.cost}
                                label="Additional cost per guest"
                                onChange={(evt) => {
                                    let packageUpdate = clone(this.state.dialogValues);
                                    packageUpdate.addon!.cost = parseInt(
                                        (document.getElementById("packageAddonCost") as HTMLInputElement)?.value!) || 0;
                                    this.setState({ dialogValues: packageUpdate });
                                }}
                            />
                        </Column>
                        <Column sm={2} md={4} lg={4} style={{ marginTop: "1rem" }}>
                            <NumberInput id="packageAddonDeposit"
                                min={0} max={this.state.dialogValues.addon.cost}
                                value={this.state.dialogValues.addon.deposit}
                                label="Additional deposit per guest"
                                onChange={(evt) => {
                                    let packageUpdate = clone(this.state.dialogValues);
                                    packageUpdate.addon!.deposit = parseInt(
                                        (document.getElementById("packageAddonDeposit") as HTMLInputElement)?.value!) || 0;
                                    this.setState({ dialogValues: packageUpdate });
                                }}
                            />
                        </Column>
                    </Grid>
                </>}
            </ModalBasic>
            <ModalConfirmDelete
                open={this.state.deleteId?.length > 0}
                heading="Are you sure that you want to delete this package?"
                onClose={async () => {
                    this.setState({ deleteId: "" });
                }}
                onDelete={async () => {
                    if (this.props.location) {
                        let locationUpdate = clone(this.props.location);
                        for (let idx = 0; idx < locationUpdate.packages.length; ++idx) {
                            if (locationUpdate.packages[idx].id === this.state.deleteId) {
                                locationUpdate.packages.splice(idx--, 1);
                            }
                        }
                        await this.props.onLocation(locationUpdate);
                    }
                }}
            />
        </>
    }
}