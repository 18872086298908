export type Day = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
export interface ISession {
    id: string,
    account_id: string
    camp_id: string
    label: string,
    dateStart: string,
    dateEnd: string,
    timeStart: string
    timeEnd: string,
    campDays: Day[],
    ageStart: string,
    ageEnd: string,
    costPerAttendee: number,
    tax?: number,
    capacity: number
}

export class SessionV1 {
    static async getSessionsByAccountId(accountId: string) : Promise<ISession[]> {
        let result = await fetch(`/api/taken/session?accountId=${accountId}`);
        return result.json();
    }

    static async getSessionsByCampId(campId: string) : Promise<ISession[]> {
        let result = await fetch(`/api/taken/session?campId=${campId}`);
        return result.json();
    }

    static async getSession(campId: string, sessionId: string) : Promise<ISession> {
        let result = await fetch(`/api/taken/session/${campId}/${sessionId}`);
        return result.json();
    }

    static async createSession(newSession: ISession) : Promise<ISession> {
        let result = await fetch(`/api/taken/session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(newSession)
        })
        if (result.status !== 200) {
            throw await result.json();
        }
        return result.json();
    }

    static async updateSession(updSession: ISession) : Promise<ISession> {
        let result = await fetch(`/api/taken/session`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(updSession)
        })
        return result.json();
    }

    static async deleteSession(id: string) {
        await fetch(`/api/taken/session/${id}`, {
            method: "DELETE"
        })
    }
}