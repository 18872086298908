import React from 'react';

import { Grid, Column } from "@carbon/react";
import "./TakeIt.scss";
import { CampV1, ICamp } from '../../../../services/CampV1';
import { AccountV1, IAccount } from '../../../../services/AccountV1';
import { ISession, SessionV1 } from '../../../../services/SessionV1';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import CampReservationV1, { ICampReservation } from '../../../../services/CampReservationV1';
import { NavigateFunction, Params, useNavigate, useParams } from 'react-router-dom';

interface TakeItState {
    account?: IAccount
    camp?: ICamp
    session?: ISession
    reservation?: ICampReservation
    // user?: IUserBasic
}

class TakeItCls extends React.Component<{ navigate: NavigateFunction, params: Params<string> }, TakeItState> {
    state: TakeItState = {
    }

    componentDidMount() {
        (async () => {
            const sessionId = this.props.params["sessionId"]!;
            const campId = this.props.params["campId"]!;
            let camp = await CampV1.getCamp(campId);
            let account = this.state.account || await AccountV1.getAccount(camp.account_id);
            let session = await SessionV1.getSession(campId, sessionId);
            // let user = await UserV1.getUser();
            this.setState({ account, camp, session })
        })();
    }

    componentWillUnmount() {
        // document.body.style.backgroundColor = "inherit";
    }

    getPayDetails() {
        const charge = this.state.session ? this.state.session.costPerAttendee : -1;
        const fee = this.state.session ? this.state.session.costPerAttendee*.03 : -1;
        let tax = 0;
        if (this.state.session && this.state.session.tax && this.state.session.tax > 0) {
            tax = (charge+fee)*tax;
        }
        return {
            charge: charge.toFixed(2),
            fee: fee.toFixed(2),
            tax: tax.toFixed(2), 
            total: (charge+fee+tax).toFixed(2)
        }
    }

    render() {
        const client_id = 'AXeYCMf-JJMBlx-H3RNHLImYZSlCn_-1CCcgrTb5eQR_OLM8mH4I6_WwhymQqDQIRp8qH78Qdc8AV2FO';
        const payDetails = this.getPayDetails();
        return (
            <>
                <main className="TakeIt">
                    <div style={{ marginTop: "3rem" }} />
                    <Grid>
                        <Column sm={4} md={8} lg={16}>
{this.state.session && <>
    <h2 style={{marginTop: "0px"}}>Camp information</h2>
    <div className="resInfo">
        <div><strong>Date:</strong> {this.state.session.dateStart} &ndash; {this.state.session.dateEnd}</div>
        {this.state.session.timeStart && <>
            <div><strong>Time:</strong> {this.state.session.timeStart}{this.state.session.timeEnd && <> &ndash; {this.state.session.timeEnd}</>}</div>
        </>}
        <div><strong>Days:</strong> {this.state.session.campDays.join(", ")}</div>
    </div>

    <h2>Estimate</h2>
    <div className="resInfo">
        <div><strong>Charge</strong> ${payDetails.charge}</div>
        <div><strong>Booking Fees</strong> ${payDetails.fee}</div>
        { payDetails.tax !== "0.00" && <div><strong>Taxes</strong> ${payDetails.tax}</div>}
        <div><strong>Total</strong> ${payDetails.total}</div>
    </div>
</>}
<div style={{marginTop: "3rem", maxWidth: "300px"}}>
    <PayPalScriptProvider options={{ "client-id": client_id }}>
            <PayPalButtons
                createOrder={async (data, actions) => {
                    const payDetails = this.getPayDetails();
                    console.log("createOrder", data);
                    let createVal = await actions.order.create({
                        // payer: {
                            // email_address: this.state.user!.email
                        // },
                        purchase_units: [
                            {
                                amount: {
                                    value: payDetails.total
                                },
                            },
                        ],
                    });
                    let reservation = await CampReservationV1.create({
                        label: `${this.state.camp?.name} ${this.state.session?.label}`,
                        zip: this.state.camp!.zip,
                        dateStart: this.state.session!.dateStart,
                        payment_id: createVal,
                        estimate: payDetails.total,
                        camp_id: this.state.camp!.id,
                        session_id: this.state.session!.id
                    });
                    this.setState({ reservation });
                    return createVal;
                }}
                onApprove={async (_data, actions) => {
                    let details = await actions.order!.capture();
                    let updRes : ICampReservation = JSON.parse(JSON.stringify(this.state.reservation));
                    updRes.payment_details = details;
                    let res = await CampReservationV1.update(updRes);
                    this.props.navigate(`/auth/reservation/${res!.id}`)
                }}
            />
        </PayPalScriptProvider>
    </div>
                        </Column>
                    </Grid>
                </main>
            </>)
    }
}

export default function TakeIt() {
    let navigate = useNavigate();
    let params = useParams();
    return <TakeItCls navigate={navigate} params={params} />
}