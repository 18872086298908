import { Button, Dialog, DialogTitle } from '@mui/material';
import React, { KeyboardEvent, MouseEvent } from 'react';
import { HRPButtonSecondary, DangerButton} from '../../../components/widgets/Customized';
import Nls from '../../../nls/nls';

interface ModalDeleteProps {
    open: boolean
    onDelete: () => Promise<void>
    onClose: () => void
}

interface ModalDeleteState {
}

export default class ModalDelete extends React.Component<ModalDeleteProps, ModalDeleteState> {
    state : ModalDeleteState = {}

    render() {
        return <Dialog open={this.props.open} onKeyDown={(evt: KeyboardEvent<HTMLDivElement>) => { 
            if (evt.key === "Escape") { 
                this.props.onClose();
                evt.preventDefault();
            }
        }}>
            <DialogTitle>Delete {Nls.get("SELECTOR_LABEL_LOWER")}</DialogTitle>
            <div style={{ margin: "0rem 1.5rem"}}>
                <div style={{marginTop: ".5rem"}}></div>
                This will delete the {Nls.get("SELECTOR_LABEL_LOWER")} and all related information. 
                This action cannot be undone.
                <div style={{marginTop: "1.5rem"}}></div>
                <div className="hrpRow">
                    <div className="hrpCol-2-mob">
                    <Button sx={HRPButtonSecondary} variant="outlined" onClick={(evt: MouseEvent) => { 
                        this.props.onClose(); 
                        evt.preventDefault();
                    }}>Cancel</Button>
                    </div>
                    <div className="hrpCol-2-mob">
                    <Button sx={DangerButton} onClick={async (evt: MouseEvent) => { 
                        evt.preventDefault();
                        await this.props.onDelete();
                        this.props.onClose(); 
                    }}>Delete</Button>
                    </div>
                </div>
                <div style={{marginTop: "1.5rem"}}></div>
            </div>
        </Dialog>
    }
}