import React from 'react';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import Wishlists from '../../../../components/wishlists/Wishlists';
import { ApiHomeroomClub } from '../../../../../../../../server/src/hrpcb/models/apiHomeroom';
import "./SectionWishes.scss";

interface SectionWishesState {
}

interface SectionWishesProps {
    user: ApiUser
    homerooms: ApiHomeroomClub[]
    onHomerooms: (homerooms: ApiHomeroomClub[]) => Promise<void>
}

export default class SectionWishes extends React.Component<SectionWishesProps, SectionWishesState> {
    state: SectionWishesState = {
    }

    render() {
        return <div className="wishlist" style={{width: "100%"}}>
            <Wishlists
                homerooms={this.props.homerooms} 
                user={this.props.user} 
                onHomerooms={this.props.onHomerooms}
            />
        </div>
    }
}