import React from 'react';

import { Grid, Column, MultiSelect, Theme, TextInput, DatePicker, DatePickerInput } from "@carbon/react";
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import "./PartyPlaces.scss";
import { PartyPlaceList } from './PartyPlaceList';

interface PartyPlacesState {
    init: boolean,
    zip: string,
    date: string,
    event_types?: string[]
}
export class PartyPlacesCls extends React.Component<{ navigate: NavigateFunction, searchParams: URLSearchParams }, PartyPlacesState> {
    state: PartyPlacesState = {
        init: false,
        zip: "",
        date: ""
    }

    changeType(evt: any) {
        let event_types = evt.selectedItems;
        this.setState({ event_types });
        this.props.navigate(`?zip=${this.state.zip}&date=${this.state.date}&type=${encodeURIComponent(event_types.join(","))}`)
    }

    changeZip(event: any) {
        this.setState({ zip: event.target.value });
        if (event.target.value.match(/\d{5}/)) {
            this.props.navigate(`?zip=${event.target.value}&date=${this.state.date}&type=${encodeURIComponent(this.state.event_types!.join(","))}`)
        }
    };

    changeDate(pickerDateStr: string) {
        let newDate = new Date(pickerDateStr);
        let dateStr = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        dateStr += "-" + (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        dateStr += "-" + newDate.getFullYear() + "";
        this.setState({ date: dateStr });
        this.props.navigate(`?zip=${this.state.zip}&date=${dateStr}&type=${encodeURIComponent(this.state.event_types!.join(","))}`)
    };

    componentDidMount() {
        let sp = this.props.searchParams;
        let eventStr = sp.get("type") ?? "";
        this.setState({ 
            zip: sp.get("zip") ?? "", 
            date: sp.get("date") ?? "", 
            event_types: eventStr.length === 0 ? [] : eventStr.split(","),
            init: true 
        });
        document.getElementById("root")!.style.backgroundColor = "#eee";
    }

    componentWillUnmount() {
        document.getElementById("root")!.style.backgroundColor = "#F4F4F4";
    }

    render() {
        return (
            <main className="partyPlaces">
                <Theme theme="white" className="cds--pt-white">
                    <Grid>
                        <Column sm={2} md={2} lg={{offset: 3,span: 3}} style={{ margin: "1rem 1rem" }}>
                            <TextInput
                                id="search-zip"
                                labelText="Zip code"
                                value={this.state.zip}
                                onChange={this.changeZip.bind(this)} />
                        </Column>
                        <Column sm={2} md={3} lg={3} style={{ margin: "1rem 1rem" }}>
                            {this.state.event_types && <MultiSelect
                                label={this.state.event_types.join(", ")}
                                id="event_type_selector"
                                titleText="Type of event"
                                initialSelectedItems={this.state.event_types}
                                items={[
                                    "Kid party"
                                    , "Adult party"
                                    , "Wedding"
                                    , "Anniversary"
                                    , "Work party"
                                    , "Other"
                                ]}
                                itemToString={item => item ?? ''}
                                selectionFeedback="fixed"
                                value={this.state.event_types}
                                // helperText="Types of events that would be appropriate at this location"
                                onChange={this.changeType.bind(this)}
                            />}
                        </Column>
                        <Column sm={2} md={3} lg={3} style={{ margin: "1rem 1rem" }}>
                            <DatePicker
                                datePickerType="single"
                                value={this.state.date}
                                onChange={this.changeDate.bind(this)}
                            >
                                <DatePickerInput
                                    id="search-date"
                                    labelText="Date"
                                    helperText="mm/dd/yyyy"
                                />
                            </DatePicker>
                        </Column>
                    </Grid>
                </Theme>
                {this.state.init && <PartyPlaceList 
                    zip={this.state.zip} 
                    date={this.state.date} 
                    event_types={this.state.event_types!} 
                /> }
            </main>
        )
    }
}

export default function PartyPlaces() {
    let navigate = useNavigate();
    let [searchParams] = useSearchParams();
    return <PartyPlacesCls navigate={navigate} searchParams={searchParams} />
}
