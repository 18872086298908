import React from 'react';
import { Grid, Column } from "@carbon/react";
import "./HomeContent.scss";

export default function ContactUs() {
    return (<div className="ptContent">
        <main>
            <Grid>
                <Column sm={4} md={6} lg={8}><h1>Contact PartyTaken</h1>
                    <p>
                        <b>email:</b><br/>
                        info@partytaken.com
                    </p>

                    <p style={{marginTop:"16px"}}>
                        <b>Phone:</b><br/>
                        (512) 337-2152
                    </p>
                </Column>
            </Grid>
        </main>
    </div>)
}