
import React from 'react';
import { Add, EmojiObjects, ExpandMore, ShoppingBasket } from '@mui/icons-material';
import { HRPButton, HRPSnack, SnackButton } from '../../../components/widgets/Customized';
import { ApiHomeroomClub } from '../../../../../../../server/src/hrpcb/models/apiHomeroom';
import { ApiUser } from '../../../../../../../server/src/hrpcb/models/apiUser';
import HomeroomSelector from '../../../components/widgets/HomeroomSelector';
import "./AuthHomerooms.scss";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, IconButton, Snackbar } from '@mui/material';
import { SectionCalendarDesktop, SectionCalendarMobile } from './Calendar/SectionCalendar';
import SectionWishes from './Wishes/SectionWishes';
import { ePageProfile } from '../../../App';
import Tile from '../../../components/widgets/Tile';
import { SectionLinksMobile, SectionLinksDesktop } from './Links/SectionLinks';
import { SectionNewsMobile, SectionNewsDesktop } from './News/SectionNews';
import { SectionGoalsMobile, SectionGoalsDesktop } from './Goals/SectionGoals';
import SectionIdeas from './Ideas/SectionIdeas';
import { SectionSignupsDesktop, SectionSignupsMobile } from './Signups/SectionSignups';
import { SectionAllergiesDesktop, SectionAllergiesMobile } from './Allergies/SectionAllergies';
import { Close } from '@mui/icons-material';
import { Helmet } from "react-helmet";
import Nls from '../../../nls/nls';
import { Theme, useTheme } from '@mui/system';
import InfoNeeds from '../../../components/info/InfoNeeds';
import InfoButton from '../../../components/info/InfoButton';
import InfoIdeas from '../../../components/info/InfoIdeas';

interface HomeroomsProps {
    user?: ApiUser | null
    homerooms?: ApiHomeroomClub[]
    onHomerooms: (homerooms: ApiHomeroomClub[]) => Promise<void>
    pageProfile: ePageProfile
}

interface HomeroomsState {
    hrFilt: string[]
    mobileSection: "links" | "news" | "calendar" | "signups" | "wishes" | "ideas" | "goals" | "allergies" | "none",
    hideSnack: boolean
}

class HomeroomsClass extends React.Component<HomeroomsProps & { theme: Theme }, HomeroomsState> {
    state: HomeroomsState = {
        hrFilt: [],
        mobileSection: "links",
        hideSnack: sessionStorage.getItem('hideSnack') === "true"
    }

    componentDidMount() {
        if (this.props.homerooms) {
            this.setState({hrFilt: this.props.homerooms.map((homeroom) => homeroom._id)});
        }
    }

    componentDidUpdate(prevProps: HomeroomsProps) {
        if (this.props && this.props.homerooms) {
            if (!prevProps || !prevProps.homerooms) {
                this.setState({hrFilt: this.props.homerooms.map((homeroom) => homeroom._id)});
            }
        }
    }

    onHomerooms(homerooms: ApiHomeroomClub[]) {
        return this.props.onHomerooms(homerooms);
    }

    render() {
        const selHrs = !this.props.homerooms ? [] : 
            this.props.homerooms.filter((homeroom) => this.state.hrFilt.includes(homeroom._id));

        const showSnack = !!this.props.homerooms 
            && !this.state.hideSnack
            && this.props.homerooms.some((hr) => {
                return hr.members.filter(mem => (
                    mem.userId === this.props.user?._id && mem.title === "Teacher")).length > 0;
            })
            && !!this.props.user 
            && !this.props.user.favorites;

        let teacherSnack = <Snackbar sx={HRPSnack}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={showSnack}
            onClose={() => {
                this.setState({ hideSnack: true });
                sessionStorage.setItem('hideSnack', "true");
            }}
            message="Share your favorite things!"
            action={
            <React.Fragment>
                <Button sx={SnackButton} color="secondary" size="small" onClick={() => {
                    document.location.href = `/auth/favorites`;
                }}>
                My Favorites
                </Button>
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => {
                    this.setState({ hideSnack: true });
                    sessionStorage.setItem('hideSnack', "true");
                }}>
                    <Close fontSize="small" />
                </IconButton>
            </React.Fragment>
            }
        />

        let appContent = <></>;
        let appHeader = <></>;

        if (!this.props.homerooms) {
            ///////////////////////////////////////////////////////////////////
            // App is initializing
            ////
            appContent = <CircularProgress />;
        } else if (this.props.homerooms.length === 0) {
            ///////////////////////////////////////////////////////////////////
            // No homerooms
            ////
            appContent = <>
                <div style={{ marginTop: "3rem" }} />
                <div className="hrpRow">
                    <div className="hrpCol-4-mob hrpCol-6-tab">
                        <p style={{fontSize: "1.25em"}}>Let's get started!</p>
                        <p style={{fontSize: "1.25em"}}>If you believe someone else has already
                            started and created a {Nls.get("SELECTOR_LABEL_LOWER")}, 
                            please reach out to them and ask to be added to that {Nls.get("SELECTOR_LABEL_LOWER")}.</p>
                        { ((Nls.get("URL_PART") === "hrp") && <>
                            <p style={{fontSize: "1.25em"}}>If you are a teacher or a
                                classroom helper, you can help your classroom homeroom parent
                                by setting up a Homeroom for your class. Our Goal is to 
                                help with your classroom communication needs. </p>
                        </>) || <>
                        <p style={{fontSize: "1.25em"}}>If you are a president, coach, or other
                                helper for your club/booster, you can help your {Nls.get("ROLE_NAME_LABEL")} by
                                setting up a {Nls.get("SELECTOR_LABEL")}. Our Goal is to 
                                help with your {Nls.get("SELECTOR_LABEL_LOWER")} communication needs. </p>
                        </>}
                        <p style={{fontSize: "1.25em"}}>To get 
                            started with a new {Nls.get("SELECTOR_LABEL_LOWER")}...</p>
                        <Button sx={HRPButton} variant="contained" color="primary"
                            endIcon={<Add />}
                            style={{ width: "inherit", marginTop: "1.5rem" }}
                            href={`/auth/${Nls.get("URL_PART")}/new`}>Create a {Nls.get("SELECTOR_LABEL_LOWER")}</Button>
                    </div>
                </div>
            </>
        } else {
            if (!this.props.user 
                && this.props.homerooms.length === 1
                && this.props.homerooms[0]._id === Nls.get("DEMO_ID"))
            {
                appHeader = <>
                    <div style={{
                        backgroundColor: this.props.theme.palette.header.faded,
                        border: `solid ${this.props.theme.palette.header.main} 1px`,
                        marginTop: "1rem",
                        marginBottom: "0rem",
                        padding: "1rem"}}
                    >
                        The {Nls.get("SELECTOR_LABEL_LOWER")} listed below is an example {Nls.get("SELECTOR_LABEL_LOWER")}. <a
                            href={`/login`}>Login</a> to create your own {Nls.get("SELECTOR_LABEL_LOWER")}. It's free!
                    </div>
                </>;
            }

            if (this.props.pageProfile === "mobile") {
                ///////////////////////////////////////////////////////////////////
                // Mobile profile
                ////
                appContent = <div className="mobileHome">
                    { teacherSnack }
                    <div style={{ marginTop: "1rem" }} />
                    <div className="hrpRow">
                        <div className="hrpCol-4-mob">
                            <HomeroomSelector
                                user={this.props.user!} 
                                pageProfile={this.props.pageProfile}
                                homerooms={this.props.homerooms!}
                                onSelected={(homeroomIds: string[]) => {
                                    this.setState({ hrFilt: homeroomIds });
                                }}
                                onHomerooms={this.onHomerooms.bind(this)}
                            />
                        </div>
                    </div>
                    <div style={{marginTop: "1rem"}}></div>
                    {selHrs.length > 0 && <>
                        <SectionLinksMobile homerooms={selHrs} 
                            expanded={this.state.mobileSection === "links"}
                            onToggle={() => {
                                this.setState({mobileSection: this.state.mobileSection !== "links" ? "links" : "none"});
                            }}
                            user={this.props.user} 
                            onHomerooms={this.onHomerooms.bind(this)}
                        /> 
                    </>}
                    {selHrs.length > 0 && <>
                        <SectionNewsMobile
                            expanded={this.state.mobileSection === "news"}
                            onToggle={() => {
                                this.setState({mobileSection: this.state.mobileSection !== "news" ? "news" : "none"});
                            }}
                            homerooms={selHrs}
                            onHomerooms={this.onHomerooms.bind(this)}
                            user={this.props.user!}
                        />
                    </>}
                    {selHrs.length > 0 && <>
                        <SectionGoalsMobile
                            expanded={this.state.mobileSection === "goals"}
                            onToggle={() => {
                                this.setState({mobileSection: this.state.mobileSection !== "goals" ? "goals" : "none"});
                            }}
                            homerooms={selHrs}
                            onHomerooms={this.onHomerooms.bind(this)}
                            user={this.props.user!}
                        />
                    </>}
                    {selHrs.length > 0 && <>
                        <SectionCalendarMobile
                            expanded={this.state.mobileSection === "calendar"}
                            onToggle={() => {
                                this.setState({mobileSection: this.state.mobileSection !== "calendar" ? "calendar" : "none"});
                            }}
                            homerooms={selHrs}
                            onHomerooms={this.onHomerooms.bind(this)}
                            user={this.props.user!}
                        />
                    </>}
                    {selHrs.length > 0 && <>
                        <SectionAllergiesMobile
                            expanded={this.state.mobileSection === "allergies"}
                            onToggle={() => {
                                this.setState({mobileSection: this.state.mobileSection !== "allergies" ? "allergies" : "none"});
                            }}
                            homerooms={selHrs}
                            onHomerooms={this.onHomerooms.bind(this)}
                            user={this.props.user!}
                        />
                    </>}
                    {selHrs.length > 0 && <>
                        <SectionSignupsMobile
                            expanded={this.state.mobileSection === "signups"}
                            onToggle={() => {
                                this.setState({mobileSection: this.state.mobileSection !== "signups" ? "signups" : "none"});
                            }}
                            homerooms={selHrs}
                            onHomerooms={this.onHomerooms.bind(this)}
                            user={this.props.user!}
                        />
                    </>}
                    <Accordion
                        square
                        expanded={this.state.mobileSection === "wishes"}
                        onChange={() => {
                            this.setState({mobileSection: this.state.mobileSection !== "wishes" ? "wishes" : "none"});
                        }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <h2>
                                <ShoppingBasket /><span style={{verticalAlign: "top", marginLeft: ".5rem"}}>{Nls.get("NEEDS_TITLE")}</span>
                            </h2>
                            <InfoButton title={`${Nls.get("NEEDS_TITLE")} Info`} showLogin={!this.props.user}>
                                <InfoNeeds />
                            </InfoButton>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SectionWishes
                                homerooms={selHrs}
                                onHomerooms={this.onHomerooms.bind(this)}
                                user={this.props.user!}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        square
                        expanded={this.state.mobileSection === "ideas"}
                        onChange={() => {
                            this.setState({mobileSection: this.state.mobileSection !== "ideas" ? "ideas" : "none"});
                        }}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <h2>
                                <EmojiObjects /><span style={{verticalAlign: "top", marginLeft: ".5rem"}}>{Nls.get("IDEAS_TITLE")}</span>
                            </h2>
                            <InfoButton title={`${Nls.get("IDEAS_TITLE")} Info`} showLogin={!this.props.user}>
                                <InfoIdeas />
                            </InfoButton>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{width: "100%"}}>
                                <SectionIdeas homerooms={selHrs} user={this.props.user!} />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <div style={{marginLeft: "1rem", marginTop: "1.5rem", fontSize: ".8em"}}>
                        Have more ideas or other feedback? <br/><a className="regLink" href="/auth/hrp/submit_idea">Let us know!</a>
                    </div>
                </div>
            } else {
                ///////////////////////////////////////////////////////////////////
                // Desktop profile
                ////
                appContent = <div className="deskHome">
                    { teacherSnack }
                    <div style={{ marginTop: "1rem" }} />
                    <div className="hrpRow">
                        <div className="hrpCol-3-tab hrpCol-5-desk">
                            <div style={{marginRight: "-1rem"}}>
                                { selHrs && selHrs.length > 0 && 
                                    <SectionCalendarDesktop
                                        homerooms={selHrs} 
                                        user={this.props.user} 
                                        onHomerooms={this.onHomerooms.bind(this)}
                                    /> 
                                }
                            </div>
                            <div style={{marginRight: "-1rem"}}>
                                { selHrs && selHrs.length > 0 && 
                                    <SectionAllergiesDesktop
                                        homerooms={selHrs} 
                                        user={this.props.user} 
                                        onHomerooms={this.onHomerooms.bind(this)}
                                    /> 
                                }
                            </div>
                            <div style={{marginRight: "-1rem"}}>
                                { selHrs && selHrs.length > 0 && 
                                    <SectionSignupsDesktop
                                        homerooms={selHrs} 
                                        user={this.props.user} 
                                        onHomerooms={this.onHomerooms.bind(this)}
                                    /> 
                                }
                            </div>
                        </div>
                        <div className="hrpCol-3-tab hrpCol-7-desk">
                            <HomeroomSelector
                                user={this.props.user!} 
                                pageProfile={this.props.pageProfile}
                                homerooms={this.props.homerooms!}
                                onSelected={(homeroomIds: string[]) => {
                                    this.setState({ hrFilt: homeroomIds });
                                }}
                                onHomerooms={this.onHomerooms.bind(this)}
                            />
                            {selHrs.length > 0 && <>
                                <div style={{marginTop: "2rem"}}></div>
                                <SectionNewsDesktop
                                    homerooms={selHrs}
                                    onHomerooms={this.onHomerooms.bind(this)}
                                    user={this.props.user!}
                                />
                            </> }
                            {selHrs.length > 0 && <>
                                <div style={{marginTop: "3rem"}}></div>
                                <SectionGoalsDesktop
                                    homerooms={selHrs}
                                    onHomerooms={this.onHomerooms.bind(this)}
                                    user={this.props.user!}
                                />
                            </> }
                        </div>
                        <div className="hrpCol-2-tab hrpCol-4-desk">
                            <div className="linkPanel" style={{marginRight: "-1rem", marginTop: "-3px"}}>
                                { selHrs && selHrs.length > 0 && 
                                    <SectionLinksDesktop 
                                        homerooms={selHrs} 
                                        user={this.props.user} 
                                        onHomerooms={this.onHomerooms.bind(this)}
                                    /> 
                                }
                            </div>
                            <div style={{marginRight: "-1rem"}}>
                                <Tile dark>
                                    <div className="sectionTitle"><h2>
                                        <ShoppingBasket /><span style={{verticalAlign: "top", marginLeft: ".5rem"}}>{Nls.get("NEEDS_TITLE")}</span>
                                    </h2>
                                    <InfoButton title={`${Nls.get("NEEDS_TITLE")} Info`} showLogin={!this.props.user}>
                                        <InfoNeeds />
                                    </InfoButton></div>
                                    <SectionWishes
                                        homerooms={selHrs}
                                        onHomerooms={this.onHomerooms.bind(this)}
                                        user={this.props.user!}
                                    />
                                </Tile>
                            </div>
                            <div className="linkPanel" style={{marginRight: "-1rem"}}>
                                <Tile dark>
                                    <div className="sectionTitle"><h2>
                                        <EmojiObjects /><span style={{verticalAlign: "top", marginLeft: ".5rem"}}>{Nls.get("IDEAS_TITLE")}</span>
                                    </h2>
                                    <InfoButton title={`${Nls.get("IDEAS_TITLE")} Info`} showLogin={!this.props.user}>
                                        <InfoIdeas />
                                    </InfoButton>
                                    </div>
                                    <SectionIdeas homerooms={selHrs} user={this.props.user!} />
                                    <div style={{marginTop: "1.5rem", fontSize: ".8em"}}>
                                        Have more ideas or other feedback? <br/>
                                        <a className="regLink" href={`/auth/${Nls.get("URL_PART")}/submit_idea`}>Let us know!</a>
                                    </div>
                                </Tile>
                            </div>
                        </div>
                    </div>
                </div>
            }
        }

        return <>
            <Helmet>
                <title>{Nls.get("SELECTOR_LABELS")} - {Nls.get("SITE_TITLE")}</title>
            </Helmet>
            {appHeader}
            {appContent}
        </>
    }
}

// Wrap and export
export default function Homerooms(props: HomeroomsProps) {
    const theme = useTheme();
  
    return <HomeroomsClass {...props} theme={theme} />;
}