import React from 'react';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import { ApiHomeroomClub } from '../../../../../../../../server/src/hrpcb/models/apiHomeroom';
import "./SectionAllergies.scss";
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip } from '@mui/material';
import { ExpandMore, LocalPharmacy } from '@mui/icons-material';
import Tile from '../../../../components/widgets/Tile';
import HomeroomService from '../../../../services/HomeroomService';
import ModalAllergyEdit from './ModalAllergyEdit';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import Edit from '@mui/icons-material/Edit';
import Nls from '../../../../nls/nls';
import InfoButton from '../../../../components/info/InfoButton';
import InfoAllergies from '../../../../components/info/InfoAllergies';

interface SectionAllergiesMobileProps {
    user?: ApiUser
    homerooms: ApiHomeroomClub[]
    expanded: boolean,
    onToggle: () => void,
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
}

export function SectionAllergiesMobile(props: SectionAllergiesMobileProps) {
    return <SectionAllergiesClass {...props} />
}

interface SectionAllergiesDesktopProps {
    user?: ApiUser | null
    homerooms: ApiHomeroomClub[]
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
}

export function SectionAllergiesDesktop(props: SectionAllergiesDesktopProps) {
    return <SectionAllergiesClass {...props} />
}

interface SectionAllergiesState {
    dialogEdit: boolean
}

interface SectionAllergiesClassProps {
    user?: ApiUser | null
    homerooms: ApiHomeroomClub[]
    expanded?: boolean,
    onToggle?: () => void,
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
}

export default class SectionAllergiesClass extends React.Component<SectionAllergiesClassProps, SectionAllergiesState> {
    state: SectionAllergiesState = {
        dialogEdit: false
    }

    async onAllergies(allergies: { [hrId: string]: string }) {
        let update : ApiHomeroomClub[] = JSON.parse(JSON.stringify(this.props.homerooms));
        for (let idx=0; idx<update.length; ++idx) {
            if (update[idx]._id in allergies) {
                update[idx].allergies = allergies[update[idx]._id];
            }
        }
        await this.props.onHomerooms(update);
    }

    render() {
        const mobile = typeof this.props.expanded !== "undefined"
        const canEditAny = HomeroomService.canEditAny(this.props.homerooms, this.props.user);
        const allergyHomerooms = this.props.homerooms.filter(hr => hr.allergies && hr.allergies.length > 0);

        let editableHomerooms = this.props.homerooms.filter((homeroom) => {
            return HomeroomService.canEdit(homeroom, this.props.user);
        });

        const sectionHeader = <div className="sectionTitle">
            <h2 style={{marginRight: "1rem"}}>
                <LocalPharmacy /><span style={{verticalAlign: "top", marginLeft: ".5rem"}}>{Nls.get("ALLERGY_TITLE")}</span>
            </h2>
            <InfoButton style={{marginLeft: "-1rem"}} title="Allergies Info" showLogin={!this.props.user}>
                <InfoAllergies />
            </InfoButton>
            { HomeroomService.canEditAny(this.props.homerooms, this.props.user) && <>
                <Tooltip placement="top" title="Edit allergies">
                    <IconButton
                        color={mobile ? undefined : "primary"}
                        style={{ width: "inherit", float: "right" }}
                        onClick={(evt) => { 
                            evt.stopPropagation();
                            this.setState({dialogEdit: true});
                        }}
                        size="large"><Edit className="homeIconButton" />
                    </IconButton>
                </Tooltip>
                <div style={{clear: "both"}}></div>
            </>
            }
        </div>;

        const sectionBody = <>
            { (allergyHomerooms.length === 0 && <>
                <p>{Nls.get("ALLERGY_NONE")}</p>
                <p>You may edit to indicate some.</p>
            </>) || <>
                <div style={{color: "#ed7777", marginBottom: "1rem"}}>
                    <NotificationImportantIcon style={{ marginBottom: "-.3rem"}}/> Attention!
                </div>
            </>}
            { allergyHomerooms.map(hr => (<>
                {hr.allergies && hr.allergies.length > 0 && <><div style={{marginBottom: "1rem"}}>
                    <strong>Grade {hr.grade} &mdash; {HomeroomService.hrClubName(hr)}</strong>
                </div>
                <div>{hr.allergies}</div>{}
                </>}
            </>)) }
        </>

        const sectionDialog = <>
            { canEditAny && <ModalAllergyEdit
                open={this.state.dialogEdit} 
                onAllergies={async (allergies: { [hrId: string]: string }) => { 
                    await this.onAllergies(allergies);
                }}
                onClose={() => {this.setState({dialogEdit: false})} }
                homerooms={editableHomerooms}
                user={this.props.user!}
            />}
        </>;

        if (allergyHomerooms.length > 0 || canEditAny) {
            if (typeof this.props.expanded !== "undefined") {
                return <Accordion
                    square
                    expanded={this.props.expanded}
                    onChange={() => {
                        this.props.onToggle!();
                    }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        {sectionHeader}
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{width: "100%"}}>
                            {sectionBody}
                        </div>
                    </AccordionDetails>
                    {sectionDialog}
                </Accordion>
            } else {
                return <Tile dark>
                    {sectionHeader}
                    {sectionBody}
                    {sectionDialog}
                </Tile>
            }
        } else {
            return <></>;
        }
    }
}