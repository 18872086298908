export interface ICamp {
    id: string,
    account_id: string,
    name: string,
    type: string,
    urlname: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    shortdesc: string,
    description: string,
    managers: string[],
    staff: string[]
}

export class CampV1 {
    static async getCampsByAccountId(accountId: string) : Promise<ICamp[]> {
        let result = await fetch(`/api/taken/camp?accountId=${accountId}`);
        return result.json();
    }

    static async getCamp(id: string) : Promise<ICamp> {
        let result = await fetch(`/api/taken/camp/${id}`);
        return result.json();
    }

    static async createCamp(newCamp: ICamp) : Promise<ICamp> {
        let result = await fetch(`/api/taken/camp`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(newCamp)
        })
        if (result.status !== 200) {
            throw await result.json();
        }
        return result.json();
    }

    static async updateCamp(updCamp: ICamp) : Promise<ICamp> {
        let result = await fetch(`/api/taken/camp`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(updCamp)
        })
        return result.json();
    }

    static async deleteCamp(id: string) {
        await fetch(`/api/taken/camp/${id}`, {
            method: "DELETE"
        })
    }
}