import React from 'react';

import { 
    Button, 
    Column, 
    DatePicker,
    DatePickerInput,
    Dropdown,
    Grid, 
    TextInput 
} from "@carbon/react";

import "./Home.scss";
import MyTheme from '../../../../components/MyTheme';
import DateUtil from '../../../../util/DateUtil';

type DropOption = {
    id: string,
    label: string
}

interface MainState {
    zip: string,
    birthStr: string,
    searchWeek: DropOption
}

export default class Main extends React.Component<{}, MainState> {
    state: MainState = {
        zip: "",
        birthStr: "",
        searchWeek: { id: "", label: "All weeks" }
    }

    changeWeek(chg: { selectedItem: DropOption }) {
        this.setState({ searchWeek: chg.selectedItem });
    }

    changeZip(event: any) {
        this.setState({zip: event.target.value});
    };

    changeBirth(pickerDateStr: string) {
        let newDate = new Date(pickerDateStr);
        let birthStr = ((newDate.getMonth()+1)<10?"0":"")+(newDate.getMonth()+1);
        birthStr += "-" + (newDate.getDate()<10?"0":"")+newDate.getDate();
        birthStr += "-" + newDate.getFullYear()+ "";
        this.setState({ birthStr });
    };

    componentDidMount() {
        document.getElementById("root")!.style.backgroundColor = "hsl(209, 100%, 53%)";
        navigator.geolocation.getCurrentPosition((position) => {
            fetch(`/api/common/zip/fromgeo?lat=${position.coords.latitude}&long=${position.coords.longitude}`)
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    if (result?.zipCode) {
                        this.setState({zip: result.zipCode});
                    }
                })
        });
    }

    componentWillUnmount() {
        document.getElementById("root")!.style.backgroundColor = "#F4F4F4";
    }

    render() {
        let searchWeeks = DateUtil.genWeeks(52);
        searchWeeks.unshift({
            id: "",
            label: "All weeks"
        });

        let spacing = "1rem";

        return (<div>
            <main className="home">
                <div className="cds--g90">
                    <Grid>
                        <Column
                            sm={4} md={{ span: 4, offset: 2 }} lg={{ span: 6, offset: 5 }}
                            style={{ marginTop: "2rem", marginBottom: "6rem" }}
                        >
                            <div className="calbal" style={{textAlign:"center", marginBottom: "2rem"}}>                                      
                                <img src="../images/calbal.svg" alt="" />
                            </div>
                            <MyTheme siteKey="ct" theme="white" style={{ margin: "-1rem", padding: "1rem"}}>
                                <div className="formHeading">
                                    <span>Find</span> a Camp
                                </div>
                                <TextInput 
                                    id="search-zip" 
                                    labelText="Zip code (required)" 
                                    value={this.state.zip}
                                    onChange={this.changeZip.bind(this)}
                                    style={{maxWidth: "18rem"}}
                                />
                                <div style={{marginTop: spacing}} />
                                <Dropdown
                                    style={{maxWidth: "18rem"}}
                                    ariaLabel="Dropdown"
                                    id="carbon-dropdown-example"
                                    items={searchWeeks}
                                    label="Week options..."
                                    titleText="Search week"
                                    onChange={this.changeWeek.bind(this)}
                                    selectedItem={this.state.searchWeek}
                                />
                                <div style={{marginTop: spacing}} />
                                <DatePicker
                                    datePickerType="single"
                                    value={this.state.birthStr}
                                    onChange={this.changeBirth.bind(this)}
                                    >
                                    <DatePickerInput
                                        id="search-date"
                                        labelText="Camper's birthday (mm/dd/yyyy)" 
                                        // onClose={function noRefCheck(){}}
                                        // onOpen={function noRefCheck(){}}
                                        // helperText="mm/dd/yyyy"
                                    />
                                </DatePicker>
                                <div style={{marginTop: "1.5rem"}}/>
                                <Button 
                                    href={`/camps?searchWeek=${this.state.searchWeek?.id}&zip=${this.state.zip}&birthStr=${this.state.birthStr}`}
                                    disabled={!/\d{5}/.test(this.state.zip)}
                                >Search</Button>
                            </MyTheme>
                        </Column>
                    </Grid>
                    <img alt="" className="background" src={"../../images/lights.png"} />
                </div>
            </main>
        </div>)
    }
}