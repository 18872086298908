import { IPaypalPayment } from "../models/IPaypal";

export interface ICampReservation {
    id?: string,
    camp_id: string,
    session_id: string,
    label: string,
    zip: string,
    dateStart: string,
    payment_id: string,
    created?: number,
    estimate: string,
    editors?: string[]
    payment_details?: IPaypalPayment
}

export default class CampReservationV1 {
    static async create(newReservation: ICampReservation) : Promise<ICampReservation | undefined> {
        let result = await fetch(`/api/taken/campreservation`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(newReservation)
        })
        return result.json();
    }

    static async update(updReservation: ICampReservation) : Promise<ICampReservation | undefined> {
        let result = await fetch(`/api/taken/campreservation`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(updReservation)
        })
        return result.json();
    }
}
