import MathUtil from "./MathUtil";

export default class DateUtil {
    /** Get a date object for now */
    static now() : Date {
        return new Date();
    }

    /** Get a string for today's date mm/dd/yyyy */
    static todayStr() : string {
        return new Date().toLocaleDateString("en-US", {
            month: "numeric", day: "numeric", year: "numeric"
        });
    }

    /** Get a date object for today's mm/dd/yyyy */
    static today() : Date {
        return new Date(DateUtil.todayStr());
    }
    
    static yearsSince(dateStr: string) : number {
        let today = DateUtil.today();
        let d = new Date(dateStr);
        let retVal = today.getFullYear()-d.getFullYear();
        d.setFullYear(today.getFullYear());
        if (d.getTime() > today.getTime()) {
            --retVal;
        }
        return retVal;
    }

    static daysOfWeek() : string[] {
        return [
            "Monday"
            , "Tuesday"
            , "Wednesday"
            , "Thursday"
            , "Friday"
            , "Saturday"
            , "Sunday"
        ]
    }

    static daysOfWeekInRange(start: string, end: string) : string[] {
        let dow = DateUtil.daysOfWeek();
        let startD = new Date(start);
        let endD = new Date(end);
        if (startD.toString() === "Invalid Date"
            || endD.toString() === "Invalid Date") 
        {
            return [];
        }
        if (endD.getTime()-startD.getTime() > 6*24*60*60*1000) {
            return dow;
        } else {
            let retVal : string[] = [];
            for (let idx=startD.getDay(); idx !== endD.getDay(); idx = (idx+1)%7) {
                retVal.push(dow[MathUtil.mod(idx-1,7)]);
            }
            retVal.push(dow[MathUtil.mod(endD.getDay()-1,7)]);
            return retVal.sort((a,b) => (dow.indexOf(a)-dow.indexOf(b)));
        }
    }

    static genWeeks(numWeeks: number) {
        let retVal : Array<{id: string, label: string}> = [];
        const now = DateUtil.now();
        let startWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        startWeek.setDate(startWeek.getDate()-startWeek.getDay());
        let endWeek = new Date(startWeek.getTime());
        endWeek.setDate(endWeek.getDate()+6);
        for (let weeks=0; weeks<numWeeks; ++weeks) {
            retVal.push({
                id: ""+startWeek.getTime(),
                label: `${startWeek.toDateString()} – ${endWeek.toDateString()}`
            });
            startWeek.setDate(startWeek.getDate()+7);
            endWeek.setDate(endWeek.getDate()+7);
        }
        return retVal;
    }
}