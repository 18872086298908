import { InfoOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from '@mui/material';
import React, { ReactNode, useState } from 'react';

type ePageProfile = "mobile" | "tablet" | "desktop" | "desktoplg";

function getProfile() {
    let profile : ePageProfile = "mobile";
    let w = window.innerWidth;
    if (w < 768) {
        profile = "mobile";
    } else if (w < 992) {
        profile = "tablet";
    } else if (w < 1200) {
        profile = "desktop";
    } else {
        profile = "desktoplg";
    }
    return profile;
}

interface InfoButtonProps {
    title: string,
    style?: {
        [key: string]: string
    },
    children: ReactNode,
    showLogin: boolean
}

export default function InfoButton(props: InfoButtonProps) {
    const [open, setOpen] = useState(false);
    let profile = getProfile();
    let myStyle : { [key: string]: string } = { width: "inherit", marginTop:"-1.75rem" };
    for (const key in props.style) {
        myStyle[key] = props.style[key];
    }
    if (profile === "mobile") {
        if ("marginLeft" in myStyle) {
            myStyle['marginLeft'] = (parseInt(myStyle['marginLeft'])+.5)+"rem";
        } else {
            myStyle['marginLeft'] = ".5rem";
        }
        if ("marginTop" in myStyle) {
            myStyle['marginTop'] = (parseInt(myStyle['marginTop'])+0)+"rem";
        } else {
            myStyle['marginTop'] = ".5rem";
        }
    }
    return <><Tooltip placement="top" title={props.title}>
        <IconButton
            // color={mobile ? undefined : "primary"}
            style={myStyle}
            onClick={(evt) => {
                setOpen(true);
                evt.stopPropagation();
            }}
            size="medium"><InfoOutlined fontSize='small' className="homeIconButton" />
        </IconButton>
    </Tooltip>
    <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={()=>{setOpen(false)}}
    >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.children}
          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
            {props.showLogin && <Button onClick={()=>{document.location.href=`/login${document.location.search}`}}>Login and Get Started</Button>}
            <Button onClick={()=>{setOpen(false)}}>Close</Button>
        </DialogActions>
    </Dialog>
    </>;
}
