import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { ApiHomeroomClub } from "../../../../../../server/src/hrpcb/models/apiHomeroom";
import Nls from '../../nls/nls';
import HomeroomService from '../../services/HomeroomService';

interface DialogAddCalProps {
    homerooms: ApiHomeroomClub[],
    open: boolean,
    onClose: (homeroom?: ApiHomeroomClub) => void
}

export default function DialogAddCal(props: DialogAddCalProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [calendarId, setCalendarId] = useState("");
    const [homeroomId, setHomeroomId] = useState(props.homerooms[0]._id);

    const onUpdate = async function() {
        let homeroom = props.homerooms.filter((homeroom) => homeroom._id === homeroomId)[0]
        let updateHr : ApiHomeroomClub = JSON.parse(JSON.stringify(homeroom));
        let newCalId = calendarId;
        setCalendarId("");
        if (newCalId) {
            updateHr.calendarIds = updateHr.calendarIds || [];
            if (!updateHr.calendarIds.some((id) => id === newCalId)) {
                updateHr.calendarIds.push(newCalId);
                props.onClose(updateHr);
            } else {
                props.onClose();
            }
        }
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.open}
                onClose={() => props.onClose()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Add a calendar"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                      <p>{Nls.get("CALENDAR_ADD_DESCRIPTION")}</p>
                      <p>You can determine the Google Calendar ID in one of two ways:</p>
                      <p>1) For a web-based Google calendar, after you click the +Google Calendar on the bottom,
                          the ID will display in the address bar after <b>cid=</b>. It should also show between
                          &lt;&gt; in a dialog, such as District &lt;<b>main@myisd.org</b>&gt;
                      </p>
                      <p>2) For a calendar already added to your Google Calendar, open "Settings and sharing" of 
                          the calendar. Then, in the "Integrate calendar" section, see the "Calendar ID"
                      </p>
                    </DialogContentText>
                    <FormControl variant="standard">
                        <InputLabel id="homeroom-label">{Nls.get("SELECTOR_LABEL")}</InputLabel>
                        <Select
                            labelId="homeroom-label"
                            id="homeroom-select"
                            value={homeroomId}
                            onChange={(evt: any) => {
                                setHomeroomId(evt.target.value);
                            }}
                        >
                            {props.homerooms.map((homeroom) => (
                                <MenuItem key={homeroom._id} value={homeroom._id}>{HomeroomService.classLabel(homeroom)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div style={{marginTop: "1rem"}}></div>
                    <TextField
                        variant="standard"
                        autoFocus
                        margin="dense"
                        id="addCalId"
                        label="Calendar ID"
                        type="text"
                        fullWidth
                        value={calendarId} 
                        onChange={((evt: any) => { setCalendarId(evt.target.value) })} 
                        onKeyDown={(evt: any) => { 
                            if (evt.key === "Enter" && calendarId.trim().length > 0) { 
                                onUpdate();
                            }
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onUpdate} color="primary" disabled={calendarId.trim().length === 0 }>
                        Add
                    </Button>
                    <Button onClick={() => props.onClose()}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}