import React from 'react';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import Signups from '../../../../components/signups/Signups';
import { ApiHomeroomClub } from '../../../../../../../../server/src/hrpcb/models/apiHomeroom';
import { ApiSignupEvent } from '../../../../../../../../server/src/hrpcb/models/apiSignup';
import "./SectionSignups.scss";
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip } from '@mui/material';
import { PostAdd, ExpandMore, HowToVote } from '@mui/icons-material';
import Tile from '../../../../components/widgets/Tile';
import HomeroomService from '../../../../services/HomeroomService';
import ModalAddSignupEvent from './ModalAddSignupEvent';
import InfoSignups from '../../../../components/info/InfoSignups';
import InfoButton from '../../../../components/info/InfoButton';

interface SectionSignupsMobileProps {
    user?: ApiUser
    homerooms: ApiHomeroomClub[]
    expanded: boolean,
    onToggle: () => void,
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
}

export function SectionSignupsMobile(props: SectionSignupsMobileProps) {
    return <SectionSignupsClass {...props} />
}

interface SectionSignupsDesktopProps {
    user?: ApiUser | null
    homerooms: ApiHomeroomClub[]
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
}

export function SectionSignupsDesktop(props: SectionSignupsDesktopProps) {
    return <SectionSignupsClass {...props} />
}

interface SectionSignupsState {
    dialogAdd: boolean
}

interface SectionSignupsClassProps {
    user?: ApiUser | null
    homerooms: ApiHomeroomClub[]
    expanded?: boolean,
    onToggle?: () => void,
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
}

export default class SectionSignupsClass extends React.Component<SectionSignupsClassProps, SectionSignupsState> {
    state: SectionSignupsState = {
        dialogAdd: false
    }

    async onSignupEvent(homeroomId: string, newEvent: ApiSignupEvent) {
        let update : ApiHomeroomClub[] = JSON.parse(JSON.stringify(this.props.homerooms));
        for (let idx=0; idx<update.length; ++idx) {
            // if (this.state.dialogEdit && update[idx]._id === this.state.dialogEdit.homeroom._id) {
            //     update[idx].news = this.state.dialogEdit.homeroom.news.filter((newsItem) => newsItem.id !== this.state.dialogEdit!.id);
            // }
            update[idx].signupEvents = update[idx].signupEvents || [];
            if (update[idx]._id === homeroomId && !update[idx].signupEvents.includes(newEvent._id!)) {
                update[idx].signupEvents.push(newEvent._id!);
            }            
        }
        await this.props.onHomerooms(update);
    }

    render() {
        const mobile = typeof this.props.expanded !== "undefined"

        let editableHomerooms = this.props.homerooms.filter((homeroom) => {
            return HomeroomService.canEdit(homeroom, this.props.user);
        });

        const sectionHeader = <div className="sectionTitle">
            <h2 style={{marginRight: "1rem"}}>
                <HowToVote /><span style={{verticalAlign: "top", marginLeft: ".5rem"}}>Signups</span>
            </h2>
            <InfoButton style={{marginLeft: "-1rem"}} title="Signups Info" showLogin={!this.props.user}>
                <InfoSignups />
            </InfoButton>
            { HomeroomService.canEditAny(this.props.homerooms, this.props.user) && <>
                <Tooltip placement="top" title="Add event">
                    <IconButton
                        color={mobile ? undefined : "primary"}
                        style={{ width: "inherit", float: "right" }}
                        onClick={(evt) => { 
                            evt.stopPropagation();
                            this.setState({dialogAdd: true});
                        }}
                        size="large"><PostAdd className="homeIconButton" />
                    </IconButton>
                </Tooltip>
                <div style={{clear: "both"}}></div>
            </>
            }
        </div>;

        const sectionBody = <div className="signups">
            <Signups
                homerooms={this.props.homerooms} 
                user={this.props.user} 
                onHomerooms={this.props.onHomerooms}
            />
        </div>

        const sectionDialog = <>
            { editableHomerooms.length > 0 && <ModalAddSignupEvent
                open={this.state.dialogAdd} 
                onSignupEvent={async (homeroomId: string, se: ApiSignupEvent) => { 
                    await this.onSignupEvent(homeroomId, se) 
                }} 
                onClose={() => {this.setState({dialogAdd: false})} }
                homerooms={editableHomerooms}
                user={this.props.user!}
            />}
        </>;

        if (typeof this.props.expanded !== "undefined") {
            return <Accordion
                square
                expanded={this.props.expanded}
                onChange={() => {
                    this.props.onToggle!();
                }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    {sectionHeader}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{width: "100%"}}>
                        {sectionBody}
                    </div>
                </AccordionDetails>
                {sectionDialog}
            </Accordion>
        } else {
            return <Tile dark>
                {sectionHeader}
                {sectionBody}
                {sectionDialog}
            </Tile>
        }
    }
}