import React from 'react';
import { Theme } from "@carbon/react";
import { SiteKey } from '../models/Site';

interface MyThemeProps {
    siteKey: SiteKey
    theme: 'white' | 'g10' | 'g90' | 'g100' | 'brand'
    style?: {
        [prop: string]: string
    }
    className?: string
}

interface MyThemeState {
}

export default class MyTheme extends React.Component<MyThemeProps, MyThemeState> {

    render() {
        let carbonTheme = ['white', 'g10', 'g90', 'g100'].includes(this.props.theme);
        const {siteKey, theme, children, ...rest} = this.props;

        if (carbonTheme) {
            return (
                <Theme theme={theme} {...rest}>
                    <div className={`cds--${siteKey}-${theme}`}>
                        {children}
                    </div>
                </Theme>
            );
        } else {
            return (
                <div className={`cds--${siteKey}-${theme}`}  {...rest}>
                    {children}
                </div>
            );
        }
    }
}
