import React from 'react';
import { CircularProgress } from '@mui/material';
import Markdown from 'markdown-to-jsx'; 

import ClubsAbout from '../../content/ClubsAbout.md'
import ClubsOverview from '../../content/ClubsOverview.md'
import ClubsPrivacy from '../../content/ClubsPrivacy.md'
import ClubsTerms from '../../content/ClubsTerms.md'
import HRPAbout from '../../content/HRPAbout.md'
import HRPOverview from '../../content/HRPOverview.md'
import HRPPrivacy from '../../content/HRPPrivacy.md'
import HRPTerms from '../../content/HRPTerms.md'
import './MDContent.scss';
import { Helmet } from "react-helmet";
import { Location, useLocation } from 'react-router-dom';

const pageMap : { [key: string]: {title: string, page: any} } = {
    "/content/hrp/overview": { title: `Overview - Homeroom Parent`, page: HRPOverview },
    "/content/hrp/about": { title: "About - Homeroom Parent", page: HRPAbout},
    "/content/hrp/terms": { title: "Terms - Homeroom Parent", page: HRPTerms},
    "/content/hrp/privacy": { title: "Privacy - Homeroom Parent", page: HRPPrivacy},
    "/content/club/overview": { title: "Overview - Club and Booster", page: ClubsOverview },
    "/content/club/about": { title: "About - Club and Booster", page: ClubsAbout},
    "/content/club/terms": { title: "Terms - Club and Booster", page: ClubsTerms},
    "/content/club/privacy": { title: "Privacy - Club and Booster", page: ClubsPrivacy},
}

interface MDContentState {
    md: string | null
}

class MDContentCls extends React.Component<{location: Location}, MDContentState> {
    state : MDContentState = {
        md: null
    }

    async componentDidMount() {
        let pageUrl = pageMap[this.props.location.pathname];
        if (pageUrl === undefined) {
            this.setState({ md: "Page not found."});
        } else {
            let response = await fetch(pageUrl.page);
            let md = await response.text();
            this.setState({ md: md });
        }
    }

    render() {
        let page = pageMap[this.props.location.pathname];

        return <>
            <Helmet>
                <title>{page.title}</title>
            </Helmet>
            <div className="mdContent hrpRow" style={{ marginTop: "0rem" }}>
                <div className="hrpCol-4-mob hrpCol-6-tab hrpCol-10-desk hrpCol-18-desklg">
                    <div style={{marginTop: "1rem"}}></div>
                    { !this.state.md && <CircularProgress /> }
                    { this.state.md && <Markdown children={this.state.md}></Markdown>}
                </div>
            </div>
        </>
    }
}
export default function MDContent() {
    let location = useLocation();
    return <MDContentCls location={location} />
}