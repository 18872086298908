import { Dialog, DialogTitle, TextField, useMediaQuery, useTheme, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import React, { MouseEvent } from 'react';
import { ApiHomeroomClub } from '../../../../../../../../server/src/hrpcb/models/apiHomeroom';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import { HRPButton, HRPButtonSecondary } from '../../../../components/widgets/Customized';
import Nls from '../../../../nls/nls';

interface ModalAddLinkProps {
    open: boolean
    onLink: (homeroomId: string, linkLabel: string, linkUrl: string) => Promise<void>
    onClose: () => void
    homerooms: ApiHomeroomClub[]
    user: ApiUser
}

interface ModalAddLinkState {
    currentLink: {
        name: string
        url: string
        category: string
    },
    possibleCategories: string[]
}

export default function ModalAddLink(props: ModalAddLinkProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return <ModalAddLinkClass {...props} fullScreen={fullScreen} />
}

class ModalAddLinkClass extends React.Component<ModalAddLinkProps & { fullScreen: boolean }, ModalAddLinkState> {
    state: ModalAddLinkState = {
        currentLink: this.resetVals(),
        possibleCategories: []
    }

    componentDidMount() {
        let catMap : { [cat: string]: boolean } = {};
        for (const hr of this.props.homerooms) {
            for (const cat in hr.links) {
                catMap[cat] = true;
            }
        }
        let cats = Object.keys(catMap);
        this.setState({ 
            currentLink: this.resetVals(cats),
            possibleCategories: cats
        });
    }

    componentDidUpdate(prevProps: ModalAddLinkProps) {
        let changed = prevProps.homerooms.length !== this.props.homerooms.length;
        if (!changed) {
            let oldKeys = prevProps.homerooms.map(hr => hr._id);
            for (const hr of this.props.homerooms) {
                changed = changed || !oldKeys.includes(hr._id)
            }
        }
        if (changed) {
            let catMap : { [cat: string]: boolean } = {};
            for (const hr of this.props.homerooms) {
                for (const cat in hr.links) {
                    catMap[cat] = true;
                }
            }
            let cats = Object.keys(catMap);
            this.setState({ 
                currentLink: this.resetVals(cats),
                possibleCategories: cats
            });
        }
    }

    onState(field: "name" | "url" | "category", evt: any) {
        let newState: ModalAddLinkState = JSON.parse(JSON.stringify(this.state));
        newState.currentLink[field] = evt.target.value;
        this.setState(newState);
    }

    resetVals(cats? : string[]) {
        return {
            name: "",
            url: "",
            category: (cats && cats.length > 0 && cats[0]) 
            || (this.state
                && this.state.possibleCategories 
                && this.state.possibleCategories.length > 0 
                && this.state.possibleCategories[0]) 
            || ""
        }
    }

    async addLink() {
        await this.props.onLink(
            this.state.currentLink.category,
            this.state.currentLink.name,
            this.state.currentLink.url
        );
        this.setState({
            currentLink: this.resetVals()
        });
        this.props.onClose();
    }

    render() {
        const { fullScreen } = this.props;
        const curLink = this.state.currentLink;
        let validLink = curLink.name.trim().length > 0
            && curLink.url.trim().length > 0;
        return <Dialog
            open={this.props.open}
            maxWidth="md"
            fullWidth={true}
            fullScreen={fullScreen}
            onClose={() => {
                this.setState({
                    currentLink: this.resetVals()
                });
                this.props.onClose();
            }}
        >
            <DialogTitle>Add link</DialogTitle>
            <div style={{ margin: "0rem 1.5rem" }}>
                <div onKeyDown={(evt: any) => { 
                    if (evt.key === "Enter" && validLink) { 
                        this.addLink();
                        evt.preventDefault();
                    }
                }}>
                    <div style={{"maxWidth": "80%"}}>This will add links to the {Nls.get("SELECTOR_LABEL_LOWER")} for reference by all members
                        of the {Nls.get("SELECTOR_LABEL_LOWER")}. These may be categorized as related to the school
                        or the {Nls.get("SELECTOR_LABEL_LOWER")}. Some examples of links you might add include:
                        school nurse, librarian, yearbook, volunteer registration, medical
                        forms, school supplies, etc.
                    </div>
                    <div style={{marginTop: "1rem"}} />
                    <FormControl variant="standard">
                        <InputLabel id="cat-label">Link category</InputLabel>
                        <Select
                            labelId="cat-label"
                            id="demo-simple-select"
                            value={this.state.currentLink.category}
                            onChange={this.onState.bind(this, "category")}
                            displayEmpty
                        >
                            { this.state.possibleCategories.map(cat => (
                                <MenuItem value={cat}>{cat}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div style={{ marginTop: "1.5rem" }}></div>
                    <TextField
                        variant="filled"
                        style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                        label="Link name"
                        value={this.state.currentLink.name}
                        onChange={this.onState.bind(this, "name")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <div style={{ marginTop: "1.5rem" }}></div>
                    <TextField
                        variant="filled"
                        style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                        label="Link item url"
                        value={this.state.currentLink.url}
                        onChange={this.onState.bind(this, "url")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div style={{ marginTop: "1.5rem" }}></div>
                <div style={{ margin: "0rem -1rem"}}>
                    <div className="hrpRow">
                        <div className="hrpCol-2-mob">
                            <Button sx={HRPButtonSecondary} variant="contained" style={{maxWidth: "100%"}} onClick={(evt: MouseEvent) => {
                                this.setState({
                                    currentLink: this.resetVals()
                                });
                                this.props.onClose();
                                evt.preventDefault();
                            }}>Cancel</Button>
                        </div>
                        <div className="hrpCol-2-mob">
                            <Button sx={HRPButton} variant="contained" color="primary" disabled={!validLink} onClick={(evt: MouseEvent) => {
                                this.addLink();
                                evt.preventDefault();
                            }}>Add</Button>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "1.5rem" }}></div>
            </div>
        </Dialog>
    }
}