import React from 'react';

import { Grid, Column } from "@carbon/react";

import "./HomeContent.scss";

export default function About() {
    return (<div>
        <main>
            <Grid>
                <Column sm={4} md={6} lg={8}>
                    <h1>CampTaken Story</h1>
                    <p>CampTaken is located in Round Rock, Texas. The business was started
                        by a part-time working mom who has three kids. She found 
                        that she was spending countless hours on the phone trying to find 
                        open venues. She noticed that there is a need for a reservation 
                        system that is convenient and easy to access.</p>

                    <p>Also, two good birthday party venues where her kids had parties 
                        closed down due to a lack of business. She knew that both businesses 
                        and customers needed a calendar reservation system that showed times, 
                        prices, and more information so the customer can book parties right 
                        at that moment. This service is to help you, the customer, book events, 
                        and for the venues organization, visibility, and marketing. It is an all 
                        around win-win for everyone.</p>
                </Column>
            </Grid>
        </main>
    </div>)
}