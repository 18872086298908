import React from 'react';
import Nls from '../../nls/nls';

interface InfoLinksProps {
}

export default function InfoLinks(_props: InfoLinksProps) {

    if (Nls.get("URL_PART") === "hrp") {
        return <>
            <p>You can add links beyond the standard links.</p>
            <p>You might include links such as school nurse, librarian, yearbook, 
volunteer registration, medical forms, school supplies, etc.
You may edit any existing links, or to provide URL for default links
Links for the clasroom, school, district, and PTA are shown on the
main page. If other links are added, see "More links"</p>
<p>When a teacher logs in, they will be prompted to share their favorite things.
They can also edit these items in their profile.
Once a teacher has specified their favorite things, a link will show under
the relevant classroom with the details.</p>
        </>;
    } else {
        return <>
            <p>You can add links beyond the standard links.</p>
<p>You might include links such as registration forms, weather info,  
medical forms, uniforms, supplies, etc.
You may edit any existing links, or to provide URL for default links
A links for the club website is shown on the
main page. If other links are added, see "More links"</p>
<p>When a coach/president logs in, they will be prompted to share their favorite things.
They can also edit these items in their profile.
Once a coach/president has specified their favorite things, a link will show under
the relevant classroom with the details.</p>
        </>;
    }
}
