import { Button, Dialog, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme } from '@mui/material';
import React, { MouseEvent } from 'react';
import { ApiHomeroomClub } from '../../../../../../../../server/src/hrpcb/models/apiHomeroom';
import { ApiSignupEvent } from '../../../../../../../../server/src/hrpcb/models/apiSignup';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import { HRPButton, HRPButtonSecondary } from '../../../../components/widgets/Customized';
import Nls from '../../../../nls/nls';
import HomeroomService from '../../../../services/HomeroomService';
import SignupService from '../../../../services/SignupService';

interface ModalAddSignupEventProps {
    open: boolean
    onSignupEvent: (homeroomId: string,
        newEvent: ApiSignupEvent
    ) => void
    onClose: () => void
    homerooms: ApiHomeroomClub[]
    user: ApiUser
}

interface ModalAddSignupEventState {
    currentEvent: {
        hrId: string
        name: string
        url: string
        shareCode: string
    }
}

export default function ModalAddSignupEvent(props: ModalAddSignupEventProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return <ModalAddSignupEventClass {...props} fullScreen={fullScreen} />
}

class ModalAddSignupEventClass extends React.Component<ModalAddSignupEventProps & { fullScreen: boolean }, ModalAddSignupEventState> {
    state: ModalAddSignupEventState = {
        currentEvent: this.resetVals()
    }

    onState(field: "name" | "url" | "shareCode", evt: any) {
        let newState: ModalAddSignupEventState = JSON.parse(JSON.stringify(this.state));
        newState.currentEvent[field] = evt.target.value;
        this.setState(newState);
    }

    resetVals() {
        return {
            hrId: this.props.homerooms[0]._id,
            name: "",
            url: "",
            shareCode: ""
        }
    }

    async createEvent() {
        let signup;
        if (this.state.currentEvent.shareCode && this.state.currentEvent.shareCode.length > 0) {
            signup = await SignupService.getByShareCode(this.state.currentEvent.shareCode);
        } else {
            signup = await SignupService.createEvent(this.state.currentEvent.name, this.state.currentEvent.url);
        }
        await this.props.onSignupEvent(
            this.state.currentEvent.hrId,
            signup
        );
        this.setState({
            currentEvent: this.resetVals()
        });
        this.props.onClose();
    }

    render() {
        const { fullScreen } = this.props;
        const curEvent = this.state.currentEvent;
        let validEvent = curEvent.name.trim().length > 0 || curEvent.shareCode.match(/[A-Z1-9]{8}/);

        return (
            <Dialog
                open={this.props.open}
                maxWidth="md"
                fullWidth={true}
                fullScreen={fullScreen}
                onClose={() => {
                    this.setState({
                        currentEvent: this.resetVals()
                    });
                    this.props.onClose();
                }}
            >
                <DialogTitle>Add event</DialogTitle>
                <div style={{ margin: "0rem 1.5rem" }}>
                    <FormControl variant="standard">
                        <InputLabel id="homeroom-label">{Nls.get("SELECTOR_LABEL")}</InputLabel>
                        <Select
                            labelId="homeroom-label"
                            id="homeroom-select"
                            value={this.state.currentEvent.hrId}
                            onChange={(evt) => {
                                this.setState({
                                    currentEvent : {
                                        hrId: evt.target.value as string,
                                        name: this.state.currentEvent.name,
                                        url: this.state.currentEvent.url,
                                        shareCode: this.state.currentEvent.shareCode
                                    }
                                })
                            }}
                        >
                            {this.props.homerooms.map((homeroom) => (
                                <MenuItem key={homeroom._id} value={homeroom._id}>{HomeroomService.classLabel(homeroom)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div style={{ marginTop: "1.5rem" }}></div>
                    <TextField
                        autoFocus
                        variant="filled"
                        style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                        label="Event name"
                        value={this.state.currentEvent.name}
                        onChange={this.onState.bind(this, "name")}
                        onKeyPress={(evt) => {
                            if (evt.key === "Enter" && curEvent.name.trim().length > 0) {
                                this.createEvent();
                            }
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <div style={{ marginTop: "1.5rem" }}></div>
                    <TextField
                        variant="filled"
                        style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                        label="Event url (optional)"
                        value={this.state.currentEvent.url}
                        onChange={this.onState.bind(this, "url")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <div style={{ marginTop: "1.5rem" }}></div>
                    <div style={{ textAlign: "center" }}>or, to include an event from another {Nls.get("SELECTOR_LABEL_LOWER")}, request a <em>Share code</em> from that event:</div>
                    <div style={{ marginTop: "1.5rem" }}></div>
                    <TextField
                        variant="filled"
                        style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                        label="Share code"
                        value={this.state.currentEvent.shareCode}
                        onChange={this.onState.bind(this, "shareCode")}
                        onKeyPress={(evt) => {
                            if (evt.key === "Enter" && curEvent.shareCode.match(/[A-Z1-9]{8}/)) {
                                this.createEvent();
                            }
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText={`An event created in another ${Nls.get("SELECTOR_LABEL_LOWER")} can be shared with your ${Nls.get("SELECTOR_LABEL_LOWER")} via a 'share code'`}
                    />                
                    <div style={{ marginTop: "1.5rem" }}></div>
                    <div style={{ margin: "0rem -1rem"}}>
                        <div className="hrpRow">
                            <div className="hrpCol-2-mob">
                                <Button sx={HRPButtonSecondary} variant="contained" style={{maxWidth: "100%"}} onClick={(evt: MouseEvent) => {
                                    this.setState({
                                        currentEvent: this.resetVals()
                                    });
                                    this.props.onClose();
                                    evt.preventDefault();
                                }}>Cancel</Button>
                            </div>
                            <div className="hrpCol-2-mob">
                                <Button sx={HRPButton} variant="contained" color="primary" disabled={!validEvent} onClick={(evt: MouseEvent) => {
                                    evt.preventDefault();
                                    this.createEvent();
                                }}>Add</Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: "1.5rem" }}></div>
                </div>
            </Dialog>
        );
    }
}