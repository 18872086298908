import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';


import './Footer.scss';
import Nls from '../../nls/nls';

export default class Footer extends React.Component {

    render() {
        return (<>
            <div style={{paddingTop: "6rem"}} />
            <footer style={{ position: "fixed", bottom: "0px", marginTop: "1rem" }}>
                <div className="hrpRow">
                    <div className="hrpCol-4-mob">
                        <Link component={RouterLink} to={`/content/${Nls.get("URL_PART")}/terms`}>
                            Terms of service
                        </Link>
                        &nbsp;|&nbsp;
                        <Link component={RouterLink} to={`/content/${Nls.get("URL_PART")}/privacy`}>
                            Privacy Policy
                        </Link>
                    </div>
                </div>
                <div className="hrpRow">
                    <div className="hrpCol-4-mob">
                        HRPCB, LLC dba {Nls.get("URL")}
                    </div>
                </div>
            </footer>
            </>
        );
    }
}
