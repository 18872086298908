import { Autocomplete, Button, Dialog, DialogTitle, TextField, useMediaQuery, useTheme } from '@mui/material';
import React, { MouseEvent } from 'react';
import { ApiHomeroomClub, ApiWish } from '../../../../../../../../server/src/hrpcb/models/apiHomeroom';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import { HRPButton, HRPButtonSecondary } from '../../../../components/widgets/Customized';

interface ModalAddWishProps {
    open: boolean
    onWish: (homeroomId: string,
        newWish: ApiWish
    ) => void
    onClose: () => void
    homeroom: ApiHomeroomClub
    user: ApiUser
}

interface ModalAddWishState {
    currentWish: {
        name: string
        category: string
        url: string
        count: number
    }
}

export default function ModalAddWish(props: ModalAddWishProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return <ModalAddWishClass {...props} fullScreen={fullScreen} />
}

class ModalAddWishClass extends React.Component<ModalAddWishProps & { fullScreen: boolean }, ModalAddWishState> {
    state: ModalAddWishState = {
        currentWish: this.resetVals()
    }

    onState(field: "name" | "url" | "count" | "category", evt: any) {
        let newState: ModalAddWishState = JSON.parse(JSON.stringify(this.state));
        if (field === "count") {
            let count = parseInt(evt.target.value);
            if (count >= 1) {
                newState.currentWish[field] = count;
            }
        } else {
            newState.currentWish[field] = evt.target.value;
        }

        this.setState(newState);
    }

    resetVals() {
        return {
            name: "",
            url: "",
            category: "General",
            count: 1
        }
    }

    addWish() {
        this.props.onWish(
            this.props.homeroom._id,
            {
                category: this.state.currentWish.category,
                name: this.state.currentWish.name,
                url: this.state.currentWish.url,
                count: this.state.currentWish.count,
                remaining: this.state.currentWish.count,
                claimed_by: {},
                timeCreated: new Date().getTime()
            }
        );
        this.setState({
            currentWish: this.resetVals()
        });
        this.props.onClose();
    }

    render() {
        const { fullScreen } = this.props;
        const curWish = this.state.currentWish;
        let validWish = curWish.name.trim().length > 0
            && curWish.url.trim().length > 0;
        let existingCats : string[] = ["General"];
        if (this.props.homeroom 
            && this.props.homeroom.wishes 
            && this.props.homeroom.wishes.length > 0) 
        {
            existingCats = this.props.homeroom.wishes.map(wish => wish.category);
        }
        return <Dialog
            open={this.props.open}
            maxWidth="md"
            fullWidth={true}
            fullScreen={fullScreen}
            onClose={() => {
                this.setState({
                    currentWish: this.resetVals()
                });
                this.props.onClose();
            }}
        >
            <DialogTitle>Add wish</DialogTitle>
            <div style={{ margin: "0rem 1.5rem" }}>
                <div onKeyDown={(evt: any) => { 
                    if (evt.key === "Enter" && validWish) { 
                        this.addWish();
                        evt.preventDefault();
                    }
                }}>
                    <Autocomplete
                        freeSolo
                        options={existingCats}
                        value={this.state.currentWish.category}
                        renderInput={(params) => (
                            <TextField {...params} 
                                autoFocus
                                variant="standard"
                                style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                                label="Wish category" 
                                onChange={this.onState.bind(this, "category")}
                                InputLabelProps={{
                                    shrink: true,
                                }}                            />
                        )}
                    />
                    <div style={{ marginTop: "1.5rem" }}></div>
                    <TextField
                        variant="filled"
                        style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                        label="Wish item url"
                        value={this.state.currentWish.url}
                        onChange={this.onState.bind(this, "url")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <div style={{ marginTop: "1.5rem" }}></div>
                    <TextField
                        variant="filled"
                        style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                        label="Wish name"
                        value={this.state.currentWish.name}
                        onChange={this.onState.bind(this, "name")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <div style={{ marginTop: "1.5rem" }}></div>
                    <TextField
                        variant="filled"
                        value={this.state.currentWish.count}
                        label="Number requested"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={this.onState.bind(this, "count")}
                    />
                </div>
                <div style={{ marginTop: "1.5rem" }}></div>
                <div style={{ margin: "0rem -1rem"}}>
                    <div className="hrpRow">
                        <div className="hrpCol-2-mob">
                            <Button sx={HRPButtonSecondary} variant="contained" style={{maxWidth: "100%"}} onClick={(evt: MouseEvent) => {
                                this.setState({
                                    currentWish: this.resetVals()
                                });
                                this.props.onClose();
                                evt.preventDefault();
                            }}>Cancel</Button>
                        </div>
                        <div className="hrpCol-2-mob">
                            <Button sx={HRPButton} variant="contained" color="primary" disabled={!validWish} onClick={(evt: MouseEvent) => {
                                this.addWish();
                                evt.preventDefault();
                            }}>Add</Button>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "1.5rem" }}></div>
            </div>
        </Dialog>
    }
}