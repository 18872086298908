import React, { useState } from 'react';
import Header from "../../components/Header";
import {
    Route,
    BrowserRouter as Router,
    Routes,
    Link
} from "react-router-dom";

import About from "./pages/Home/About";
import Account from './pages/Account/Account';
import Accounts from './pages/Accounts/Accounts';
import Camps from "./pages/Camps/Camps";
import ContactUs from "./pages/Home/ContactUs";
import Home from "./pages/Home/Home";
import HomeNav from '../../components/HomeNav';
import Login from '../common/pages/Login/Login';
import MyTheme from '../../components/MyTheme';
import NewAccount from './pages/NewAccount/NewAccount';
import PWResetInvalid from '../common/pages/Login/PWResetInvalid';
import PWReset from '../common/pages/Login/PWReset';
import PWResetPW from '../common/pages/Login/PWResetPW';
import PWResetSent from '../common/pages/Login/PWResetSent';
import Reservation from './pages/Reservation/Reservation';
import Reservations from './pages/Reservations/Reservations';
import TakeIt from './pages/TakeIt/TakeIt';
import Unauthorized from '../common/pages/Unauthorized/Unauthorized';
import UserV1 from '../../services/UserV1';
import VerifyFail from '../common/pages/Login/VerifyFail';
import VerifyLogin from '../common/pages/Login/VerifyLogin';
// import Location from './pages/Camp/Camp';
import { IUserBasic } from '../../models/IUser';
import { Page404 } from '../../components/Page404';
import { ProtectLogin } from '../../components/ProtectLogin';
import { SiteKey } from '../../models/Site';
import { ToastNotification } from '@carbon/react';
import './SiteCampTaken.scss';

const SiteCampTaken: React.FC = () => {
    document.title = "CampTaken";
    const [user, setUser] = useState<IUserBasic | undefined>(undefined);
    const [, setInit] = useState<boolean>(false);

    UserV1.addUserListener((newUser) => {
        setUser(newUser);
    })
    UserV1.getUser().then(newUser => {
        setUser(newUser);
        setInit(true);
    });

    let siteKey: SiteKey = "ct";
    let local = document.location.href.includes("localhost") || document.location.href.includes("127.0.0.1");
    
    return (
        <MyTheme siteKey={siteKey} theme="g10">
            <Router>
                <Header siteKey={siteKey}>
                    <Link className="clean" to="/">Home</Link>
                    <span className="spacer">|</span>
                    <Link className="clean" to="/about">About</Link>
                    <span className="spacer">|</span>
                    <Link className="clean" to="/contactus">Contact Us</Link>
                </Header>
                <HomeNav siteKey={siteKey}></HomeNav>
                {!user && !local && <ToastNotification
                    style={{ position: "absolute", left: "calc(50vw - 10rem)", top: "20rem", zIndex: "1000" }}
                    hideCloseButton={false}
                    statusIconDescription="notification"
                    subtitle="This site is not yet live. If you own an event venue and
                        want to list with us, contact us at info@partytaken.com"
                    title="Coming soon!"
                />}
                <Routes>
                    <Route path="/" element={ <Home /> } />

                    {/* AUTH ROUTES */}
                <Route path="/auth/reservations" element={
                    <ProtectLogin>
                        <Reservations />
                    </ProtectLogin>
                } />
                <Route path="/auth/reservation/:id" element={
                    <ProtectLogin>
                        <Reservation /> 
                    </ProtectLogin>
                } />
                <Route path="/auth/:campId/:sessionId/takeit" element={
                    <ProtectLogin>
                        <TakeIt />
                    </ProtectLogin>
                } />
                <Route path="/contactus" element={
                    <ProtectLogin>
                        <ContactUs/>
                    </ProtectLogin>
                } />

                {/* ADMIN ROUTES */}
                <Route path="/account/accounts" element={
                    <ProtectLogin roles={["ADMIN", "OWNER"]}>
                        <Accounts />
                    </ProtectLogin>
                } />
                <Route path="/account/new_account" element={
                    <ProtectLogin roles={["ADMIN", "OWNER"]}>
                        <NewAccount />
                    </ProtectLogin> 
                } />
                <Route path="/account/:id" element={
                    <ProtectLogin roles={["ADMIN", "OWNER"]}>
                        <Account />
                    </ProtectLogin>
                } />

                {/* LOGIN/PW ROUTES */}
                <Route path="/login" element={<Login siteKey={siteKey} /> } />
                <Route path="/pwreset" element={<PWReset siteKey={siteKey} />} />
                <Route path="/pwreset_sent" element={<PWResetSent/>} />
                <Route path="/pwreset_invalid" element={<PWResetInvalid/>} />
                <Route path="/pwreset_pw" element={<PWResetPW/>} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/verifying" element={<VerifyLogin siteKey={siteKey} />} />
                <Route path="/verify_fail" element={<VerifyFail siteKey={siteKey} />} />

                {/* PUBLIC ROUTES */}
                <Route path="/about" element={ <About /> } />
                <Route path="/camps" element={<Camps />} />
                <Route path="/*" element={<Page404 /> } />
            </Routes>
        </Router>
        <div style={{ paddingTop: "0rem" }} />
    </MyTheme>
    );
}

export default SiteCampTaken;
