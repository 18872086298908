import React from 'react';
import { ApiUser } from '../../../../../../../server/src/hrpcb/models/apiUser';
import "./SubmitIdea.scss";
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, TextareaAutosize } from '@mui/material';
import { ePageProfile } from '../../../App';
import { YellowCheckbox, HRPButtonNarrow, HRPButtonCancel } from '../../../components/widgets/Customized';
import FeedbackService from '../../../services/FeedbackService';
import Nls from '../../../nls/nls';

interface SubmitIdeaProps {
    user?: ApiUser | null
    pageProfile: ePageProfile
}

interface SubmitIdeaState {
    grades: string[];
    feedback: string;
}

export default class SubmitIdea extends React.Component<SubmitIdeaProps, SubmitIdeaState> {
    state: SubmitIdeaState = {
        grades: [],
        feedback: ""
    }

    static order: string[] = [
        "K", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "Any", "None"
    ];

    makeCheckbox(label: string) {
        return <FormControlLabel
            className="gradeChk"
            control={<Checkbox sx={YellowCheckbox} />}
            label={label} 
            checked={this.state.grades.includes(label)}
            onChange={() => { 
                let newState : string[] = JSON.parse(JSON.stringify(this.state.grades));
                if (!newState.includes(label)) {
                    newState.push(label);
                    newState.sort((a,b) => SubmitIdea.order.indexOf(a)-SubmitIdea.order.indexOf(b));
                } else {
                    let idx = newState.indexOf(label);
                    newState.splice(idx, 1);
                }
                this.setState({ grades: newState });
            }}
        />
    }

    async onFeedback() {
        let res = await FeedbackService.sendFeedback({
            grades: this.state.grades,
            feedback: this.state.feedback
        })
        if (res) {
            document.location.href=`/auth/${Nls.get("URL_PART")}`;
        }
    }

    render() {
        let form = <>
            <FormControl component="fieldset">
                <FormLabel component="legend">Target grade levels</FormLabel>
                <FormGroup>
                    <div>
                        { this.makeCheckbox("K") }
                        { this.makeCheckbox("1st") }
                        { this.makeCheckbox("2nd") }
                        { this.makeCheckbox("3rd") }
                        { this.makeCheckbox("4th") }
                    </div>
                    <div>
                        { this.makeCheckbox("5th") }
                        { this.makeCheckbox("6th") }
                        { this.makeCheckbox("7th") }
                        { this.makeCheckbox("8th") }
                        { this.makeCheckbox("9th") }
                    </div>
                    <div>
                        { this.makeCheckbox("10th") }
                        { this.makeCheckbox("11th") }
                        { this.makeCheckbox("12th") }
                        { this.makeCheckbox("Any") }
                        { this.makeCheckbox("None") }
                    </div>
                </FormGroup>
            </FormControl>
            <div style={{marginTop: "1rem"}} />
            <TextareaAutosize 
                style={{width: "100%"}} 
                aria-label="Idea" 
                minRows={10} 
                placeholder="" 
                value={this.state.feedback}
                onChange={(evt) => {
                    this.setState({feedback: evt.currentTarget.value})
                }}
            />
            <div>Note: If sharing {Nls.get("SELECTOR_LABEL_LOWER")} ideas that require items, please help us by including Amazon links! <br/> Thank you!</div>
            <div style={{margin: "1rem 0rem"}}>
                <Button sx={HRPButtonCancel} variant="contained"
                    style={{marginRight: "1rem"}}
                    onClick={() => {
                        document.location.href=`/auth/${Nls.get("URL_PART")}`;
                    }}
                >
                    Cancel
                </Button>
                <Button sx={HRPButtonNarrow} variant="contained" color="primary"
                    onClick={() => {
                        this.onFeedback();
                    }}
                >
                    Send
                </Button>
            </div>
        </>

        return <>
            <div style={{ marginTop: "2rem" }} />
            <div className="hrpRow">
                <div className="hrpCol-4-mob">
                    {Nls.get("URL_PART") === "hrp" && <>Share your thoughts or favorite classroom ideas!</>}
                    {Nls.get("URL_PART") === "club" && <>Share your thoughts or favorite club ideas!</>}
                </div>
            </div>
            <div style={{ marginTop: "1rem" }} />
            <div className="hrpRow">
                <div className="hrpCol-4-mob">
                    <h1>My idea...</h1>
                </div>
            </div>
            {/******************** Homeroom Selector ****************************************/}
            {(!this.props.user &&
                <CircularProgress />
            )
            || (this.props.pageProfile === "mobile" && <div className="mobileHome">
                <div className="hrpRow">
                    <div className="hrpCol-4-mob">
                        {form}
                    </div>
                </div>
            </div>)
            || <>
                <div className="hrpRow">
                    <div className="hrpCol-6-tab hrpCol-10-desk">
                        {form}
                    </div>
                </div>
            </>}
        </>
    }
}