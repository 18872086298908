import React, { useState } from 'react';
import { ApiHomeroomClub } from '../../../../../../server/src/hrpcb/models/apiHomeroom';
import { ApiSignup, ApiSignupEvent } from '../../../../../../server/src/hrpcb/models/apiSignup';
import { ApiUser } from '../../../../../../server/src/hrpcb/models/apiUser';
import "./Signups.scss";
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import ModalAddSignup from '../../pages/auth/Homerooms/Signups/ModalAddSignup';
import { PersonAdd, AddAlarm, Close, Delete, Share, ExpandMore } from '@mui/icons-material';
import SignupService from '../../services/SignupService';
import HomeroomService from '../../services/HomeroomService';
import Util from '../../services/Util';
import Nls from '../../nls/nls';

function RemoveDialog({ onRemove, onClose }: { onRemove: () => void, onClose: () => void }) {

    return (
        <div>
            <Dialog
                open={true}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Remove signup?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        All information about this signup will be lost if it is removed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={onRemove} color="primary" autoFocus>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function RemoveEventDialog({ onRemove, onClose, pProps, pState }: 
    { onRemove: (hrs:string[]) => void, onClose: () => void, pProps: SignupsProps, pState: SignupsState }) 
{
    const editableHRs = pProps.homerooms.filter((homeroom) => {
        return homeroom.signupEvents.includes(pState.removeEventId!) && HomeroomService.canEdit(homeroom, pProps.user!);
    }).map((homeroom) => homeroom._id);
    const [selectedHRs, setSelectedHRs] = useState(editableHRs);

    return (
        <div>
            <Dialog
                open={true}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Remove event?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Information about this event will be unavailable if it is removed from your {Nls.get("SELECTOR_LABEL")}(s). 
                        Select the {Nls.get("SELECTOR_LABELS")} from which to remove the event.
                    </DialogContentText>
                    <FormControl>
                        <InputLabel id="homeroom-selection-label">Homerooms</InputLabel>
                        <Select
                            labelId="homeroom-selection-label"
                            id="homeroom-select"
                            // value={homeroomId}
                            onChange={(evt: any) => {
                                setSelectedHRs(evt.target.value);
                            }}
                            value={selectedHRs}
                            multiple
                        >
                            {pProps.homerooms.map((homeroom) => (
                                homeroom.signupEvents.includes(pState.removeEventId!) &&
                                <MenuItem key={homeroom._id} value={homeroom._id}>{`${HomeroomService.classLabel(homeroom)}`}</MenuItem>
                            ))}
                        </Select>
                </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => { onRemove(selectedHRs)}} color="primary" autoFocus>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function ShareEventDialog({ onClose, shareCode }: 
    { onClose: () => void, shareCode: string }) 
{
    return (
        <div>
            <Dialog
                open={true}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Share this event
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div>To add this event to another {Nls.get("SELECTOR_LABEL_LOWER")}, ask the {Nls.get("SELECTOR_LABEL_LOWER")} owner or teacher
                        to add a Signup Event using the following code:</div>
                        <div style={{fontFamily: "Times", textAlign: "center", marginTop: "1rem", fontSize: "2em"}}>{shareCode}</div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

interface SignupsProps {
    homerooms: ApiHomeroomClub[]
    onHomerooms: (homerooms: ApiHomeroomClub[]) => Promise<void>
    user?: ApiUser | null
}

interface SignupsState {
    // dialogAdd: boolean
    signupMap: { [id: string]: ApiSignupEvent }
    addEvent: ApiSignupEvent | null
    expandIndex?: string
    removeIndex?: string
    removeEventId?: string
    shareEventCode?: string
}

export default class Signups extends React.Component<SignupsProps, SignupsState> {

    state: SignupsState = {
        // dialogAdd: false,
        addEvent: null,
        signupMap: {}
    }

    async componentDidMount() {
        let signupMap: { [id: string]: ApiSignupEvent } = {}
        // See if a homeroom id was specified
        const search = document.location.search;
        const params = new URLSearchParams(search);
        const homeroomId = params.get('homeroomId');
        const code = params.get('code');
        if (homeroomId && code) {
            let ses = await SignupService.getByHomeroomId(homeroomId, code);
            for (const se of ses) {
                signupMap[se._id!] = se;
            }
        }

        if (!this.props.user && !homeroomId) {
            const id = Nls.get("DEMO_ID");
            let ses = await SignupService.getByHomeroomId(id, id);
            for (const se of ses) {
                signupMap[se._id!] = se;
            }
        }
        
        if (this.props.user) {
            let ses = await SignupService.getByUser();
            for (const se of ses) {
                signupMap[se._id!] = se;
            }
        }
        this.setState({ signupMap: signupMap });
    }

    componentDidUpdate() {
        let update = false;
        for (const hr of this.props.homerooms) {
            for (const se of (hr.signupEvents || [])) {
                if (!(se in this.state.signupMap)) {
                    update = true;
                }
            }
        }
        if (update) {
            this.componentDidMount();
        } else {
            let removeKeys: string[] = []
            for (const seId in this.state.signupMap) {
                if (!this.props.homerooms.some((homeroom) => homeroom.signupEvents.includes(seId))) {
                    removeKeys.push(seId);
                }
            }
            if (removeKeys.length > 0) {
                let sMap = JSON.parse(JSON.stringify(this.state.signupMap));
                for (const key of removeKeys) {
                    delete sMap[key];
                }

                this.setState({signupMap: sMap});
            }
        }
    }

    async onSignupEvent(newSignupEvent: ApiSignupEvent) {
        let updated = await SignupService.updateEvent(newSignupEvent);
        let temp = JSON.parse(JSON.stringify(this.state.signupMap));
        temp[updated._id!] = updated;
        this.setState({signupMap: temp });
    }

    async removeSignup(seId: string, idxSignup: number) {
        let se = this.state.signupMap[seId];
        let update = JSON.parse(JSON.stringify(se));
        update.signups.splice(idxSignup, 1);
        let updated = await SignupService.updateEvent(update);
        let temp = JSON.parse(JSON.stringify(this.state.signupMap));
        temp[updated._id!] = updated;
        this.setState({signupMap: temp });
    }

    async removeSignupEvent(hrIds: string[], eventId: string) {
        let update : ApiHomeroomClub[] = JSON.parse(JSON.stringify(this.props.homerooms));
        for (let idx=0; idx<update.length; ++idx) {
            const hr = update[idx];
            if (hrIds.includes(hr._id)) {
                hr.signupEvents = hr.signupEvents.filter((event) => event !== eventId);
            }
        }
        await this.props.onHomerooms(update);
    }

    async onSignupChange(signup: ApiSignupEvent) {
        await SignupService.updateEvent(signup);
    }

    render() {
        let user = this.props.user;
        let seIds = Object.keys(this.state.signupMap);
        return <>
            <div style={{ marginTop: "1rem" }}>
                {seIds.length === 0 && <>
                    <div>
                        <p>{Nls.get("SIGNUP_NONE")}</p>
                        <p>{Nls.get("SIGNUP_NONE2")}</p>
                    </div>
                </>}
                {seIds.map((seId: string, index: number) => {
                    let se = this.state.signupMap[seId];
                    let canEdit: boolean = !!user 
                        && this.props.homerooms.some((homeroom) => (
                            homeroom.signupEvents.includes(seId) && HomeroomService.canEdit(homeroom, user)
                        ));
                    return <React.Fragment key={seId}>
                        {index > 0 && <hr style={{ marginBottom: "1.5rem" }} />}
                        <div className="homeClass" style={{ display: "block", marginTop: "0rem", marginBottom: "1rem" }}>
                            <div>
                                <span style={{fontWeight: 600}}>{(se.url && <a href={se.url}>{se.name}</a>) || se.name}</span>
                                {canEdit && <>
                                    <Tooltip placement="top" title="Delete signup" style={{float: "right"}}>
                                        <IconButton
                                            aria-label="delete"
                                            size="small"
                                            onClick={((evt) => {
                                                evt.stopPropagation();
                                                this.setState({ removeEventId: seIds[index] });
                                            })}
                                        >
                                            <Delete style={{ color: "#2F5043" }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip placement="top" title="Share event" style={{float: "right"}}>
                                        <IconButton
                                            aria-label="share event"
                                            size="small"
                                            onClick={((evt) => {
                                                this.setState({ shareEventCode: se.shareCode })
                                                evt.stopPropagation();
                                            })}
                                        >
                                            <Share style={{ color: "#2F5043" }} />
                                        </IconButton>
                                    </Tooltip>
                                </>}
                                { canEdit && <Tooltip placement="top" title="Add signup" style={{marginLeft: ".5rem", float: "right"}}>
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            this.setState({ addEvent: this.state.signupMap[seId] });
                                        }}><AddAlarm style={{ color: "#2F5043" }} />
                                    </IconButton>
                                </Tooltip>}
                                <div style={{ clear: "both" }}></div>
                            </div>
                            <div style={{ marginTop: ".5rem" }} />
                            {se.signups.map((signup: ApiSignup, idx: number) => {
                                let canAdjustClaim = canEdit || signup.remaining > 0 || (!!user && (user.email in signup.claimed_by));
                                let hadClaimed = (!!user && (user.email in signup.claimed_by));
                                return <Accordion
                                    square
                                    expanded={this.state.expandIndex === (index + "_" + idx)}
                                    onChange={() => {
                                        if (signup.remaining < signup.count) {
                                            this.setState({ expandIndex: this.state.expandIndex === index + "_" + idx ? undefined : index + "_" + idx});
                                        } else {
                                            this.setState({ expandIndex: undefined});
                                        }
                                    }}>
                                    <AccordionSummary expandIcon={(signup.remaining < signup.count && <ExpandMore />) || <span style={{width: "24px"}} />}>
                                        <div style={{ width: "100%" }}>
                                            <div>{signup.name}</div>
                                            <div style={{ fontSize: ".8em", color: "#666666" }}>{new Date(signup.date).toDateString()} {Util.t24to12(signup.startTime)}-{Util.t24to12(signup.endTime)}</div>
                                            <div style={{ fontSize: ".8em", color: "#666666" }}>[
                                                {(signup.remaining === signup.count && 
                                                    <>{signup.remaining} needed.</>
                                                ) || (signup.remaining > 0 &&
                                                    <>{signup.remaining} of {signup.count} more needed.</>
                                                ) ||
                                                    <>No more needed.</>}
                                            ]</div>
                                        </div>
                                        <div style={{ marginTop: "1rem", minWidth: canEdit ? "48px" : "20px" }}>
                                            {canAdjustClaim && (<>
                                                {((this.props.user && this.props.user!.email in signup.claimed_by) && <Tooltip placement="top" title="Cancel signup">
                                                    <IconButton
                                                        disabled={!hadClaimed}
                                                        aria-label="unclaim"
                                                        onClick={((evt) => {
                                                            evt.stopPropagation();
                                                            ++signup.remaining;
                                                            --signup.claimed_by[this.props.user!.email];
                                                            if (signup.claimed_by[this.props.user!.email] === 0) {
                                                                delete signup.claimed_by[this.props.user!.email];
                                                            }
                                                            this.onSignupChange(se);
                                                            this.setState({ expandIndex: (index + "_" + idx) });
                                                        })}
                                                        size="small"
                                                    >
                                                        <Close fontSize="small" style={{ color: "#2F5043" }} />
                                                    </IconButton>
                                                </Tooltip>)
                                                || (this.props.user && <Tooltip placement="top" title="Sign up">
                                                    <IconButton
                                                        disabled={signup.remaining === 0}
                                                        aria-label="claim"
                                                        onClick={((evt) => {
                                                            evt.stopPropagation();
                                                            signup.claimed_by[this.props.user!.email] = signup.claimed_by[this.props.user!.email] || 0;
                                                            --signup.remaining;
                                                            ++signup.claimed_by[this.props.user!.email];
                                                            this.onSignupChange(se);
                                                            this.setState({ expandIndex: (index + "_" + idx) });
                                                        })}
                                                        size="small"
                                                    >
                                                        <PersonAdd fontSize="small" style={{ color: "#2F5043" }} />
                                                    </IconButton>
                                                </Tooltip>)}
                                            </>)}
                                            {canEdit &&
                                                <Tooltip placement="top" title="Delete signup" style={{marginLeft: ".5rem"}}>
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={((evt) => {
                                                            evt.stopPropagation();
                                                            this.setState({ removeIndex: index + "_" + idx });
                                                        })}
                                                        size="small"
                                                    >
                                                        <Delete fontSize="small" style={{ color: "#2F5043" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </div>
                                    </AccordionSummary>
                                    {signup.remaining !== signup.count && (
                                        <AccordionDetails style={{ borderTop: "dashed black 1px" }}>
                                            <div style={{ width: "100%" }}>

                                                {Object.keys(signup.claimed_by).map((email) => (
                                                    <div className="switchStatus">- {email}</div>
                                                ))}
                                            </div>

                                        </AccordionDetails>
                                    )}
                                </Accordion>
                            })}
                        </div>
                        {this.state.removeIndex &&
                            <RemoveDialog
                                onRemove={async () => {
                                    let idxs = this.state.removeIndex!.split("_").map(val => parseInt(val));
                                    let seId = Object.keys(this.state.signupMap)[idxs[0]];
                                    await this.removeSignup(seId, idxs[1]);
                                    this.setState({ removeIndex: undefined });
                                }}
                                onClose={() => {
                                    this.setState({ removeIndex: undefined });
                                }}
                            />
                        }
                        {this.state.removeEventId &&
                            <RemoveEventDialog
                                pProps={this.props}
                                pState={this.state}
                                onRemove={async (hrIds: string[]) => {
                                    await this.removeSignupEvent(hrIds, this.state.removeEventId!);
                                    this.setState({ removeEventId: undefined });
                                }}
                                onClose={() => {
                                    this.setState({ removeEventId: undefined });
                                }}
                            />
                        }
                        { this.state.shareEventCode &&
                            <ShareEventDialog
                                shareCode={ this.state.shareEventCode }
                                onClose={() => {
                                    this.setState({ shareEventCode: undefined });
                                }}
                            />
                        }
                    </React.Fragment>
                })}
            </div>
            { this.props.user && this.state.addEvent && <ModalAddSignup
                open={this.state.addEvent}
                onSignupEvent={(newSignupEvent: ApiSignupEvent) => {
                    this.onSignupEvent(newSignupEvent);
                }}
                onClose={() => { this.setState({ addEvent: null }) }}
                user={this.props.user}
            />}
        </>
    }
}