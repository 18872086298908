export type time12 = {
    timeStr: string;
    ampm: "AM" | "PM";
}

export type time24 = string;

export type range24 = {
    start: string,
    end: string
}

export type range12 = {
    start: time12,
    end: time12
}

export class TimeUtil {
///////////////////////////////////////////////////////////////////////////////
// Times
///////////////////////////////////////////////////////////////////////////////

    public static isHHMM12(s: string) {
        return /^(1[012]|[1-9])(:[0-5][0-9])?$/.test(s.trim());
    }

    public static isTime12(s: string) {
        return /^(1[012]|[1-9])(:[0-5][0-9])? (AM|PM)$/.test(s.trim());
    }

    public static isTime24(s: string) {
        return /^(2|[0-3]|1[0-9]|[1-9])(:[0-5][0-9])?$/.test(s.trim());
    }

    public static toString12(time: time12): string {
        return time.timeStr+" "+time.ampm;
    }

    public static toString24(time: time24): string {
        return time;
    }

    public static fromString12(timeStr: string): time12 {
        let re = /^((1[012]|[1-9])(:[0-5][0-9])?) (AM|PM)$/;
        let m = re.exec(timeStr.trim());
        if (!m) {
            throw new Error(`Invalid time format: ${timeStr}`)
        } else {
            return {
                timeStr: m[1],
                ampm: m[4] as "AM"|"PM"
            }
        }
    }

    public static fromString24(timeStr: string): time24 {
        if (!this.isTime24(timeStr)) {
            throw new Error(`Invalid time format: ${timeStr}`)
        }
        return timeStr;
    }

    public static conv12to24(time: time12): time24{
        if (!TimeUtil.isTime12(time.timeStr.trim()+" "+time.ampm)) {
            throw new Error(`Invalid time format: ${time.timeStr}`)
        }
        const parts = time.timeStr.trim().split(":");
        if (parts[0] === "12") {
            if (time.ampm === "AM") {
                parts[0] = "0";
            }
        } else if (time.ampm === "PM") {
            parts[0] = ""+(parseInt(parts[0])+12);
        }
        if (parts.length === 1) {
            return parts[0]+":00";
        } else {
            return parts.join(":");
        }
    }

    public static conv24to12(timeStr: time24): time12 {
        if (!this.isTime24(timeStr.trim())) {
            throw new Error(`Invalid time format: ${timeStr}`)
        }
        const parts = timeStr.trim().split(":");
        let retVal: time12 = {
            timeStr: timeStr.trim(),
            ampm: "AM"
        }
        if (parts[0] === "12") {
            retVal.ampm = "PM";
        } else if (parts[0] === "0") {
            parts[0] = "12";
            retVal.timeStr = parts.join(":");
        } else if (parseInt(parts[0]) > 12) {
            parts[0] = ""+(parseInt(parts[0])-12);
            retVal.timeStr = parts.join(":");
            retVal.ampm = "PM";
        }
        return retVal;
    }

    public static conv12to24Str(time: time12): string {
        return this.toString24(this.conv12to24(time));
    }

    public static conv24to12Str(time: time24): string {
        return this.toString12(this.conv24to12(time));
    }

    public static conv24StrTo12(timeStr: string): time12 {
        return this.conv24to12(this.fromString24(timeStr));
    }

    public static conv12StrTo24(timeStr: string): time24 {
        return this.conv12to24(this.fromString12(timeStr));
    }
///////////////////////////////////////////////////////////////////////////////
//// RANGES ///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

    public static isRange12(s: string) {
        return /^(1[012]|[1-9])(:[0-5][0-9])? (AM|PM)[-–](1[012]|[1-9])(:[0-5][0-9])? (AM|PM)$/.test(s.trim());
    }

    public static isRange24(s: string) {
        return /^(2|[0-3]|1[0-9]|[1-9])(:[0-5][0-9])?[-–](2|[0-3]|1[0-9]|[1-9])(:[0-5][0-9])?$/.test(s.trim());
    }

    public static toStringR12(r: range12): string {
        return `${TimeUtil.toString12(r.start)}–${TimeUtil.toString12(r.end)}`
    }

    public static toStringR24(r: range24): string {
        return `${TimeUtil.toString24(r.start)}–${TimeUtil.toString24(r.end)}`
    }

    public static fromStringR12(timeStr: string): range12 {
        if (!this.isRange12(timeStr)) {
            throw new Error(`Invalid time format: ${timeStr}`)
        }
        let parts = timeStr.split(/[-–]/);
        return {
            start: this.fromString12(parts[0]),
            end: this.fromString12(parts[1])
        }
    }

    public static fromStringR24(timeStr: string): range24 {
        if (!this.isRange24(timeStr)) {
            throw new Error(`Invalid time format: ${timeStr}`)
        }
        let parts = timeStr.split(/[-–]/);
        return {
            start: this.fromString24(parts[0]),
            end: this.fromString24(parts[1])
        }
    }

    public static convR24toR12(range: range24) : range12 {
        return {
            start: this.conv24to12(range.start),
            end: this.conv24to12(range.end)
        }
    }
    public static convR12toR24(range:range12): range24 {
        return {
            start: this.conv12to24(range.start),
            end: this.conv12to24(range.end)
        }
    }

    public static convR12toR24Str(range: range12): string {
        return this.toStringR24(this.convR12toR24(range));
    }

    public static convR24toR12Str(range: range24): string {
        return this.toStringR12(this.convR24toR12(range));
    }

    public static convR24StrToR12(rangeStr: string): range12 {
        return this.convR24toR12(this.fromStringR24(rangeStr));
    }

    public static convR12StrToR24(rangeStr: string): range24 {
        return this.convR12toR24(this.fromStringR12(rangeStr));
    }
}
