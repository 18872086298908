import React from 'react';
import { Column, Grid } from "@carbon/react";
import "./Login.scss";

export default class PWResetSent extends React.Component<{location?:any}> {
    render() {
        return (<>
            <main className="verifyLogin">
                <Grid>
                    <Column sm={4} md={8} lg={12}>
                        <p>An email has been sent to verify your email address.
                            Please check your mail (and spam folder) to continue with
                            the password reset process</p>
                    </Column>
                </Grid>
            </main>
        </>)
    }
}