import React from 'react';

export const Page404: React.FC = () => {
    return (<main>
        <div className="hrpRow">
            <div className="hrpCol-4-mob">
                <h1>Page not found</h1>
                <p>We're sorry, but you seem to have followed a link to a page that does not exist.</p>
            </div>
        </div>
    </main>);
}