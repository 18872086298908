import React from "react";
import { IPTLocation } from "../../services/LocationV1";
import { clone } from "../../../../util/MiscUtil";
import { 
    Button,
    Layer,
    TextArea 
} from "@carbon/react";

export class LocationDescription extends React.Component<{
    location?: IPTLocation,
    onLocation: (location: IPTLocation) => Promise<void>
}, {
    searchDescription: string,
    locationDescription: string
}> {
    state = {
        searchDescription: this.props?.location?.searchdescription || "",
        locationDescription: this.props?.location?.description || ""
    }

    componentDidUpdate(prevProps: Readonly<{ location?: IPTLocation | undefined; onLocation: (location: IPTLocation) => Promise<void>; }>, prevState: Readonly<{ locationDescription: string; }>, snapshot?: any): void {
        if (!prevProps.location && this.props.location) {
            this.setState({
                locationDescription: this.props.location.description,
                searchDescription: this.props.location.searchdescription
            })
        }
    }

    render() {
        let lines = this.state.searchDescription.split("\n");
        let longestLine = 0;
        for (const line of lines) {
            longestLine = Math.max(longestLine, line.length);
        }
        const disabled = ((this.state.locationDescription ?? "") === (this.props.location?.description ?? "")
            && (this.state.searchDescription ?? "") === (this.props.location?.searchdescription ?? ""))
            || lines.length > 2
            || longestLine > 60;

        return <>
            <Layer>
                <div>Location image</div>
                {this.props.location?.primaryImage && <img src={`data:image/png;base64, ${this.props.location.primaryImage}`} alt="" />}
                {!this.props.location?.primaryImage && <>No image</>}
                <div style={{ marginTop: "1rem" }} />
                <TextArea
                    labelText="Search description"
                    helperText="Brief description of the location shown during search (two line, 60 character limit)"
                    rows={2} id="location-searchdescription"
                    value={this.state.searchDescription}
                    onChange={(evt) => {
                        let desc = evt.target.value;
                        this.setState({ searchDescription: desc })
                    }}
                    invalid={longestLine > 60 || lines.length > 2}
                    invalidText={longestLine > 60 ? 
                        `Search description lines must be 60 characters or less` : 
                        `Search description must be one or two lines`
                    }
                />
                <div style={{ marginTop: "1rem" }} />
                <TextArea
                    labelText="Location full description"
                    helperText="What do you want potential customers to know?"
                    rows={4} id="location-description"
                    value={this.state.locationDescription}
                    onChange={(evt) => {
                        this.setState({ locationDescription: evt.target.value })
                    }}
                />
            </Layer>
            <div style={{ marginTop: "1rem" }} />
            <Button
                type="submit"
                kind={disabled? "tertiary" : "primary"}
                disabled={disabled}
                onClick={async () => {
                    if (this.props.location) {
                        let newLocation = clone(this.props.location);
                        newLocation.description = this.state.locationDescription;
                        newLocation.searchdescription = this.state.searchDescription;
                        await this.props.onLocation(newLocation);
                    }
                }}
            >Save</Button>
        </>
    }
}