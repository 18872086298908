import React from 'react';

import "./NewHomeroom.scss";
import { ApiUser } from '../../../../../../../server/src/hrpcb/models/apiUser';
import { Button, Stepper, Step, StepLabel, CircularProgress } from '@mui/material';
import { HRPButtonSecondary, HRPButton } from '../../../components/widgets/Customized';
import SelectSchool, { SelectSchoolState } from "./SelectSchool";
import HomeroomInfo, { HomeroomInfoState } from './HomeroomInfo';
import HomeroomService from "../../../services/HomeroomService"
import { ApiHomeroomClub } from '../../../../../../../server/src/hrpcb/models/apiHomeroom';
import ModalDelete from './ModalDelete';
import { Helmet } from "react-helmet";
import Nls from '../../../nls/nls';

interface NewHomeroomState extends HomeroomInfoState, SelectSchoolState {
    activeStep: number,
    wizardButtons: boolean,

    zipCode: string,
    schoolId: string,
    schoolName: string,
    selectedHr?: (ApiHomeroomClub),
    modalDelete: boolean
}

interface NewHomeroomProps {
    user: ApiUser
    homeroomId?: string
}

export default class NewHomeroom extends React.Component<NewHomeroomProps, NewHomeroomState> {
    state: NewHomeroomState = {
        activeStep: 0,
        wizardButtons: true,

        grade: "K",
        memberInfos: this.props.user ? [{
            userId: this.props.user?._id,
            name: "",
            email: this.props.user?.email,
            title: "Homeroom Parent",
            role: "ADMIN"
        }] : [],

        zipCode: "",
        schoolId: "",
        schoolOptions: [],
        schoolName: "",
        clubName: "",
        modalDelete: false
    }


    async componentDidMount() {
        if (this.props.homeroomId) {
            let hr = await HomeroomService.get(this.props.homeroomId);
            this.setState({
                activeStep: 1,        
                grade: hr.grade,
                memberInfos: hr.members,
                selectedHr: hr
            })
        }
    }

    async componentDidUpdate(oldProps: NewHomeroomProps) {
        if (this.props.user && (!oldProps.user || oldProps.user._id !== this.props.user._id)) {
            let stateUpdate = JSON.parse(JSON.stringify(this.state.memberInfos));
            stateUpdate.push({
                userId: this.props.user._id,
                name: "",
                email: this.props.user.email,
                title: "Homeroom Parent",
                role: "ADMIN"
            });
            this.setState({ memberInfos: stateUpdate });
        }
    }
    // admins: UserID[],
    // students: Student[],
    // room: string,
    // lastDay: string

    onChangeValue(field: string, _evt: any, value: any) {
        let stateChange: {
            [key: string]: string
        } = {};
        stateChange[field] = value;
        this.setState(stateChange as any);
    }

    // onChange(field: string, evt: any) {
    //     let stateChange: {
    //         [key: string]: any
    //     } = {};
    //     stateChange[field] = evt.target.value;
    //     this.setState(stateChange as any);
    // }

    onStateChange(newState: any) {
        this.setState(newState);
    }

    // onHomeroomInfo(info: HomeroomInfoState) {
    //     this.setState(info);
    // }

    onToggleWizard(visible: boolean) {
        this.setState({ wizardButtons: visible });
    }

    nextStep() {
        if (this.state.activeStep < 2) {
            this.setState({ activeStep: this.state.activeStep+1});
        }
    }

    prevStep() {
        if (this.state.activeStep > 0) {
            this.setState({ activeStep: this.state.activeStep-1});
        }
    }

    async create() {
        if (Nls.get("URL_PART") === "hrp") {
            await HomeroomService.createHomeroom({
                _id: "",
                code: "",
                schoolId: this.state.schoolId || "",
                schoolName: this.state.schoolName,
                members: this.state.memberInfos,
                grade: this.state.grade,
                zip: this.state.zipCode,
                wishes: [],
                calendarIds: [],
                news: [],
                links: {},
                signupEvents: []
            })
        } else {
            await HomeroomService.createClub({
                _id: "",
                code: "",
                clubName: this.state.clubName,
                members: this.state.memberInfos,
                grade: this.state.grade,
                zip: this.state.zipCode,
                wishes: [],
                calendarIds: [],
                news: [],
                links: {},
                signupEvents: []
            });
        }
        document.location.href = `/auth/${Nls.get("URL_PART")}/`
    }

    async confirmDelete() {
        this.setState({ modalDelete: true });
    }

    async delete() {
        await HomeroomService.deleteHomeroom(this.props.homeroomId!);
        document.location.href = `/auth/${Nls.get("URL_PART")}/`
    }

    async update() {
        let update : ApiHomeroomClub = JSON.parse(JSON.stringify(this.state.selectedHr));
        update.grade = this.state.grade;
        update.members = this.state.memberInfos;
        await HomeroomService.updateHomeroom(update);
        document.location.href = `/auth/${Nls.get("URL_PART")}/`
    }

    async cancel() {
        document.location.href = `/auth/${Nls.get("URL_PART")}/`
    }

    render() {
        console.log(this.props, this.state);
        let editMode = typeof this.props.homeroomId === "string";
        if (editMode && this.state.memberInfos.length === 0) {
            return <CircularProgress />
        }
        let nextActive : boolean = false;
        if (this.state.activeStep === 0) {
            nextActive = (!!this.state.schoolId && this.state.schoolId.length > 0)
                || (!!this.state.schoolName && this.state.schoolName.trim().length > 0)
                || (!!this.state.clubName && this.state.clubName.trim().length > 0);
        } else if (this.state.activeStep === 1) {
            nextActive = !!this.state.grade 
                && this.state.grade.length > 0 
                && this.state.memberInfos.length > 0;
        }

        return <main className="newHomeroom">
            <Helmet>
                {!editMode && <title>Create {Nls.get("SELECTOR_LABEL_LOWER")} - Homeroom Parent</title>}
                {editMode && <title>Edit {Nls.get("SELECTOR_LABEL_LOWER")} - Homeroom Parent</title>}
            </Helmet>
            <div className="hrpRow">
                <div className="hrpCol-4-mob hrpCol-12-desk">
                    { (editMode && <h1>Edit {Nls.get("SELECTOR_LABEL")}</h1>) 
                    || <>
                        <h1>New {Nls.get("SELECTOR_LABEL")}</h1>
                        <p>Let's create a new {Nls.get("SELECTOR_LABEL_LOWER")}!</p>
                    </>}
                    <Stepper style={{padding: "0px", marginBottom: "1.5rem"}} activeStep={this.state.activeStep}>
                        <Step>
                            <StepLabel>{Nls.get("ADD_STEP1")}</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Tell us about your {Nls.get("SELECTOR_LABEL_LOWER")}</StepLabel>
                        </Step>
                    </Stepper>
                </div>
            </div>
            { this.state.activeStep === 0 && 
                <SelectSchool initState={this.state} onStateChange={this.onStateChange.bind(this)} onToggleWizard={this.onToggleWizard.bind(this)}/> }
            { this.state.activeStep === 1 && 
                <HomeroomInfo initState={this.state} onStateChange={this.onStateChange.bind(this)} /> }
            <div style={{marginTop: "2rem"}}></div>
            <div className="hrpRow">
                { this.state.wizardButtons && <>
                    {(editMode || this.state.activeStep === 0) && <div className="hrpCol-1-mob hrpCol-1-tab">
                        <div style={{marginRight: "-1rem"}}>
                            <Button sx={HRPButtonSecondary} variant="outlined"
                                onClick={this.cancel.bind(this)}
                            >Cancel</Button>
                        </div>
                    </div>}
                    {this.state.activeStep > (editMode?1:0) && <div className="hrpCol-1-mob hrpCol-1-tab">
                        <div style={{marginRight: "-1rem"}}>
                            <Button sx={HRPButtonSecondary} variant="outlined"
                                onClick={this.prevStep.bind(this)}
                            >Back</Button>
                        </div>
                    </div>}
                    {this.state.activeStep < 1 && <div className="hrpCol-1-mob hrpCol-1-tab">
                        <div style={{marginRight: "-1rem"}}>
                            <Button sx={HRPButton} variant="contained" color="primary" 
                                disabled={!nextActive} 
                                onClick={this.nextStep.bind(this)}
                            >Next</Button>
                        </div>
                    </div>}
                    {this.state.activeStep === 1 && <div className="hrpCol-1-mob hrpCol-1-tab">
                        <div style={{marginRight: "-1rem"}}>
                            { (editMode && 
                                <Button sx={HRPButton} variant="contained" color="primary"
                                    onClick={this.update.bind(this)}
                                    disabled={Nls.get("URL_PART") === "hrp" && !this.state.memberInfos.some(member => member.title === "Teacher")}
                                >Update</Button>
                            ) || <Button sx={HRPButton} variant="contained" color="primary"
                                    onClick={this.create.bind(this)}
                                    disabled={Nls.get("URL_PART") === "hrp" && !this.state.memberInfos.some(member => member.title === "Teacher")}
                                >Create</Button>
                            }
                        </div>
                    </div>}
                    {editMode && <div className="hrpCol-1-mob hrpCol-1-tab">
                        <div style={{marginRight: "-1rem"}}>
                            <Button sx={HRPButtonSecondary} variant="outlined"
                                onClick={this.confirmDelete.bind(this)}
                            >Delete</Button>
                        </div>
                        <ModalDelete 
                            open={this.state.modalDelete} 
                            onDelete={async () => {
                                this.delete();
                            }} 
                            onClose={() => {
                                this.setState({modalDelete: false});
                            }} 
                        />
                    </div>}
                </> }
            </div>
            <div style={{marginTop: "2rem"}}></div>
        </main>

    }
}