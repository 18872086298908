import React from 'react';

interface InfoIdeasProps {
}

export default function InfoIdeas(_props: InfoIdeasProps) {

    return <>
        <p>Lastly, we will provide seasonal ideas. This section
may include links to seasonal decorations or games. We may
also provide links to recipes, crafts, or other items that can be
useful for classroom breaks or parties.</p>
    </>;
}
