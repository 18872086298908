import React from 'react';
import * as ReactDOM from 'react-dom';

import { 
    Checkbox,
    ComposedModal,
    DatePicker,
    DatePickerInput,
    ModalFooter,
    ModalHeader,
    ModalBody,
    NumberInput,
    TextInput
} from "@carbon/react";
import DateUtil from '../../../../util/DateUtil';
import TimePicker12 from '../../../../components/TimePicker12';
import { ISession } from '../../../../services/SessionV1';
// import SimpleTable from '../../../../components/SimpleTable';
interface AddEditSessionModalProps {
    show: boolean
    initialValue?: ISession
    onSession: (fields: AddEditSessionModalState) => Promise<void>
    onClose: () => Promise<void>
}

export interface AddEditSessionModalState {
    label?: string,
    dateStart?: string
    dateEnd?: string
    timeStart?: string | null
    timeEnd?: string | null
    campDays: string[]
    ageStart?: string
    ageEnd?: string
    capacity: number
    costPerAttendee: string
}

export class AddEditSessionModal extends React.Component<AddEditSessionModalProps, AddEditSessionModalState> {
    state : AddEditSessionModalState = (this.props.initialValue 
        && {
            label: this.props.initialValue.label,
            dateStart: this.props.initialValue.dateStart,
            dateEnd: this.props.initialValue.dateEnd,
            timeStart: this.props.initialValue.timeStart,
            timeEnd: this.props.initialValue.timeEnd,
            campDays: this.props.initialValue.campDays,
            ageStart: this.props.initialValue.ageStart,
            ageEnd: this.props.initialValue.ageEnd,
            capacity: this.props.initialValue.capacity,
            costPerAttendee: ""+this.props.initialValue.costPerAttendee
        })|| {
        label: "",
        timeStart: "",
        timeEnd: "",
        campDays: [],
        capacity: 20,
        costPerAttendee: ""
    }

    componentDidUpdate(prevProps: Readonly<AddEditSessionModalProps>, 
        _prevState: Readonly<AddEditSessionModalState>, 
        _snapshot?: any): void 
    {
        if (!prevProps.show && this.props.show) {
            this.setState((this.props.initialValue 
                && {
                    label: this.props.initialValue.label,
                    dateStart: this.props.initialValue.dateStart,
                    dateEnd: this.props.initialValue.dateEnd,
                    timeStart: this.props.initialValue.timeStart,
                    timeEnd: this.props.initialValue.timeEnd,
                    campDays: this.props.initialValue.campDays,
                    ageStart: this.props.initialValue.ageStart,
                    ageEnd: this.props.initialValue.ageEnd,
                    capacity: this.props.initialValue.capacity,
                    costPerAttendee: ""+this.props.initialValue.costPerAttendee
                })|| {
                label: "",
                dateStart: "",
                dateEnd: "",
                timeStart: "",
                timeEnd: "",
                campDays: [],
                ageStart: "",
                ageEnd: "",
                capacity: 20,
                costPerAttendee: ""
            })
        }
    }

    render() {
        const dialogLabel = (this.props.initialValue 
            && "Edit session") || "Add session";
        const dialogLabel2 = (this.props.initialValue 
            && "Save session") || "Add session";
            let addSessionValid = true;
        if (this.props.show) {            

            addSessionValid = !!this.state.dateStart 
                && this.state.dateStart.length > 0
                && !!this.state.dateEnd
                && this.state.dateEnd.length > 0
                && !!this.state.ageStart 
                && this.state.ageStart.length > 0
                && !!this.state.ageEnd
                && this.state.ageEnd.length > 0
                && this.state.campDays.length > 0
                && this.state.capacity > 0
                && this.state.timeStart !== null
                && this.state.timeEnd !== null
                && this.state.costPerAttendee.length > 0
                && !!this.state.label
                && this.state.label.length > 0;
        }

        return ReactDOM.createPortal(
            <ComposedModal 
                open={this.props.show} 
                onClose={this.props.onClose}
                preventCloseOnClickOutside={true}
            >
                <ModalHeader>{dialogLabel}</ModalHeader>
                <ModalBody hasForm hasScrollingContent>
                    <TextInput
                        id="sessLabel"
                        labelText="Session name"
                        value={this.state.label}
                        onChange={(evt) => {
                            this.setState({ label: evt.target.value });
                        }}
                    />
                    <div style={{paddingTop: "1rem"}} />
                    <DatePicker 
                        id="sessDateRange" datePickerType="range"
                        minDate={DateUtil.todayStr()}
                        onChange={(newVals) => { 
                            let newState : {
                                dateStart?: string,
                                dateEnd?: string,
                                campDays?: string[]
                            }= {}
                            if (newVals[0] && new Date(newVals[0]).toString() !== "Invalid Date") {
                                newState.dateStart = new Date(newVals[0]).toLocaleDateString("en-US", {
                                    month: "numeric", day: "numeric", year: "numeric"
                                });
                            }
                            if (newVals[1] && new Date(newVals[1]).toString() !== "Invalid Date") {
                                newState.dateEnd =new Date(newVals[1]).toLocaleDateString("en-US", {
                                    month: "numeric", day: "numeric", year: "numeric"
                                });
                            }
                            if (newVals[0] && newVals[1]) {
                                newState.campDays = DateUtil.daysOfWeekInRange(
                                    newVals[0],
                                    newVals[1]
                                );
                            }
                            this.setState(newState as AddEditSessionModalState);
                        }}
                    >
                        <DatePickerInput 
                            id="sessDateRangeStart" 
                            labelText="Session start date"
                            value={this.state.dateStart || ""}
                        />
                        <DatePickerInput 
                            id="sessDateRangeEnd" 
                            labelText="Session end date"
                            value={this.state.dateEnd || ""}
                        />
                    </DatePicker>
                    <div style={{paddingTop: "1rem"}} />
                    <TimePicker12
                        id="sessTimeStart" 
                        labelText="Session start time"
                        value={this.state.timeStart}
                        onChange={(newVal) => {
                            this.setState({ timeStart: newVal });
                        }}
                    />
                    <div style={{paddingTop: "1rem"}} />
                    <TimePicker12
                        id="sessTimeEnd" 
                        labelText="Session end time"
                        value={this.state.timeEnd}
                        onChange={(newVal) => {
                            this.setState({ timeEnd: newVal });
                        }}
                    />
                    <div style={{paddingTop: "1rem"}} />
                    <fieldset className={`cds--fieldset`}>
                        <legend className={`cds--label`}>Camp days</legend>
                        {DateUtil.daysOfWeek().map((dow) => (
                            <Checkbox 
                                labelText={dow}
                                id={`dow-${dow}`}
                                checked={this.state.campDays.includes(dow)}
                                disabled={!this.state.dateStart
                                    || !this.state.dateEnd 
                                    || !DateUtil.daysOfWeekInRange(
                                    this.state.dateStart,
                                    this.state.dateEnd).includes(dow)}
                                onChange={(_event, { checked }) => {
                                    let campDays = JSON.parse(JSON.stringify(this.state.campDays));
                                    if (checked) {
                                        campDays.push(dow);
                                    } else {
                                        campDays = campDays.filter(s => s !== dow);
                                    }
                                    this.setState({ campDays });
                                }}
                            />
                        ))}
                    </fieldset>
                    <div style={{paddingTop: "1rem"}} />
                    <DatePicker 
                        id="sessAgeRange" 
                        datePickerType="range" 
                        maxDate={DateUtil.todayStr()}
                        onChange={(newVals) => { 
                            let newState : {
                                ageStart?: string,
                                ageEnd?: string
                            } = {}
                            if (newVals[0] && new Date(newVals[0]).toString() !== "Invalid Date") {
                                newState.ageStart = new Date(newVals[0]).toLocaleDateString("en-US", {
                                    month: "numeric", day: "numeric", year: "numeric"
                                });
                            }
                            if (newVals[1] && new Date(newVals[1]).toString() !== "Invalid Date") {
                                newState.ageEnd =new Date(newVals[1]).toLocaleDateString("en-US", {
                                    month: "numeric", day: "numeric", year: "numeric"
                                });
                            }
                            this.setState(newState);
                        }}
                    >
                        <DatePickerInput 
                            id="sessAgeRangeStart" 
                            labelText="Camper birthday start" 
                            value={this.state.ageStart || ""}
                            helperText={this.state.ageStart && `${DateUtil.yearsSince(this.state.ageStart)} years old`} />
                        <DatePickerInput 
                            id="sessAgeRangeEnd" 
                            labelText="Camper birthday end" 
                            value={this.state.ageEnd || ""}
                            helperText={this.state.ageEnd && `${DateUtil.yearsSince(this.state.ageEnd)} years old`} />
                    </DatePicker>
                    <div style={{paddingTop: "1rem"}} />
                    <div className="cds--text-input__label-wrapper"><label className="cds--label">Maximum attendees</label></div>
                    <NumberInput
                        id="sessLimit"
                        // labelText="Maximum attendees"
                        value={this.state.capacity}
                        onChange={(evt) => { 
                            this.setState({ capacity: evt.target.value });
                        }}
                    />
                    <div style={{paddingTop: "1rem"}} />
                    <TextInput
                        id="sessCost"
                        labelText="Cost per attendee"
                        value={this.state.costPerAttendee}
                        onChange={(evt) => { 
                            this.setState({ costPerAttendee: evt.target.value });
                        }}
                    />
                </ModalBody>
                <ModalFooter 
                    primaryButtonDisabled={!addSessionValid}
                    primaryButtonText={dialogLabel2} 
                    secondaryButtonText="Cancel" 
                    onRequestSubmit={async () => {
                        await this.props.onSession(this.state);
                        await this.props.onClose();
                    }}
                />
            </ComposedModal>,
            document.body
        )
    }
}