import React from 'react';
import FullCalendar from '@fullcalendar/react'
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import listWeekPlugin from '@fullcalendar/list';
import "./HRCalendar.scss";
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiHomeroomClub } from '../../../../../../server/src/hrpcb/models/apiHomeroom';
import { ApiUser } from '../../../../../../server/src/hrpcb/models/apiUser';
import DialogMonth from './DialogMonth';
import HomeroomService from '../../services/HomeroomService';
import Nls from '../../nls/nls';

type EventSources = Array<{ googleCalendarId: string }>;

interface FCWrapperProps {
    demo: boolean
    eventSources: EventSources
    onMonth: () => void
}

class FCWrapper extends React.Component<FCWrapperProps, {}> {
    shouldComponentUpdate(nextProps: FCWrapperProps, _nextState: {}, _nextContext: any): boolean {
        if (this.props.eventSources.length !== nextProps.eventSources.length) {
            return true;
        }
        let keys : { [key: string]: boolean }= {}
        for (const src of this.props.eventSources) {
            keys[src.googleCalendarId] = true;
        }
        for (const src of nextProps.eventSources) {
            if (!(src.googleCalendarId in keys)) {
                return true;
            }
        }
        return false;
    }

    render() {
        let myThis = this;
        let events: any[] = [];
        if (this.props.demo) {
            let d = new Date();
            let dow = d.getDay();
            d.setDate(d.getDate()+(5-dow));
            d.setHours(14);
            d.setMinutes(0);
            events.push({ title: Nls.get("URL_PART") === "club"? "Club party!": "Class party!", date: d });
        }

        return <FullCalendar
            plugins={[googleCalendarPlugin, dayGridPlugin, listWeekPlugin]}
            initialView="listWeek"
            googleCalendarApiKey="AIzaSyCUkWy8gF0_9gNns5D6uPGc5kt6q_qlM40"
            eventSources={this.props.eventSources}
            events={events}
            height={this.props.demo?"10rem":"20rem"}
            customButtons={{
                myCustomButton: {
                    text: 'month',
                    click: function () {
                        myThis.props.onMonth();
                    }
                }
            }}
            headerToolbar={{
                left: 'title',
                center: '',
                right: 'myCustomButton today prev,next'
            }}
        />
    }
}

interface HRCalendarProps {
    canEdit: boolean
    homerooms: ApiHomeroomClub[]
    onHomerooms: (homerooms: ApiHomeroomClub[]) => void
    user?: ApiUser | null
}

interface DialogStates {
    calMonth: boolean
}
interface HRCalendarState {
    dialogs: DialogStates
    calDelMenu: Node | null
}

export default class HRCalendar extends React.Component<HRCalendarProps, HRCalendarState> {
    state = {
        dialogs: {
            calMonth: false
        },
        calDelMenu: null
    }
    render() {
        let calIds: string[] = [];
        let calInfo : {
            [calId: string]: ApiHomeroomClub[]
        } = {};
        if (this.props.homerooms && this.props.homerooms.length > 0) {
            for (const hr of this.props.homerooms) {
                if (hr.calendarIds) {
                    for (const id of hr.calendarIds) {
                        calInfo[id] = calInfo[id] || [];
                        calInfo[id].push(hr);
                        if (!calIds.includes(id)) {
                            calIds.push(id);
                        }
                    }
                }
            }
        }

        let demo = this.props.homerooms.length === 1 && this.props.homerooms[0]._id === Nls.get("DEMO_ID");

        let evtSources: Array<{ googleCalendarId: string }> = calIds.map((id) => {
            return { googleCalendarId: id };
        });

        const self = this;
        return <div className="hrCalendar">
            {/* No calendars to show */}
            {evtSources.length === 0
                && <div className="hrpRow">
                    <div className="hrpCol-4-mob">
                        {/* Homeroom admin */}
                        {this.props.homerooms.length === 1 && this.props.canEdit && <>
                            <p>{Nls.get("CALENDAR_NONE")}</p>
                            <p>{Nls.get("CALENDAR_NONE_EXTRA")}</p>
                        </>}
                        {this.props.homerooms.length > 1 && this.props.canEdit && <>
                            <p>{Nls.get("CALENDAR_NONE2")}</p>
                            <p>{Nls.get("CALENDAR_NONE_EXTRA")}</p>
                        </>}
                        {/* Homeroom user */}
                        {!demo && this.props.homerooms.length === 1 && !this.props.canEdit && <>
                            Your Homeroom admin has not yet added any calendars.
                        </>}
                        {!demo && this.props.homerooms.length > 1 && !this.props.canEdit && <>
                            Your Homeroom admin has not yet added any calendars.
                        </>}
                    </div>
                </div>
            }

            {/* Calendar section */}
            {(demo || evtSources.length > 0)
                && <div style={{ marginTop: "1rem" }} className="hrpRow">
                    <div className="hrpCol-4-mob">
                        <div style={{ margin: "1rem -1rem 0rem -1rem" }}>
                            <FCWrapper demo={demo} eventSources={evtSources} onMonth={() => {
                                self.setState({
                                    dialogs: {
                                        calMonth: true
                                    }
                                });
                            }} />
                        </div>
                    </div>
                    <div style={{ marginBottom: "2rem" }}></div>
                </div>
            }

            {/* List of calendars under the calendar section */}
            {evtSources.length > 0
                && <div style={{ marginTop: "1rem" }} className="hrpRow hrCalFooter">
                    <div className="hrpCol-4-mob">
                        <div>
                            {this.props.homerooms.length === 1 && <>Your {Nls.get("SELECTOR_LABEL")} includes the following calendars:</>}
                            {this.props.homerooms.length > 1 && <>Your {Nls.get("SELECTOR_LABELS")} include the following calendars:</>}
                        </div>
                        <ul>
                            {evtSources.map((evtSource) => {
                                return <li key={evtSource.googleCalendarId}>
                                    <a className="regLink calLink" target="_blank" rel="noreferrer noopener" href={`https://calendar.google.com/calendar/u/0/r/settings/addcalendar?cid=${evtSource.googleCalendarId}`}>
                                        {evtSource.googleCalendarId}
                                    </a>
                                    {HomeroomService.canEditAny(calInfo[evtSource.googleCalendarId], this.props.user) && <>
                                        <Tooltip placement="top" title="Delete">
                                            <IconButton size="small" aria-label="delete" aria-controls="calDelMenu" aria-haspopup="true" 
                                                onClick={(async (event) => {
                                                    this.setState({ calDelMenu: event.currentTarget });
                                                })}>
                                                <DeleteIcon style={{color: "#2F5043"}} />
                                            </IconButton>
                                        </Tooltip>
                                        <Menu 
                                            id="calDelMenu" 
                                            anchorEl={this.state.calDelMenu} 
                                            keepMounted
                                            open={!!this.state.calDelMenu} 
                                            onClose={() => { this.setState({calDelMenu: null})}}
                                        >
                                            {calInfo[evtSource.googleCalendarId].map((hrDel) => (
                                                <MenuItem key={`mi_${hrDel._id}`} onClick={() => {
                                                    let temp : ApiHomeroomClub[] = JSON.parse(JSON.stringify(this.props.homerooms));
                                                    let change=false;
                                                    for (const hr of temp) {
                                                        if (hr._id === hrDel._id && hr.calendarIds && hr.calendarIds.includes(evtSource.googleCalendarId)) {
                                                            hr.calendarIds = hr.calendarIds.filter((id) => {
                                                                return id !== evtSource.googleCalendarId;
                                                            });
                                                            change=true;
                                                        }
                                                    }
                                                    change && this.props.onHomerooms && this.props.onHomerooms(temp);
                                                }}>
                                                    {HomeroomService.classLabel(hrDel)}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </>}
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
            }

            <DialogMonth
                evtSources={evtSources}
                open={this.state.dialogs.calMonth}
                onClose={() => {
                    this.setState({
                        dialogs: {
                            calMonth: false
                        }
                    })
                }}
            />

        </div>
    }
}