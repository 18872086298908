import React from 'react';
import Nls from '../../nls/nls';

interface InfoCalendarProps {
}

export default function InfoCalendar(_props: InfoCalendarProps) {

    return <>
        <p>In this section, you can associate multiple Google calendars
with your {Nls.get("SELECTOR_LABEL_LOWER")} and see them all together.
To associate a calendar with the {Nls.get("SELECTOR_LABEL_LOWER")}, you first need to
determine the calendar id:</p>
        <ul>
            <li>If you have the calendar added to your Google account, you can find the id 
in the calendar settings, in the Integrate Calendar section</li>
            <li>For a public calendar, you might find the calendar id in the URL</li>
        </ul>
        <p>Once you have the calendar id, open the add calendar dialog.
Select the {Nls.get("SELECTOR_LABEL_LOWER")} you wish to associate the calendar with
and provide the calendar id. You will then see the week's events in the Calendar section.</p>
    </>;
}
