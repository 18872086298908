import React from 'react';
import "./Login.scss";

export default class PWResetInvalid extends React.Component<{location?:any}> {
    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (<>
            <main className="verifyLogin">
                <div className="hrpRow" style={{ marginTop: "0rem" }}>
                    <div className="hrpCol-4-mob hrpCol-8-tab hrpCol-12-desk">
                        <p>The password reset code is invalid. You may have already
                            cancelled the password reset. Please check your email for a 
                            newer reset email, or <a href="/pwreset">start over</a>.</p>
                    </div>
                </div>
            </main>
        </>)
    }
}