import React from 'react';
import './Tile.scss';
import { NavigateFunction, useNavigate } from 'react-router-dom';

interface TileProps {
    href?: string
    ratio?: "1x1" | "2x1" | "4x3" | "16x9"
    dark?: boolean,
    style?: React.CSSProperties,
    children?: any
}

class TileCls extends React.Component<TileProps & { navigate: NavigateFunction }, {}> {

    render() {
        let tileCls = "tile";
        if (this.props.href) {
            tileCls += " clickable";
        }
        if (this.props.dark === true) {
            tileCls += " dark";
        }
        if (this.props.ratio) {
            tileCls += " ratio"+this.props.ratio;
        }
        return (
            <>
                <div className={tileCls} style={this.props.style} onClick={() => {
                    if (this.props.href) {
                        this.props.navigate(this.props.href);
                    }
                }}>
                    <div className="tile-content">
                        {this.props.children}
                    </div>
                </div>
            </>
        );
    }
}

export default function Tile(props: TileProps) {
    let navigate = useNavigate();
    return <TileCls navigate={navigate} {...props} />
}
