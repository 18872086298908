// import React from 'react';

// Snackbar
const HRPSnack = {
    top: "56px",
    "& div": {
    }
}

// Button
const SnackButton = {
    background: "#515151",
    color: "#f4f4f0",
    '&:hover': {
        background: "#717171"
    },
    width: "100%",
    borderRadius: 0,
    ":disabled": {
        background: "#bdbdbd",
        color: "#999999"
    }
}

// Button
const HRPButton = {
    width: "100%",
    borderRadius: 0
}

// Button
const HRPButtonNarrow = {
    borderRadius: 0
}

// Button
const HRPButtonCancel = {
    borderRadius: 0
}

// Button
const DangerButton = {
    background: "#cf142b",
    color: "#f4f4f0",
    '&:hover': {
        background: "#ef344b"
    },
    width: "100%",
    // maxWidth: "275px",
    borderRadius: 0,
    ":disabled": {
        background: "#bdbdbd",
        color: "#999999"
    }
}

// IconButton
const HRPIconButton = {
    background: "#2F5043",
    color: "#f4f4f0",
    '&:hover': {
        background: "#4d8062"
    },
    width: "100%",
    maxWidth: "275px",
    borderRadius: 0
}

// Button
const HRPButtonSecondary = {
    width: "100%",
    maxWidth: "275px"
}

// Button
const HRPButtonSecondaryWide = {
    width: "100%"
}

// Switch
const HRPSwitch = {
    color: "#FFFFFF",
    '&$checked': {
        color: "#FFCC00",
    },
    '&$checked + $track': {
        backgroundColor: "#4d8062",
    },
}

// Checkbox
const YellowCheckbox = {
    color: "#2F5043",
    '&$checked': {
        color: "#2F5043",
    },
}

export {
    HRPButton, HRPIconButton, HRPButtonSecondary, HRPSwitch, HRPButtonCancel,
    YellowCheckbox, DangerButton, HRPButtonNarrow,
    HRPSnack, SnackButton, HRPButtonSecondaryWide
};