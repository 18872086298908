import React from 'react';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbSkeleton,
    Button,
    Column,
    Dropdown,
    Grid,
    TextArea,
    TextInput 
} from "@carbon/react";
import { Edit } from "@carbon/react/icons";
import "./NewCamp.scss";
import { IAccount } from '../../../../services/AccountV1';
import { ICamp, CampV1 } from '../../../../services/CampV1';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export interface CampFieldsProps {
    initialState?: {
        name: string
        urlname: string
        address: string
        city: string
        state: string
        zip: string
        shortdesc: string,
        description: string,
        type: string
    }

    account?: IAccount
    editing: boolean
    submitLabel: string
    onSubmit: (loc: ICamp) => void
    onCancel?: () => void
}

export interface CampFieldsState {
    name: string
    urlname: string
    address: string
    city: string
    state: string
    zip: string
    shortdesc: string,
    description: string,
    type: string,

    conflictName: boolean
    conflictUrl: boolean
    invalid: boolean    
    editing: boolean
}

export class CampFields extends React.Component<CampFieldsProps, CampFieldsState> {
    state: CampFieldsState = {
        name: (this.props.initialState || { name: "" }).name,
        urlname: (this.props.initialState || { urlname: "" }).urlname,
        address: (this.props.initialState || { address: "" }).address,
        city: (this.props.initialState || { city: "" }).city,
        state: (this.props.initialState || { state: "" }).state,
        zip: (this.props.initialState || { zip: "" }).zip,
        shortdesc: (this.props.initialState || { shortdesc: "" }).shortdesc,
        description: (this.props.initialState || { description: "" }).description,
        type: (this.props.initialState || { type: "" }).type,
        conflictName: false,
        conflictUrl: false,
        invalid: false,
        editing: this.props.editing
    }

    async onSubmit() {
        let newCamp : ICamp = {
            id: "",
            account_id: this.props.account!.id,
            address: this.state.address,
            city: this.state.address,
            state: this.state.state,
            zip: this.state.zip,
            shortdesc: this.state.shortdesc,
            description: this.state.description,
            managers: [],
            staff: [],
            name: this.state.name,
            urlname: this.state.urlname,
            type: this.state.type
        }
        if (newCamp.name.length === 0 
            || newCamp.urlname.length === 0
        ) {
            this.setState({ invalid: true });
        } else {
            try {
                this.props.onSubmit(newCamp);
            } catch (err: any) {
                if (err.message === "name_conflict") {
                    this.setState({conflictName: true})
                } else if (err.message === "url_conflict") {
                    this.setState({conflictUrl: true})
                }
            }
        }
    }

    render() {
        if (!this.state.editing) {
            return <>
                <div className="bodyText">
                    <div><strong>Camp name</strong>: {this.state.name}</div>
                    <div><strong>URL part</strong>: {this.state.urlname}</div>
                    <div><strong>Camp type</strong>: {this.state.type}</div>
                    <div><strong>Address</strong>:</div>
                    <div style={{paddingLeft: "1rem"}}>
                        <div>{this.state.address}</div>
                        <div>{this.state.city}, {this.state.state} {this.state.zip}</div>
                    </div>
                    <div><strong>Short description</strong>: </div>
                    <div style={{paddingLeft: "1rem"}}>{this.state.shortdesc}</div>
                    <div><strong>Long description</strong>: </div>
                    <div style={{paddingLeft: "1rem"}}>{this.state.description}</div>
                </div>
                
                <div style={{marginTop: "1rem"}} />
                <Button 
                    kind="tertiary"
                    renderIcon={Edit}
                    onClick={() => {
                        this.setState({editing: true});
                    }}>Edit camp</Button>
            </>
        } else {
            let disableButton = this.state.name.length === 0 
                || this.state.urlname.length === 0
                || this.state.zip.length === 0;

            if (this.props.initialState) {
                let change = false;
                for (const key in this.props.initialState) {
                    change = change || (this.state[key] !== this.props.initialState[key]);
                }
                disableButton = disableButton || !change;
            }
            const states = [
                { id: "AL", text: "Alabama" },
                { id: "AK", text: "Alaska" },
                { id: "AZ", text: "Arizona" },
                { id: "AR", text: "Arkansas" },
                { id: "CA", text: "California" },
                { id: "CO", text: "Colorado" },
                { id: "CT", text: "Connecticut" },
                { id: "DE", text: "Delaware" },
                { id: "DC", text: "District of Columbia" },
                { id: "FL", text: "Florida" },
                { id: "GA", text: "Georgia" },
                { id: "HI", text: "Hawaii" },
                { id: "ID", text: "Idaho" },
                { id: "IL", text: "Illinois" },
                { id: "IN", text: "Indiana" },
                { id: "IA", text: "Iowa" },
                { id: "KS", text: "Kansas" },
                { id: "KY", text: "Kentucky" },
                { id: "LA", text: "Louisiana" },
                { id: "ME", text: "Maine" },
                { id: "MD", text: "Maryland" },
                { id: "MA", text: "Massachusetts" },
                { id: "MI", text: "Michigan" },
                { id: "MN", text: "Minnesota" },
                { id: "MS", text: "Mississippi" },
                { id: "MO", text: "Missouri" },
                { id: "MT", text: "Montana" },
                { id: "NE", text: "Nebraska" },
                { id: "NV", text: "Nevada" },
                { id: "NH", text: "New Hampshire" },
                { id: "NJ", text: "New Jersey" },
                { id: "NM", text: "New Mexico" },
                { id: "NY", text: "New York" },
                { id: "NC", text: "North Carolina" },
                { id: "ND", text: "North Dakota" },
                { id: "OH", text: "Ohio" },
                { id: "OK", text: "Oklahoma" },
                { id: "OR", text: "Oregon" },
                { id: "PA", text: "Pennsylvania" },
                { id: "RI", text: "Rhode Island" },
                { id: "SC", text: "South Carolina" },
                { id: "SD", text: "South Dakota" },
                { id: "TN", text: "Tennessee" },
                { id: "TX", text: "Texas" },
                { id: "UT", text: "Utah" },
                { id: "VT", text: "Vermont" },
                { id: "VA", text: "Virginia" },
                { id: "WA", text: "Washington" },
                { id: "WV", text: "West Virginia" },
                { id: "WI", text: "Wisconsin" },
                { id: "WY", text: "Wyoming"}
            ];

            const campTypes = [
                { id: "daycamp", text: "Day camp" }
                , { id: "sleepaway", text: "Sleepaway camp" }
            ]
            return <>
                <TextInput id="name" 
                    data-modal-primary-focus 
                    labelText="Camp name" 
                    value={this.state.name} 
                    onChange={(evt) => {
                        this.setState({ conflictName: false, name: evt.target.value })
                    }}
                    invalid={this.state.conflictName || (this.state.invalid && this.state.name.length === 0)}
                    invalidText={this.state.conflictName ? "Camp name conflicts with existing camp" : "Camp name is required"}
                />
                <div style={{marginTop: "1rem"}} />
                <TextInput id="urlname" 
                    labelText="URL string"
                    value={this.state.urlname} 
                    helperText="URL string must only be letters or numbers"
                    onChange={(evt) => {
                        let cleanVal = evt.target.value.toLowerCase().replace(/[^a-z0-9]/g, "");
                        this.setState({ conflictUrl: false, urlname: cleanVal })
                    }}
                    invalid={this.state.conflictUrl || (this.state.invalid && this.state.urlname.length === 0)}
                    invalidText={this.state.conflictUrl ? "URL part conflicts with existing camp" : "URL part is required"}
                />
                <div style={{marginTop: "1rem"}} />
                <div className="cds--text-input__label-wrapper"><label className="cds--label">Camp type</label></div>
                <Dropdown
                    items={campTypes}
                    itemToString={(item) => (item ? item.text : '')}
                    label="Camp type"
                    id="campType"
                    onChange={({ selectedItem }) => this.setState({ type: selectedItem.id })}
                    selectedItem={campTypes.filter(type => type.id === this.state.type)[0]}
                />
                <div style={{marginTop: "3rem"}} />
                <TextInput id="address" 
                    labelText="Street address (optional)"
                    value={this.state.address} 
                    onChange={(evt) => {
                        this.setState({ address: evt.target.value })}
                    }
                />
                <Grid>
                    <Column sm={4} md={6} lg={4} style={{marginTop: "1rem"}}>
                        <TextInput id="city" 
                            labelText="City (optional)"
                            value={this.state.city} 
                            onChange={(evt) => {
                                this.setState({ city: evt.target.value })}
                            }
                        />
                    </Column>
                    <Column sm={2} md={3} lg={2} style={{marginTop: "1rem"}}>
                        <div className="cds--text-input__label-wrapper"><label className="cds--label">State (optional)</label></div>
                        <Dropdown
                            items={states}
                            itemToString={(item) => (item ? item.text : '')}
                            label="State"
                            id="state"
                            onChange={({ selectedItem }) => this.setState({ state: selectedItem.id })}
                            selectedItem={states.filter(state => state.id === this.state.state)[0]}
                        />
                    </Column>
                    <Column sm={2} md={3} lg={2} style={{marginTop: "1rem"}}>
                        <TextInput id="zip" 
                            labelText="Zip code"
                            value={this.state.zip} 
                            onChange={(evt) => {
                                let cleanVal = evt.target.value.toLowerCase().replace(/[^0-9]/g, "");
                                this.setState({ zip: cleanVal })}
                            }
                            invalid={this.state.invalid && this.state.zip.length !== 5}
                            invalidText="Zip code is required"
                        />
                    </Column>
                </Grid>
                <div style={{marginTop: "3rem"}} />
                <TextArea id="shortdesc" 
                    labelText="Short description (optional)"
                    value={this.state.shortdesc} 
                    onChange={(evt) => {
                        this.setState({ shortdesc: evt.target.value })}
                    }
                />
                <div style={{marginTop: "2rem"}} />
                <TextArea id="description" 
                    labelText="Long description (optional)"
                    value={this.state.description} 
                    onChange={(evt) => {
                        this.setState({ description: evt.target.value })}
                    }
                />
                <div style={{marginTop: "2rem"}} />
                <Button 
                    disabled={ disableButton }
                    onClick={() => {
                        this.onSubmit();
                        if (!this.props.editing) {
                            this.setState({editing: false});
                        }
                    }}>{this.props.submitLabel}</Button>
                    {(this.props.onCancel || !this.props.editing) && <Button style={{marginLeft: "1rem"}} kind="secondary" onClick={() => {
                        this.props.onCancel && this.props.onCancel();
                        if (!this.props.editing) {
                            this.setState({
                                name: (this.props.initialState || { name: ""}).name,
                                urlname: (this.props.initialState || { urlname: ""}).urlname,
                                address: (this.props.initialState || { address: ""}).address,
                                city: (this.props.initialState || { city: ""}).city,
                                state: (this.props.initialState || { state: ""}).state,
                                zip: (this.props.initialState || { zip: ""}).zip,
                                shortdesc: (this.props.initialState || { shortdesc: ""}).shortdesc,
                                description: (this.props.initialState || { description: ""}).description,
                                editing: false
                            });
                        }
                    }}>Cancel</Button>}
            </>;
        }
    }
}
interface NewCampProps {
    account?: IAccount
    onNewCamp?: (camp: ICamp) => Promise<void>
}

interface NewCampState {
}

class NewCampCls extends React.Component<NewCampProps&{ navigate: NavigateFunction }, NewCampState> {
    async refresh() {
    }

    async create(newCamp: ICamp) {
        await CampV1.createCamp(newCamp);
        this.props.onNewCamp && await this.props.onNewCamp(newCamp);
        this.props.navigate(`/account/${this.props.account?.id}`);
    }

    async cancel() {
        this.props.navigate(`/account/${this.props.account?.id}`);
    }

    render() {
        return (
            <>
                <div style={{ marginTop: "3rem" }} />
                <Grid>
                    <Column sm={4} md={8} lg={16}>
                        { !this.props.account && <BreadcrumbSkeleton /> }
                        { this.props.account && <Breadcrumb noTrailingSlash>
                            <BreadcrumbItem href="/account/accounts">Accounts</BreadcrumbItem>
                            <BreadcrumbItem href={`/account/${this.props.account.id}`}>
                                {this.props.account.name}</BreadcrumbItem>
                            <BreadcrumbItem href="/account/new_camp" isCurrentPage>
                                New camp
                            </BreadcrumbItem>
                        </Breadcrumb>}
                    </Column>
                </Grid>
                <div style={{ marginTop: "1rem" }} />
                <main className="NewCamp">
                    <Grid>
                        <Column sm={4} md={6} lg={8}>
                            <h1>New camp</h1>
                            <div style={{marginTop: "2rem"}} />
                            <>
                                <CampFields
                                    editing={true}
                                    account={this.props.account}
                                    submitLabel="Create camp"
                                    onSubmit={(loc: ICamp) => {
                                        this.create(loc);
                                    }}
                                    onCancel={() => {
                                        this.cancel();
                                    }} />
                            </>
                        </Column>
                    </Grid>
                </main>
            </>)
    }
}
export default function NewCamp(props: NewCampProps) {
    let navigate = useNavigate();
    return <NewCampCls navigate={navigate} {...props} />
}