import { Button, Dialog, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme } from '@mui/material';
import React, { MouseEvent } from 'react';
import { ApiHomeroomClub, ApiNews } from '../../../../../../../../server/src/hrpcb/models/apiHomeroom';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import { HRPButton, HRPButtonSecondary } from '../../../../components/widgets/Customized';
import Nls from '../../../../nls/nls';
import HomeroomService from '../../../../services/HomeroomService';
import Util from '../../../../services/Util';

interface ModalAddNewsProps {
    open: boolean
    onNews: (homeroomId: string,
        newNews: ApiNews
    ) => void
    onClose: () => void
    homerooms: ApiHomeroomClub[]
    user: ApiUser
}

interface ModalAddNewsState {
    currentNews: {
        homeroomId: string
        subject: string
        content: string
        expires: string
    }
}

export default function ModalAddNews(props: ModalAddNewsProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return <ModalAddNewsClass {...props} fullScreen={fullScreen}/>
}
class ModalAddNewsClass extends React.Component<ModalAddNewsProps & { fullScreen: boolean }, ModalAddNewsState> {
    state: ModalAddNewsState = {
        currentNews: this.resetVals()
    }

    onState(field: "homeroomId" | "subject" | "content" | "expires", evt: any) {
        let newState: ModalAddNewsState = JSON.parse(JSON.stringify(this.state));
        newState.currentNews[field] = evt.target.value;
        this.setState(newState);
    }

    resetVals() {
        let d = new Date();
        d.setDate(d.getDate() + 1 * 7);
        return {
            homeroomId: this.props.homerooms[0]._id,
            subject: "",
            content: "",
            expires: d.toISOString().split("T")[0]
        }
    }

    render() {
        const { fullScreen } = this.props;
        const curNews = this.state.currentNews;
        let validNews = curNews.content.trim().length > 0
            && curNews.subject.trim().length > 0;

        return <Dialog 
            open={this.props.open} 
            maxWidth="md"
            fullWidth={true}
            fullScreen={fullScreen}
            onClose={() => {
                this.setState({
                    currentNews: this.resetVals()
                });
                this.props.onClose();
            }}
            >
            <DialogTitle>Add news</DialogTitle>
            <div style={{ margin: "0rem 1.5rem" }}>
                <FormControl variant="standard">
                    <InputLabel id="homeroom-label">{Nls.get("SELECTOR_LABEL")}</InputLabel>
                    <Select
                        labelId="homeroom-label"
                        id="homeroom-select"
                        value={this.state.currentNews.homeroomId}
                        onChange={(evt) => {
                            this.setState({
                                currentNews : {
                                    homeroomId: evt.target.value as string,
                                    subject: this.state.currentNews.subject,
                                    content: this.state.currentNews.content,
                                    expires: this.state.currentNews.expires
                                }
                            })
                        }}
                    >
                        {this.props.homerooms.map((homeroom) => (
                            <MenuItem key={homeroom._id} value={homeroom._id}>{HomeroomService.classLabel(homeroom)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div style={{ marginTop: ".5rem" }}></div>
                <TextField
                    type="date"
                    variant="filled"
                    style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                    label="News expiration date"
                    value={this.state.currentNews.expires}
                    onChange={this.onState.bind(this, "expires")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <div style={{ marginTop: ".5rem" }}></div>
                <TextField
                    autoFocus
                    variant="filled"
                    style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                    label="Subject"
                    value={this.state.currentNews.subject}
                    onChange={this.onState.bind(this, "subject")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <div style={{ marginTop: ".5rem" }}></div>
                <TextField
                    variant="filled"
                    style={{ minWidth: "400px", width: fullScreen ? "100%" : "90%" }}
                    label="News message"
                    value={this.state.currentNews.content}
                    onChange={this.onState.bind(this, "content")}
                    multiline
                    rows={10}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <div style={{ marginTop: "1.5rem" }}></div>
                <div className="hrpRow">
                    <div className="hrpCol-2-mob">
                        <Button sx={HRPButtonSecondary} variant="contained" onClick={(evt: MouseEvent) => {
                            this.setState({
                                currentNews: this.resetVals()
                            });
                            this.props.onClose();
                            evt.preventDefault();
                        }}>Cancel</Button>
                    </div>
                    <div className="hrpCol-2-mob">
                        <Button sx={HRPButton} variant="contained" color="primary" disabled={!validNews} onClick={async (evt: MouseEvent) => {
                            await this.props.onNews(
                                this.state.currentNews.homeroomId,
                                {
                                    id: Util.uuidv4(),
                                    subject: this.state.currentNews.subject,
                                    content: this.state.currentNews.content,
                                    timestamp: new Date().getTime(),
                                    from: this.props.user.email,
                                    expires: this.state.currentNews.expires
                                }
                            );
                            this.setState({
                                currentNews: this.resetVals()
                            });
                            this.props.onClose();
                            evt.preventDefault();
                        }}>Post</Button>
                    </div>
                </div>
                <div style={{ marginTop: "1.5rem" }}></div>
            </div>
        </Dialog>
    }
}