import { ApiClub, ApiHomeroom, ApiHomeroomClub } from "../../../../../server/src/hrpcb/models/apiHomeroom";
import { ApiUser } from "../../../../../server/src/hrpcb/models/apiUser";
import Nls from "../nls/nls";

export default class HomeroomService {
    static isHomeroom(hrClub: ApiHomeroomClub) : boolean {
        return !!(hrClub.schoolId && hrClub.schoolId.length > 0);
    }
    
    static hrClubName(hrClub: ApiHomeroomClub) : string {
        if (this.isHomeroom(hrClub)) {
            return hrClub.members.filter(m => m.title === "Teacher").map((t) => t.name).join(", ");
        } else {
            return (hrClub as ApiClub).clubName;
        }
    }

    static classLabel(hrClub: ApiHomeroomClub) : string {
        let gr = `${hrClub.grade} - `
        if (this.isHomeroom(hrClub)) {
            return gr + hrClub.members.filter(m => m.title === "Teacher").map((t) => t.name).join(", ");
        } else {
            return gr + hrClub.clubName;
        }
    }

    static sort(hrClub: ApiHomeroomClub[] | ApiClub[]) {
        hrClub.sort((a: ApiHomeroomClub, b: ApiHomeroomClub) => {
            let gradeA = parseInt(a.grade);
            let gradeB = parseInt(b.grade);
            if (a.grade === "K") gradeA = 0;
            if (a.grade === "PK") gradeA = -1;
            if (b.grade === "K") gradeB = 0;
            if (b.grade === "PK") gradeB = -1;
            if (a.grade === "NA") gradeA = 13;
            if (b.grade === "NA") gradeB = 13;
            if (gradeA !== gradeB) {
                return gradeA-gradeB;
            } else if (!HomeroomService.isHomeroom(a) && !HomeroomService.isHomeroom(b)) {
                return (a as ApiClub).clubName.localeCompare((b as ApiClub).clubName);
            } else {
                return a.members.filter(m => m.title === "Teacher")[0].name.localeCompare(b.members.filter(m => m.title === "Teacher")[0].name);
            }
        })
    }
    static cleanHR(hrClub: ApiHomeroomClub) : ApiHomeroomClub {
        hrClub.links = hrClub.links || {}
        if (HomeroomService.isHomeroom(hrClub)) {
            let hr = hrClub as ApiHomeroom;
            hrClub.links[hr.schoolName] = hrClub.links[hr.schoolName] || {}
            hrClub.links[hr.schoolName]["School"] = hrClub.links[hr.schoolName]["School"] || [""]
            hrClub.links[hr.schoolName]["District"] = hrClub.links[hr.schoolName]["District"] || [""]
            hrClub.links[hr.schoolName]["PTA/PTO"] = hrClub.links[hr.schoolName]["PTA/PTO"] || [""]

            let classLabel = HomeroomService.classLabel(hrClub);

            hrClub.links[classLabel] = hrClub.links[classLabel] || {}
            hrClub.members = hrClub.members || [];
            for (const member of hrClub.members) {
                if (member.title === "Teacher" && !!member.favorites) {
                    hrClub.links[classLabel][`${member.name}'s favorites`] = [
                        `/auth/favorites/${hrClub._id}/${member.userId}`
                    ]
                }
            }
            for (const cat in hrClub.links) {

                if (cat !== classLabel && cat.match(/^(K|1|2|3|4|5|6|7|8|9|10|11|12) - /)) {

                    for (const label in hrClub.links[cat]) {
                        for (const url of hrClub.links[cat][label]) {
                            if (url.trim().length > 0) {
                                hrClub.links[classLabel][label] = hrClub.links[classLabel][label] || [];
                                hrClub.links[classLabel][label].push(url.trim());
                            }
                        }
                    }
                    delete hrClub.links[cat];
                } else {
                    // console.log(cat !== classLabel, cat.match(/^(K|1|2|3|4|5|6|7|8|9|10|11|12) - /));
                }
            }
            hrClub.links[classLabel]["Class"] = hrClub.links[classLabel]["Class"] || [""]
        } else {
            let club = hrClub as ApiClub;
            hrClub.links[club.clubName] = hrClub.links[club.clubName] || {}
            hrClub.links[club.clubName]["Website"] = hrClub.links[club.clubName]["Website"] || [""]
            hrClub.members = hrClub.members || [];
            for (const member of hrClub.members) {
                if (member.title === "Teacher" && !!member.favorites) {
                    hrClub.links[club.clubName][`${member.name}'s favorites`] = [
                        `/auth/favorites/${hrClub._id}/${member.userId}`
                    ]
                }
            }
        }
        return hrClub;
    }

    static cleanClub(club: ApiClub) : ApiClub {
        club.links = club.links || {}
        // club.links[club.schoolName] = club.links[club.schoolName] || {}
        // club.links[club.schoolName]["School"] = club.links[club.schoolName]["School"] || [""]
        // club.links[club.schoolName]["District"] = club.links[club.schoolName]["District"] || [""]
        // club.links[club.schoolName]["PTA/PTO"] = club.links[club.schoolName]["PTA/PTO"] || [""]

        // let classLabel = HomeroomService.classLabel(club);

        // club.links[classLabel] = club.links[classLabel] || {}
        // for (const member of club.members) {
        //     if (member.title === "Teacher" && !!member.favorites) {
        //         club.links[classLabel][`${member.name}'s favorites`] = [
        //             `/auth/favorites/${club._id}/${member.userId}`
        //         ]
        //     }
        // }
        // for (const cat in club.links) {

        //     if (cat !== classLabel && cat.match(/^(K|1|2|3|4|5|6|7|8|9|10|11|12) - /)) {

        //         for (const label in club.links[cat]) {
        //             for (const url of club.links[cat][label]) {
        //                 if (url.trim().length > 0) {
        //                     club.links[classLabel][label] = club.links[classLabel][label] || [];
        //                     club.links[classLabel][label].push(url.trim());
        //                 }
        //             }
        //         }
        //         delete club.links[cat];
        //     } else {
        //         // console.log(cat !== classLabel, cat.match(/^(K|1|2|3|4|5|6|7|8|9|10|11|12) - /));
        //     }
        // }
        // club.links[classLabel]["Class"] = club.links[classLabel]["Class"] || [""]
        return club;
    }

    static async getByUser() : Promise<(ApiHomeroomClub)[]> {
        let result = await fetch(`/api/${Nls.get("URL_PART")}/cur_user`);
        let hrs : (ApiHomeroomClub)[] = await result.json();
        return hrs.map(hr => HomeroomService.cleanHR(hr));
    }

    static async get(id: string, code?: string) : Promise<(ApiHomeroomClub)> {
        let result = await fetch(`/api/${Nls.get("URL_PART")}/by_id/${id}${code?`?code=${code}`:""}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
        });
        let hr : ApiHomeroomClub = await result.json();
        return HomeroomService.cleanHR(hr);
    }

    static async createHomeroom(homeroom: ApiHomeroomClub & { zip: string }) : Promise<(ApiHomeroomClub)> {
        let result = await fetch(`/api/${Nls.get("URL_PART")}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(homeroom)
        });
        let hr : (ApiHomeroomClub) = await result.json();
        return HomeroomService.cleanHR(hr);
    }

    static async createClub(homeroom: ApiClub & { zip: string }) : Promise<ApiClub> {
        let result = await fetch(`/api/${Nls.get("URL_PART")}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(homeroom)
        });
        let hr : ApiClub = await result.json();
        return HomeroomService.cleanClub(hr);
    }

    static async deleteHomeroom(id: string) : Promise<void> {
        let result = await fetch(`/api/${Nls.get("URL_PART")}/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json'
            }
        });
        return result.json();
    }

    static async updateHomeroom(homeroom: ApiHomeroomClub) : Promise<(ApiHomeroomClub)> {
        let result = await fetch(`/api/${Nls.get("URL_PART")}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(homeroom)
        });
        let hr : (ApiHomeroomClub) = await result.json();
        return HomeroomService.cleanHR(hr);
    }

    static canEdit(homeroom?: ApiHomeroomClub, user?: ApiUser | null) : boolean {
        let canEdit : boolean = !!(homeroom && user && homeroom.members.some((m) => (
            m.userId === user._id && m.role === "ADMIN"
        )));
        return canEdit;
    }

    static isMember(homeroom?: ApiHomeroomClub, user?: ApiUser | null) : boolean {
        let isMember : boolean = !!(homeroom && user && homeroom.members.some((m) => (
            m.userId === user._id
        )));
        return isMember;
    }

    static canEditAny(homerooms?: ApiHomeroomClub[], user?: ApiUser | null) : boolean {
        if (!homerooms) return false;
        for (const homeroom of homerooms) {
            let canEdit : boolean = !!(homeroom && user && homeroom.members.some((m) => (
                m.userId === user._id && m.role === "ADMIN"
            )));
            if (canEdit) return canEdit;
        }
        return false;
    }

    static anyNotMember(homerooms?: ApiHomeroomClub[], user?: ApiUser | null) : boolean {
        if (!homerooms) return false;
        for (const homeroom of homerooms) {
            let isMember : boolean = !!(homeroom && user && homeroom.members.some((m) => (
                m.userId === user._id
            )));
            if (!isMember) return true;
        }
        return false;
    }
}