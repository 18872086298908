import React from 'react';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { URLSearchParams } from 'url';
import {
    Breadcrumb, BreadcrumbItem, BreadcrumbSkeleton,
    Button,
    Column,
    DatePicker, DatePickerInput,
    DefinitionTooltip,
    Grid,
    Layer,
    Loading,
    NumberInput,
    RadioTile,
    Theme,
    TileGroup,
    Tile
} from '@carbon/react';

import { TimeUtil, range24 } from '../../../../util/TimeUtil';
import { SearchV1, IPTSearchResult } from '../../services/SearchV1';
import "./PartyPlace.scss";
import { PTPackage } from '../../services/LocationV1';

interface PartyPlaceClsState {
    init: boolean,
    zip: string,
    date: string,
    event_types: string[]
    locationId?: string,
    timeStr?: string,
    pkgId?: string,
    searchResult?: IPTSearchResult,
    extraGuests: number
}

class PartyPlaceCls extends React.Component<{ navigate: NavigateFunction, searchParams: URLSearchParams }, PartyPlaceClsState> {
    state: PartyPlaceClsState = {
        init: false,
        zip: "",
        date: "",
        event_types: [],
        extraGuests: 0
    }

    componentDidMount() {
        let sp = this.props.searchParams;
        let eventStr = sp.get("type") ?? "";
        let locationId = sp.get("location") ?? "";
        let timeStr = sp.get("time") || undefined;
        this.setState({
            zip: sp.get("zip") ?? "",
            date: sp.get("date") ?? "",
            event_types: eventStr.length === 0 ? [] : eventStr.split(","),
            locationId: locationId,
            init: true,
            timeStr
        });
        // document.getElementById("root")!.style.backgroundColor = "#eee";
        // this.refresh();
    }

    componentDidUpdate(prevProps: Readonly<{ navigate: NavigateFunction; searchParams: URLSearchParams; }>, prevState: Readonly<PartyPlaceClsState>, snapshot?: any): void {
        if (this.state.date !== prevState.date) {
            this.refresh();
        }
    }

    async refresh() {
        let results = await SearchV1.search(this.state.zip, this.state.date, this.state.event_types);
        this.setState({ searchResult: results.filter(result => result && result.location_id === this.state.locationId)?.[0] });
    }

    changeDate(pickerDateStr: string) {
        let newDate = new Date(pickerDateStr);
        let dateStr = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        dateStr += "-" + (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        dateStr += "-" + newDate.getFullYear() + "";
        this.setState({ date: dateStr });
        this.props.navigate(`?zip=${this.state.zip}&date=${dateStr}&type=${encodeURIComponent(this.state.event_types!.join(",") || "")}&location=${this.state.locationId}`)
    };

    render() {
        let qStr = `?zip=${this.state.zip}&date=${this.state.date}&type=${encodeURIComponent(this.state.event_types?.join(",") || "")}`;
        let sr = this.state.searchResult;
        let address = (sr ? `${sr.address}
${sr.city}, ${sr.state} ${sr.zip}
${sr.email}
${sr.phone}` : "").trim().replace(/\n\s*\n/g, "\n");

        const availTimes: range24[] = this.state.searchResult ? SearchV1.getSearchTimes(this.state.searchResult.areas) : [];
        let searchCapacity = 0;
        if (this.state.searchResult) {
            if (this.state.timeStr) {
                searchCapacity = SearchV1.getTimeCapacity(
                    this.state.searchResult.areas,
                    TimeUtil.convR12StrToR24(this.state.timeStr)
                );
            } else {
                searchCapacity = SearchV1.getDayCapacity(
                    this.state.searchResult.areas);
            }
        }
        let costs: Array<{ label: string, value: number }> = [];
        let deposits: Array<{ label: string, value: number }> = [];
        let chosenPackage: PTPackage | null = null;
        if (this.state.pkgId && this.state.searchResult) {
            chosenPackage = this.state.searchResult.packages.find(pkg => pkg.id === this.state.pkgId)!;

            costs.push({ label: "Package charge", value: chosenPackage.base.cost });
            deposits.push({ label: "Package deposit", value: chosenPackage.base.deposit });
            if (chosenPackage.addon) {
                let extraGuests = this.state.extraGuests;
                costs.push({ label: "Guest charge", value: chosenPackage.addon.cost * extraGuests});
                deposits.push({ label: "Guest deposit", value: chosenPackage.addon.deposit * extraGuests});
            }

            let subtotal = costs.reduce((acc, curr) => acc + curr.value, 0);
            let serviceFee = Math.max(subtotal*.03, 5);
            costs.push({ label: "Service fee", value: serviceFee });
            deposits.push({ label: "Service fee", value: serviceFee });
        }

        return <main className="PartyPlace">
            <Theme theme="white" className="cds--pt-white">
                <Grid>
                    <Column sm={2} md={3} lg={3} style={{ margin: "1rem 1rem" }}>
                        <DatePicker
                            datePickerType="single"
                            value={this.state.date}
                            onChange={this.changeDate.bind(this)}
                        >
                            <DatePickerInput
                                id="search-date"
                                labelText="Date"
                                helperText="mm/dd/yyyy"
                            />
                        </DatePicker>
                    </Column>
                </Grid>
            </Theme>
            <div style={{ marginTop: "1rem" }} />
            <Grid>
                <Column sm={4} md={8} lg={16}>
                    {!sr?.location_id && <>
                        <BreadcrumbSkeleton />
                        <Loading />
                    </>}
                    {sr && <>
                        <Breadcrumb noTrailingSlash>
                            <BreadcrumbItem href={`/partyplaces${qStr}`} >Search results</BreadcrumbItem>
                            <BreadcrumbItem isCurrentPage>{sr.name}</BreadcrumbItem>
                        </Breadcrumb>
                        <h1>{sr.name}</h1>
                        <Grid>
                            <Column sm={4} md={4} lg={10}>
                                <h2>Description</h2>
                                <p>{sr.description.split("\n").map(line => <>{line}<br /></>)}</p>
                                <h2>Location & Contact</h2>
                                <p>{address.split("\n").map(line => <>{line}<br /></>)}</p>
                                <div style={{ marginBottom: "1rem" }} />
                            </Column>
                            <Column sm={4} md={4} lg={6}>
                                <Tile style={{ margin: "0rem -1rem" }}>
                                    <Layer>
                                        <h2 style={{ marginTop: "0rem" }}>Take it!</h2>
                                        <div style={{ marginTop: "1rem" }} />
                                        {/* <Dropdown
                                        id="timePick"
                                        titleText="Reservation time"
                                        // initialSelectedItem={sr.times[0]} 
                                        label="Select a time"
                                        items={availTimes}
                                        itemToString={timeInfo => timeInfo ? TimeUtil.convR24toR12Str(timeInfo) : ''}
                                    /> */}
                                        <div id="timeGroupLabel" className="cds--label">Event time</div><br />
                                        <div onKeyDown={async (evt: React.KeyboardEvent<HTMLDivElement>) => {
                                            let idx = -1;
                                            if (this.state.timeStr) {
                                                idx = availTimes.findIndex(availTime => TimeUtil.convR24toR12Str(availTime) === this.state.timeStr);
                                            }
                                            if (evt.key === "ArrowUp" || evt.key === "ArrowLeft") {
                                                if (idx === -1) {
                                                    idx = availTimes.length - 1;
                                                } else if (idx > 0) {
                                                    --idx;
                                                }
                                            } else if (evt.key === "ArrowDown" || evt.key === "ArrowRight") {
                                                if (idx < availTimes.length - 1) {
                                                    ++idx;
                                                }
                                            }
                                            if (idx !== -1) {
                                                let timeStr = TimeUtil.convR24toR12Str(availTimes[idx]);
                                                this.setState({ timeStr })
                                                this.props.navigate(`/partyplace${qStr}&location=${this.state.locationId}&time=${timeStr}`);

                                            }
                                        }}
                                            tabIndex={0}
                                            aria-labelledby="timeGroupLabel"
                                            className="timeGroup"
                                            role="radiogroup"
                                            aria-activedescendant={this.state.timeStr ? `time_${this.state.timeStr}` : undefined}
                                        >
                                            {availTimes.map(timeInfo => {
                                                let timeStr = TimeUtil.convR24toR12Str(timeInfo);
                                                return <button key={`time_${timeStr}`} id={`time_${timeStr}`} tabIndex={-1}
                                                    role="radio" aria-checked={timeStr === this.state.timeStr}
                                                    className={`${timeStr === this.state.timeStr ? "selected" : ""}`}
                                                    onClick={() => {
                                                        this.props.navigate(`/partyplace${qStr}&location=${this.state.locationId}&time=${timeStr}`);
                                                        this.setState({ timeStr });
                                                    }}
                                                >{timeStr}</button>
                                            })}
                                        </div>
                                        <div style={{ marginTop: "1rem" }} />
                                        <TileGroup
                                            legend="Select a package"
                                            name="package"
                                            onChange={(pkgId) => this.setState({pkgId})}
                                        >
                                            {sr.packages.map((pkg, idx) => (
                                                <RadioTile
                                                    id={`pkg-option-${idx}`}
                                                    key={`pkg-option-${idx}`}
                                                    style={{
                                                        marginBottom: '.5rem'
                                                    }}
                                                    value={pkg.id}
                                                >
                                                    <strong>{pkg.label}</strong>
                                                    <div>Cost: {`$${pkg.base.cost}`}</div>
                                                    {pkg.base.capacity <= searchCapacity && <div>Included guests: {pkg.base.capacity}</div>}
                                                    {pkg.base.capacity > searchCapacity && <div>
                                                        Included guests: <DefinitionTooltip 
                                                            openOnHover 
                                                            definition="This number was reduced because there is not enough space available at this time"
                                                        ><del>{pkg.base.capacity}</del> <ins>{searchCapacity}</ins>
                                                        </DefinitionTooltip>
                                                    </div>}

                                                    {pkg.addon && <div style={{ fontStyle: "italic" }}>Charge per additional guest: {`$${pkg.addon.cost}`}</div>}
                                                </RadioTile>
                                            ))}
                                        </TileGroup>
                                        {chosenPackage?.addon && <NumberInput
                                            label="Additional guests"
                                            id="addonCount"
                                            max={searchCapacity-chosenPackage.base.capacity}
                                            onChange={() => {
                                                this.setState({ extraGuests: parseInt((document.getElementById("addonCount")! as HTMLInputElement).value)})
                                            }}
                                        />}
                                        <div style={{marginTop: "1rem"}} />
                                        {chosenPackage && <DefinitionTooltip openOnHover align="top"
                                            definition={costs.filter(cost => cost.value > 0).map(cost => `${cost.label}: $${cost.value}`).join("\n")}>Cost: ${
                                            costs.reduce((acc, curr) => acc + curr.value, 0)
                                        }</DefinitionTooltip>}
                                        <div style={{marginTop: ".5rem"}} /> 
                                        {chosenPackage && <DefinitionTooltip openOnHover 
                                            definition={deposits.filter(cost => cost.value > 0).map(cost => `${cost.label}: $${cost.value}`).join("\n")}>Deposit: ${
                                            deposits.reduce((acc, curr) => acc + curr.value, 0)
                                        }</DefinitionTooltip>}
                                        <div style={{marginTop: "1rem"}} />
                                        <Button
                                            disabled={!chosenPackage || !this.state.timeStr}
                                        >Take it!</Button>
                                    </Layer>
                                </Tile>
                                <div style={{marginBottom: "2rem"}} />
                            </Column>
                        </Grid>
                    </>}
                </Column>
            </Grid>
        </main>
    }
}

export default function PartyPlace() {
    let navigate = useNavigate();
    let [searchParams] = useSearchParams();
    return <PartyPlaceCls navigate={navigate} searchParams={searchParams} />
}
