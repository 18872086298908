import React, { ReactNode } from 'react';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import { ApiHomeroomClub, ApiNews } from '../../../../../../../../server/src/hrpcb/models/apiHomeroom';
import "./SectionNews.scss";
import ModalAddNews from './ModalAddNews';
import ModalNewsPrompt from "./ModalNewsPrompt";
import HomeroomLabel from '../../../../components/widgets/HomeroomLabel';
import { Add, ExpandMore, MenuBook } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip } from '@mui/material';
import HomeroomService from '../../../../services/HomeroomService';
import ModalEditNews from './ModalEditNews';
import Nls from '../../../../nls/nls';
import InfoNews from '../../../../components/info/InfoNews';
import InfoButton from '../../../../components/info/InfoButton';


interface SectionNewsMobileProps {
    user: ApiUser
    homerooms: ApiHomeroomClub[]
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
    expanded: boolean,
    onToggle: () => void,
}

export function SectionNewsMobile(props: SectionNewsMobileProps) {
    return <SectionNewsClass {...props} />
}

interface SectionNewsDesktopProps {
    user: ApiUser
    homerooms: ApiHomeroomClub[]
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
}

export function SectionNewsDesktop(props: SectionNewsDesktopProps) {
    return <SectionNewsClass {...props} />
}

interface SectionNewsState {
    dialogAdd: boolean
    dialogQuestion: NewsRow | null
    dialogQuestionIdx: number
    dialogAnswer: NewsRow | null
    dialogAnswerIdx: number
    dialogEdit: NewsRow | null
}

interface SectionNewsClassProps {
    user: ApiUser
    homerooms: ApiHomeroomClub[]
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
    expanded?: boolean,
    onToggle?: () => void,
}

export interface NewsRow {
    id: string, 
    from: string, 
    postDate: ReactNode,
    homeroom: ApiHomeroomClub, 
    subject: ReactNode,
    subjectStr: string,
    timestamp: number,
    content: string,
    questions?: Array<{
        from: string,
        question: string,
        answer: string
    }>
}

class SectionNewsClass extends React.Component<SectionNewsClassProps, SectionNewsState> {
    state: SectionNewsState = {
        dialogAdd: false,
        dialogQuestion: null,
        dialogQuestionIdx: -1,
        dialogAnswer: null,
        dialogAnswerIdx: -1,
        dialogEdit: null
    }

    async onNews(homeroomId: string, newNews: ApiNews) {
        let update = JSON.parse(JSON.stringify(this.props.homerooms));
        for (let idx=0; idx<update.length; ++idx) {
            if (this.state.dialogEdit && update[idx]._id === this.state.dialogEdit.homeroom._id) {
                update[idx].news = this.state.dialogEdit.homeroom.news.filter((newsItem) => newsItem.id !== this.state.dialogEdit!.id);
            }
            if (update[idx]._id === homeroomId) {
                update[idx].news.push(newNews);
            }            
        }
        await this.props.onHomerooms(update);
    }

    async onQuestion(question: string) {
        let update = JSON.parse(JSON.stringify(this.props.homerooms));
        for (let idx=0; idx<update.length; ++idx) {
            if (this.state.dialogQuestion && update[idx]._id === this.state.dialogQuestion.homeroom._id) {
                const hr = update[idx];
                for (let idxNews = 0; idxNews < hr.news.length; ++idxNews) {
                    if (hr.news[idxNews].id === this.state.dialogQuestion.id) {
                        if (this.state.dialogQuestionIdx === -1) {
                            hr.news[idxNews].questions = hr.news[idxNews].questions || []
                            hr.news[idxNews].questions!.push({
                                from: this.props.user._id,
                                question: question,
                                answer: ""
                            })
                        } else {
                            hr.news[idxNews].questions![this.state.dialogQuestionIdx].question = question;
                        }
                    }
                }
            }   
        }
        await this.props.onHomerooms(update);
    }

    async onAnswer(answer: string) {
        let update = JSON.parse(JSON.stringify(this.props.homerooms));
        for (let idx=0; idx<update.length; ++idx) {
            if (this.state.dialogAnswer && update[idx]._id === this.state.dialogAnswer.homeroom._id) {
                const hr = update[idx];
                for (let idxNews = 0; idxNews < hr.news.length; ++idxNews) {
                    if (hr.news[idxNews].id === this.state.dialogAnswer.id) {
                        hr.news[idxNews].questions![this.state.dialogAnswerIdx].answer = answer;
                    }
                }
            }   
        }
        await this.props.onHomerooms(update);
    }

    async onRemove(newsId: string, homeroom: ApiHomeroomClub) {
        let update = JSON.parse(JSON.stringify(this.props.homerooms));
        for (let idx=0; idx<update.length; ++idx) {
            if (update[idx]._id === homeroom._id) {
                update[idx].news = homeroom.news.filter((newsItem) => newsItem.id !== newsId);
            }            
        }
        await this.props.onHomerooms(update);
    }

    render() {
        if (this.props.homerooms) {
            HomeroomService.sort(this.props.homerooms);
        }

        const mobile = typeof this.props.expanded !== "undefined"

        let rows : NewsRow[] = [];
        let messageMap : { [id: string]: ApiNews }= {}
        if (this.props.homerooms) {
            rows = this.props.homerooms.reduce<NewsRow[]>(
                (prevValue: NewsRow[], homeroom: ApiHomeroomClub, currentIndex: number, array: ApiHomeroomClub[]) => {
                    let curRows : NewsRow[] = homeroom.news.map((newsItem: ApiNews) => {
                        const timeDate = new Date(newsItem.timestamp);
                        messageMap[newsItem.id] = newsItem;
                        return {
                            id: newsItem.id,
                            from: newsItem.from,
                            homeroom: homeroom,
                            subject: newsItem.subject,
                            subjectStr: newsItem.subject,
                            content: newsItem.content,
                            postDate: <span>{`${timeDate.toLocaleDateString("en-US", {
                                weekday: "short",
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour12: true,
                                hour: "numeric",
                                minute: "numeric"
                            })}`}</span>,
                            timestamp: newsItem.timestamp,
                            questions: newsItem.questions
                        };
                    });
                    prevValue = prevValue.concat(curRows)
                    return prevValue;
                }, []
            );
        }
        rows.sort((a, b) => {
            return b.timestamp-a.timestamp;
        })

        let editableHomerooms = this.props.homerooms.filter((homeroom) => {
            return HomeroomService.canEdit(homeroom, this.props.user);
        });

        const canEditAny = HomeroomService.canEditAny(this.props.homerooms, this.props.user);

        const sectionHeader = <div>
            <h2 style={{marginRight: "1rem"}}>
                <MenuBook /><span style={{verticalAlign: "top", marginLeft: ".5rem"}}>News</span>
            </h2>
            <InfoButton style={{marginLeft: "-1rem", marginTop: "-1rem"}} title="News Info" showLogin={!this.props.user}>
                <InfoNews />
            </InfoButton>
            { canEditAny && <>
                <Tooltip placement="top" title="Add news">
                    <IconButton
                        color={mobile ? undefined : "primary"}
                        style={{ 
                            width: "inherit", 
                            float: "right", 
                            marginTop: mobile ? "0rem" : "-.8rem", 
                            marginBottom: mobile ? "0rem" : ".5rem" 
                        }}
                        onClick={(evt) => { 
                            evt.stopPropagation();
                            this.setState({dialogAdd: true});
                        }}
                        size="large"><Add className="homeIconButton" />
                    </IconButton>
                </Tooltip>
                <div style={{clear: "both"}}></div>
            </>
            }
        </div>;
        
        const sectionBody = rows.length > 0 ? rows.map((row) => {
            let canEdit = HomeroomService.canEdit(row.homeroom, this.props.user);
            return <div key={row.id} style={{border: "solid #999 1px", marginBottom: "1.5rem"}}>
                <div className="newsHead">
                    <div>
                        <HomeroomLabel 
                            edit={HomeroomService.canEdit(row.homeroom, this.props.user)} 
                            homeroom={row.homeroom} 
                            onEdit={() => {
                                this.setState({dialogEdit: row})
                            }} 
                            onDelete={async () => {
                                if (window.confirm("Confirm deletion")) {
                                    await this.onRemove(row.id, row.homeroom);
                                }
                            }} />
                    </div>
                </div>
                <div className="newsBody" style={{marginBottom: "0rem"}}>
                    <div className="newsDate">{row.postDate}</div>
                    <div style={{fontSize: ".8rem", fontWeight: 500}}>From: {row.from}</div>
                    <div style={{fontSize: ".8rem", fontWeight: 500}}>Subject: {row.subject}</div>
                    <div style={{marginTop: "1rem"}}></div>
                    {row.content.split("\n").map((line) => (
                        <div style={{fontWeight: 300}}>{line}</div>
                    ))}
                </div>
                {row.questions && row.questions.length > 0 && <>
                    <div className="newsBody" style={{
                        marginBottom: "0rem", fontSize: ".8rem", 
                        borderTop: "solid #ccc 1px", borderBottom: "solid #ccc 1px",
                        paddingTop: "1rem"
                    }}>
                        {row.questions.map((question, index) => {
                            let hasAnswer = question.answer && question.answer.length > 0;
                            if (!canEdit && question.from !== this.props.user._id) return <></>;
                            return <>
                                <div>
                                    { (question.from !== this.props.user._id && <>Question: </>)
                                        || <a href="#/" onClick={(evt) => {
                                            evt.stopPropagation();
                                            evt.preventDefault();
                                            this.setState({dialogQuestion: row, dialogQuestionIdx: index});
                                        }}>Question: </a>
                                    }
                                    <span style={{fontWeight: 300}}> {question.question}</span>
                                </div>
                                <div style={{marginTop: ".5rem"}} />
                                { hasAnswer && !canEdit && <div>Answer: <span style={{fontWeight: 300}}>{question.answer}</span></div> }
                                { hasAnswer && canEdit && <div>
                                    <a href="#/" onClick={(evt) => {
                                        evt.stopPropagation();
                                        evt.preventDefault();
                                        this.setState({
                                            dialogAnswer: row,
                                            dialogAnswerIdx: index
                                        });
                                    }}>Answer:</a> <span style={{fontWeight: 300}}>{question.answer}</span>
                                </div> }
                                { !hasAnswer && canEdit && <a href="#/" onClick={(evt) => {
                                    evt.stopPropagation();
                                    evt.preventDefault();
                                    this.setState({
                                        dialogAnswer: row,
                                        dialogAnswerIdx: index
                                    });
                                    }}>Answer question</a>
                                }
                            </>
                        })}
                    </div>
                </>}
                <div className="newsBody" style={{textAlign: "right", fontSize: ".8rem"}}>
                    {this.props.user && <a href="#/" className="regLink" onClick={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                        this.setState({dialogQuestion: row, dialogQuestionIdx: -1});
                    }}>Ask a question</a>}
                </div>
            </div>
        }) : canEditAny ? <>
            { /* Admin message */ }
            <p>There are no news items available at this time.</p>
            <p>You can use news for general notifications and reminders. News 
                postings will be emailed to members of the {Nls.get("SELECTOR_LABEL")} and will be
                removed on the expiration date.
            </p>
        </> : <>
            { /* non-admin message */ }
            <p>There are no news items available at this time.</p>
        </>;

        const sectionDialog = <>
            { editableHomerooms.length > 0 && <ModalAddNews
                open={this.state.dialogAdd} 
                onNews={async (homeroomId: string, news: ApiNews) => { await this.onNews(homeroomId, news) }} 
                onClose={() => {this.setState({dialogAdd: false})} }
                homerooms={editableHomerooms}
                user={this.props.user}
            />}
            { editableHomerooms.length > 0 && this.state.dialogEdit && <ModalEditNews
                open={this.state.dialogEdit} 
                onNews={async (homeroomId: string, news: ApiNews) => { 
                    await this.onNews(homeroomId, news) 
                }} 
                onClose={() => {this.setState({dialogEdit: null})} }
                homerooms={editableHomerooms}
                user={this.props.user}
            />}
            { editableHomerooms.length > 0 && this.state.dialogQuestion && <ModalNewsPrompt
                user={this.props.user}
                open={this.state.dialogQuestion}
                title="Ask a question"
                textPrompt="Question to ask"
                initialValue={
                    this.state.dialogQuestionIdx === -1 ? undefined :
                    this.state.dialogQuestion.questions![this.state.dialogQuestionIdx].question
                }
                onResponse={async (response: string) => {
                    await this.onQuestion(response);
                }}
                onClose={() => {this.setState({dialogQuestion: null, dialogQuestionIdx: -1})} }
            />}
            { editableHomerooms.length > 0 && this.state.dialogAnswer && <ModalNewsPrompt
                user={this.props.user}
                open={this.state.dialogAnswer}
                title="Answer question"
                subTitle={"Question: "+this.state.dialogAnswer.questions![this.state.dialogAnswerIdx].question}
                textPrompt="Response"
                initialValue={this.state.dialogAnswer.questions![this.state.dialogAnswerIdx].answer}
                onResponse={async (response: string) => {
                    await this.onAnswer(response);
                }}
                onClose={() => {this.setState({ dialogAnswer: null, dialogAnswerIdx: -1 })} }
            />}
        </>;
        
        if (mobile) {
            return <Accordion
                square
                expanded={this.props.expanded}
                onChange={() => {
                    this.props.onToggle!();
                }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    {sectionHeader}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{width: "100%", marginTop: "1rem"}}>
                        {sectionBody}
                    </div>
                </AccordionDetails>
                {sectionDialog}
            </Accordion>
        } else {
            return <>
                <div>
                    {sectionHeader}
                    {sectionBody}
                </div>
                {sectionDialog}
            </>
        }
    }
}