import React from 'react';
import Nls from '../../nls/nls';

interface InfoGoalsProps {
}

export default function InfoGoals(_props: InfoGoalsProps) {

    return <>
        <p>You can set goals to show progress for fundraisers, books read
or any other goal you would like to track for your {Nls.get("SELECTOR_LABEL_LOWER")}.
As progress is made, edit the goal and update the current value</p>
    </>;
}
