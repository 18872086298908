
import React from 'react';
import { Add, PersonAddAlt, Settings, Share } from '@mui/icons-material';
import { HRPButton } from '../../components/widgets/Customized';
import { ApiHomeroomClub } from '../../../../../../server/src/hrpcb/models/apiHomeroom';
import { Button, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Theme, Tooltip, useTheme } from '@mui/material';
import "./HomeroomSelector.scss";
import { ePageProfile } from '../../App';
import { ApiUser } from '../../../../../../server/src/hrpcb/models/apiUser';
import HomeroomService from '../../services/HomeroomService';
import Nls from '../../nls/nls';

interface HomeroomSelectorProps {
    user?: ApiUser
    homerooms: ApiHomeroomClub[]
    onSelected: (homeroomIds: string[]) => void
    onHomerooms: (homerooms: ApiHomeroomClub[]) => void
    pageProfile: ePageProfile
}

interface HomeroomSelectorState {
    hrSelection: string
}

class HomeroomSelectorClass extends React.Component<HomeroomSelectorProps & { theme: Theme }, HomeroomSelectorState> {
    state: HomeroomSelectorState = {
        hrSelection: this.props.homerooms.length > 1 ? "All" : this.props.homerooms[0]._id
    }

    onSelection(newSelection: string) {
        this.setState({ hrSelection: newSelection });
        if (newSelection === "All") {
            this.props.onSelected(this.props.homerooms!.map((homeroom) => homeroom._id));
        } else {
            this.props.onSelected([newSelection]);
        }
    }

    render() {
        // const canEdit : boolean = HomeroomService.canEditAny(this.props.homerooms, this.props.user);
        const anyNotMember : boolean = HomeroomService.anyNotMember(this.props.homerooms, this.props.user);
        const demo = !this.props.user 
            && this.props.homerooms.length === 1
            && this.props.homerooms[0]._id === Nls.get("DEMO_ID");

        // let selWidth = "calc(100% - 237px)";
        // if (!canEdit) {
            // selWidth = "calc(100% - 180px)";
        // }
        // let formMargin = "0rem .5rem 0rem 0rem";
        // if (this.props.pageProfile === "tablet") {
            // if (canEdit) {
                // selWidth = "calc(100% - 76px)";
            // } else {
                // selWidth = "calc(100% - 38px)";
            // }
            // formMargin = "0rem .5rem 0rem 0rem";
        // }

        this.props.homerooms.sort((a, b) => {
            if (a.grade === "PK") return b.grade === "PK" ? 0 : -1;
            if (b.grade === "PK") return 1;
            if (a.grade === "K") return b.grade === "K" ? 0 : -1;
            if (b.grade === "K") return 1;
            if (a.grade === "NA") return b.grade === "NA" ? 0 : 1;
            if (b.grade === "NA") return -1;
            return a.grade.localeCompare(b.grade);
        });

        // let selectedHR = null;
        // if (this.state.hrSelection !== "All") {
        //     selectedHR = this.props.homerooms.filter(homeroom => homeroom._id === this.state.hrSelection)[0];
        // }

        // let canEditSelected = this.state.hrSelection !== "All" 
        //     && HomeroomService.canEdit(selectedHR!, this.props.user);
            // console.log(canEditSelected, HomeroomService.canEdit(selectedHR!, this.props.user))

        let intro = <></>
        if (!this.props.user) {
            if (!demo) {
                intro = <div style={{ padding: "1rem 0rem 0rem 0rem" }}>
                    If you would like to see {Nls.get("SELECTOR_LABELS")} for which
                    you are listed as a member, you must <a 
                        className="cleanLink" 
                        href={`/login${document.location.search}`} 
                        style={{ 
                            color: this.props.theme.palette.primary.main,
                            textDecoration: "underline",
                            fontWeight: "800"
                        }}
                    >login</a> to see them.
                    {anyNotMember && <> If you would like to receive notifications for
                    a {Nls.get("SELECTOR_LABEL")}, you must be a member of that {Nls.get("SELECTOR_LABEL")} </>}
                </div>;
            }
        } else if (anyNotMember) {
            intro = <div style={{ padding: "1rem 0rem 0rem 0rem" }}>
                If you would like to receive notifications for
                a {Nls.get("SELECTOR_LABEL")}, you must be a member of 
                that {Nls.get("SELECTOR_LABEL")}
            </div>
        }
        return <>
            {intro}
            <div className="">
                <div className="">
                    {/* Homerooms */}
                    <div style={{marginBottom: "1rem", marginTop: "3px"}}>
                    {this.props.user && <Button sx={HRPButton} variant="contained" color="primary"
                        endIcon={<Add />}
                        style={{ width: "inherit", marginRight: "-.5rem", marginTop: ".8rem" }}
                        href={`/auth/${Nls.get("URL_PART")}/new`}>Create a new {Nls.get("SELECTOR_LABEL_LOWER")}</Button>}
                    </div>
                    {this.props.homerooms && this.props.homerooms.length > 0 && <>
                        <FormControl variant="standard">
                            <FormLabel id="homeroomSelectLabel">{Nls.get("SELECTOR_LABEL")} filter</FormLabel>
                            <RadioGroup
                                id="homeroomSelect"
                                value={this.state.hrSelection}
                                onChange={(event: any) => { 
                                    this.onSelection(event.target.value);
                                }}>
                                { this.props.homerooms.length > 1 && <FormControlLabel 
                                    sx={{marginBottom: "-8px"}}
                                    value="All" control={<Radio />} 
                                    label={<span style={{fontSize: ".8em"}}>Show all</span>} /> }
                                { this.props.homerooms.map(homeroom => (<>
                                    <div>
                                        <FormControlLabel 
                                            sx={{marginBottom: "-8px"}}
                                            key={homeroom._id} 
                                            value={homeroom._id} 
                                            control={<Radio />} 
                                            label={<span style={{fontSize: ".8em"}}>Grade {homeroom.grade} &mdash; {HomeroomService.hrClubName(homeroom) ? HomeroomService.hrClubName(homeroom) : "Unknown"}</span>} />
                                        {!demo && <span>
                                            <Tooltip placement="top" title={`Copy link to clipboard`}>
                                                <span>
                                                    <IconButton
                                                        style={{ width: "inherit", marginRight: ".5rem", marginTop: "1rem" }}
                                                        size="small"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(`${document.location.origin}?homeroomId=${homeroom._id}&code=${homeroom.code}`);
                                                        }}><Share style={{color: "#2F5043"}}/></IconButton>
                                                </span>
                                            </Tooltip>
                                        </span>}
                                        <span> { HomeroomService.canEdit(homeroom, this.props.user) && 
                                            <Tooltip placement="top" title={`${Nls.get("SELECTOR_LABEL")} settings`}>
                                                <span>
                                                    <IconButton
                                                        style={{ width: "inherit", marginRight: ".5rem", marginTop: "1rem" }}
                                                        size="small"
                                                        onClick={() => {
                                                            document.location.href = `/auth/${Nls.get("URL_PART")}/edit/${homeroom._id}`;
                                                        }}><Settings style={{color: "#2F5043"}}/></IconButton>
                                                </span>
                                            </Tooltip>}
                                        </span>
                                        <span> { this.props.user && !HomeroomService.isMember(homeroom, this.props.user) && 
                                            <Tooltip placement="top" title={`Become member of ${Nls.get("SELECTOR_LABEL")}`}>
                                                <span>
                                                    <IconButton
                                                        style={{ width: "inherit", marginRight: ".5rem", marginTop: "1rem" }}
                                                        size="small"
                                                        onClick={async () => {
                                                            homeroom.members.push({
                                                                email: this.props.user?.email!,
                                                                name: "",
                                                                userId: this.props.user?._id!,
                                                                title: "Parent/Guardian",
                                                                role: "READER"
                                                            });
                                                            await HomeroomService.updateHomeroom(homeroom);
                                                            await this.props.onHomerooms(this.props.homerooms);
                                                        }}><PersonAddAlt style={{color: "#2F5043"}}/></IconButton>
                                                </span>
                                            </Tooltip>}
                                        </span>
                                    </div>
                                </>))}
                            </RadioGroup>
                        </FormControl></>
                    }
                </div>
            </div>
        </>
    }
}

// Wrap and export
export default function HomeroomSelector(props: HomeroomSelectorProps) {
    const theme = useTheme();
  
    return <HomeroomSelectorClass {...props} theme={theme} />;
}