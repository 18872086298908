import React from "react";
import { IPTAccount } from "../../services/AccountV1";
import { IPTLocation } from "../../services/LocationV1";
import UserV1 from "../../../../services/UserV1";
import { clone } from "../../../../util/MiscUtil";
import ModalBasic from "../../../../components/ModalBasic";
import { 
    Button,
    Checkbox,
    RadioButton,
    RadioButtonGroup,
    SkeletonText,
    TextInput 
} from "@carbon/react";
import { Add, Checkmark, Close, Edit } from '@carbon/react/icons';

interface LocationAccessState {
    addingPrivilege: boolean,
    editingPrivilege: boolean,
    emails: {
        managers?: string[],
        staff?: string[]
    }
    addUserStr: string,
    addUserRole: string,
}

export class LocationAccess extends React.Component<{
    account?: IPTAccount,
    location?: IPTLocation,
    onLocation: (location: IPTLocation) => Promise<void>
}, LocationAccessState> {
    state: LocationAccessState = {
        addingPrivilege: false,
        addUserStr: "",
        addUserRole: "manager",
        editingPrivilege: false,
        emails: {},
    }

    componentDidMount() {
        (async () => {
            if (this.props.location) {
                let manEmails: string[] | undefined = undefined;
                let staffEmails: string[] | undefined = undefined;
                if (this.props.location?.managers) {
                    manEmails = await UserV1.idsToEmails(this.props.location.managers);
                }
                if (this.props.location?.staff) {
                    staffEmails = await UserV1.idsToEmails(this.props.location.staff);
                }
                this.setState({
                    emails: { managers: manEmails, staff: staffEmails }
                })
            }
        })();
    }

    componentDidUpdate(prevProps: Readonly<{ account?: IPTAccount | undefined; location?: IPTLocation | undefined; onLocation: (location: IPTLocation) => Promise<void>; }>, prevState: Readonly<LocationAccessState>, snapshot?: any): void {
        if (!prevProps.location && this.props.location) {
            this.componentDidMount();
        }
    }

    render() {
        let sortedMgrEmails = clone(this.state.emails?.managers)?.map((val, idx) => (
            {
                value: val,
                idx: idx
            }
        ))
        sortedMgrEmails?.sort((a, b) => a.value.localeCompare(b.value));
        let sortedStaffEmails = clone(this.state.emails?.staff)?.map((val, idx) => (
            {
                value: val,
                idx: idx
            }
        ))
        sortedStaffEmails?.sort((a, b) => a.value.localeCompare(b.value));

        return <>
            <div style={{ float: "right", marginTop: "-.5rem", marginRight: "-.5rem" }}>
                {!this.state.editingPrivilege && <>
                    {((this.props.location?.managers && this.props.location?.managers.length > 0)
                        || (this.props.location?.staff && this.props.location?.staff.length > 0)) &&
                        <Button size="sm" hasIconOnly renderIcon={Edit} iconDescription="Edit managers/staff" kind="ghost" tooltipAlignment="end" onClick={() => {
                            this.setState({ editingPrivilege: true });
                        }} />}
                    <Button size="sm" hasIconOnly renderIcon={Add} iconDescription="Add managers/staff" kind="ghost" tooltipAlignment="end" onClick={() => {
                        this.setState({ addingPrivilege: true });
                    }} />
                </>}

                {this.state.editingPrivilege && <>
                    <Button size="sm" hasIconOnly renderIcon={Checkmark} iconDescription="Confirm edit" kind="ghost" tooltipAlignment="end" onClick={async () => {
                        if (this.props.location) {
                            let locationUpdate = clone(this.props.location);
                            locationUpdate.managers = [];
                            locationUpdate.staff = [];
                            let locationEmails: { managers: string[], staff: string[] } = { managers: [], staff: [] };
                            for (let idx = 0; idx < this.props.location.managers.length; ++idx) {
                                let checkbox = document.getElementById(`mgrcheckbox_${idx}`)! as HTMLInputElement;
                                if (checkbox.checked) {
                                    locationUpdate.managers.push(this.props.location.managers[idx]);
                                    locationEmails.managers.push(this.state.emails.managers![idx]);
                                }
                            }
                            for (let idx = 0; idx < this.props.location.staff.length; ++idx) {
                                let checkbox = document.getElementById(`staffcheckbox_${idx}`)! as HTMLInputElement;
                                if (checkbox.checked) {
                                    locationUpdate.staff.push(this.props.location.staff[idx]);
                                    locationEmails.staff.push(this.state.emails.staff![idx]);
                                }
                            }
                            await this.props.onLocation(locationUpdate);
                            this.setState({
                                emails: locationEmails,
                                editingPrivilege: false
                            })
                        } else {
                            this.setState({ editingPrivilege: false })
                        }
                    }} />
                    <Button size="sm" hasIconOnly renderIcon={Close} iconDescription="Cancel edit" kind="ghost" tooltipAlignment="end" onClick={() => {
                        this.setState({ editingPrivilege: false })
                    }} />
                </>}
            </div>
            <div className="fields">
                <div><strong>Managers:</strong></div>
            </div>
            {(() => {
                if (!this.props.account || !this.props.location) {
                    return <SkeletonText />
                } else if (sortedMgrEmails && sortedMgrEmails.length > 0) {
                    return <>
                        {sortedMgrEmails.map((emailInfo) => (<>
                            <div>
                                {(this.state.editingPrivilege && <>
                                    <Checkbox id={`mgrcheckbox_${emailInfo.idx}`} labelText={emailInfo.value} defaultChecked={true} />
                                </>) || emailInfo.value}
                            </div>
                        </>))}
                    </>
                } else {
                    return <>No managers for this location...</>;
                }
            })()}
            <div className="fields" style={{ marginTop: "1rem" }}>
                <div><strong>Staff:</strong></div>
            </div>
            {(() => {
                if (!this.props.account || !this.props.location) {
                    return <SkeletonText />
                } else if (sortedStaffEmails && sortedStaffEmails.length > 0) {
                    return <>
                        {sortedStaffEmails.map((emailInfo) => (<>
                            <div>
                                {(this.state.editingPrivilege && <>
                                    <Checkbox id={`staffcheckbox_${emailInfo.idx}`} labelText={emailInfo.value} defaultChecked={true} />
                                </>) || emailInfo.value}
                            </div>
                        </>))}
                    </>
                } else {
                    return <>No staff for this location...</>
                }
            })()}
            {this.state.addingPrivilege && <ModalBasic
                open={this.state.addingPrivilege}
                heading="Add managers / staff"
                primaryButtonText="Add"
                isDisabled={() => !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.state.addUserStr)}
                onSubmit={async () => {
                    if (this.props.account) {
                        let [id] = await UserV1.emailsToIds([this.state.addUserStr]);
                        let locationUpdate = clone(this.props.location);
                        if (locationUpdate) {
                            let locationUpdateEmails = clone(this.state.emails) || {
                                managers: [],
                                staff: []
                            };
                            if ((document.getElementById("role-manager") as HTMLInputElement).checked) {
                                locationUpdateEmails.managers = locationUpdateEmails.managers || []
                                locationUpdateEmails.managers.push(this.state.addUserStr);
                                locationUpdate.managers = locationUpdate?.managers || [];
                                locationUpdate.managers.push(id);
                            }
                            if ((document.getElementById("role-staff") as HTMLInputElement).checked) {
                                locationUpdateEmails.staff = locationUpdateEmails.staff || []
                                locationUpdateEmails.staff.push(this.state.addUserStr);
                                locationUpdate.staff = locationUpdate?.staff || [];
                                locationUpdate.staff.push(id);
                            }
                            await this.props.onLocation(locationUpdate);
                            this.setState({ emails: locationUpdateEmails });
                        }
                    }
                }}
                onReset={async () => {
                    this.setState({ addUserStr: "" });
                }}
                onClose={async () => {
                    this.setState({ addingPrivilege: false })
                }}
            >
                <TextInput
                    data-modal-primary-focus
                    id="newPrivilege"
                    labelText="User email"
                    value={this.state.addUserStr}
                    onChange={(evt) => { this.setState({ addUserStr: evt.target.value }) }}
                />
                <div style={{ marginTop: "1rem" }} />
                <RadioButtonGroup legendText="Role" name="role" defaultSelected="manager" orientation="vertical">
                    <RadioButton labelText="Manager (can edit all location info)" value="manager" id="role-manager" />
                    <RadioButton labelText="Staff" value="staff" id="role-staff" />
                </RadioButtonGroup>
            </ModalBasic>}
        </>
    }
}