import React from 'react';
import { Column, Grid } from '@carbon/react';
import "./Login.scss";

export default class PWResetInvalid extends React.Component<{location?:any}> {
    render() {
        return (<>
            <main className="verifyLogin">
                <Grid>
                    <Column sm={4} md={8} lg={12}>
                        <p>The password reset code is invalid. You may have already
                            cancelled the password reset. Please check your email for a 
                            newer reset email, or <a href="/pwreset">start over</a>.</p>
                    </Column>
                </Grid>
            </main>
        </>)
    }
}