import React from 'react';
import { ApiUser } from '../../../../../../../server/src/hrpcb/models/apiUser';
import "./AuthFavorites.scss";
import { Button, CircularProgress, TextField } from '@mui/material';
import { ePageProfile } from '../../../App';
import { DBFavorites } from '../../../../../../../server/src/hrpcb/models/dbUser';
import { HRPButton } from '../../../components/widgets/Customized';
import { Helmet } from "react-helmet";
import Nls from '../../../nls/nls';
import { NavigateFunction, useNavigate } from 'react-router-dom';

interface AuthFavoritesProps {
    user?: ApiUser | null
    onUser: (user: ApiUser) => Promise<void>
    pageProfile: ePageProfile
}

interface AuthFavoritesState {
    favs?: DBFavorites
}

class AuthFavoritesCls extends React.Component<AuthFavoritesProps & { navigate: NavigateFunction }, AuthFavoritesState> {
    state: AuthFavoritesState = {
    }

    componentDidMount() {
        if (this.props.user) {
            this.setState({ favs: this.props.user.favorites || {
                color: "",
                flower: "",
                candy: "",
                drink: "",
                dessert: "",
                restaurant: "",
                store: "",
                scent: "",
                hobby: "",
                lunch: "",
                dinner: "",
                relax: "",
                giftcard: "",
                supplies: "",
                receivedgift: "",
                allergies: ""            
            } });
        }
    }

    componentDidUpdate(prevProps: AuthFavoritesProps) {
        if (!prevProps.user && this.props.user) {
            this.setState({ favs: this.props.user.favorites || {
                color: "",
                flower: "",
                candy: "",
                drink: "",
                dessert: "",
                restaurant: "",
                store: "",
                scent: "",
                hobby: "",
                lunch: "",
                dinner: "",
                relax: "",
                giftcard: "",
                supplies: "",
                receivedgift: "",
                allergies: ""            
            } });
        }
    }

    onUser(user: ApiUser) {
        return this.props.onUser(user);
    }

    onVal(key: string, val : string) {
        let newFav = JSON.parse(JSON.stringify(this.state.favs));
        newFav[key] = val;
        this.setState({ favs: newFav });
    }

    render() {
        const field = (label: string, field: string) => (
            <>
                <div style={{marginTop: ".5rem"}} />
                <TextField 
                    fullWidth 
                    label={label} 
                    value={(this.state.favs as any)[field]} 
                    onChange={(evt) => { 
                        this.onVal(field, evt.currentTarget.value)}
                    } 
                    onBlur={() => {
                        let newVal : ApiUser = JSON.parse(JSON.stringify(this.props.user));
                        newVal.favorites = this.state.favs;
                        this.props.onUser(newVal);
                    }}
                />
            </>
        );

        let form = <></>
        if (this.state.favs) {
            form = <form noValidate autoComplete="off">
                <div>
                    {field("Colors", "color")}
                    {field("Flowers/Plants", "flower")}
                    {field("Candy/Snacks", "candy")}
                    {field("Drinks", "drink")}
                    {field("Desserts", "dessert")}
                    {field("Places to eat", "restaurant")}
                    {field("Places to shop", "store")}
                    {field("Scent", "scent")}
                    {field("Activities/Hobbies", "hobby")}
                    {field("Lunch", "lunch")}
                    {field("Dinner", "dinner")}
                    {field("Way to Relax", "relax")}
                    {field("Gift cards", "giftcard")}
                    {field("Gift you have received", "receivedgift")}
                    {field("Supplies for your classroom", "supplies")}
                    {field("Do you have any allergies?", "allergies")}
                </div>
                <div style={{ marginTop: "2rem" }}></div>
                <Button sx={HRPButton} variant="contained" color="primary" onClick={() => {
                    this.props.navigate(`/auth/${Nls.get("URL_PART")}`);
                }}>Done</Button>
            </form>;
        }
        return <>
            <Helmet>
                <title>Favorites - {Nls.get("SITE_TITLE")}</title>
            </Helmet>
            <div style={{ marginTop: "2rem" }} />
            <div className="hrpRow">
                <div className="hrpCol-4-mob">
                    Share your favorite things with your classrooms to help them get you great gifts!
                </div>
            </div>
            <div style={{ marginTop: "1rem" }} />
            <div className="hrpRow">
                <div className="hrpCol-4-mob">
                    <h1>My Favorite...</h1>
                </div>
            </div>
            {/******************** Homeroom Selector ****************************************/}
            {(!this.props.user &&
                <CircularProgress />
            )
            || (this.props.pageProfile === "mobile" && <div className="mobileHome">
                <div className="hrpRow">
                    <div className="hrpCol-4-mob">
                        {form}
                    </div>
                </div>
            </div>)
            || <>
                <div className="hrpRow">
                    <div className="hrpCol-6-tab hrpCol-10-desk">
                        {form}
                    </div>
                </div>
            </>}
        </>
    }
}

export default function AuthFavorites(props: AuthFavoritesProps) {
    let navigate = useNavigate();
    return <AuthFavoritesCls navigate={navigate} {...props} />
}