import React from 'react';
import App from "../hrpcb/App";

const SiteHomeroomParent: React.FC = () => {
  return (
    <App />
  );
}

export default SiteHomeroomParent;
