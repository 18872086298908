
export interface IAccount {
    id: string
    name: string
    urlname: string
    phone?: string
    owners?: string[]
}

export class AccountV1 {
    static async getAccounts() : Promise<IAccount[]> {
        let result = await fetch(`/api/taken/account`);
        return result.json();
    }

    static async getAccount(id: string) : Promise<IAccount> {
        let result = await fetch(`/api/taken/account/${id}`);
        return result.json();
    }

    static async createAccount(newAccount: IAccount) : Promise<IAccount> {
        let result = await fetch(`/api/taken/account`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(newAccount)
        })
        return result.json();
    }

    static async updateAccount(updAccount: IAccount) : Promise<IAccount> {
        let result = await fetch(`/api/taken/account`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(updAccount)
        })
        return result.json();
    }

    static async deleteAccount(id: string) {
        await fetch(`/api/taken/account/${id}`, {
            method: "DELETE"
        })
    }
}