import React from 'react';
import Header from "./components/widgets/Header";
import Footer from "./components/widgets/Footer";
import {
    Location,
    Route,
    BrowserRouter as Router,
    Routes,
    useLocation,
    useParams
} from "react-router-dom";

import UserService from './services/UserService';
import HomeroomService from './services/HomeroomService';
import MDContent from './components/widgets/MDContent';

import Login from './pages/Login/Login';
import Unauthorized from "./pages/Unauthorized/Unauthorized";
import PWReset from './pages/Login/PWReset';
import VerifyLogin from './pages/VerifyLogin/VerifyLogin';

import NewHomeroom from './pages/auth/NewHomeroom/NewHomeroom';
import Homerooms from './pages/auth/Homerooms/Homerooms';
import AuthLinks from './pages/auth/Homerooms/AuthLinks';

import deepEqual from "deep-equal";
import AuthFavorites from './pages/auth/Favorites/AuthFavorites';
import AuthFavoritesUser from './pages/auth/Favorites/AuthFavoritesUser';
import SubmitIdea from './pages/auth/Ideas/SubmitIdea';
import PWResetSent from './pages/Login/PWResetSent';
import PWResetInvalid from './pages/Login/PWResetInvalid';
import PWResetPW from './pages/Login/PWResetPW';
import { Helmet } from "react-helmet";
import BasicStats from './pages/admin/BasicStats/BasicStats';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import HomeHRP from './pages/Home/HomeHRP';
import HomeClubs from './pages/Home/HomeClubs';
import Nls from './nls/nls';
import { ApiUser } from '../../../../server/src/hrpcb/models/apiUser';
import { ApiHomeroomClub } from '../../../../server/src/hrpcb/models/apiHomeroom';
import './index.scss';
import './App.scss';
import { ProtectLogin } from '../../components/ProtectLogin';

// declare module '@mui/material/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }


declare module "@mui/material/styles/createPalette" {
    interface Palette {
        header: {
            main: string,
            text: string,
            border: string
        }
    }
    interface PaletteOptions {
        header: {
            main: string,
            faded: string,
            text: string,
            border: string
        }
    }
}

const themeHRP = createTheme({
    palette: {
        primary: {
            main: "#2F5043"
        },
        header: {
            main: "#FFCC00",
            faded: "rgba(255, 204, 0, .1)",
            text: "#333333",
            border: "#DD9A00"
        }
    }
})

const themeClubs = createTheme({
    palette: {
        primary: {
            main: "#245E6B"
        },
        header: {
            main: "#153243",
            faded: "rgba(21, 50, 67, .1)",
            text: "#f4f4f4",
            border: "#2C698C"
        }
    }
})

const ErrorPage: React.FC = () => {
    return (<main>
        <div className="hrpRow">
            <div className="hrpCol-4-mob">
                <h1>Page not found</h1>
                <p>We're sorry, but you seem to have followed a link to a page that does not exist.</p>
            </div>
        </div>
    </main>);
}

export type ePageProfile = "mobile" | "tablet" | "desktop" | "desktoplg";

function getProfile() {
    let profile: ePageProfile = "mobile";
    let w = window.innerWidth;
    if (w < 768) {
        profile = "mobile";
    } else if (w < 992) {
        profile = "tablet";
    } else if (w < 1200) {
        profile = "desktop";
    } else {
        profile = "desktoplg";
    }
    return profile;
}

function getSite() {
    if (document.location.href.includes("9043")
        || document.location.href.includes("homeroomparent.com")
        || document.location.href.includes("homeroomparent.us-south.cf.appdomain.cloud")) {
        return "homeroomParent"
    } else {
        return "clubBooster";
    }
}

///////////////////////////////////////////////////////////////////////////////

interface AppState {
    user?: ApiUser | null
    homerooms?: ApiHomeroomClub[]
    pageProfile: ePageProfile
}

class AppCls extends React.Component<{ location: Location }, AppState> {
    state: AppState = {
        pageProfile: getProfile()
    }

    async getCurrentUser() {
        if (this.state.user) {
            return Promise.resolve(this.state.user);
        } else {
            return UserService.getUser();
        }
    }

    async componentDidMount() {
        this.initApp();
        window.addEventListener('resize', this.onWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    /**
     * Initialize the app
     */
    async initApp() {
        let user = this.state.user;
        if (!this.state.user) {
            user = await this.getCurrentUser();
        }
        // Use a blank homeroom as worst case
        let newState: AppState = {
            user: user,
            homerooms: [],
            pageProfile: this.state.pageProfile
        }
        // See if a homeroom id was specified
        const search = document.location.search;
        const params = new URLSearchParams(search);
        const homeroomId = params.get('homeroomId');
        const code = params.get('code');
        if (homeroomId && code) {
            let lookupHR = await HomeroomService.get(homeroomId, code);
            if (lookupHR) {
                newState.homerooms = [lookupHR];
            }
        }
        // If no homeroom loaded, see if we're logged in and fetch homerooms
        if (user) {
            let userHomerooms = await HomeroomService.getByUser();
            if (newState.homerooms!.length > 0) {
                userHomerooms = userHomerooms.filter(hr => hr._id !== newState.homerooms![0]._id);
            }
            newState.homerooms = newState.homerooms!.concat(userHomerooms);
        }
        // Else start with a blank homeroom
        if (!user && newState.homerooms!.length === 0) {
            let id = Nls.get("DEMO_ID");
            let lookupHR = await HomeroomService.get(id, id);
            if (lookupHR) {
                newState.homerooms = [lookupHR];
            }
        }
        for (const hr of newState.homerooms!) {
            hr.wishes.sort((a, b) => {
                if (a.remaining === 0 && b.remaining === 0) return a.name.localeCompare(b.name);
                if (a.remaining === 0) return 1;
                if (b.remaining === 0) return -1;
                return a.name.localeCompare(b.name);
            })
        }

        this.setState(newState);
    }

    /**
     * Manages the app size state
     */
    onWindowResize = () => {
        let newProfile = getProfile();
        if (newProfile !== this.state.pageProfile) {
            this.setState({ pageProfile: newProfile });
        }
    }

    logout(evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        evt.preventDefault();
        fetch(`/api/common/user/logout`)
            .then(() => {
                document.location.href = "/";
                // self.setState({ user: null, homerooms: [JSON.parse(JSON.stringify(emptyHomeroom))] })
                // this.props.history.push("/");
            })
            .catch((err) => {
                console.error(err);
            })
        return false;
    }

    async onHomeroom(homeroom: ApiHomeroomClub) {
        let update = await HomeroomService.updateHomeroom(homeroom);
        let stateUpdate = {
            homerooms: JSON.parse(JSON.stringify(this.state.homerooms))
        }
        let found = false;
        for (let idx = 0; idx < stateUpdate.homerooms.length; ++idx) {
            if (stateUpdate.homerooms[idx]._id === homeroom._id) {
                found = true;
                stateUpdate.homerooms[idx] = update;
            }
        }
        if (!found) {
            stateUpdate.homerooms.push(update);
        }
        this.setState({ homerooms: stateUpdate.homerooms });
    }

    async onHomerooms(homerooms: ApiHomeroomClub[]) {
        let hrMap: { [id: string]: ApiHomeroomClub } = {}
        if (homerooms) {
            for (const hr of homerooms) {
                hrMap[hr._id] = hr;
            }
        }
        let updates = await Promise.all(this.state.homerooms!.map((homeroom) => {
            if (homeroom._id in hrMap) {
                if (deepEqual(homeroom, hrMap[homeroom._id])) {
                    return Promise.resolve(homeroom);
                } else {
                    return HomeroomService.updateHomeroom(hrMap[homeroom._id])
                }
            } else {
                return Promise.resolve(homeroom);
            }
        }));
        this.setState({ homerooms: updates });
    }

    render() {
        const classMap = (path: string): string => {
            if (path === "/") {
                return `themeHome hrpcb ${getSite()}`;
            } else if (path.includes("/content")) {
                return `themeContent hrpcb ${getSite()}`;
            }
            return `themeDefault hrpcb ${getSite()}`;
        }
        const themeMap = () => {
            if (getSite() === "homeroomParent") {
                return themeHRP;
            } else {
                return themeClubs;
            }
        }
        let bTest = document.location.href.includes("localhost");
        return <>
            {!bTest && <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-FZ80M7731D"></script>
                <script>{`
                    window.dataLayer = window.dataLayer || [];
                    function gtag() { dataLayer.push(arguments); }
                    gtag('js', new Date());
                    gtag('config', 'G-FZ80M7731D');
                `}</script>
            </Helmet>}
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themeMap()}>
                    <div className={classMap(this.props.location.pathname)}>
                        <Header
                            user={this.state.user}
                            homerooms={this.state.homerooms}
                            pageProfile={this.state.pageProfile}
                            logout={this.logout.bind(this)}
                        />
                        <Routes>
                            <Route path="/hrp/about" element={<HomeHRP />} />
                            <Route path="/club/about" element={<HomeClubs />} />
                            {/* {getSite() === "homeroomParent" && <Route path="/" component={HomeHRP} />} */}
                            {/* {getSite() === "clubBooster" && <Route path="/" component={HomeClubs} />} */}
                            <Route path="/content/*" element={<MDContent />} />
                            <Route path="/admin/basic_stats" element={
                                <ProtectLogin roles={["ADMIN"]}>
                                    <BasicStats
                                        user={this.state.user}
                                        pageProfile={this.state.pageProfile}
                                    />
                                </ProtectLogin>
                            } />
                            <Route path="/auth/favorites" element={
                                <ProtectLogin>
                                    <AuthFavorites
                                        user={this.state.user}
                                        onUser={async (user: ApiUser) => {
                                            UserService.updateFavs(user);
                                        }}
                                        pageProfile={this.state.pageProfile}
                                    />
                                </ProtectLogin>
                            } />
                            <Route path="/auth/favorites/00000000-0000-0000-0000-000000000000/:teacherId" element={
                                <ProtectLogin>
                                    <AuthFavoritesUserWrap
                                        user={this.state.user!}
                                        homerooms={this.state.homerooms?.filter(hr => hr._id === "00000000-0000-0000-0000-000000000000")}
                                        teacherId={(params) => params.teacherId!}
                                        pageProfile={this.state.pageProfile}
                                    />
                                </ProtectLogin>
                            } />
                            <Route path="/auth/favorites/00000000-0000-0000-0000-000000000001/:teacherId" element={
                                <ProtectLogin>
                                    <AuthFavoritesUserWrap
                                        user={this.state.user!}
                                        homerooms={this.state.homerooms?.filter(hr => hr._id === "00000000-0000-0000-0000-000000000001")}
                                        teacherId={(params) => params.teacherId!}
                                        pageProfile={this.state.pageProfile}
                                    />
                                </ProtectLogin>
                            } />
                            <Route path="/auth/favorites/:hrId/:teacherId" element={
                                <ProtectLogin>
                                    <AuthFavoritesUserWrap
                                        user={this.state.user!}
                                        homerooms={(params) => this.state.homerooms?.filter(hr => hr._id === params.hrId)}
                                        teacherId={(params) => params.teacherId!}
                                        pageProfile={this.state.pageProfile}
                                    />
                                </ProtectLogin>
                            } />
                            <Route path="/auth/hrp" element={
                                <ProtectLogin>
                                    <Homerooms
                                        user={this.state.user}
                                        homerooms={this.state.homerooms}
                                        onHomerooms={this.onHomerooms.bind(this)}
                                        pageProfile={this.state.pageProfile}
                                    />
                                </ProtectLogin>
                            } />
                            <Route path="/" element={
                                <Homerooms
                                    user={this.state.user}
                                    homerooms={this.state.homerooms}
                                    onHomerooms={this.onHomerooms.bind(this)}
                                    pageProfile={this.state.pageProfile}
                                />
                            } />
                            <Route path="/auth/hrp/links" element={
                                <ProtectLogin>
                                    <AuthLinks
                                        user={this.state.user}
                                        homerooms={this.state.homerooms}
                                        onHomerooms={this.onHomerooms.bind(this)}
                                        pageProfile={this.state.pageProfile}
                                    />
                                </ProtectLogin>
                            } />
                            <Route path="/auth/hrp/new" element={
                                <ProtectLogin>
                                    {(this.state.user && <NewHomeroom user={this.state.user} />) || <></>}
                                </ProtectLogin>
                            } />
                            <Route path="/auth/hrp/edit/:homeroomId" element={
                                <ProtectLogin>
                                    {(this.state.user
                                        && <NewHomeroomWrap user={this.state.user} homeroomId={(params) => params.homeroomId} /> 
                                    ) || <></>}
                                </ProtectLogin>
                            } />
                            <Route path="/auth/hrp/submit_idea" element={
                                <ProtectLogin>
                                    <SubmitIdea
                                        user={this.state.user!}
                                        pageProfile={this.state.pageProfile}
                                    />
                                </ProtectLogin>
                            } />
                            <Route path="/auth/club" element={
                                <ProtectLogin>
                                    <Homerooms
                                        user={this.state.user}
                                        homerooms={this.state.homerooms}
                                        onHomerooms={this.onHomerooms.bind(this)}
                                        pageProfile={this.state.pageProfile}
                                    />
                                </ProtectLogin>
                            } />
                            <Route path="/auth/club/links" element={
                                <ProtectLogin>
                                    <AuthLinks
                                        user={this.state.user}
                                        homerooms={this.state.homerooms}
                                        onHomerooms={this.onHomerooms.bind(this)}
                                        pageProfile={this.state.pageProfile}
                                    />
                                </ProtectLogin>
                            } />
                            <Route path="/auth/club/new" element={
                                <ProtectLogin>
                                    <NewHomeroom user={this.state.user!} />
                                </ProtectLogin>
                            } />
                            <Route path="/auth/club/edit/:homeroomId" element={
                                <ProtectLogin>
                                    <NewHomeroomWrap user={this.state.user!} homeroomId={(params) => params.homeroomId} />
                                </ProtectLogin>
                            } />
                            <Route path="/auth/club/submit_idea" element={
                                <ProtectLogin>
                                    <SubmitIdea
                                        user={this.state.user!}
                                        pageProfile={this.state.pageProfile}
                                    />
                                </ProtectLogin>
                            } />
                            <Route path="/login" element={<Login />} />
                            <Route path="/unauthorized" element={<Unauthorized />} />
                            <Route path="/pwreset" element={<PWReset />} />
                            <Route path="/pwreset_sent" element={<PWResetSent />} />
                            <Route path="/pwreset_invalid" element={<PWResetInvalid />} />
                            <Route path="/pwreset_pw" element={<PWResetPW />} />
                            <Route path="/verifying" element={
                                <VerifyLogin
                                    user={this.state.user}
                                    onUser={async () => {
                                        let user = await this.getCurrentUser();
                                        this.setState({ user: user })
                                    }}
                                    getUser={this.getCurrentUser.bind(this)}
                                />}
                            />
                            <Route path="/*" element={<ErrorPage />} />
                        </Routes>
                        <Footer />
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        </>;
    }
}
export default function App() {
    return <Router>
        <AppWrap />
    </Router>
}

function AppWrap() {
    let location = useLocation();
    return <AppCls location={location} />
}

function AuthFavoritesUserWrap(props: any) {
    let newProps: any = {};
    let params = useParams();
    for (const key in props) {
        if (typeof props[key] === "function") {
            newProps[key] = props[key](params);
        } else {
            newProps[key] = props[key];
        }
    }
    return <AuthFavoritesUser {...newProps} />
}

function NewHomeroomWrap(props: any) {
    let newProps: any = {};
    let params = useParams();
    for (const key in props) {
        if (typeof props[key] === "function") {
            newProps[key] = props[key](params);
        } else {
            newProps[key] = props[key];
        }
    }
    return <NewHomeroom {...newProps} />    
}