import { Column } from '@carbon/react';
import { Grid } from '@carbon/react';
import React from 'react';
import { Helmet } from "react-helmet";

export default class Unauthorized extends React.Component<{location?:any}> {
    render() {
        return (<>
        <Helmet>
            <title>Unauthorized</title>
        </Helmet>
        <main>
            <Grid>
                <Column sm={4}>
                    <h1>Unauthorized</h1>
                </Column>
            </Grid>
        </main>
        </>)
    }
}