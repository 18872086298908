import { ApiFeedback } from "../../../../../server/src/hrpcb/models/apiFeedback";
import Nls from "../nls/nls";

export default class FeedbackService {
    static async sendFeedback(feedback: ApiFeedback) : Promise<boolean> {
        let result = await fetch(`/api/feedback?site=${Nls.get("URL_PART")}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(feedback)
        });
        let retVal = await result.json();
        return retVal.ok;
    }
}