import FullCalendar from '@fullcalendar/react';
import { Button, Dialog, DialogActions, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { ApiHomeroomClub } from '../../../../../../server/src/hrpcb/models/apiHomeroom';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import listWeekPlugin from '@fullcalendar/list';

interface DialogMonthProps {
    evtSources: Array<{ googleCalendarId: string }>
    open: boolean,
    onClose: (homeroom?: ApiHomeroomClub) => void
}

export default function DialogMonth(props: DialogMonthProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return <div>
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={() => props.onClose()}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
            <FullCalendar
                plugins={[googleCalendarPlugin, dayGridPlugin, listWeekPlugin]}
                initialView="dayGridMonth"
                googleCalendarApiKey="AIzaSyCUkWy8gF0_9gNns5D6uPGc5kt6q_qlM40"
                eventSources={props.evtSources}
                height="auto"
            />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}