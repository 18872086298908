import { IconButton, Menu, MenuItem, Theme, useTheme } from '@mui/material';
import React from 'react';
import { ApiHomeroomClub } from '../../../../../../server/src/hrpcb/models/apiHomeroom';
import { ApiUser } from '../../../../../../server/src/hrpcb/models/apiUser';
import { ePageProfile } from '../../App';
import UserService from '../../services/UserService';
import HRPIcon from "../../images/hrpicon.svg";
import ClubIcon from "../../images/clubicon.svg";
import './Header.scss';
import Nls from '../../nls/nls';
import MenuIcon from '@mui/icons-material/Menu';
import { MenuOpen } from '@mui/icons-material';

interface HeaderProps {
    user?: ApiUser | null
    homerooms?: ApiHomeroomClub[]
    logout: (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void 
    pageProfile: ePageProfile
}

interface HeaderState {
    userMenu: Node | null
    anchorElement: HTMLElement | null
}

class HeaderClass extends React.Component<HeaderProps & { theme: Theme }, HeaderState> {
    state = {
        userMenu: null,
        anchorElement: null
    }

    changePassword() {
        UserService.changePassword();
    }

    render() {
        const showFavs = !!this.props.homerooms 
            && this.props.homerooms.some((hr) => {
                return hr.members.filter(mem => (
                    mem.userId === this.props.user?._id && mem.title === "Teacher")).length > 0;
            })
            && !!this.props.user;
        
        let profileItems: Array<React.JSX.Element> = [];
        if (this.props.user?.login_methods.includes("local")) {
            profileItems.push(<MenuItem onClick={() => {
                    this.changePassword();
                }}>
                    Change password
            </MenuItem>);
        }
        if (showFavs) {
            profileItems.push(<MenuItem onClick={() => {
                    document.location.href = "/auth/favorites";
                }}>
                    My favorite things
            </MenuItem>);
        }

        const fontColor = Nls.get("URL_PART") === "hrp" ? "#333" : "#F4F4F4";
        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            this.setState({ anchorElement: event.currentTarget });
        };
        const handleClose = () => {
            this.setState({ anchorElement: null });
        };
        const open = !!this.state.anchorElement;
        const hamburgerMenu = <>
            <IconButton
                style={{ 
                    marginLeft: this.props.pageProfile === "mobile" ? "-.7rem" : "-.5rem", 
                    marginRight: this.props.pageProfile === "mobile" ? "0rem" : ".3rem", 
                    marginTop: "-.3rem "}}
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                { open ? <MenuOpen style={{ color: fontColor }} /> : <MenuIcon style={{ color: fontColor }} /> }
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                'aria-labelledby': 'long-button',
                }}
                anchorEl={this.state.anchorElement}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => { handleClose(); document.location.href = `/${Nls.get("URL_PART")}/about`; }}>
                    Overview
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); document.location.href = `/content/${Nls.get("URL_PART")}/overview`; }}>
                    Welcome
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); document.location.href = `/content/${Nls.get("URL_PART")}/about`; }}>
                    About Us
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); document.location.href = `/auth/${Nls.get("URL_PART")}/submit_idea`; }}>
                    Contact Us
                </MenuItem>
            </Menu>
        </>;
        return (
            <header className="mainHeader" style={{backgroundColor: this.props.theme.palette.header.main}}>
                <div className="hrpRow">
                    <div className="hrpCol-2-mob hrpCol-4-tab">
                        { hamburgerMenu }
                        { this.props.pageProfile !== "mobile" && Nls.get("URL_PART") === "hrp" && <img src={HRPIcon} alt="" style={{height: "1.25rem", marginRight: ".5rem"}} /> }
                        { this.props.pageProfile !== "mobile" && Nls.get("URL_PART") === "club" && <img src={ClubIcon} alt="" style={{height: "1.25rem", marginRight: ".5rem"}} /> }
                        <a href="/" className="cleanLink name" style={{color: this.props.theme.palette.header.text}}>{Nls.get("SITE_TITLE")}</a>
                    </div>
                    <div className="hrpCol-2-mob hrpCol-4-tab">
                        <div className="headLogin">
                        {/* {this.props.pageProfile}&nbsp;&nbsp;&nbsp; */}
                            {((this.props.user
                            && <>
                            {(profileItems.length > 0 && <>
                                <a className="cleanLink" href="about:blank" onClick={(evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => { 
                                    this.setState({ userMenu: evt.currentTarget });
                                    evt.preventDefault();
                                    return false;
                                }} style={{color: this.props.theme.palette.header.text}}>
                                <span title={this.props.user.email}>Profile</span>
                                <svg style={{fill: this.props.theme.palette.header.text, marginLeft: ".5rem", marginRight: "1rem"}} id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                    <defs></defs><title>{this.props.user.email}</title><path d="M16,4a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0-2a7,7,0,1,0,7,7A7,7,0,0,0,16,2Z"/><path d="M26,30H24V25a5,5,0,0,0-5-5H13a5,5,0,0,0-5,5v5H6V25a7,7,0,0,1,7-7h6a7,7,0,0,1,7,7Z"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" style={{fill:"none"}} width="32" height="32"/>
                                </svg>
                                </a>
                                { <Menu
                                    id="userMenu" 
                                    anchorEl={this.state.userMenu} 
                                    keepMounted
                                    open={!!this.state.userMenu} 
                                    onClose={() => { this.setState({userMenu: null})}}
                                >
                                    {profileItems}
                                </Menu> }
                            </>) ||  <>
                                <span title={this.props.user.email} style={{color: this.props.theme.palette.header.text}}>Profile</span>
                                <svg style={{fill: this.props.theme.palette.header.text, marginLeft: ".5rem", marginRight: "1rem"}} id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                    <defs></defs><title>{this.props.user.email}</title><path d="M16,4a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0-2a7,7,0,1,0,7,7A7,7,0,0,0,16,2Z"/><path d="M26,30H24V25a5,5,0,0,0-5-5H13a5,5,0,0,0-5,5v5H6V25a7,7,0,0,1,7-7h6a7,7,0,0,1,7,7Z"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" style={{fill:"none"}} width="32" height="32"/>
                                </svg>
                            </>}
                            <a className="cleanLink" href="about:blank" onClick={(evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => { this.props.logout(evt); }} style={{color: this.props.theme.palette.header.text}}>Logout
                                <svg style={{fill: this.props.theme.palette.header.text}} id="icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                    <title>logout</title>
                                    <path d="M6,30H18a2.0023,2.0023,0,0,0,2-2V25H18v3H6V4H18V7h2V4a2.0023,2.0023,0,0,0-2-2H6A2.0023,2.0023,0,0,0,4,4V28A2.0023,2.0023,0,0,0,6,30Z"/><polygon points="20.586 20.586 24.172 17 10 17 10 15 24.172 15 20.586 11.414 22 10 28 16 22 22 20.586 20.586"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" style={{fill:"none"}} width="32" height="32"/>
                                </svg>
                            </a>
                            </>)
                            || <>
                            <a className="cleanLink" href={`/login`} style={{color: this.props.theme.palette.header.text}}>Login 
                                <svg style={{fill: this.props.theme.palette.header.text}} id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                    <title>login</title>
                                    <path d="M26,30H14a2,2,0,0,1-2-2V25h2v3H26V4H14V7H12V4a2,2,0,0,1,2-2H26a2,2,0,0,1,2,2V28A2,2,0,0,1,26,30Z"/><polygon points="14.59 20.59 18.17 17 4 17 4 15 18.17 15 14.59 11.41 16 10 22 16 16 22 14.59 20.59"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" style={{fill:"none"}} width="32" height="32"/>
                                </svg>
                            </a>
                            </>)}
                        </div> 
                    </div>
                </div>
            </header>
        );
    }
}

// Wrap and export
export default function Header(props: HeaderProps) {
    const theme = useTheme();
  
    return <HeaderClass {...props} theme={theme} />;
}