import React from 'react';
import Nls from '../../nls/nls';

interface InfoSignupsProps {
}

export default function InfoSignups(_props: InfoSignupsProps) {

    return <>
<p>Start with Signups by opening the "Add" dialog.
If another {Nls.get("SELECTOR_LABEL_LOWER")} has already created the event, they can
share that event with your {Nls.get("SELECTOR_LABEL_LOWER")} using a "Share code".
Otherwise, select a {Nls.get("SELECTOR_LABEL_LOWER")}, name your event, optionally,
provide a URL, and "Add". Once the event is created, you can share it and obtain the Share code.
You may also start adding sign-ups with the "Add sign-up" button.</p>

<p>When adding a sign-up, first specify the name of the shift and 
how many people you'd like to sign-up. Then, select the days that
the shift is needed. If you need a shift every Monday for the month,
click the checkbox above Monday. You can add or remove days by
clicking on them individually.</p>

<p>Once you have selected the needed days, you can start adding 
start and end times. Once you have specified the days and times,
add the sign-up to allow people to start signing up.</p>
    </>;
}
