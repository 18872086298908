
import React from 'react';
import { Add } from '@mui/icons-material';
import { HRPButton } from '../../../components/widgets/Customized';
import { ApiHomeroom, ApiHomeroomClub } from '../../../../../../../server/src/hrpcb/models/apiHomeroom';
import { ApiUser } from '../../../../../../../server/src/hrpcb/models/apiUser';
import "./AuthLinks.scss";
import { Button, CircularProgress } from '@mui/material';
import { ePageProfile } from '../../../App';
import Tile from '../../../components/widgets/Tile';
import { Helmet } from "react-helmet";
import Nls from '../../../nls/nls';

interface AuthLinksProps {
    user?: ApiUser | null
    homerooms?: ApiHomeroomClub[]
    onHomerooms: (homerooms: ApiHomeroomClub[]) => Promise<void>
    pageProfile: ePageProfile
}

interface AuthLinksState {
    hrFilt: string[]
    mobileSection: "links" | "news" | "calendar" | "signups" | "wishes" | "ideas" | "none",
    hideSnack: boolean
}

type CatEntry = {
    category: string,
    labelList: Array<{
        label: string
        urls: string[]
    }>
}

type SchoolClassLinkMap = {
    [schoolId: string]: {
        [category : string] : {
            [label: string] : string[]
        }
    }
}

export default class AuthLinks extends React.Component<AuthLinksProps, AuthLinksState> {
    state: AuthLinksState = {
        hrFilt: [],
        mobileSection: "links",
        hideSnack: sessionStorage.getItem('hideSnack') === "true"
    }

    componentDidMount() {
        if (this.props.homerooms) {
            this.setState({hrFilt: this.props.homerooms.map((homeroom) => homeroom._id)});
        }
    }

    componentDidUpdate(prevProps: AuthLinksProps) {
        if (this.props && this.props.homerooms) {
            if (!prevProps || !prevProps.homerooms) {
                this.setState({hrFilt: this.props.homerooms.map((homeroom) => homeroom._id)});
            }
        }
    }

    onHomerooms(homerooms: ApiHomeroomClub[]) {
        return this.props.onHomerooms(homerooms);
    }


    getLinkList(selHrs: ApiHomeroomClub[]): CatEntry[][] {
        const linkMap: SchoolClassLinkMap = {}
        for (const hrClub of selHrs) {
            for (const cat in hrClub.links) {
                for (const label in hrClub.links[cat]) {
                    let urls = hrClub.links[cat][label];
                    if (urls.length > 0) {
                        let hr = hrClub as ApiHomeroom;
                        const schoolId = hr.schoolId || "NA";
                        linkMap[schoolId] = linkMap[schoolId] || {};
                        linkMap[schoolId][cat] = linkMap[schoolId][cat] || {}
                        linkMap[schoolId][cat][label] = linkMap[schoolId][cat][label] || [];
                        for (const url of urls) {
                            if (!linkMap[schoolId][cat][label].includes(url) && url.trim().length > 0) {
                                linkMap[schoolId][cat][label].push(url);
                            }
                        }
                    }
                }
            }
        }

        const mainLinksNames = [
            "School", "District", "PTA/PTO", "Class"
        ]
        const retVal: CatEntry[][] = [];
        for (const schoolId in linkMap) {
            let linkList : CatEntry[] = [];
            for (const cat in linkMap[schoolId]) {
                let catInfo: CatEntry = {
                    category: cat,
                    labelList: []
                }
                for (const label in linkMap[schoolId][cat]) {    
                    if (!(mainLinksNames.includes(label) || label.includes("favorites"))) {
                        catInfo.labelList.push({
                            label: label,
                            urls: linkMap[schoolId][cat][label].length > 0 ? linkMap[schoolId][cat][label] : [""]
                        })
                    }
                }
                for (const label in linkMap[schoolId][cat]) {    
                    if (mainLinksNames.includes(label) || label.includes("favorites")) {
                        catInfo.labelList.push({
                            label: label,
                            urls: linkMap[schoolId][cat][label].length > 0 ? linkMap[schoolId][cat][label] : [""]
                        })
                    }
                }
                linkList.push(catInfo);
            }
            retVal.push(linkList);
        }
        return retVal;
    }

    render() {
        const linkList = this.props.homerooms ? this.getLinkList(this.props.homerooms) : [];
        let tileCount = (this.props.pageProfile === "desktop" || this.props.pageProfile === "desktoplg") ? 4 : 2;
        return <div style={{ margin: "1rem" }}>
            <Helmet>
                <title>Links - {Nls.get("SITE_TITLE")}</title>
            </Helmet>
            <div className="hrpRow">
                <div className="hrpCol-4-mob hrpCol-6-tab">
                    <div><a href={`/auth/${Nls.get("URL_PART")}/`}>&lt; Back to {Nls.get("SELECTOR_LABELS_LOWER")}</a></div>
                </div>
            </div>
            <div className="hrpRow" style={{marginTop: "1rem"}}>
                <div className="hrpCol-4-mob hrpCol-6-tab hrpCol-8-desk">
                    <>This page shows the links added to all of the {Nls.get("SELECTOR_LABELS_LOWER")} for which you
                    are a member. </>
                    { (Nls.get("SELECTOR_LABELS_LOWER") === "homerooms" && <>Each panel represents the link for a single school. Some examples of links you might see here: school nurse, librarian, yearbook, 
                    volunteer registration, medical forms, school supplies, etc. </>) || <>
                        Some examples of links you might see here: yearbook, volunteer registration, medical forms, team store, etc.
                    </>} 
                    
                </div>
            </div>
            {/******************** Homeroom Selector ****************************************/}
            {(!this.props.homerooms &&
                <CircularProgress />
            )
            || (this.props.homerooms!.length === 0 && <>
                {/* No homerooms */}
                <div style={{ marginTop: "3rem" }} />
                <div className="hrpRow">
                    <div className="hrpCol-4-mob hrpCol-6-tab">
                        <p style={{fontSize: "1.25em"}}>You are not yet associated with any homerooms. </p>
                        <p style={{fontSize: "1.25em"}}>If you are trying to join an existing homeroom, please reach out to your homeroom’s
                            administrator and ask to be added. Otherwise, if you want to get started with a new homeroom...</p>
                        <Button sx={HRPButton} variant="contained" color="primary"
                            endIcon={<Add />}
                            style={{ width: "inherit", marginTop: "1.5rem" }}
                            href={`/auth/${Nls.get("URL_PART")}/new`}>Create a {Nls.get("SELECTOR_LABEL_LOWER")}</Button>
                    </div>
                </div>
            </>)
            || (<>
                <div className="hrpRow">
                   { linkList.map((schoolLinkList: CatEntry[], index: number) => (
                        <div className="hrpCol-2-mob hrpCol-4-tab hrpCol-4-desk">
                            <Tile dark style={{height: "calc(100% - 1rem)", marginRight: `${index%tileCount===tileCount-1?"-1rem":"0rem"}`}}>
                            { schoolLinkList.map((catEntry: CatEntry) => {
                                return <React.Fragment key={`ce_${catEntry.category}`}>
                                    <div style={{ fontSize: ".8em" }}>{catEntry.category}</div>
                                    <ul style={{ marginTop: "0rem" }}>
                                        { catEntry.labelList.map((labelInfo) => (<React.Fragment key={`scli_${labelInfo.label}`}>
                                            { labelInfo.urls.map((url, index) => {
                                                return (<React.Fragment key={`scli2_${index}`}>
                                                    { (url.trim().length === 0 && <li>{labelInfo.label} (link missing)</li>)
                                                    || <li key={`scli_${labelInfo.label}`}><a className="regLink" href={url}>{labelInfo.label}</a></li>}
                                                </React.Fragment>)
                                            })}
                                        </React.Fragment>))}
                                    </ul>
                                </React.Fragment>
                            })}
                            </Tile>
                        </div>
                    ))}
                </div>
            </>)}
        </div>
    }
}