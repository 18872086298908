import React from 'react';
import { TextInput, Button, Grid, Column } from '@carbon/react';
import { SiteKey } from '../../../../models/Site';

export default class PWReset extends React.Component<{ location?: any, siteKey: SiteKey }> {
    state = {
        email: ""
    }

    changeEmail(event: any) {
        this.setState({ email: event.target.value });
    };

    async requestReset() {
        await fetch(`/api/common/user/pwreset?site=${this.props.siteKey}`, {
            headers: {
                "Content-type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(
                {
                    email: this.state.email
                })
        });
        document.location.href = `/pwreset_sent?email=${this.state.email}`;
        return true;
    }

    render() {
        const validPWReset = this.state.email.trim().length > 0
            && this.state.email.trim().match(/.*@.*\..*/);
        return <>
            <main>
                <Grid>
                    <Column sm={3} md={4} lg={6} style={{ marginTop: "1rem" }}>
                        <div><h2>Reset password</h2></div>
                        <div style={{paddingTop: "2rem"}} />
                        <div onKeyDown={async (evt: any) => {
                            if (evt.key === "Enter" && validPWReset) {
                                await this.requestReset();
                            }
                        }}>
                            <div><TextInput labelText="Email" type="email"
                                value={this.state.email} onChange={this.changeEmail.bind(this)} /></div>
                        </div>
                        <div style={{ marginTop: "40px" }}><Button disabled={!validPWReset} onClick={async () => {
                            await this.requestReset();
                        }}>Request password reset</Button></div>
                    </Column>
                </Grid>
            </main></>;
    }
}