import { ApiUser } from "../../../../../server/src/hrpcb/models/apiUser";

export default class UserService {
    static async getUser() : Promise<ApiUser | null> {
        let result = await fetch(`/api/common/user`);
        if (result.status === 204 || result.statusText === "No Content") {
            return null;
        } else {
            return result.json();
        }
    }

    static async changePassword()  {
        let result = await fetch(`/api/common/user/changepassword`);
        if (!(result.status === 204 || result.statusText === "No Content")) {
            let res = await result.json();
            if (res.ok) {
                let code = res.code;
                let userId = res.userId;
                document.location.href = `/api/common/user/reset_confirm?userId=${userId}&code=${code}`;
            }
        }
    }

    static async updateFavs(user: ApiUser) {
        let result = await fetch(`/api/common/user`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        });
        return await result.json();
    }
}