import React from 'react';
import { ApiUser } from '../../../../../../../../server/src/hrpcb/models/apiUser';
import { ApiClub, ApiGoal, ApiHomeroomClub } from '../../../../../../../../server/src/hrpcb/models/apiHomeroom';
import "./SectionGoals.scss";
import { Add, ExpandMore, Flag } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, LinearProgress, Tooltip } from '@mui/material';
import HomeroomService from '../../../../services/HomeroomService';
import ModalGoalAddEdit from './ModalGoalAddEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Nls from '../../../../nls/nls';
import InfoButton from '../../../../components/info/InfoButton';
import InfoGoals from '../../../../components/info/InfoGoals';

interface SectionGoalsMobileProps {
    user: ApiUser
    homerooms: ApiHomeroomClub[]
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
    expanded: boolean
    onToggle: () => void
}

export function SectionGoalsMobile(props: SectionGoalsMobileProps) {
    return <SectionGoalsClass {...props} />
}

interface SectionGoalsDesktopProps {
    user: ApiUser
    homerooms: ApiHomeroomClub[]
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
}

export function SectionGoalsDesktop(props: SectionGoalsDesktopProps) {
    return <SectionGoalsClass {...props} />
}

interface SectionGoalsState {
    dialogAdd: boolean
    dialogEdit: (ApiGoal & { homeroomId: string }) | null
    dialogDelete: boolean
}

interface SectionGoalsClassProps {
    user: ApiUser
    homerooms: ApiHomeroomClub[]
    onHomerooms: (homeroom: ApiHomeroomClub[]) => Promise<void>
    expanded?: boolean,
    onToggle?: () => void,
}

export function isHomeroom(hrClub: ApiHomeroomClub) : boolean {
    return !!(hrClub.schoolId && hrClub.schoolId.length > 0);
}

class SectionGoalsClass extends React.Component<SectionGoalsClassProps, SectionGoalsState> {
    state: SectionGoalsState = {
        dialogAdd: false,
        dialogEdit: null,
        dialogDelete: false
    }

    async onGoal(homeroomId: string, newGoal: ApiGoal) {
        let update: ApiHomeroomClub[] = JSON.parse(JSON.stringify(this.props.homerooms));
        for (let idx = 0; idx < update.length; ++idx) {
            if (update[idx]._id === homeroomId) {
                let found = false;
                update[idx].goals = update[idx].goals || [];
                for (const goal of update[idx].goals!) {
                    if (goal.id === newGoal.id) {
                        found = true;
                        goal.label = newGoal.label;
                        goal.current = newGoal.current;
                        goal.goal = newGoal.goal;
                    }
                }
                if (!found) {
                    update[idx].goals!.push(JSON.parse(JSON.stringify(newGoal)));
                }
            }
        }
        await this.props.onHomerooms(update);
    }

    async onRemove(homeroomId: string, goalId: string) {
        let update: ApiHomeroomClub[] = JSON.parse(JSON.stringify(this.props.homerooms));
        for (let idx = 0; idx < update.length; ++idx) {
            if (update[idx]._id === homeroomId) {
                update[idx].goals = (update[idx].goals || []).filter(goal => goal.id !== goalId);
            }
        }
        await this.props.onHomerooms(update);
    }

    render() {
        if (this.props.homerooms) {
            this.props.homerooms.sort((a: ApiHomeroomClub, b: ApiHomeroomClub) => {
                let gradeA = parseInt(a.grade);
                let gradeB = parseInt(b.grade);
                if (a.grade === "K") gradeA = 0;
                if (a.grade === "PK") gradeA = -1;
                if (b.grade === "K") gradeB = 0;
                if (b.grade === "PK") gradeB = -1;
                if (a.grade === "NA") gradeA = 13;
                if (b.grade === "NA") gradeB = 13;
                if (gradeA !== gradeB) {
                    return gradeA - gradeB;
                } else if (!isHomeroom(a) && !isHomeroom(b)) {
                    return (a as ApiClub).clubName.localeCompare((b as ApiClub).clubName);
                } else {
                    return a.members.filter(m => m.title === "Teacher")[0].name.localeCompare(b.members.filter(m => m.title === "Teacher")[0].name);
                }
            })
        }

        const mobile = typeof this.props.expanded !== "undefined"

        let editableHomerooms = this.props.homerooms.filter((homeroom) => {
            return HomeroomService.canEdit(homeroom, this.props.user);
        });

        const canEditAny = HomeroomService.canEditAny(this.props.homerooms, this.props.user);
        const goalHomerooms = this.props.homerooms.filter(hr => hr.goals && hr.goals.length > 0);

        const sectionHeader = <div>
            <h2 style={{ marginRight: "1rem" }}>
                <Flag /><span style={{verticalAlign: "top", marginLeft: ".5rem"}}>Goals</span>
            </h2>
            <InfoButton style={{marginLeft: "-1rem", marginTop: "-1rem"}} title="Goals Info" showLogin={!this.props.user}>
                <InfoGoals />
            </InfoButton>
            {canEditAny && <>
                <Tooltip placement="top" title="Add goals">
                    <IconButton
                        color={mobile ? undefined : "primary"}
                        style={{ 
                            width: "inherit", 
                            float: "right", 
                            marginTop: mobile ? "0rem" : "-.8rem", 
                            marginBottom: mobile ? "0rem" : ".5rem" 
                        }}
                        onClick={(evt) => {
                            evt.stopPropagation();
                            this.setState({ dialogAdd: true });
                        }}
                        size="large"><Add className="homeIconButton" />
                    </IconButton>
                </Tooltip>
                <div style={{ clear: "both" }}></div>
            </>
            }
        </div>;

        let tileIdx = 0;
        const sectionBody = <>
            {goalHomerooms.length === 0 && <>
                <p>There are no goals set for your {Nls.get("SELECTOR_LABELS_LOWER")}. </p>
                <p>Goals are used to indicate progress toward fundraising or
                    other goals.
                </p>
            </>}
            <div className="hrpRow">
            {goalHomerooms.map(hr => (<>
                {hr.goals && hr.goals.length > 0 && hr.goals.map(goal => {
                    let odd = ++tileIdx % 2 === 0;
                    return <>
                        <div className="hrpCol-2-mob">
                            <div style={{
                                padding: "1rem", 
                                margin: odd ? "0rem -1rem 1rem -1rem" : "0rem 0rem 1rem -1rem", 
                                backgroundColor: "#f4f4f4",
                                border: "solid #999 1px",
                                fontSize: ".8rem",
                                position: "relative"
                            }}>
                                <div><strong>Grade {hr.grade}: <br/> {HomeroomService.hrClubName(hr)}</strong></div>
                                <div style={{marginTop: ".5rem"}} />
                                <div>{goal.label}</div>
                                <div>({goal.current} / {goal.goal}: {Math.round(goal.current / goal.goal * 100.0)}%)</div>
                                <LinearProgress sx={
                                    {
                                        height: "1rem",
                                        borderRadius: 5,
                                        width: "5rem",
                                        transform: "rotate(-90deg)",
                                        margin: "3rem 2rem 3rem -2rem",
                                        border: "solid #999 1px",
                                        colorPrimary: {
                                            backgroundColor: "#dcdcdc"
                                        },
                                        bar: {
                                            borderRadius: 5,
                                            backgroundColor: '#ed7777',
                                        }
                                    }}
                                    variant="determinate" value={Math.min(100, goal.current * 100 / goal.goal)} />
                                <div style={{position: "absolute", right: "1rem", bottom: "4.5rem"}}>
                                    {HomeroomService.canEdit(hr, this.props.user) &&
                                        <Tooltip placement="top" title="Edit">
                                            <IconButton
                                                aria-label="edit"
                                                onClick={async () => {
                                                    let temp = JSON.parse(JSON.stringify(goal));
                                                    temp.homeroomId = hr._id;
                                                    this.setState({ dialogEdit: temp });
                                                }}
                                                size="large">
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </div>
                                <div style={{position: "absolute", right: "1rem", bottom: "1rem"}}>
                                    {HomeroomService.canEdit(hr, this.props.user) &&
                                        <Tooltip placement="top" title="Delete">
                                            <IconButton
                                                aria-label="delete"
                                                onClick={async () => {
                                                    if (window.confirm(`Confirm deletion of goal ${goal.label}`)) {
                                                        await this.onRemove(hr._id, goal.id);
                                                    }
                                                }}
                                                size="large">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                        </div>
                    </>;
                })}
                {/* {hr.goals && hr.goals.length > 0 && <div style={{ marginBottom: "1rem" }}>
                    <strong>Grade {hr.grade} &mdash; {HomeroomService.hrClubName(hr)}</strong>
                </div>}
                {hr.goals && hr.goals.map(goal => (<div>
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: "80% 10" }}>
                            {goal.label} ({goal.current} / {goal.goal}: {Math.round(goal.current / goal.goal * 100.0)}%)
                            <div style={{ marginTop: ".5rem" }} />
                            <VerticalProgress variant="determinate" value={Math.min(100, goal.current * 100 / goal.goal)} />
                        </div>
                        <div style={{ flex: "10% 1" }}>{HomeroomService.canEdit(hr, this.props.user) &&
                            <Tooltip placement="top" title="Edit">
                                <IconButton aria-label="edit" onClick={async () => {
                                    let temp = JSON.parse(JSON.stringify(goal));
                                    temp.homeroomId = hr._id;
                                    this.setState({ dialogEdit: temp });
                                }}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        }</div>
                        <div style={{ flex: "10% 1" }}>{HomeroomService.canEdit(hr, this.props.user) &&
                            <Tooltip placement="top" title="Delete">
                                <IconButton aria-label="delete" onClick={async () => {
                                    if (window.confirm(`Confirm deletion of goal ${goal.label}`)) {
                                        await this.onRemove(hr._id, goal.id);
                                    }
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        }</div>
                    </div>
                </div>))} */}
            </>))}
            </div>
        </>;

        const sectionDialog = <>
            {canEditAny && <ModalGoalAddEdit
                open={this.state.dialogAdd || !!this.state.dialogEdit}
                onGoal={async (homeroomId: string, goal: ApiGoal) => {
                    await this.onGoal(homeroomId, goal);
                }}
                initValue={this.state.dialogEdit || undefined}
                onClose={() => { this.setState({ dialogAdd: false, dialogEdit: null }) }}
                homerooms={editableHomerooms}
                user={this.props.user}
            />}
        </>;

        if (goalHomerooms.length > 0 || canEditAny) {
            if (mobile) {
                return <Accordion
                    square
                    expanded={this.props.expanded}
                    onChange={() => {
                        this.props.onToggle!();
                    }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        {sectionHeader}
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ width: "100%", marginTop: "1rem" }}>
                            {sectionBody}
                        </div>
                    </AccordionDetails>
                    {sectionDialog}
                </Accordion>
            } else {
                return <>
                    <div>
                        {sectionHeader}
                        {sectionBody}
                    </div>
                    {sectionDialog}
                </>
            }
        } else {
            return <></>;
        }
    }
}