import React from 'react';
import { TextInput } from '@carbon/react';

interface NumericPatternInputProps {
    id: string
    labelText: string
    pattern: string
    helperText?: string
    initialValue?: string
    invalid?: boolean
    invalidText?: string
    onChange?: (newVal: string) => void
}

interface NumericPatternInputState {
    curVal: string
}

export default class NumericPatternInput extends React.Component<NumericPatternInputProps, NumericPatternInputState> {
    state : NumericPatternInputState = {
        curVal: this.props.initialValue || ""
    }

    render() {
        return <TextInput id={this.props.id}
            labelText={this.props.labelText}
            value={this.state.curVal}
            onChange={(evt) => {
                let newVal: string = evt.target.value;
                if (newVal.length < this.state.curVal.length) {
                    this.setState({ curVal: newVal });
                    this.props.onChange && this.props.onChange(newVal);
                } else {
                    let checkIdx = newVal.length - 1;
                    let goodStr: string = newVal.substring(0, checkIdx);
                    let newChar = newVal.charAt(checkIdx);
                    if (newChar < '0' || newChar > '9') {
                        // New character isn't a digit
                        if (newVal.length <= this.props.pattern.length 
                            && this.props.pattern.charAt(checkIdx) === newChar)
                        {
                            // Matches the pattern
                            this.setState({ curVal: newVal });
                            this.props.onChange && this.props.onChange(newVal);
                        }
                    } else {
                        // New character is a digit
                        let patternCheck = this.props.pattern.replace(/[0-9a-zA-Z]/g, "0");
                        while (checkIdx < patternCheck.length && patternCheck.charAt(checkIdx) !== "0") {
                            goodStr += patternCheck.charAt(checkIdx++);
                        }
                        if (checkIdx >= patternCheck.length) {
                            if (goodStr !== this.state.curVal) {
                                // Update the string with the missing non-numeric values
                                this.setState({ curVal: goodStr });
                                this.props.onChange && this.props.onChange(goodStr);
                            }
                        } else {
                            if (patternCheck.charAt(checkIdx) === "0") {
                                goodStr += newChar;
                                ++checkIdx;
                                while (checkIdx < patternCheck.length && patternCheck.charAt(checkIdx) !== "0") {
                                    goodStr += patternCheck.charAt(checkIdx++);
                                }        
                                if (goodStr !== this.state.curVal) {
                                    // Update the string with the missing non-numeric values
                                    this.setState({ curVal: goodStr });
                                    this.props.onChange && this.props.onChange(goodStr);
                                }
                            }
                        }
                    }
                }
            }}
            helperText={this.props.helperText}
            invalid={this.props.invalid}
            invalidText={this.props.invalidText}
        />
    }
}