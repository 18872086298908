import React from 'react';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbSkeleton,
    Button,
    Column,
    Dropdown,
    Grid,
    TextInput,
    Layer,
    Link,
    MultiSelect,
    Tile,
    ListItem,
    UnorderedList
} from "@carbon/react";
import { Edit } from "@carbon/react/icons";
import { LocationV1, IPTLocation } from '../../services/LocationV1';
import NumericPatternInput from '../../../../components/NumericPatternInput';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { AccountV1, IPTAccount } from '../../services/AccountV1';

interface Fields {
    name: string,
    urlname: string,
    phone: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    email: string,
    event_types: string[]
}

export interface LocationFieldsProps {
    initialState?: Partial<Fields>
    accountId: string
    editing: boolean
    submitLabel: string
    onSubmit: (loc: Partial<IPTLocation>) => Promise<void>
    onCancel?: () => void
}

export interface LocationFieldsState extends Fields {
    conflictName: boolean
    conflictUrl: boolean
    invalid: boolean
    editing: boolean
}

const us_states = [
    { value: "AK", label: "Alaska" },
    { value: "AL", label: "Alabama" },
    { value: "AR", label: "Arkansas" },
    { value: "AZ", label: "Arizona" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "IA", label: "Iowa" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "MA", label: "Massachusetts" },
    { value: "MD", label: "Maryland" },
    { value: "ME", label: "Maine" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MO", label: "Missouri" },
    { value: "MS", label: "Mississippi" },
    { value: "MT", label: "Montana" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "NE", label: "Nebraska" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NV", label: "Nevada" },
    { value: "NY", label: "New York" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VA", label: "Virginia" },
    { value: "VT", label: "Vermont" },
    { value: "WA", label: "Washington" },
    { value: "WI", label: "Wisconsin" },
    { value: "WV", label: "West Virginia" },
    { value: "WY", label: "Wyoming" }
]

export class LocationFields extends React.Component<LocationFieldsProps, LocationFieldsState> {
    private static FIELD_KEYS = [
        "name", "urlname", "address", "city", "state", "zip", "phone", "event_types"
    ]
    state: LocationFieldsState = {
        name: this.props.initialState?.name ?? "",
        urlname: this.props.initialState?.urlname ?? "",
        address: this.props.initialState?.address ?? "",
        city: this.props.initialState?.city ?? "",
        state: this.props.initialState?.state ?? "",
        zip: this.props.initialState?.zip ?? "",
        phone: this.props.initialState?.phone ?? "",
        email: this.props.initialState?.email ?? "",
        event_types: this.props.initialState?.event_types ?? [],
        conflictName: false,
        conflictUrl: false,
        invalid: false,
        editing: this.props.editing
    }

    componentDidMount(): void {
        if (this.state.editing) {
            setTimeout(() => { document.getElementById("name")?.focus(); }, 0);
        }
    }
    componentDidUpdate(prevProps: Readonly<LocationFieldsProps>, prevState: Readonly<LocationFieldsState>, snapshot?: any): void {
        if (prevState.editing === false && this.state.editing === true) {
            setTimeout(() => { document.getElementById("name")?.focus(); }, 0);
        }
    }

    async onSubmit() {
        let newLocation: Fields & { id: string, account_id: string } = {
            ...this.props.initialState,
            id: "",
            account_id: this.props.accountId,
            name: this.state.name,
            urlname: this.state.urlname,
            phone: this.state.phone,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            email: this.state.email,
            event_types: this.state.event_types
        }
        if (newLocation.name.length === 0
            || newLocation.urlname.length === 0
            || (newLocation.phone.length > 0 && newLocation.phone.length < 13)) {
            this.setState({ invalid: true });
        } else {
            try {
                await this.props.onSubmit(newLocation);
            } catch (err) {
                if ((err as Error).message === "name_conflict") {
                    this.setState({ conflictName: true })
                } else if ((err as Error).message === "url_conflict") {
                    this.setState({ conflictUrl: true })
                }
            }
        }
    }

    render() {
        if (!this.state.editing) {
            return <>
                <Tile>
                    <div className="bodyText">
                        <div><strong>Location name</strong>: {this.state.name}</div>
                        <div><strong>URL part</strong>: {this.state.urlname}</div>
                        <div><strong>Phone</strong>: {this.state.phone}</div>
                        <div><strong>Email</strong>: {this.state.email}</div>
                        <div style={{ marginTop: "1rem" }} />
                        <div><strong>Address</strong>:</div>
                        <div>{this.state.address}</div>
                        <div>{this.state.city}{this.state.city ? ", " : ""} {this.state.state} {this.state.zip}</div>
                        <div>Location event types: </div>
                        <UnorderedList>
                            {(this.state.event_types || []).map(type => (
                                <ListItem key={type}>{type}</ListItem>
                            ))}
                        </UnorderedList>
                    </div>
                    <div style={{ marginTop: "1rem" }} />
                    <Link
                        kind="tertiary"
                        renderIcon={Edit}
                        onClick={() => {
                            this.setState({ editing: true });
                        }}>Edit location details</Link>
                </Tile>
            </>
        } else {
            let disableButton = this.state.name.length === 0
                || this.state.urlname.length === 0
                || (this.state.zip.length !== 5 && this.state.zip.length !== 10);
            if (this.props.initialState && !disableButton) {
                let changed = LocationFields.FIELD_KEYS.filter(key => JSON.stringify(this.state[key]) !== JSON.stringify(this.props.initialState![key]));
                disableButton = changed.length === 0;
            }

            return <Tile><form onSubmit={(evt) => { evt.preventDefault(); }} onKeyDown={(evt) => {
                if (evt.code === "Escape") {
                    this.props.onCancel?.();
                    if (!this.props.editing) {
                        this.setState({
                            name: this.props.initialState?.name ?? "",
                            urlname: this.props.initialState?.urlname ?? "",
                            phone: this.props.initialState?.phone ?? "",
                            editing: false
                        });
                    }
                }
            }}>
                <Layer>
                    <TextInput id="name"
                        data-modal-primary-focus
                        labelText="Location name"
                        value={this.state.name}
                        onChange={(evt) => {
                            this.setState({ conflictName: false, name: evt.target.value })
                        }}
                        invalid={this.state.conflictName || (this.state.invalid && this.state.name.length === 0)}
                        invalidText={this.state.conflictName ? "Location name conflicts with existing location" : "Location name is required"}
                    />
                    <div style={{ marginTop: ".75rem" }} />
                    <TextInput id="urlname"
                        labelText="URL part"
                        value={this.state.urlname}
                        // helperText="URL part must only be letters or numbers"
                        onChange={(evt) => {
                            let cleanVal = evt.target.value.toLowerCase().replace(/[^a-z0-9-_]/g, "");
                            this.setState({ conflictUrl: false, urlname: cleanVal })
                        }}
                        invalid={this.state.conflictUrl || (this.state.invalid && this.state.urlname.length === 0)}
                        invalidText={this.state.conflictUrl ? "URL part conflicts with existing account" : "URL part is required"}
                    />
                    <div style={{ marginTop: ".75rem" }} />
                    <TextInput id="address"
                        labelText="Address (optional)"
                        value={this.state.address}
                        // helperText="URL part must only be letters or numbers"
                        onChange={(evt) => {
                            let cleanVal = evt.target.value;
                            this.setState({ address: cleanVal })
                        }}
                    />
                    <div style={{ marginTop: ".75rem" }} />
                    <TextInput id="city"
                        labelText="City (optional)"
                        value={this.state.city}
                        // helperText="URL part must only be letters or numbers"
                        onChange={(evt) => {
                            let cleanVal = evt.target.value;
                            this.setState({ city: cleanVal })
                        }}
                    />
                    <div style={{ marginTop: ".75rem" }} />
                    <Grid>
                        <Column sm={2} md={2} lg={3}>
                            <Dropdown id="state"
                                titleText="State"
                                initialSelectedItem={us_states.find(state => state.value === this.state.state) ?? us_states[1]}
                                label="State"
                                items={us_states}
                                itemToString={item => item ? item.label : ''}
                                onChange={(evt) => {
                                    let cleanVal = evt.selectedItem.value;
                                    this.setState({ state: cleanVal })
                                }}
                            />
                        </Column>
                        <Column sm={2} md={2} lg={3}>
                            <TextInput id="zip"
                                labelText="Zip code"
                                value={this.state.zip}
                                // helperText="URL part must only be letters or numbers"
                                onChange={(evt) => {
                                    let cleanVal = evt.target.value;
                                    this.setState({ zip: cleanVal })
                                }}
                            />
                        </Column>
                    </Grid>
                    <div style={{ marginTop: ".75rem" }} />
                    <NumericPatternInput
                        id="phone"
                        labelText="Phone (optional)"
                        pattern="(000)000-0000"
                        helperText="(000)000-0000"
                        initialValue={this.state.phone}
                        onChange={(newVal: string) => {
                            this.setState({ phone: newVal });
                        }}
                        invalid={this.state.invalid && this.state.phone.length > 0 && this.state.phone.length < 13}
                        invalidText="Phone number is invalid"
                    />
                    <div style={{ marginTop: ".75rem" }} />
                    <MultiSelect
                        label={this.state.event_types.join(", ")}
                        id="event_type_selector"
                        titleText="Event types"
                        initialSelectedItems={this.state.event_types}
                        items={[
                            "Kid party"
                            , "Adult party"
                            , "Wedding"
                            , "Anniversary"
                            , "Work party"
                            , "Other"
                        ]}
                        itemToString={item => item ?? ''}
                        selectionFeedback="fixed"
                        helperText="Types of events that would be appropriate at this location"
                        onChange={(evt: { selectedItems: string[] }) => {
                            this.setState({ event_types: evt.selectedItems });
                        }}
                    />
                    <div style={{ marginTop: "1rem" }} />
                </Layer>
                <Button
                    type="submit"
                    disabled={disableButton}
                    onClick={() => {
                        this.onSubmit();
                        if (!this.props.editing) {
                            this.setState({ editing: false });
                        }
                    }}>{this.props.submitLabel}</Button>
                {(this.props.onCancel || !this.props.editing) && <Button style={{ marginLeft: "1rem" }} kind="secondary" onClick={() => {
                    this.props.onCancel?.();
                    if (!this.props.editing) {
                        this.setState({
                            name: this.props.initialState?.name ?? "",
                            urlname: this.props.initialState?.urlname ?? "",
                            phone: this.props.initialState?.phone ?? "",
                            editing: false
                        });
                    }
                }}>Cancel</Button>}
            </form></Tile>;
        }
    }
}

interface NewLocationState {
    account?: IPTAccount
}

class NewLocationCls extends React.Component<{ accountId: string, navigate: NavigateFunction }, NewLocationState> {
    state: NewLocationState = {
    }

    componentDidMount(): void {
        (async () => {
            let account = await AccountV1.getAccount(this.props.accountId);
            this.setState({ account })
        })();
    }

    async create(newLocation: Partial<IPTLocation>) {
        newLocation.account_id = this.props.accountId;
        let createdLocation = await LocationV1.createLocation(newLocation);
        this.props.navigate(`/account/home/${createdLocation.account_id}/location/home/${createdLocation.id}`);
    }

    async cancel() {
        this.props.navigate("/account/accounts");
    }

    render() {
        return (
            <>
                <div style={{ marginTop: "3rem" }} />
                <Grid>
                    <Column sm={4} md={8} lg={16}>
                        {!this.state.account && <BreadcrumbSkeleton />}
                        {this.state.account && <Breadcrumb noTrailingSlash>
                            <BreadcrumbItem href="/account/accounts">Accounts</BreadcrumbItem>
                            <BreadcrumbItem href={`/account/account/home/${this.state.account.id}`}>{this.state.account.name}</BreadcrumbItem>
                            <BreadcrumbItem href={`/account/home/${this.state.account.id}/new_location`} isCurrentPage>
                                New Location
                            </BreadcrumbItem>
                        </Breadcrumb>}
                    </Column>
                </Grid>
                <div style={{ marginTop: "1rem" }} />
                <main className="NewLocation">
                    <Grid>
                        <Column sm={4} md={6} lg={8}>
                            <h1>New location</h1>
                            <div style={{ marginTop: "2rem" }} />
                            <LocationFields
                                accountId={this.props.accountId}
                                editing={true}
                                submitLabel="Create location"
                                onSubmit={async (newLocation: Partial<IPTLocation>) => {
                                    await this.create(newLocation);
                                }}
                                onCancel={() => {
                                    this.cancel();
                                }} />
                        </Column>
                    </Grid>
                </main>
            </>)
    }
}

export default function NewLocation() {
    let navigate = useNavigate();
    let params = useParams();
    let accountId: string = params["id"]!;
    return <NewLocationCls accountId={accountId} navigate={navigate} />
}