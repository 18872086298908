import React from 'react';
import { ApiUser } from '../../../../../../../server/src/hrpcb/models/apiUser';
import "./AuthFavorites.scss";
import { Button, CircularProgress } from '@mui/material';
import { ePageProfile } from '../../../App';
import { DBFavorites } from '../../../../../../../server/src/hrpcb/models/dbUser';
import { HRPButton } from '../../../components/widgets/Customized';
import { ApiHomeroomClub } from '../../../../../../../server/src/hrpcb/models/apiHomeroom';
import Nls from '../../../nls/nls';
import { Theme, useTheme } from '@mui/system';
import { NavigateFunction, useNavigate } from 'react-router-dom';

interface AuthFavoritesUserProps {
    user?: ApiUser | null
    homerooms?: ApiHomeroomClub[]
    teacherId: string
    pageProfile: ePageProfile
}

class AuthFavoritesUserClass extends React.Component<AuthFavoritesUserProps & { theme: Theme, navigate: NavigateFunction }> {

    render() {
        let favs : DBFavorites | null = null;
        let teacherName = "";
        if (this.props.homerooms) {
            for (const hr of this.props.homerooms) {
                for (const member of hr.members) {
                    if (member.userId === this.props.teacherId && member.favorites) {
                        favs = member.favorites;
                        teacherName = member.name;
                    }
                }
            }
        }
        const field = (label: string, field: string) => (
            <>
                <div style={{marginTop: ".8rem"}} />
                <span style={{color: "#161616", fontWeight: 300}}>{label}</span>: <span style={{marginLeft: ".25rem"}}>{(favs as any)[field]} </span>
            </>
        );

        let form = <></>
        if (favs) {
            form = <form noValidate autoComplete="off">
                <div>
                    {field("Colors", "color")}
                    {field("Flowers/Plants", "flower")}
                    {field("Candy/Snacks", "candy")}
                    {field("Drinks", "drink")}
                    {field("Desserts", "dessert")}
                    {field("Places to eat", "restaurant")}
                    {field("Places to shop", "store")}
                    {field("Scent", "scent")}
                    {field("Activities/Hobbies", "hobby")}
                    {field("Lunch", "lunch")}
                    {field("Dinner", "dinner")}
                    {field("Way to Relax", "relax")}
                    {field("Gift cards", "giftcard")}
                    {field("Gift you have received", "receivedgift")}
                    {field("Supplies for your classroom", "supplies")}
                    {field("Do you have any allergies?", "allergies")}
                </div>
                <div style={{ marginTop: "2rem" }}></div>
                <Button sx={HRPButton} variant="contained" color="primary" onClick={() => {
                    this.props.navigate(this.props.user?`/auth/${Nls.get("URL_PART")}`:`/`)
                }}>Done</Button>
            </form>;
        }
        let demo = this.props.homerooms 
            && this.props.homerooms.length === 1 
            && this.props.homerooms[0]._id
        return <>
            <div style={{ marginTop: "1rem" }} />
            { demo &&  <div style={{
                backgroundColor: this.props.theme.palette.header.faded,
                border: `solid ${this.props.theme.palette.header.main} 1px`,
                marginTop: "1rem",
                marginBottom: "0rem",
                padding: "1rem"}}
            >
                The favorites listed below is an example. <a
                    href={`/login`}>Login</a> to create your own {Nls.get("SELECTOR_LABEL_LOWER")}. It's free!
            </div>}
            <div className="hrpRow">
                <div className="hrpCol-4-mob">
                    <h1>{teacherName}'s Favorite...</h1>
                </div>
            </div>
            {/******************** Homeroom Selector ****************************************/}
            {(!favs &&
                <CircularProgress />
            )
            || (this.props.pageProfile === "mobile" && <div className="mobileHome">
                <div className="hrpRow">
                    <div className="hrpCol-4-mob">
                        {form}
                    </div>
                </div>
            </div>)
            || <>
                <div className="hrpRow">
                    <div className="hrpCol-6-tab hrpCol-10-desk">
                        {form}
                    </div>
                </div>
            </>}
        </>
    }
}

// Wrap and export
export default function AuthFavoritesUser(props:AuthFavoritesUserProps) {
    const theme = useTheme();
    const navigate = useNavigate();
    return <AuthFavoritesUserClass {...props} theme={theme} navigate={navigate} />;
}